import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { getPhysicianAppointmentId } from 'actions/patientPhysicianActions'
import { getInstallBillDetails, getPayActivity } from 'actions/patientActions'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'
import EnterPatientAccountNumberMobile from './EnterPatientAccountNumberMobile'
import PaidBillDetails from './PaidBillDetails'
import RemaningBalanceBillDetails from './RemaningBalanceBillDetails'
import { CgChevronLeft } from 'react-icons/cg'
import PhysicianProfileMobile from './PhysicianProfileMobile'
import SuccessAlert from 'shared/SuccessAlert'
import { getTransferFundsData } from 'actions/patientBankingActions'
import PatientPayMethod from '../account/mobile/PatientPayMethod'
import moment from 'moment'
import { dateFormat } from 'constants/Constant'
import PatientPaymentMobile from '../account/mobile/PatientPaymentMobile'

// import SelectPaymentMethods from '../physician/SelectPaymentMethods';

function PhysicianBillRecordMobile(props) {
    const {
        physicianDetails,
        patientId,
        handleCloseBillRecordPage,
        physicianIds,
        globalPayment,
    } = props
    const dispatch = useDispatch()
    const [openAccountNumberPage, setAccountNumberPage] = useState(false)
    const [billPaidPage, setBillPaidPage] = useState(false)
    const [remaningBalancePage, setRemaningBalancePage] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [billPaidPageDetails, setBillPaidPageDetails] = useState({})
    const [remaningBalancePageDetails, setremaningBalancePageDetails] =
        useState({})
    const [openPhysicianProfile, setOpenPhysicianProfile] = useState(false)
    const [payMethodPage, setPayMethodPage] = useState(false)
    const patientAppointmentId = useSelector(
        (state) => state.patientAppointmentId
    )
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const patientCardListing = useSelector((state) => state.patientCardListing)
    // const paymentActivityData = useSelector(state => state.paymentActivityData)
    const transferFundData = useSelector((state) => state.transferFundData)
    const [paymentActivityData, setPaymentActivityData] = useState([])
    const [totalBalance, setTotalBalance] = useState('')
    const [isEmptyList, setIsEmptyList] = useState(false)
    // const [ paymentOption, setPaymentOption ] = useState(false);
    // const [
    //     openRemaningPayDetailsModalDetails,
    //     setOpenRemaningPayDetailsModalDetails,
    // ] = useState({});
    useEffect(() => {
        dispatch(getPhysicianAppointmentId(physicianDetails.physician_id))
        dispatch(getTransferFundsData())
        dispatch(getPayActivity(patientId, physicianDetails.physician_id)).then(
            (res) => {
                setPaymentActivityData(res && res)
                setTotalBalance(res && res.total_balance)
            }
        )
    }, [''])
    const openPatientAccountNumberPage = () => {
        if (physicianDetails && physicianDetails.stripe_account_id) {
            if (
                physicianIds.includes(
                    physicianDetails && physicianDetails.physician_id
                )
            ) {
                setSuccessAlertMessage(
                    'Your previous arrangement is still under review'
                )
                setSuccessAlert(true)
            } else {
                setAccountNumberPage(!openAccountNumberPage)
            }
        } else {
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
            setSuccessAlert(true)
        }
    }
    const closePatientAccountNumber = () => {
        setAccountNumberPage(!openAccountNumberPage)
    }
    const handleBillPaidFunc = (data) => {
        dispatch(
            getInstallBillDetails(
                data.patient_payment_id,
                physicianDetails.physician_id
            )
        )
        setBillPaidPage(!billPaidPage)
        setBillPaidPageDetails(data)
    }
    const handleCloseBillPaidFunc = () => {
        setBillPaidPage(!billPaidPage)
    }
    const hanldeRemaninigBalanceFunc = (data) => {
        if (physicianDetails && physicianDetails.stripe_account_id) {
            dispatch(
                getInstallBillDetails(
                    data.patient_payment_id,
                    physicianDetails.physician_id
                )
            )
            setRemaningBalancePage(!remaningBalancePage)
            setremaningBalancePageDetails(data)
            // setOpenRemaningPayModal(!openRemaningPayModal)
            // setOpenRemaningPayDetailsModalDetails(data)
        } else {
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
            setSuccessAlert(true)
        }
    }
    const handlePayMethodPage = () => {
        setTimeout(() => {
            if (Object.keys(patientCardListing).length === 0) {
                setIsEmptyList(!isEmptyList)
            } else {
                setPayMethodPage(!payMethodPage)
                setIsEmptyList(false)
            }
        }, 500)
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
        setSuccessAlertMessage('')
    }
    const handleCloseRemaningBalanceFunc = () => {
        setRemaningBalancePage(!remaningBalancePage)
    }
    const handleOpenProfile = () => {
        setOpenPhysicianProfile(!openPhysicianProfile)
    }
    const closePhysicianProfile = () => {
        setOpenPhysicianProfile(!openPhysicianProfile)
    }
    // const closePaymentMethod = () => {
    //     setPaymentOption(!paymentOption)
    // }
    const goRemainingBalancePageFunc = () => {
        // setIsEmptyList(!isEmptyList)
        setIsEmptyList(false)
    }
    const goToPaymentMethodPageFromPastDueFunc = () => {
        setPayMethodPage(!payMethodPage)
    }
    const goTotalBalancePageFunc = () => {
        setIsEmptyList(false)
    }
    const installBillDetails = useSelector(
        (state) =>
            state.installBillDetails &&
            state.installBillDetails.remaining_balance
    )
    return (
        <>
            {!openAccountNumberPage &&
                !billPaidPage &&
                !remaningBalancePage &&
                !openPhysicianProfile &&
                !payMethodPage &&
                !isEmptyList && (
                    <div className="remaining_balance_page">
                        <div className="device-height">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center mobile_account_info">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => handleCloseBillRecordPage()}
                                />
                                <label className="nav-heading">
                                    {(physicianDetails.physician_full_name &&
                                        'Dr.' +
                                            physicianDetails.physician_full_name.split(
                                                '.'
                                            )[1]) ||
                                        physicianDetails.physician_name}
                                </label>
                                <BsThreeDots
                                    style={{ zIndex: 1 }}
                                    className="color_dark_grey mr-2 font20"
                                    onClick={() => handleOpenProfile()}
                                />
                            </nav>
                            <div className="content">
                                <div className="text-center mt-5">
                                    <span className="balance-label">
                                        Remaining Balance
                                    </span>
                                    {isModalLoading && <ModalLoader />}
                                    {!isModalLoading && totalBalance !== '' && (
                                        <div>
                                            <h3 className="balance">
                                                {' '}
                                                {currencyFormatter.format(
                                                    totalBalance || ''
                                                )}{' '}
                                                <MdChevronRight
                                                    onClick={() =>
                                                        handlePayMethodPage()
                                                    }
                                                    className="remainingAmountBtn"
                                                />
                                            </h3>
                                        </div>
                                    )}
                                </div>
                                <div className="tab_section">
                                    <ul
                                        className="nav nav-tabs"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="current-tab"
                                                data-toggle="tab"
                                                href="#current"
                                                role="tab"
                                                aria-controls="current"
                                                aria-selected="true"
                                            >
                                                Current
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="history-tab"
                                                data-toggle="tab"
                                                href="#history"
                                                role="tab"
                                                aria-controls="history"
                                                aria-selected="false"
                                            >
                                                History
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="tab-content overflowAuto"
                                        id="myTabContent"
                                        style={{
                                            height: '47.4vh',
                                            maxHeight: '47.4vh',
                                        }}
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="current"
                                            role="tabpanel"
                                            aria-labelledby="current-tab"
                                        >
                                            {isModalLoading && <ModalLoader />}
                                            {!isModalLoading &&
                                                paymentActivityData &&
                                                paymentActivityData.current &&
                                                paymentActivityData.current.map(
                                                    (data, id) => {
                                                        return (
                                                            <div
                                                                className="row no-gutters"
                                                                key={id}
                                                                onClick={() =>
                                                                    hanldeRemaninigBalanceFunc(
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="tab-lite-data">
                                                                            {' '}
                                                                            Due{' '}
                                                                            <span className="tab-bold-data">
                                                                                {data &&
                                                                                    moment(
                                                                                        data.due_amount_date
                                                                                    ).format(
                                                                                        dateFormat
                                                                                    )}{' '}
                                                                            </span>
                                                                        </span>
                                                                        <span className="tab-lite-data">
                                                                            {' '}
                                                                            Remaining
                                                                            amount{' '}
                                                                            <span className="tab-bold-data">
                                                                                {' '}
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount
                                                                                )}{' '}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-sm-5 right_row_padding">
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className="d-flex flex-column text-right">
                                                                            <span className="tab-bold-data d-flex space-4">
                                                                                <span>
                                                                                    {currencyFormatter.format(
                                                                                        data.days_past_due ||
                                                                                            0
                                                                                    )}
                                                                                </span>
                                                                                {Boolean(
                                                                                    data?.late_fee
                                                                                ) && (
                                                                                    <span className="error">{` (+${currencyFormatter.format(
                                                                                        data.late_fee ||
                                                                                            0
                                                                                    )})`}</span>
                                                                                )}
                                                                            </span>
                                                                            <span className="color_alert tab-superlite-data">
                                                                                {data.payment_left
                                                                                    ? data.payment_left
                                                                                    : data.days_past_count}
                                                                            </span>
                                                                        </div>
                                                                        <MdChevronRight
                                                                            className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer"
                                                                            onClick={() =>
                                                                                hanldeRemaninigBalanceFunc(
                                                                                    data
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            {paymentActivityData &&
                                                paymentActivityData.current &&
                                                paymentActivityData.current
                                                    .length === 0 && (
                                                    <p className="no-items">
                                                        You don’t have any
                                                        active bills with{' '}
                                                        {physicianDetails.physician_name ||
                                                            (physicianDetails.physician_full_name &&
                                                                physicianDetails.physician_full_name)}
                                                    </p>
                                                )}
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="history"
                                            role="tabpanel"
                                            aria-labelledby="history-tab"
                                        >
                                            {isModalLoading && <ModalLoader />}
                                            {!isModalLoading &&
                                                paymentActivityData &&
                                                paymentActivityData.history &&
                                                paymentActivityData.history.map(
                                                    (data, id) => {
                                                        return (
                                                            <div
                                                                className="row no-gutters"
                                                                key={id}
                                                                onClick={() =>
                                                                    handleBillPaidFunc(
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="tab-lite-data">
                                                                            Amount{' '}
                                                                            <span className="tab-bold-data">
                                                                                {currencyFormatter.format(
                                                                                    data.paid_amount
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        <span className="tab-superlite-data">
                                                                            Final
                                                                            payment
                                                                            date{' '}
                                                                            <span className="tab-lighterbold-data">
                                                                                {data &&
                                                                                    moment(
                                                                                        data.payment_date
                                                                                    ).format(
                                                                                        dateFormat
                                                                                    )}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-sm-5 right_row_padding d-flex align-items-center justify-content-end">
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className="d-flex flex-column text-right">
                                                                            <span className="bold-text">
                                                                                {' '}
                                                                                {
                                                                                    data.payment_status
                                                                                }{' '}
                                                                            </span>
                                                                        </div>
                                                                        <MdChevronRight
                                                                            className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer"
                                                                            onClick={() =>
                                                                                handleBillPaidFunc(
                                                                                    data
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 mt-auto">
                                <button
                                    className="btn mobile-green-btn w-100"
                                    onClick={() =>
                                        openPatientAccountNumberPage()
                                    }
                                >
                                    New Bill
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            {openAccountNumberPage && (
                <EnterPatientAccountNumberMobile
                    openAccountNumber={openAccountNumberPage}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
            {billPaidPage && (
                <PaidBillDetails
                    isOpen={billPaidPage}
                    handleCloseBillPaidFunc={handleCloseBillPaidFunc}
                    billPaidPageDetails={billPaidPageDetails}
                    history={props.history}
                    physicianDetails={physicianDetails}
                />
            )}
            {!payMethodPage && remaningBalancePage && (
                <RemaningBalanceBillDetails
                    billPaidPage={billPaidPage}
                    handleCloseRemaningBalanceFunc={
                        handleCloseRemaningBalanceFunc
                    }
                    remaningBalancePageDetails={remaningBalancePageDetails}
                    history={props.history}
                    physicianDetails={physicianDetails}
                />
            )}
            {openPhysicianProfile && (
                <PhysicianProfileMobile
                    isOpen={openPhysicianProfile}
                    closePhysicianProfile={closePhysicianProfile}
                    patientAppointmentId={patientAppointmentId}
                    history={props.history}
                    physicianDetails={physicianDetails}
                    patientId={patientId}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
            {/* {!remaningBalancePage && paymentOption && <SelectPaymentMethods isOpen = { paymentOption } closePaymentMethod={ closePaymentMethod } physicianDetails={ physicianDetails }  openRemaningPayDetailsModalDetails={ openRemaningPayDetailsModalDetails } history={ history } installBillDetails = { installBillDetails } fullPayment = { true } paymentActivityData = { paymentActivityData } />} */}
            {payMethodPage && (
                <PatientPayMethod
                    payMethodPage={payMethodPage}
                    handlePayMethodPage={handlePayMethodPage}
                    history={props.history}
                    individualPayKey="individualPayKey"
                    installmentDetails={installBillDetails}
                    physicianDetails={physicianDetails}
                    remaningBalancePageDetails={remaningBalancePageDetails}
                    transferFundData={transferFundData}
                    fullPayment={true}
                    paymentActivityData={paymentActivityData}
                    isRemainingPage={true}
                    globalPayment={globalPayment}
                />
            )}
            {isEmptyList && (
                <PatientPaymentMobile
                    remainingBalancePage={'yes'}
                    goRemainingBalancePageFunc={goRemainingBalancePageFunc}
                    goToPaymentMethodPageFromPastDueFunc={
                        goToPaymentMethodPageFromPastDueFunc
                    }
                    goTotalBalancePageFunc={goTotalBalancePageFunc}
                />
            )}
        </>
    )
}

PhysicianBillRecordMobile.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    closeBillRecordModal: PropTypes.func,
    physicianDetails: PropTypes.object,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    history: PropTypes.object,
    // transferFundData: PropTypes.object,
    physicianIds: PropTypes.array,
    handleCloseBillRecordPage: PropTypes.func,
    globalPayment: PropTypes.bool,
}

export default PhysicianBillRecordMobile
