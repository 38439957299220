// import CryptoJS from 'crypto-js'
// import { ENCRYPT_KEY } from '../constants/common'

// export const getUserDetails = () => {
//     let userDetails = sessionStorage.getItem('accessToken');
//     userDetails = userDetails ? JSON.parse(userDetails) : '';
//     if (userDetails && userDetails.token) {
//         return userDetails;
//     }
//     return '';
// }

// export const commonLogoutFunc = (history) => {
//     sessionStorage.removeItem('accessToken');
//     return history.push('/login')
// }

export const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
        )
    }
}
export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
}

export const toOrdinalSuffix = (num) => {
    const int = parseInt(num),
        digits = [int % 10, int % 100],
        ordinals = ['st', 'nd', 'rd', 'th'],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? int + ordinals[digits[0] - 1]
        : int + ordinals[3]
}

export const titleCapatilize = (str) => {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
}

export const onlyNumber = (evt) => {
    // eslint-disable-next-line no-extra-semi
    ;['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
}
export const addDateFunc = (days) => {
    return new Date(new Date().getTime() + parseInt(days) * 24 * 60 * 60 * 1000)
}
export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
export const numberWithCommas = (x) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// formatter.format(2500); /* $2,500.00 */

export const numberFormatter = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'G'
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M'
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K'
    }
    if (num >= 0) {
        if (num < 999) {
            return currencyFormatter.format(num)
        }
    }
    return num
}
export const fullNameFormatter = (data) => {
    return data.first_name + ' ' + data.last_name
}
export const bolderNameFormatter = (data) => {
    return data.last_name + ',' + ' ' + data.first_name
}

export const sumOfArray = (data) => {
    return data
        ? data.reduce((a, b) => a + b, 0)
        : [].reduce((a, b) => a + b, 0)
}

export const encryptFunc = (data) => {
    //return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPT_KEY).toString()
    return data
}

export const decryptFunc = (data) => {
    // var bytes = CryptoJS.AES.decrypt(data, ENCRYPT_KEY)
    // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    // return decryptedData
    return data
}

export const calculateTransactionFee = (originalFee) => {
    if (!originalFee) {
        return 0
    }

    return parseFloat((originalFee * 0.039 + 0.99).toFixed(2)) //3.9% + 0.99
}

export const calculatePayoutFee = (originalFee) => {
    if (!originalFee) {
        return 0
    }

    return originalFee * 0.0025 + 0.25 //0.25% + $0.25
}
