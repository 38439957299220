import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { addPhysicianData } from 'actions/patientPhysicianActions'
import ModalLoader from 'shared/ModalLoader'
import SuccessAlert from 'shared/SuccessAlert'
const AddPhysicianMobile = (props) => {
    const dispatch = useDispatch()
    const [successAlert, setSuccessAlert] = useState(false)
    const physicianDetails = useSelector((state) => state.physicianDetails)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const addPhysicianFunc = () => {
        const data = {
            physician_id: physicianDetails.physician_id,
            access_code: physicianDetails.access_code,
        }
        dispatch(addPhysicianData(data)).then((res) => {
            if (res.statusCode === 200) {
                setSuccessAlert(!successAlert)
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
        props.history.push('/home')
    }
    const handleBack = () => {
        props.history.push('/home')
    }
    return (
        <>
            <div className="add-physician-page">
                <div id="content">
                    <button
                        type="button"
                        className="close cross-icon"
                        onClick={handleBack}
                        // onClick={ ()=> props.history.push('/home') }
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="d-flex justify-content-center flex-column doctor-info-wrapper">
                        <div className="profile-wrapper">
                            <Avatar
                                name={
                                    physicianDetails.full_name &&
                                    physicianDetails.full_name.split('.')[1]
                                }
                                className="profile_image"
                                size="100"
                                round={true}
                                src={physicianDetails.physician_profile_pic}
                            />
                        </div>
                        {isModalLoading && <ModalLoader />}
                        {!isModalLoading && (
                            <div>
                                <h3 className="doctor-name">
                                    {physicianDetails.full_name}
                                </h3>
                                <div className="d-flex justify-content-center flex-column">
                                    <label className="doctor-info mb-2">
                                        {physicianDetails.speciality}
                                    </label>
                                    <span className="doctor-info doctor-address mx-auto mb-3">
                                        {physicianDetails.office_address &&
                                            physicianDetails
                                                .office_address[0] &&
                                            physicianDetails.office_address[0]
                                                .address1}
                                    </span>
                                    <span className="doctor-info">
                                        {physicianDetails.phone_number}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="px-3 pb-3">
                    <button
                        className="btn mobile-green-btn"
                        onClick={addPhysicianFunc}
                    >
                        Add Physician
                    </button>
                </div>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="The Physician has been added."
                />
            )}
        </>
    )
}
AddPhysicianMobile.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    addPhysician: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}
export default AddPhysicianMobile
