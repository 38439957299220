import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FundTransfer from './FundTransfer'
import { CgChevronLeft } from 'react-icons/cg'
import { currencyFormatter } from 'utils/helper'
const PaymentTransfer = (props) => {
    const {
        isOpen,
        closePaymentTransfer,
        totalAmount,
        availableToPayOutAmount,
    } = props
    const [fundTransfer, setFundTransfer] = useState(false)
    const [transferAmount, setTransferAmount] = useState('')
    const [error, setError] = useState(false)
    const fundTransferFunc = () => {
        if (parseFloat(transferAmount) > parseFloat(totalAmount)) {
            setError('Amount must be less then total available amount')
        } else if (transferAmount === '') {
            setError('Please enter transfer amount')
        } else {
            setFundTransfer(true)
        }
    }
    const closeFundTransferFunc = () => {
        setFundTransfer(false)
    }
    const enabled = transferAmount && transferAmount.length > 0
    return (
        <>
            {isOpen && !fundTransfer && (
                <div className="mobile_account_info withdrawal-amount-page">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => closePaymentTransfer()}
                            />
                            <label className="nav-heading">
                                Transfer Amount
                            </label>
                            <span></span>
                        </nav>
                        <div id="nav-tabContent">
                            <div className="heading-wrapper">
                                <span className="heading">
                                    How much would you
                                    <br /> like to transfer?
                                </span>
                            </div>
                            {/* <span className="balance">$3,500.87</span> */}
                            <span>
                                <input
                                    name="transferAmount"
                                    type="number"
                                    placeholder="$0"
                                    className="balance-textfield"
                                    value={transferAmount}
                                    onChange={(event) =>
                                        setTransferAmount(event.target.value)
                                    }
                                />
                            </span>
                            <div className="available-balance mt-3 mb-5">
                                <div>
                                    <span className="light-gray">
                                        Available{' '}
                                    </span>
                                    <span className="green">
                                        {currencyFormatter.format(
                                            totalAmount || 0
                                        )}
                                    </span>
                                </div>
                                <div className="mt-2">
                                    <span className="light-gray">
                                        Available to payout{' '}
                                    </span>
                                    <span className="green">
                                        {currencyFormatter.format(
                                            availableToPayOutAmount || 0
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center">
                                    {error && (
                                        <small className="alert alert-danger position-absolute ">
                                            {error}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        <button
                            className="btn mobile-green-btn"
                            disabled={!enabled}
                            onClick={fundTransferFunc}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
            {fundTransfer && (
                <FundTransfer
                    isOpen={fundTransfer}
                    closeFundTransferFunc={closeFundTransferFunc}
                    transferAmount={transferAmount}
                    history={props.history}
                />
            )}
        </>
    )
}
PaymentTransfer.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    isOpen: PropTypes.bool,
    closePaymentTransfer: PropTypes.func,
    totalAmount: PropTypes.string,
    availableToPayOutAmount: PropTypes.number,
}
export default PaymentTransfer
