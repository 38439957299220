/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AdminHeader from './AdminHeader'
import AdminSideBarNavigation from './AdminSideBarNavigation'
import SubFooter from './SubFooter'
import AdminBreadCrumb from './AdminBreadCrumb'
import { getAdminTeamMemberList } from 'actions/adminActions'
import { Table } from 'antd'
import Loading from 'shared/Loader'
function AdminTeamMemberList(props) {
    const { history } = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAdminTeamMemberList())
    }, [''])
    // const [ orderFilter, setOrderFilter ] = useState('');
    const [serchValue, setSerchValue] = useState('')
    const teamMemberDetails = useSelector(
        (state) => state.adminTeamMemberDetails
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const columns = [
        {
            title: 'Member name',
            dataIndex: 'team_full_name',
            sorter: (a, b) => a.team_full_name.localeCompare(b.team_full_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Mobile number',
            dataIndex: 'phone_number',
        },
        {
            title: 'Physician name',
            dataIndex: 'physician_name',
            sorter: (a, b) => a.physician_name.localeCompare(b.physician_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Email',
            dataIndex: 'email_id',
        },
        {
            title: 'Role',
            dataIndex: 'speciality',
            sorter: (a, b) => a.speciality.localeCompare(b.speciality),
            sortDirections: ['ascend', 'descend'],
        },
    ]
    const updateSearch = (event) => {
        event.preventDefault()
        setSerchValue(event.target.value.toLowerCase().substr(0, 20))
    }
    const handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }
    const result =
        teamMemberDetails && teamMemberDetails.filter((o) => o.first_name)
    const filteredData =
        result &&
        result.filter((d) => {
            return (
                (d.team_full_name &&
                    d.team_full_name.toLowerCase().indexOf(serchValue) !==
                        -1) ||
                (d.speciality &&
                    d.speciality.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.email_id &&
                    d.email_id.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.physician_name &&
                    d.physician_name.toLowerCase().indexOf(serchValue) !==
                        -1) ||
                (d.phone_number &&
                    d.phone_number
                        .replace(/[- )(]/g, '')
                        .indexOf(serchValue) !== -1) ||
                (d.phone_number && d.phone_number.indexOf(serchValue) !== -1)
            )
        })
    return (
        <div className="dashboard-main-wrapper admin-dashboard">
            <AdminHeader />
            <AdminSideBarNavigation history={history} />
            <div className="dashboard-wrapper">
                <div className="list-page">
                    <div className="container-fluid dashboard-content">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="page-header">
                                    <h3 className="mb-2 text-left">
                                        Team Member List{' '}
                                    </h3>
                                    <AdminBreadCrumb
                                        routes={[
                                            {
                                                path: '/admin-dashboard',
                                                name: 'Dashboard',
                                            },
                                            {
                                                path: '/admin-team-member',
                                                name: 'Team Member list',
                                                isActive: true,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <form>
                                                    <input
                                                        className="form-control form-control-lg search-input"
                                                        type="search"
                                                        name="patientSearchParam"
                                                        value={serchValue || ''}
                                                        onChange={updateSearch}
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </h5>
                                    <div className="card-body">
                                        {/* <div className="table-responsive"> */}
                                        {isLoading && (
                                            <div className="mt-5 mx-4">
                                                <Loading />
                                            </div>
                                        )}
                                        {!isLoading && (
                                            <Table
                                                columns={columns}
                                                dataSource={filteredData}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SubFooter />
                </div>
            </div>
        </div>
    )
}

AdminTeamMemberList.propTypes = {
    history: PropTypes.object,
}
export default AdminTeamMemberList
