import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from '../utils/helper'
import { triggerNotifier } from 'shared/notifier'

export function isAdminAuthenticated(bool) {
    return {
        type: 'IS_ADMIN_AUTHENTICATED',
        isAdmin: bool,
    }
}

export function fetchPhysicianVerification(physicianVerification) {
    return {
        type: 'FETCH_PHYSICIAN_VERIFICATION',
        physicianVerification,
    }
}

export function fetchAdminPatientList(adminPatientDetails) {
    return {
        type: 'FETCH_ADMIN_PATIENT_LIST_DETAILS',
        adminPatientDetails,
    }
}
export function fetchAdminPhysicianList(adminPhysicianDetails) {
    return {
        type: 'FETCH_ADMIN_PHYSICIAN_LIST_DETAILS',
        adminPhysicianDetails,
    }
}
export function fetchAdminTeamMemberList(adminTeamMemberDetails) {
    return {
        type: 'FETCH_ADMIN_TEAM_MEMBER_LIST_DETAILS',
        adminTeamMemberDetails,
    }
}
export function fetchAdminDashboardData(adminDashboardDetails) {
    return {
        type: 'FETCH_ADMIN_DASHBOARD_DETAILS',
        adminDashboardDetails,
    }
}
export function fetchAdminTransactions(adminTransactionsDetails) {
    return {
        type: 'FETCH_ADMIN_TRANSACTION',
        adminTransactionsDetails,
    }
}
export function adminLoginAction(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/admin/login',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                console.log('OTP', res.data.verification_otp)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function adminVerifyOtp(data, history) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/admin/verify/otp',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                sessionStorage.setItem('accessToken', res.data.access_token)
                dispatch(isAdminAuthenticated(true))
                dispatch(applicationIsLoading(false))
                history.push('/admin-dashboard')
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function physicianVerifyAction(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/admin/verify/physician',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPhysicianVerification(res.data))
                dispatch(getAdminPhysicianList())
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getAdminPatientList() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/admin/patient/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchAdminPatientList(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function getAdminPhysicianList() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/admin/physician/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchAdminPhysicianList(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function getAdminTeamMemberList() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/admin/physician/team/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchAdminTeamMemberList(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function getAdminDashboardData() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/admin/dashboard',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchAdminDashboardData(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function getAdminTransactions(
    startDate,
    endDate,
    physicianName,
    patientName
) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/admin/patient/transaction/activity?start_date=' +
                startDate +
                '&' +
                'end_date=' +
                endDate +
                '&physician_name=' +
                physicianName +
                '&patient_name=' +
                patientName,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(
                    fetchAdminTransactions(
                        res.data && res.data.payment_transaction_list
                    )
                )
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function adminLogoutAction(history) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/admin/logout',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                sessionStorage.removeItem('accessToken')
                history ? history.push('/admin') : (window.location = '/admin')
                dispatch(isAdminAuthenticated(false))
                return res.data
            })
            .catch((error) => {
                console.log(error)
                return error
            })
    }
}
