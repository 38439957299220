import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import ModalLoader from 'shared/ModalLoader'
import { MODAL_STYLES } from 'constants/Constant'
import SuccessAlert from 'shared/SuccessAlert'

export class StripeAccountCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            successAlert: false,
            stripCheckLoading: false,
            // failErrorMessage: false
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        if (event.target.type.includes('file')) {
            input[event.target.name] = event.target.files[0]
        } else {
            input[event.target.name] = event.target.value
        }
        this.setState({
            input,
            errors: {},
            // failErrorMessage:''
        })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['ssn']) {
            isValid = false
            errors['ssn'] = 'Please enter first name'
        }
        if (input['ssn'] && input['ssn'].length !== 9) {
            isValid = false
            errors['ssn'] = 'ssn should be of 9 digit.'
        }
        if (!input['city']) {
            isValid = false
            errors['city'] = 'Please enter city'
        }
        if (typeof input['city'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['city'])) {
                isValid = false
                errors['city'] = 'Please enter alphabets only'
            }
        }
        if (!input['state']) {
            isValid = false
            errors['state'] = 'Please enter state'
        }
        if (typeof input['state'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['state'])) {
                isValid = false
                errors['state'] = 'Please enter alphabets only'
            }
        }
        if (!input['address']) {
            isValid = false
            errors['address'] = 'Please enter address'
        }
        if (!input['gender']) {
            isValid = false
            errors['gender'] = 'Please enter gender'
        }
        if (!input['front']) {
            isValid = false
            errors['front'] = 'Please upload any identity'
        }
        if (!input['back']) {
            isValid = false
            errors['back'] = 'Please upload any identity'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }

    handleSubmit = (event) => {
        event.preventDefault()
        if (this.validate()) {
            this.setState({
                stripCheckLoading: true,
            })
            const { address, city, ssn, state, gender, front, back } =
                this.state.input
            var data = new FormData()
            data.append('front', front)
            data.append('back', back)
            data.append(
                'data',
                JSON.stringify({
                    ssn: ssn,
                    city: city,
                    state: state,
                    address: address,
                    gender: gender,
                })
            )
            this.props.sendBankingAuthDeatils(data).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({
                        successAlert: true,
                        stripCheckLoading: false,
                    })
                    const input = {}
                    this.setState({ input: input })
                } else {
                    this.setState({ stripCheckLoading: false })
                }
                // else {
                //     this.setState({ failErrorMessage: true })
                // }
            })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
        this.props.closeStripeModal()
    }
    render() {
        const { input, errors, successAlert, stripCheckLoading } = this.state
        const { isOpen, closeStripeModal } = this.props
        const enabled =
            Object.keys(input).length > 6 &&
            input.address &&
            input.address.length > 0 &&
            input.ssn &&
            input.ssn.length > 0 &&
            input.city &&
            input.city.length > 0 &&
            input.state &&
            input.state.length > 0
        return (
            <>
                <Modal
                    id="authorization-details-modal"
                    isOpen={isOpen}
                    onRequestClose={closeStripeModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                >
                    <button
                        type="button"
                        className="close modal_close_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => closeStripeModal()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {stripCheckLoading && (
                        <div className="d-flex justify-content-center mt-5">
                            {' '}
                            <ModalLoader />{' '}
                        </div>
                    )}
                    {!stripCheckLoading && (
                        <div>
                            {/* {failErrorMessage && <div className="alert alert-danger" role="alert">
                                Must be at least 13 years of your age to use Stripe or Invalid US state or province.
                            </div>} */}
                            <h5 className="modal-heading text-center my-2">
                                Authorization details
                            </h5>
                            <p className="content">
                                {' '}
                                Required details to create your stripe account{' '}
                            </p>
                            <form>
                                <div className="form-row flex-column">
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    name="ssn"
                                                    autoComplete="off"
                                                    id="ssn"
                                                    value={input.ssn || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter SSN"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="ssn"
                                                >
                                                    SSN
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.ssn}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    autoComplete="off"
                                                    id="city"
                                                    value={input.city || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter city name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="city"
                                                >
                                                    City
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.city}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="state"
                                                    id="state"
                                                    value={input.state || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter state name"
                                                    autoComplete="off"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="state"
                                                >
                                                    State
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.state}
                                        </small>
                                    </div>

                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    autoComplete="off"
                                                    id="address"
                                                    value={input.address || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter address"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="address"
                                                >
                                                    Address
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.address}
                                        </small>
                                    </div>

                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <select
                                                    className="form-control account_info_field"
                                                    id="select-card"
                                                    name="gender"
                                                    onChange={this.handleChange}
                                                >
                                                    <option defaultValue="select card">
                                                        {' '}
                                                        Select gender
                                                    </option>
                                                    <option
                                                        name="gender"
                                                        value="male"
                                                    >
                                                        Male
                                                    </option>
                                                    <option
                                                        name="gender"
                                                        value="female"
                                                    >
                                                        Female
                                                    </option>
                                                    <option
                                                        name="gender"
                                                        value="others"
                                                    >
                                                        Others
                                                    </option>
                                                </select>
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="select-card"
                                                >
                                                    Gender
                                                </label>
                                                {/* <input
                                                type="text"
                                                name="gender"
                                                autoComplete="off"
                                                id="gender"
                                                value={ input.gender || '' }
                                                onChange={ this.handleChange }
                                                className="form-control account_info_field"
                                                placeholder="Male/Female/Others"
                                            /> */}
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.gender}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="file"
                                                    name="front"
                                                    id="front"
                                                    // value={ input.gender || '' }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    // placeholder="Enter email"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="front"
                                                >
                                                    Identity(front)
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.front}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="file"
                                                    name="back"
                                                    id="back"
                                                    // value={ input.gender || '' }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    // placeholder="Enter email"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="back"
                                                >
                                                    Identity(back)
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger">
                                            {errors.back}
                                        </small>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn save_team_member_btn mt-2 d-block mx-auto save-btn"
                                    onClick={this.handleSubmit}
                                    disabled={!enabled}
                                    data-dismiss="modal"
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                    )}
                </Modal>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message="Your stripe account has been successfully added"
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        teams: state.teams,
        isLoading: state.applicationIsLoading,
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         // sendBankingAuthDeatils: (data) => dispatch(sendBankingAuthDeatils(data)),

//     };
// };

StripeAccountCheck.propTypes = {
    closeStripeModal: PropTypes.func,
    sendBankingAuthDeatils: PropTypes.func,
    isOpen: PropTypes.bool,
}

export default connect(mapStateToProps, null)(StripeAccountCheck)
