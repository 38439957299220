import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import Avatar from 'react-avatar'

const QrCode = () => {
    const code =
        history &&
        history.state &&
        history.state.state &&
        history.state.state.qrCode
    return (
        <div className="mobile_account_info">
            <div id="content">
                <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                    <CgChevronLeft
                        className="back_icon"
                        onClick={() => history.back()}
                    />
                    <label className="nav-heading">QR Code</label>
                </nav>
                <div className="row no-gutters">
                    <div className="col-md-12">
                        <div style={{ margin: '100px 0px 0px 0px' }}>
                            <Avatar size="150" square={true} src={code} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

QrCode.propTypes = {
    history: PropTypes.object,
}
export default QrCode
