import React from 'react'
import '../App.css'
import { useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import AppRoutes from 'app/app_routes'
import '../static/stylesheets/auth.scss'
import '../static/stylesheets/common.scss'
import { logoutAction } from 'actions/authActions'

function App() {
    const dispatch = useDispatch()

    const handleOnIdle = () => {
        dispatch(logoutAction(''))
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    console.log('time remaining', getRemainingTime())
    console.log('last active', getLastActiveTime())

    return (
        <div className="App">
            <AppRoutes />
        </div>
    )
}

export default App
