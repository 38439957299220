import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import { MODAL_STYLES } from 'constants/Constant'
import AddPhysicianModal from './AddPhysicianModal'
import { sendPhysicianAccessCode } from 'actions/patientPhysicianActions'

function FindPhysicianModal(props) {
    const dispatch = useDispatch()
    const [addModal, setAddModal] = useState(false)
    const [findModal, setFindModal] = useState(true)
    const [accessCode, setAccessCode] = useState('')
    const [accessCodeError, setAccessCodeError] = useState(false)
    const validate = () => {
        let isValid = true
        if (accessCode === '') {
            isValid = false
            setAccessCodeError('Please enter access code')
        }
        if (accessCode !== 'undefined') {
            // const pattern = new RegExp(/^[0-9]{6,8}$/);
            // if (!pattern.test(accessCode)) {
            //     isValid = false;
            //     setAccessCodeError('Access code lenght must be between 6 to 8 digits')
            // }
            if (accessCode.length !== 6) {
                isValid = false
                setAccessCodeError('Access code lenght must be of 6 digits')
            }
        }
        return isValid
    }
    const openAddModal = () => {
        if (validate()) {
            dispatch(sendPhysicianAccessCode(accessCode)).then((res) => {
                if (res.statusCode === 200) {
                    setFindModal(false)
                    setAddModal(true)
                }
                // else {
                //     setAccessCodeError('The access code you entered is invalid.');
                // }
            })
        }
        if (accessCode !== '')
            if (accessCode !== '') {
                setFindModal(true)
                setAddModal(false)
                dispatch(sendPhysicianAccessCode(accessCode))
            } else {
                setAccessCodeError('please enter access code ')
            }
    }
    const closeAddPhysicianModal = () => {
        setAddModal(!addModal)
    }
    const handleChange = (event) => {
        setAccessCode(event.target.value)
        setAccessCodeError(false)
    }
    return (
        <>
            {findModal && (
                <Modal
                    isOpen={props.isOpen}
                    onRequestClose={props.closeModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="physician-access-code-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.closeModal()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal text-center">
                            <div className="modal-body">
                                <h4 className="heading">
                                    Enter your physician
                                    <br /> access code
                                </h4>
                                <input
                                    type="text"
                                    autoFocus
                                    className="code-textfield"
                                    name="accessCode"
                                    autoComplete="off"
                                    placeholder="Enter access code"
                                    value={accessCode}
                                    onChange={handleChange}
                                    data-dismiss="modal"
                                />
                                {accessCodeError && (
                                    <div>
                                        <small className="alert alert-danger error-msg">
                                            {accessCodeError}
                                        </small>
                                    </div>
                                )}
                                <button
                                    className="btn find-btn physician-button"
                                    onClick={openAddModal}
                                >
                                    Find My Physician
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {addModal && (
                <AddPhysicianModal
                    isOpen={addModal}
                    closeModal={closeAddPhysicianModal}
                    closeOptionModal={props.closeOptionModal}
                />
            )}
        </>
    )
}

FindPhysicianModal.propTypes = {
    closeModal: PropTypes.func,
    closeOptionModal: PropTypes.func,
    openAddModal: PropTypes.func,
    sendPhysicianAccessCode: PropTypes.func,
    isOpen: PropTypes.bool,
}

export default FindPhysicianModal
