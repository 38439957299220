export function isAdmin(
    state = sessionStorage.accessToken ? true : false,
    action
) {
    switch (action.type) {
        case 'IS_ADMIN_AUTHENTICATED':
            return action.isAdmin
        default:
            return state
    }
}
export function physicianVerification(state = '', action) {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_VERIFICATION':
            return action.physicianVerification
        default:
            return state
    }
}
export function adminPatientDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_ADMIN_PATIENT_LIST_DETAILS':
            return action.adminPatientDetails
        default:
            return state
    }
}
export function adminPhysicianDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_ADMIN_PHYSICIAN_LIST_DETAILS':
            return action.adminPhysicianDetails
        default:
            return state
    }
}
export function adminTeamMemberDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_ADMIN_TEAM_MEMBER_LIST_DETAILS':
            return action.adminTeamMemberDetails
        default:
            return state
    }
}
export function adminDashboardDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_ADMIN_DASHBOARD_DETAILS':
            return action.adminDashboardDetails
        default:
            return state
    }
}
export function adminTransactionsDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_ADMIN_TRANSACTION':
            return action.adminTransactionsDetails
        default:
            return state
    }
}
