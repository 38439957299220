import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { useDispatch } from 'react-redux'
import { removeCard } from 'actions/bankingActions'
import { removePatientCard } from 'actions/patientBankingActions'
import { BsCreditCard } from 'react-icons/bs'
import ConfirmDelete from 'shared/ConfirmDelete'

const RemoveCard = (props) => {
    const dispatch = useDispatch()
    const {
        removeCardDetails,
        closeRemoveCard,
        userRole,
        closeRemoveCardPage,
    } = props
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const deleteCard = () => {
        userRole
            ? dispatch(removePatientCard(removeCardDetails.id))
            : dispatch(removeCard(removeCardDetails.id))
        setTimeout(() => {
            closeRemoveCard()
        }, 200)
    }
    const handleConfirm = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    return (
        <>
            <div className="mobile_account_info remove-card-page">
                <nav
                    className="navbar navbar-expand-lg navbar-light custom_navbar"
                    style={{ boxShadow: 'none' }}
                >
                    <CgChevronLeft
                        className="back_icon"
                        onClick={() => closeRemoveCardPage()}
                    />
                </nav>
                <div>
                    <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                    <div>
                        <h4 className="account-name">
                            {removeCardDetails.card_name}
                        </h4>
                        <div className="account-number">
                            {removeCardDetails.card_type}{' '}
                            {'****' + removeCardDetails.card_number}
                        </div>
                    </div>
                </div>
                <button
                    className="btn btn-link remove-link"
                    onClick={handleConfirm}
                >
                    Remove
                </button>
            </div>
            {isConfirmDelete && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={handleConfirm}
                    handleRemoveFunc={deleteCard}
                    role={userRole ? 'Card' : 'bank account'}
                />
            )}
        </>
    )
}
RemoveCard.propTypes = {
    // isTeam: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
    // cardListing: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
    removeCardDetails: PropTypes.object,
    closeRemoveCard: PropTypes.func,
    closeRemoveCardPage: PropTypes.func,
    userRole: PropTypes.string,
}
export default RemoveCard
