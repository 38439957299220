import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import Avatar from 'react-avatar'
import { FiChevronLeft } from 'react-icons/fi'
import PatientAccountNumberModal from './PatientAccountNumberModal'
import { getPhysicianProfileInfo } from 'actions/patientPhysicianActions'
import ModalLoader from 'shared/ModalLoader'
import SuccessAlert from 'shared/SuccessAlert'
function PhysicianProfile(props) {
    const dispatch = useDispatch()
    const [openPatientAccountNumber, setOpenPatientAccountNumber] =
        useState(false)
    const {
        isOpen,
        closePhysicianProfile,
        patientAppointmentId,
        physicianDetails,
        physicianIds,
        isHideNewBill,
    } = props
    useEffect(() => {
        dispatch(getPhysicianProfileInfo(physicianDetails.physician_id))
    }, [''])
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const profileDetails = useSelector((state) => state.physicianProfileDetails)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const closePatientAccountNumber = () => {
        setOpenPatientAccountNumber(false)
    }
    const handleNewBill = () => {
        if (physicianDetails && physicianDetails.stripe_account_id) {
            if (
                physicianIds.includes(
                    physicianDetails && physicianDetails.physician_id
                )
            ) {
                setSuccessAlertMessage(
                    'Your previous arrangement is still under review'
                )
                setSuccessAlert(true)
            } else {
                setOpenPatientAccountNumber(true)
            }
        } else {
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
            setSuccessAlert(true)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    return (
        <>
            {!openPatientAccountNumber && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closePhysicianProfile}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="profile-info"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closePhysicianProfile()}
                                />
                                <span className="patient-name">Profile</span>
                                <span></span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="mt-3">
                                    <Avatar
                                        name={profileDetails.physician}
                                        className="physician-image"
                                        size="70"
                                        src={profileDetails.avatar}
                                        round={true}
                                    />
                                </div>
                            </div>
                            {isModalLoading && (
                                <div className="text-center my-5">
                                    <ModalLoader />
                                </div>
                            )}
                            {!isModalLoading && (
                                <div className="info-wrapper">
                                    <div className="d-flex flex-column field-wrapper">
                                        <div className="profile-label">
                                            Patient Account Number
                                        </div>
                                        <div className="profile-value">
                                            {patientAppointmentId}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column field-wrapper">
                                        <div className="profile-label">
                                            Physician
                                        </div>
                                        <div className="profile-value">
                                            {profileDetails &&
                                                profileDetails.physician}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column field-wrapper">
                                        <div className="profile-label">
                                            Speciality
                                        </div>
                                        <div className="profile-value">
                                            {(profileDetails &&
                                                profileDetails.speciality) ||
                                                'General'}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column field-wrapper">
                                        <div className="profile-label">
                                            Address
                                        </div>
                                        <div className="profile-value">
                                            {profileDetails &&
                                                profileDetails.address}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column field-wrapper">
                                        <div className="profile-label">
                                            Phone Number
                                        </div>
                                        <div className="profile-value">
                                            {profileDetails &&
                                                profileDetails.phone_number}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isHideNewBill && (
                                <button
                                    className="btn next-btn"
                                    onClick={handleNewBill}
                                >
                                    New Bill
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {openPatientAccountNumber && (
                <PatientAccountNumberModal
                    isOpen={openPatientAccountNumber}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianDetails}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}
PhysicianProfile.propTypes = {
    closePhysicianProfile: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    patientAppointmentId: PropTypes.number,
    physicianIds: PropTypes.array,
    isHideNewBill: PropTypes.bool,
}
export default PhysicianProfile
