import React from 'react'
import PropTypes from 'prop-types'
import PompayTopLogo from 'shared/PompayTopLogo'
import moment from 'moment'
import { currencyFormatter } from 'utils/helper'
const FundInitiationConfirmation = (props) => {
    const { isInitialOpen, transferAmount, selectedCardNumber } = props
    return (
        <>
            {isInitialOpen && (
                <div className="mobile_account_info transfer-initiated-page">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between">
                            <PompayTopLogo className="small_logo" />
                        </nav>
                        <div className="transfer-Content">
                            <h4 className="heading">
                                Your transfer has been initiated.
                            </h4>
                            <h6 className="sub-heading">Transfer details</h6>
                            <div className="transfer-details">
                                <span className="transfer-label">
                                    Transfer amount
                                </span>
                                <span className="transfer-value">
                                    {currencyFormatter.format(
                                        transferAmount || 0
                                    )}
                                </span>
                            </div>
                            <div className="transfer-details">
                                <span className="transfer-label">
                                    {selectedCardNumber.card_name}
                                </span>
                                <span className="transfer-value">
                                    {selectedCardNumber.card_type} ****
                                    {selectedCardNumber.card_number}
                                </span>
                            </div>
                            <div className="transfer-details">
                                <span className="transfer-label">Date</span>
                                <span className="transfer-value">
                                    {moment(new Date()).format('MM/DD/YY')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        <button
                            className="btn mobile-green-btn"
                            onClick={() =>
                                props.history.push('/account-homepage-mobile')
                            }
                        >
                            Done
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
FundInitiationConfirmation.propTypes = {
    isInitialOpen: PropTypes.bool,
    closeInitiationFunc: PropTypes.func,
    transferAmount: PropTypes.func,
    selectedCardNumber: PropTypes.string,
    history: PropTypes.object,
}
export default FundInitiationConfirmation
