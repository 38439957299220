import React from 'react'
import { IoMdPeople, IoMdSettings } from 'react-icons/io'
// import { FiSettings } from 'react-icons/fi';
import { MdHome } from 'react-icons/md'

const PatientBottombarNavigation = () => {
    const pathUrl = window.location.pathname
    return (
        <div className="mobile-navbar d-flex justify-content-around align-items-center">
            {/* <ul className="list-unstyled components mt-4">
                <li className="active"> */}
            <a href="/home" className={pathUrl === '/home' ? 'active' : ''}>
                <MdHome className="react_font" />
            </a>
            {/* </li>
                <li> */}
            <a
                href="/physician-list-view"
                className={pathUrl === '/physician-list-view' ? 'active' : ''}
            >
                <IoMdPeople className="react_font" />
            </a>
            {/* </li>
            </ul> */}
            {/* <ul className="list-unstyled components">
                <li> */}
            <a
                href="/patient-account"
                className={pathUrl === '/patient-account' ? 'active' : ''}
            >
                <IoMdSettings className="react_font" />
            </a>
            {/* </li>
            </ul> */}
        </div>
    )
}

export default PatientBottombarNavigation
