import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import '../static/stylesheets/common.scss'
import { sendPasscode } from 'actions/authActions'
import { maxLengthCheck, onlyNumber } from 'utils/helper'
import SuccessAlert from './SuccessAlert'
function Passcode(props) {
    const [ownerCode, setOwnerCode] = useState('')
    const [error, setError] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const isvalidPasscode = useSelector((state) => state.isvalidPasscode)
    const dispatch = useDispatch()
    const hanldeChange = (event) => {
        setOwnerCode(event.target.value)
        if (event.target.value.length === 4) {
            const checkLock = !props.locked ? 1 : 0
            const data = {
                owner_passcode: event.target.value,
                is_locked: checkLock,
            }
            if (isvalidPasscode) {
                setError(true)
            }
            setTimeout(() => {
                dispatch(sendPasscode(props.physicianId, data)).then((res) => {
                    if (res.statusCode === 200) {
                        setOwnerCode('')
                        setSuccessAlert(true)
                        props.closeModal()
                    }
                })
            }, 500)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    return (
        <>
            <div className="test">
                <Modal
                    isOpen={props.isOpen}
                    onRequestClose={props.closeModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                >
                    <button
                        type="button"
                        className="close modal_close_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.closeModal()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered owner-passcode"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="modal-body">
                                {isvalidPasscode && !error && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        Invalid verification code. Please try
                                        again.
                                    </div>
                                )}
                                <h4>Owner Passcode</h4>
                                <p className="font-weight400 font17">
                                    To make a change, please enter your
                                    four-digit passcode{' '}
                                </p>
                                <input
                                    type="password"
                                    maxLength="4"
                                    autoFocus
                                    onInput={maxLengthCheck}
                                    className="otp_text_field"
                                    name="owner_code"
                                    autoComplete="off"
                                    value={ownerCode || ''}
                                    onChange={hanldeChange}
                                    onKeyDown={(event) => onlyNumber(event)}
                                    placeholder="----"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message={
                        props.locked
                            ? 'You have successfully locked the screen.'
                            : 'You have successfully unlocked the screen.'
                    }
                />
            )}
        </>
    )
}

Passcode.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    locked: PropTypes.number,
    isvalidPasscode: PropTypes.bool,
    physicianId: PropTypes.number,
}

export default Passcode
