import React from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import moment from 'moment'
import PropTypes from 'prop-types'
import { MdChevronLeft } from 'react-icons/md'
import { MODAL_STYLES } from 'constants/Constant'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'

function BillDetailsModal(props) {
    const paydetails = props.remBalanceDetails ? props.remBalanceDetails : []
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    return (
        <>
            <Modal
                isOpen={props.isBill}
                onRequestClose={props.closeModal}
                style={MODAL_STYLES}
                contentLabel="Example Modal"
                id="bill-details-modal"
                ariaHideApp={false}
            >
                <div className="bill_details mb-5">
                    <div className="d-flex justify-content-between mb-2 modal_heading_section">
                        <MdChevronLeft
                            className="dark_icon back_icon color_light_green font38 pointer"
                            onClick={props.closeModal}
                        />
                        <a className="navbar-brand">
                            <p className="modal-heading">Bill Details</p>
                        </a>
                        <button
                            type="button"
                            className="close modal_close_custom_icon"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={props.closeAll}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        <div className="heading_section d-flex flex-column text-center mt-5">
                            <span className="main-balanceheading">
                                Remaining Balance
                            </span>
                            {isModalLoading && <ModalLoader />}
                            {!isModalLoading && (
                                <span className="main-amount">
                                    {currencyFormatter.format(
                                        paydetails.remaining_balance || 0
                                    )}
                                </span>
                            )}
                        </div>
                        {!isModalLoading && (
                            <div className="my-4 text-center">
                                <span className="content-heading">
                                    Full payment schedule:
                                </span>
                            </div>
                        )}

                        {/* due_amount_status */}
                        {!isModalLoading &&
                            !isLoading &&
                            paydetails.full_payment_schedule &&
                            paydetails.full_payment_schedule.map((data, id) => {
                                return (
                                    <div
                                        className="row no-gutters my-4 installment-row"
                                        key={id}
                                    >
                                        <div className="col-2 col-sm-2">
                                            <form className="radio_form">
                                                <label
                                                    className="radio_btn"
                                                    htmlFor="sms"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="radio"
                                                        defaultChecked={
                                                            data.due_amount_status !==
                                                                'Due' && true
                                                        }
                                                        id="sms"
                                                    />
                                                    <span className="checkmark"></span>
                                                    <div className="v-border"></div>
                                                </label>
                                            </form>
                                        </div>
                                        <div className="col-7 col-sm-7 text-left">
                                            <div className="d-flex flex-column ml-2">
                                                {id === 0 ? (
                                                    <span className="installment-label">
                                                        {id + 1}st installment
                                                    </span>
                                                ) : id === 1 ? (
                                                    <span className="installment-label">
                                                        {id + 1}nd installment
                                                    </span>
                                                ) : id === 2 ? (
                                                    <span className="installment-label">
                                                        {id + 1}rd installment
                                                    </span>
                                                ) : (
                                                    <span className="installment-label">
                                                        {id + 1}th installment
                                                    </span>
                                                )}
                                        <span className="installment-date">
                                            { data.due_amount_status }: { moment(data.due_amount_date).format('l') }&nbsp;
                                            {data.late_fee > 0 && <span className="installment-label text-danger">(PAST DUE)</span>}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-3 col-sm-3">
                                    {/* <span className="installment-label">{currencyFormatter.format(data.due_amount || 0 )}</span> */}
                                    <div className="d-flex flex-column mr-4">
                                        <span className="installment-label">{currencyFormatter.format(data.due_amount || 0)}</span>
                                        {data.late_fee > 0 && <span className="installment-label text-danger">+{currencyFormatter.format(data.late_fee)}</span>}
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-column mr-4">
                                    <span className="paid-amount">{currencyFormatter.format(data.due_amount || 0)}</span>
                                    {data.late_fee > 0 && <span className="paid-amount text-danger">+{currencyFormatter.format(data.late_fee)}</span>}
                                </div> */}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </Modal>
        </>
    )
}

BillDetailsModal.propTypes = {
    isBill: PropTypes.bool,
    remBalanceDetails: PropTypes.object,
    closeModal: PropTypes.func,
    closeAll: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default BillDetailsModal
