import React from 'react'
import PropTypes from 'prop-types'
import PompayLogo from 'static/images/PompayLogo.svg'
import '../static/stylesheets/common.scss'

const PompayTopLogo = (props) => (
    <a className={props.ancorClassName ? props.ancorClassName : 'navbar-brand'}>
        <img src={PompayLogo} className={props.className} alt="PompayLogo" />
    </a>
)
PompayTopLogo.propTypes = {
    className: PropTypes.string,
    ancorClassName: PropTypes.string,
}

export default PompayTopLogo
