import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { DatePicker, Space } from 'antd'
import { dateFormat } from '../../../../constants/Constant'
import moment from 'moment'
import { CgChevronLeft } from 'react-icons/cg'
import { getPatientList } from 'actions/patient/patient.actions'
const { RangePicker } = DatePicker

function PatientFilter(props) {
    // paid_amount: '',
    //         total_amount: '',
    //         unpaid_amount: '',
    const { handleFilter } = props
    const dispatch = useDispatch()
    const [dateRange, setDateRange] = useState([])
    const [data, setData] = useState([])
    // const [ paid_amount, setPaid_amount ] = useState('')
    // const [ total_amount, setTotal_amount ] = useState('')
    // const [ unpaid_amount, setUnpaid_amount ] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    const [alphabeticalOrder, setAlphabeticalOrder] = useState('')
    const [sortByAmount, setSortByAmount] = useState('')
    const [sortByDate, setSortByDate] = useState('')
    const handleDateRange = (date, dateString) => {
        setDateRange(dateString)
    }
    const applyFilter = () => {
        const params = {
            dateRange,
            paymentStatus,
            alphabeticalOrder,
            sortByAmount,
            sortByDate,
        }
        dispatch(getPatientList(params)).then((res) => {
            if (res.statusCode === 200) {
                const condCheck =
                    res.data &&
                    res.data.patient_info &&
                    res.data.patient_info[0] &&
                    res.data.patient_info[0].due_payments_details
                        ? true
                        : false
                const payDetailsArray = []
                if (condCheck) {
                    const duePay =
                        res.data &&
                        res.data.patient_info &&
                        res.data.patient_info.map((d) => {
                            return d.due_payments_details
                        })
                    //   const payDetailsArray = [];
                    duePay.map((d) => {
                        return d.map((updateData) => {
                            payDetailsArray.push(updateData)
                        })
                    })
                } else {
                    const duePay = res.data && res.data.patient_info
                    duePay.map((d) => {
                        payDetailsArray.push(d)
                    })
                }
                setData(data)
                // setPaid_amount(res.paid_amount)
                // setTotal_amount(res.total_amount)
                // setUnpaid_amount(res.unpaid_amount)
            }
        })
        setTimeout(() => {
            props.history.push({
                pathname: '/payments',
                state: {
                    data: data,
                },
            })
        }, 200)
    }
    const clearFilters = () => {
        setDateRange('')
        setPaymentStatus('')
        setAlphabeticalOrder('')
        setSortByAmount('')
        setSortByDate('')
    }

    return (
        <div className="mobile_account_info patient-filter">
            <div id="content">
                <nav className="navbar navbar-expand-lg bg_primary_color navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                    <CgChevronLeft
                        className="back_icon text-white"
                        onClick={() => handleFilter()}
                    />
                    <label className="nav-heading text-white">Filter</label>
                    <a
                        onClick={clearFilters}
                        style={{ zIndex: 1 }}
                        className="clear-all-link"
                    >
                        Clear all
                    </a>
                </nav>
                <div id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="nav-patient-filter"
                        role="tabpanel"
                        aria-labelledby="nav-patient-filter-tab"
                    >
                        <div className="row mt-2 mb-4">
                            <div className="col-sm-5 col-5 text-left">
                                <span className="form-label">Date range:</span>
                            </div>
                            <div className="col-sm-7 col-7">
                                <Space direction="vertical" size={12}>
                                    <RangePicker
                                        defaultValue={[
                                            moment(new Date(), dateFormat),
                                            moment(new Date(), dateFormat),
                                        ]}
                                        format={dateFormat}
                                        onChange={handleDateRange}
                                    />
                                </Space>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-sm-5 col-5 text-left">
                                <span className="form-label">Status:</span>
                            </div>
                            <div className="col-sm-7 col-7">
                                {/* <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="role_name"
                                >
                                    <option name="role_name" value="All" id="All">
                                        Paid
                                    </option>
                                    <option name="role_name" value="All" id="All">
                                        Past Due
                                    </option>
                                </select> */}
                                <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="paymentStatus"
                                    onChange={(evt) =>
                                        setPaymentStatus(evt.target.value)
                                    }
                                >
                                    <option defaultValue="all"> All</option>
                                    <option name="paymentStatus" value="Paid">
                                        Paid
                                    </option>
                                    <option
                                        name="paymentStatus"
                                        value="Awaiting"
                                    >
                                        Awaiting
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="row box-shadow"></div>
                        <div className="row bg_thin_gray">
                            <span className="form-label p-3">
                                Sort payments by:
                            </span>
                        </div>
                        <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="form-label">Name</span>
                            </div>
                            <div className="col-6 text-right my-2">
                                {/* <div className="col-sm-7 col-7"> */}
                                {/* <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="role_name"
                                >
                                    <option name="role_name" value="All" id="All">
                                        Paid
                                    </option>
                                    <option name="role_name" value="All" id="All">
                                        Past Due
                                    </option>
                                </select> */}
                                <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="alphabeticalOrder"
                                    onChange={(evt) =>
                                        setAlphabeticalOrder(evt.target.value)
                                    }
                                >
                                    <option defaultValue="all"> All</option>
                                    <option
                                        name="alphabeticalOrder"
                                        value="A-Z"
                                    >
                                        A-Z
                                    </option>
                                    <option
                                        name="alphabeticalOrder"
                                        value="Z-A"
                                    >
                                        Z-A
                                    </option>
                                </select>
                                {/* </div> */}
                                {/* <span className="color_dark_grey font16">A-Z</span> */}
                            </div>
                        </div>
                        {/* <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="color_dark_grey font-weight500 font16">
                                    Name
                                </span>
                            </div>
                            <div className="col-6 text-right my-2">
                                <span className="color_dark_grey font16">Z-A</span>
                            </div>
                        </div> */}
                        <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="form-label">Amount</span>
                            </div>
                            <div className="col-6 text-right my-2">
                                <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="sortByAmount"
                                    onChange={(evt) =>
                                        setSortByAmount(evt.target.value)
                                    }
                                >
                                    <option defaultValue="all"> All</option>
                                    <option
                                        name="sortByAmount"
                                        value="Highest to Lowest"
                                    >
                                        Highest to Lowest
                                    </option>
                                    <option
                                        name="sortByAmount"
                                        value="Lowest to Highest"
                                    >
                                        Lowest to Highest
                                    </option>
                                </select>
                                {/* <span className="color_dark_grey font16">Highest to Lowest</span> */}
                            </div>
                        </div>
                        {/* <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="color_dark_grey font-weight500 font16">
                                    Amount
                                </span>
                            </div>
                            <div className="col-6 text-right my-2">
                                <span className="color_dark_grey font16">Lowest to Highest</span>
                            </div>
                        </div> */}
                        <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="form-label">Date</span>
                            </div>
                            <div className="col-6 text-right my-2">
                                <select
                                    className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                    name="sortByDate"
                                    onChange={(evt) =>
                                        setSortByDate(evt.target.value)
                                    }
                                >
                                    <option defaultValue="all"> All</option>
                                    <option
                                        name="sortByDate"
                                        value="Newest to Oldest"
                                    >
                                        Newest to Oldest
                                    </option>
                                    <option
                                        name="sortByDate"
                                        value="Oldest to Newest"
                                    >
                                        Oldest to Newest
                                    </option>
                                </select>
                                {/* <span className="color_dark_grey font16">Newest to Oldest</span> */}
                            </div>
                        </div>
                        {/* <div className="row my-3">
                            <div className="col-6 text-left my-2">
                                <span className="color_dark_grey font-weight500 font16">
                                    Date
                                </span>
                            </div>
                            <div className="col-6 text-right my-2">
                                <span className="color_dark_grey font16">Oldest to Newest</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="px-3">
                <button
                    className="btn custom_btn green_btn d-md-none d-lg-none d-xl-none"
                    onClick={applyFilter}
                >
                    Apply Filter
                </button>
            </div>
        </div>
    )
}
PatientFilter.propTypes = {
    history: PropTypes.object,
    handleFilter: PropTypes.func,
    // handleDropDown: PropTypes.func,
}

export default PatientFilter
