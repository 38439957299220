import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { alphabets, MODAL_STYLES } from 'constants/Constant'
import { getPhysicianList } from 'actions/patientPhysicianActions'
import { MdKeyboardArrowRight } from 'react-icons/md'
import PatientAccountNumberModal from '../physician/PatientAccountNumberModal'
import ModalLoader from 'shared/ModalLoader'
import { FiChevronLeft } from 'react-icons/fi'
import SuccessAlert from 'shared/SuccessAlert'

function SelectPhysician(props) {
    const dispatch = useDispatch()
    const { isOpen, closePhysiaicanSelect, physicianIds, closeOptionModal } =
        props
    const [openAccountNumber, setOpenAccountNumber] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const [physicianId, setPhysicianId] = useState({})
    const patientAppointmentId = useSelector(
        (state) => state.patientAppointmentId
    )
    useEffect(() => {
        dispatch(getPhysicianList())
    }, [''])
    const physicianList = useSelector((state) => state.physicianList)
    const handleSelectPhysician = (data) => {
        if (data.stripe_account_id) {
            if (physicianIds.includes(data && data.physician_id)) {
                setSuccessAlertMessage(
                    'Your previous arrangement is still under review'
                )
                setSuccessAlert(true)
            } else {
                setPhysicianId(data)
                setOpenAccountNumber(!openAccountNumber)
            }
        } else {
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
            setSuccessAlert(true)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const closePatientAccountNumber = () => {
        // window.location = '/home'
        setOpenAccountNumber(!openAccountNumber)
    }
    const closePhysicianListModal = () => {
        closeOptionModal()
    }
    const sortedUser =
        physicianList &&
        physicianList.physician_list &&
        physicianList.physician_list.sort((a, b) =>
            a.last_name.localeCompare(b.last_name)
        )
    return (
        <>
            {!openAccountNumber && (
                <Modal
                    isOpen={isOpen}
                    // onRequestClose={ () =>closePhysiaicanSelect() }
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="select-physician-modal"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closePhysiaicanSelect()}
                                />
                                <button
                                    type="button"
                                    className="close modal_close_icon z-index1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closePhysicianListModal()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <h4 className="heading">
                                        Select your physician
                                    </h4>
                                    <div className="d-flex flex-column">
                                        {isLoading && (
                                            <div className="text-center mt-5">
                                                <ModalLoader />
                                            </div>
                                        )}
                                        {!isLoading &&
                                            alphabets.map((dx) => {
                                                let checkCount = 0
                                                return (
                                                    sortedUser &&
                                                    sortedUser.map((d, idx) => {
                                                        if (
                                                            dx.toLowerCase() ==
                                                            d.last_name
                                                                .charAt(0)
                                                                .toLowerCase()
                                                        ) {
                                                            checkCount++
                                                            return (
                                                                <div key={idx}>
                                                                    {checkCount ==
                                                                        1 && (
                                                                        <div className="alphabet-divider d-flex align-items-center">
                                                                            <span>
                                                                                {
                                                                                    dx
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <div className="d-flex justify-content-between align-items-center row-padding">
                                                                        <div className="d-flex align-items-center">
                                                                            <Avatar
                                                                                // name={ d.physician_name.split('.')[ 1 ] }
                                                                                name={
                                                                                    d.physician_name &&
                                                                                    d.physician_name.split(
                                                                                        '.'
                                                                                    )[1]
                                                                                }
                                                                                className="profile-image"
                                                                                size="50"
                                                                                round={
                                                                                    true
                                                                                }
                                                                                src={
                                                                                    d.profile_pic
                                                                                }
                                                                            />
                                                                            <div className="d-flex flex-column text-left">
                                                                                <span className="physician-name">
                                                                                    {
                                                                                        d.physician_name
                                                                                    }
                                                                                </span>
                                                                                <span className="physician-info">
                                                                                    {
                                                                                        d.speciality
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <MdKeyboardArrowRight
                                                                            className="float-right font24 icon-margin pointer"
                                                                            onClick={() =>
                                                                                handleSelectPhysician(
                                                                                    d
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                )
                                            })}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <button onClick={ handleNext }> Next </button> */}
                        </div>
                    </div>
                </Modal>
            )}
            {openAccountNumber && (
                <PatientAccountNumberModal
                    isOpen={openAccountNumber}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianId}
                    history={props.history}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}

SelectPhysician.propTypes = {
    closePhysiaicanSelect: PropTypes.func,
    isOpen: PropTypes.bool,
    patientAppointmentId: PropTypes.number,
    closeOptionModal: PropTypes.func,
    history: PropTypes.object,
    physicianIds: PropTypes.array,
}

export default SelectPhysician
