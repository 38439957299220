import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { currencyFormatter } from 'utils/helper'
const PatientTransactionReceipt = (props) => {
    const {
        isOpenTranHistory,
        openTransactionReceiptFunc,
        patTransactionDetails,
    } = props
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy

    const billAmount = patTransactionDetails?.amount || 0
    const transactionFee = patTransactionDetails?.transaction_fee || 0

    const totalAmount = billAmount + parseFloat(transactionFee.toFixed(2))

    return (
        <>
            {isOpenTranHistory && (
                <div className="mobile-bill-details mobile_account_info patient-receipt-page">
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => openTransactionReceiptFunc()}
                        />
                        <label className="nav-heading">Receipt</label>
                        <span></span>
                    </nav>
                    <div className="heading_section d-flex flex-column text-center mt-5">
                        <span className="main-balanceheading">
                            Total Amount
                        </span>
                        <span className="main-amount">
                            {currencyFormatter.format(totalAmount)}
                        </span>
                    </div>
                    <div className="my-4 text-center">
                        <span className="content-heading">Payment details</span>
                    </div>
                    <div className="data-wrapper">
                        <div className="d-flex justify-content-between">
                            <span className="receipt-data">Physician</span>
                            <span className="receipt-data">
                                {patTransactionDetails.physician_name}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="receipt-data">Date</span>
                            <span className="receipt-data">
                                {formattedToday}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="receipt-data">Bill amount</span>
                            <span className="receipt-data">
                                {currencyFormatter.format(billAmount)}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="receipt-data">
                                Transaction fee
                            </span>
                            <span className="receipt-data">
                                {currencyFormatter.format(transactionFee)}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="receipt-total-data">Total</span>
                            <span className="receipt-total-data">
                                {currencyFormatter.format(totalAmount)}
                            </span>
                        </div>
                    </div>
                    {/* })} */}
                </div>
            )}
        </>
    )
}
PatientTransactionReceipt.propTypes = {
    isOpenTranHistory: PropTypes.bool,
    openTransactionReceiptFunc: PropTypes.func,
    patTransactionDetails: PropTypes.object,
}
export default PatientTransactionReceipt
