import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../../../static/stylesheets/account.scss'
import AccountTabsHeader from '../AccountTabsHeader'
import AccountTabs from '../AccountTabs'
import { MdChevronRight } from 'react-icons/md'
import { getTotalAmount } from 'actions/bankingActions'
import BottomBarNavigation from './BottomBarNavigation'
import { currencyFormatter } from 'utils/helper'
import { isMobile } from 'constants/Constant'
export class Account extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accountDetails: true,
            officeInfo: false,
            guidelines: false,
            team: false,
            banking: false,
            userActivity: false,
        }
        this.showSection = this.showSection.bind(this)
    }

    componentDidMount() {
        this.props.getTotalAmount()
    }

    showSection(e) {
        this.setState({
            accountDetails: e.target.id === 'nav-account-tab',
            officeInfo: e.target.id === 'nav-officeInfo-tab',
            guidelines: e.target.id === 'nav-guidlines-tab',
            team: e.target.id === 'nav-team-tab',
            banking: e.target.id === 'nav-banking-tab',
            userActivity: e.target.id === 'nav-activity-tab',
        })
    }
    render() {
        const totalAmount =
            this.props.totalAmountStripe &&
            this.props.totalAmountStripe.total_balance

        const availableToPayOutAmount =
            this.props.totalAmountStripe &&
            this.props.totalAmountStripe.available_to_payout
        return (
            <>
                <AccountTabsHeader history={this.props.history} />
                <div className="wrapper mobile-navbar-page">
                    <div id="content">
                        <div
                            className="d-flex align-items-center justify-content-between balance-heading-wrapper mx-3 mt-4 mb-5 d-md-none d-lg-none d-xl-none"
                            onClick={() =>
                                this.props.history.push({
                                    pathname: '/balance-mobile',
                                    state: {
                                        totalAmount,
                                        availableToPayOutAmount,
                                    },
                                })
                            }
                        >
                            <h3 className="main-heading mb-0">
                                Your account balance
                                <br /> is{' '}
                                {currencyFormatter.format(totalAmount || 0)}
                            </h3>
                            <MdChevronRight className="float-right font24 mt-1 color_dark_grey" />
                        </div>
                        <AccountTabs
                            showSection={this.showSection}
                            history={this.props.history}
                            isMobile={isMobile}
                        />
                    </div>
                    <BottomBarNavigation />
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        totalAmountStripe: state.totalAmountStripe,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTotalAmount: () => dispatch(getTotalAmount()),
    }
}

Account.propTypes = {
    history: PropTypes.object,
    getTotalAmount: PropTypes.func,
    totalAmountStripe: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
