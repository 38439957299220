import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'antd'
import 'antd/dist/antd.css'
import { Table, Divider, Space } from 'antd'
import moment from 'moment'
import {
    getPatientProfileInfo,
    updatePatientProfileInfo,
    sendReminderAction,
} from 'actions/patientActions'
import CurrentHistoryModal from './CurrentHistoryModal'
import SidebarNavigation from '../../../account/SidebarNavigation'
import Loading from '../../../../shared/Loader'
import { HiChevronRight } from 'react-icons/hi'
import PompayTopLogo from 'shared/PompayTopLogo'
import PatientListMobile from '../mobile/PatientListMobile'
import { isMobile } from 'constants/Constant'
import { MdSearch } from 'react-icons/md'
import { IoIosSend } from 'react-icons/io'
import PatientProfileModal from './PatientProfileModal'
import { bolderNameFormatter, currencyFormatter } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'
import { dateFormat } from 'constants/Constant'
import { getPatientList } from 'actions/patient/patient.actions'
import { DueStatus } from 'models/GetPatientListResponse'
import { stringUtils } from 'utils/string'
export class PatientList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCurrentHistory: false,
            totalAccounts: '',
            total_amount: '',
            selectionType: 'checkbox',
            isProfile: false,
            searchValue: '',
            isToday: '',
            paymentStatus: '',
            successAlert: false,
            successMessage: '',
            start_date: '',
            end_date: '',
            input: {},
            columns: [
                {
                    title: 'Name',
                    dataIndex: '',
                    sorter: (a, b) => a.last_name.localeCompare(b.last_name),
                    sortDirections: ['ascend', 'descend'],
                    render: (key) => (
                        <div className="patient-name">
                            {bolderNameFormatter(key)}
                        </div>
                    ),
                },
                {
                    title: 'Patient I.D',
                    dataIndex: 'patient_appointment_id',
                },
                {
                    title: 'Due Date',
                    dataIndex: 'due_date',
                    render: (due_date) => (
                        <>{moment(due_date).format(dateFormat)}</>
                    ),
                },
                {
                    title: 'Days Past Due',
                    dataIndex: 'days_left',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.days_left - b.days_left,
                    render: (days_left) => (
                        <>{days_left ? days_left + ' days' : '0 days'}</>
                    ),
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    sortDirections: ['ascend', 'descend'],
                    render: (amount, data) => {
                        return (
                            <>
                                <span>
                                    {currencyFormatter.format(amount || 0)}{' '}
                                </span>
                                {Boolean(data?.late_fee) && (
                                    <span className="error">
                                        (+
                                        {currencyFormatter.format(
                                            data?.late_fee
                                        )}
                                        )
                                    </span>
                                )}
                            </>
                        )
                    },
                },
                {
                    title: 'Total Amount',
                    dataIndex: 'amount',
                    render: (amount, data) => {
                        return (
                            <b>
                                {currencyFormatter.format(
                                    (amount || 0) + (data?.late_fee || 0)
                                )}
                            </b>
                        )
                    },
                    sorter: (a, b) => a.amount - b.amount,
                },
                {
                    title: <a>Notify</a>,
                    dataIndex: '',
                    key: 'x',
                    render: (key) => (
                        <IoIosSend
                            className="color_dark_grey font20"
                            onClick={() => this.handleReminder(key)}
                        />
                    ),
                },
                {
                    title: '',
                    dataIndex: '',
                    key: 'x',
                    render: (key) => (
                        <Space size="middle">
                            <a
                                className="ant-dropdown-link"
                                // onClick={ () => this.handleOpenProfile(key) }
                                onClick={() =>
                                    this.handleOpenCurrentHistory(key)
                                }
                            >
                                <HiChevronRight className="font18" />
                            </a>
                        </Space>
                    ),
                },
            ],
            patientsInfo: [],
            duePaymentsDetails: [],
            loadingFirstTime: false,
        }
    }

    handleOpenCurrentHistory = (key) => {
        this.setState({ isCurrentHistory: true, currentData: key })
    }
    handleNotifyAll = () => {
        const past_due_details = []
        this.state.data &&
            this.state.data.map((data) => {
                const obj = {}
                obj.patient_id = data.patient_id
                obj.phone_number = data.phone_number
                obj.due_amount = data.amount
                obj.days_past = data.days_left?.toString() ?? '0'
                return past_due_details.push(obj)
            })
        const data = {
            past_due_details: past_due_details,
        }
        this.props.sendReminderAction(data).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    successAlert: true,
                    successMessage:
                        'Reminder has been successfully sent to all patients',
                })
            }
        })
    }
    handleReminder = (key) => {
        const past_due_details = []
        const obj = {}
        obj.patient_id = key.patient_id
        obj.phone_number = key.phone_number
        obj.due_amount = key.amount
        obj.days_past = (key.days_left || 0).toString()
        past_due_details.push(obj)
        const data = {
            past_due_details: past_due_details,
        }

        this.props.sendReminderAction(data).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    successAlert: true,
                    successMessage:
                        'Reminder has been successfully sent to patient',
                })
            }
        })
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    sortDuePaymentDetails = (left, right) => {
        const leftDueDate = new Date(left.due_date).getTime()
        const rightDueDate = new Date(right.due_date).getTime()

        // Past Due first
        if (left.due_status === DueStatus.PastDue) {
            if (right.due_status !== DueStatus.PastDue) return -1

            return leftDueDate - rightDueDate
        }

        // Payments that are due TODAY but have NOT been paid yet
        if (left.due_status === DueStatus.Paid) {
            if (right.due_status !== DueStatus.Paid) return -1

            return leftDueDate - rightDueDate
        }

        // Payments that are due TODAY and HAVE been paid
        if (
            left.due_status === DueStatus.Awaiting &&
            right.due_status !== DueStatus.Awaiting
        ) {
            return -1
        }

        // TODO: Talk with Nicholas
        // Past due payments that were paid today will appear at the bottom
        // Future payments
        return leftDueDate - rightDueDate
    }

    componentDidMount() {
        this.setState({
            loadingFirstTime: true,
        })
        this.props.getPatientList(this.props.checked).then((res) => {
            if (res.statusCode === 200) {
                const duePay = res.data && res.data.patient_info
                const payDetailsArray = []
                duePay.map((d) => {
                    payDetailsArray.push(d)
                })
                const patients = []
                const patientsPaymentsDetails = []
                res.data?.patient_info?.forEach((patientInfo) => {
                    patients.push(patientInfo)

                    if (patientInfo.due_payments_details?.length > 0) {
                        patientInfo.due_payments_details.forEach(
                            (duePaymentsDetail) =>
                                patientsPaymentsDetails.push(duePaymentsDetail)
                        )
                    }
                })
                this.setState({
                    patientsInfo: patients,
                    duePaymentsDetails: patientsPaymentsDetails,
                    totalAccounts: res.data.accounts,
                    total_amount: res.data.total_past_due_amount,
                    paid_amount: res.data.paid_amount,
                    unpaid_amount: res.data.unpaid_amount,
                })
            }
            this.setState({
                loadingFirstTime: false,
            })
        })
    }

    handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    handleOpenProfile(key) {
        this.setState({ isProfile: true, profileDetail: key })
        this.props.getPatientProfileInfo(key.patient_id).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    input: res.data,
                })
            }
        })
    }
    closeModal = () => {
        //   this.setState({ isProfile: false });
        this.setState({ isCurrentHistory: false })
    }
    updateSearch = (event) => {
        if (event.target.key === 'Enter') {
            event.preventDefault()
        } else {
            this.setState({ searchValue: event.target.value.substr(0, 20) })
        }
    }
    handleDropDown = (event) => {
        const dateValue = event.target.value
        const filterFlag = true
        var date = new Date()
        let start_date = ''
        let end_date = ''
        if (dateValue === '1-30') {
            start_date = moment(new Date()).format('MM/DD/YYYY')
            end_date = moment(date.setDate(date.getDate() - 30)).format(
                'MM/DD/YYYY'
            )
        } else if (dateValue === '31-60') {
            start_date = moment(date.setDate(date.getDate() - 31)).format(
                'MM/DD/YYYY'
            )
            end_date = moment(date.setDate(date.getDate() - 60)).format(
                'MM/DD/YYYY'
            )
        } else if (dateValue === '61-90') {
            start_date = moment(date.setDate(date.getDate() - 61)).format(
                'MM/DD/YYYY'
            )
            end_date = moment(date.setDate(date.getDate() - 90)).format(
                'MM/DD/YYYY'
            )
        } else if (dateValue === '91+') {
            start_date = moment(date.setDate(date.getDate() - 91)).format(
                'MM/DD/YYYY'
            )
            end_date = '01/01/1893'
        }

        this.setState({ start_date, end_date }, () => {
            this.props.getPatientList(this.state, filterFlag).then((res) => {
                if (res.statusCode === 200) {
                    const duePay = res.data && res.data.patient_info
                    const payDetailsArray = []
                    duePay.map((d) => {
                        payDetailsArray.push(d)
                    })
                    const patients = []
                    const patientsPaymentsDetails = []
                    res.data?.patient_info?.forEach((patientInfo) => {
                        patients.push(patientInfo)

                        if (patientInfo.due_payments_details?.length > 0) {
                            patientInfo.due_payments_details.forEach(
                                (duePaymentsDetail) =>
                                    patientsPaymentsDetails.push(
                                        duePaymentsDetail
                                    )
                            )
                        }
                    })
                    this.setState({
                        patientsInfo: patients,
                        duePaymentsDetails: patientsPaymentsDetails,
                        totalAccounts: res.data.accounts,
                        total_amount: res.data.total_past_due_amount,
                        paid_amount: res.data.paid_amount,
                        unpaid_amount: res.data.unpaid_amount,
                    })
                }
            })
        })
    }
    handleProfileChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
        })
    }
    updateProfileInfo = () => {
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            zipcode,
            date_of_birth,
            patient_id,
            patient_appointment_id,
        } = this.state.input

        const data = {
            first_name: first_name,
            last_name: last_name,
            patient_id: patient_id,
            patient_appointment_id: patient_appointment_id,
            email_id: email_id,
            phone_number: phone_number,
            zipcode: zipcode,
            date_of_birth: date_of_birth,
        }

        this.props.updatePatientProfileInfo(data, patient_id).then((res) => {
            if (res.statusCode === 200) {
                setTimeout(() => {
                    this.props.getPatientList('')
                }, 500)
            }
        })
    }
    render() {
        const {
            duePaymentsDetails,
            columns,
            //selectionType,
            totalAccounts,
            total_amount,
            isProfile,
            profileDetail,
            searchValue: searchValue,
            paid_amount,
            unpaid_amount,
            input,
            successAlert,
            successMessage,
            isCurrentHistory,
            currentData,
            loadingFirstTime,
        } = this.state

        const { isLoading, paymentActivityData, accountDetails } = this.props
        // const rowSelection = {
        //     getCheckboxProps: (record) => ({
        //         disabled: record.name === 'Disabled User',
        //         // Column configuration not to be checked
        //         name: record.name,
        //     }),
        // }
        const filteredData = duePaymentsDetails?.filter((details) => {
            const firstValue = details?.first_name.toLowerCase()
            const secondValue = details?.last_name.toLowerCase()
            const first_last_name = `${firstValue} ${secondValue}`.trim()
            const last_first_name = `${secondValue} ${firstValue}`.trim()

            return (
                !searchValue ||
                stringUtils.containsIC(details.first_name, searchValue) ||
                stringUtils.containsIC(details.last_name, searchValue) ||
                stringUtils.containsIC(first_last_name, searchValue) ||
                stringUtils.containsIC(last_first_name, searchValue) ||
                stringUtils.containsIC(
                    details?.patient_appointment_id,
                    searchValue
                )
            )
        })

        return (
            <>
                {!isMobile && (
                    <div className="pastDue-list-page">
                        <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar">
                            <PompayTopLogo className="small_logo patient_list_logo" />
                            <ul className="navbar-nav mr-auto patient_list_custom_navbar">
                                <li className="nav-item d-flex align-items-center ml-4 mr-3 toggle_nav_btn">
                                    <span className="mr-2 font12">
                                        Past Due
                                    </span>
                                    <Switch
                                        defaultChecked={this.props.checked}
                                        onChange={(check) =>
                                            this.props.onToggleSwitchChange(
                                                check
                                            )
                                        }
                                    />
                                </li>
                                <li className="nav-item">
                                    <div className="form-group m-0">
                                        <label className="wrap-select mr-3">
                                            <select
                                                className="form-control header_drop_down"
                                                name="dateRange"
                                                onChange={this.handleDropDown}
                                            >
                                                <option selected="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="1-30"
                                                >
                                                    1-30
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="31-60"
                                                >
                                                    31-60
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="61-90"
                                                >
                                                    61-90
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="91+"
                                                >
                                                    91+
                                                </option>
                                            </select>
                                        </label>
                                    </div>
                                </li>
                                <div className="vertical-dash"></div>
                                <li className="nav-item search_wrapper d-flex align-items-center">
                                    <form
                                        className="form-inline"
                                        autoComplete="off"
                                    >
                                        <MdSearch className="color_dark_grey font20 m-2" />
                                        <input
                                            className="form-control mr-sm-2 patient_search"
                                            type="search"
                                            name="patientSearchParam"
                                            value={searchValue || ''}
                                            onChange={this.updateSearch}
                                            placeholder="Search"
                                            aria-label="Search"
                                            autoComplete="off"
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' &&
                                                    e.preventDefault()
                                            }}
                                        />
                                    </form>
                                </li>
                            </ul>
                            <div className="d-flex patient_right_nav">
                                <div className="vertical-dash"></div>
                                <div className="d-flex justify-content-center flex-column margin_XY30">
                                    <span className="digit">
                                        {totalAccounts}
                                    </span>
                                    <span className="cash-flow">
                                        Payment(s)
                                    </span>
                                </div>
                                <div className="mini-vertical-dash"></div>
                                <div className="d-flex marginX">
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2 nav-label">
                                                {' '}
                                                Total
                                            </span>
                                            <span className="total_amount">
                                                {currencyFormatter.format(
                                                    total_amount || 0
                                                )}
                                            </span>
                                        </div>
                                        {/* <div className="select-item-dropdown-wrapper">
                                            <div className="form-group m-0">
                                                <label className="wrap-Selected-Items">
                                                    <select
                                                        className="form-control select-item-dropdown"
                                                        name="print"
                                                        //   onChange={ () => () }
                                                    >
                                                        <option defaultValue="select day">
                                                            {' '}
                                                            Selected Items
                                                        </option>
                                                        <option
                                                            name="print"
                                                            value="today"
                                                        >
                                                            Print Transactions
                                                        </option>
                                                        <option
                                                            name="print"
                                                            value="today"
                                                        >
                                                            Mark as Posted
                                                        </option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="wrapper">
                            <SidebarNavigation history={this.props.history} />

                            <div id="content">
                                <Divider />
                                {(isLoading || loadingFirstTime) && (
                                    <div className="mt-5 mx-4">
                                        <Loading />
                                    </div>
                                )}
                                {!isLoading && !loadingFirstTime && (
                                    <div className="past-due-table-container">
                                        <Table
                                            // rowSelection={{
                                            //     type: selectionType,
                                            //     ...rowSelection,
                                            // }}
                                            columns={columns}
                                            dataSource={filteredData}
                                            onChange={this.handleChange}
                                            scroll={{ y: 535 }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {isMobile && (
                    <PatientListMobile
                        data={filteredData}
                        paid_amount={paid_amount}
                        total_amount={total_amount}
                        unpaid_amount={unpaid_amount}
                        history={history}
                        updateSearch={this.updateSearch}
                        searchValue={searchValue}
                    />
                )}
                {isProfile && (
                    <PatientProfileModal
                        profileDetail={profileDetail}
                        isOpen={isProfile}
                        closeModal={this.closeModal}
                        input={input}
                        handleProfileChange={this.handleProfileChange}
                        updateProfileInfo={this.updateProfileInfo}
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successMessage}
                    />
                )}
                {isCurrentHistory && (
                    <CurrentHistoryModal
                        history={history}
                        isOpen={isCurrentHistory}
                        balanceDetails={paymentActivityData}
                        closeModal={this.closeModal}
                        currentData={currentData}
                        physicianId={
                            accountDetails &&
                            accountDetails.physician &&
                            accountDetails.physician[0] &&
                            accountDetails.physician[0].physician_id
                        }
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        paymentActivityData: state.paymentActivityData,
        accountDetails: state.accountDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientList: (data, flag) => dispatch(getPatientList(data, flag)),
        getPatientProfileInfo: (data) => dispatch(getPatientProfileInfo(data)),
        sendReminderAction: (data) => dispatch(sendReminderAction(data)),
        updatePatientProfileInfo: (data, id) =>
            dispatch(updatePatientProfileInfo(data, id)),
    }
}
PatientList.propTypes = {
    getPatientList: PropTypes.func,
    onToggleSwitchChange: PropTypes.func,
    getPatientProfileInfo: PropTypes.func,
    sendReminderAction: PropTypes.func,
    updatePatientProfileInfo: PropTypes.func,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
    checked: PropTypes.bool,
    paymentActivityData: PropTypes.object,
    accountDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientList)
