import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { isMobile } from 'constants/Constant'
import { dateFormat } from 'constants/Constant'
import moment from 'moment'

const PatientArrangementDetailsMobile = (props) => {
    const profileData = props.location.state.data
    return (
        <>
            {isMobile && (
                <div
                    className="patient-profile patient-profile-page mobile_account_info"
                    id="content"
                >
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">
                            Arrangement Details
                        </label>
                        <span></span>
                    </nav>
                    <div className="row no-gutters">
                        <div className="col-md-12 p-0">
                            <form>
                                <div className=" flex-column">
                                    <div className="form-row p-0">
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.first_name ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="fname"
                                                        disabled
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="fname"
                                                    >
                                                        First name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.last_name ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="lname"
                                                        disabled
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="lname"
                                                    >
                                                        Last name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="input-group date_field d-flex">
                                                <div className="input-group-prepend">
                                                    <input
                                                        disabled
                                                        defaultValue={moment(
                                                            profileData.date_of_birth
                                                        ).format(dateFormat)}
                                                        type="text"
                                                        id="dob"
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="dob"
                                                        style={{ zIndex: '1' }}
                                                    >
                                                        Date of birth
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        type="number"
                                                        id="patient-id"
                                                        disabled
                                                        defaultValue={
                                                            profileData.patient_appointment_id
                                                        }
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="patient-id"
                                                    >
                                                        Patient I.D
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        type="number"
                                                        id="o-amt"
                                                        disabled
                                                        value={
                                                            profileData.original_amount ===
                                                            0
                                                                ? 0
                                                                : (profileData.original_amount &&
                                                                      parseFloat(
                                                                          profileData.original_amount
                                                                      ).toFixed(
                                                                          2
                                                                      )) ||
                                                                  ''
                                                        }
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="o-amt"
                                                    >
                                                        Original amount
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        type="number"
                                                        id="disc-amt"
                                                        disabled
                                                        value={
                                                            profileData.discount_amount ===
                                                            0
                                                                ? 0
                                                                : parseFloat(
                                                                      profileData.discount_amount
                                                                  ).toFixed(
                                                                      2
                                                                  ) || 0
                                                        }
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="disc-amt"
                                                    >
                                                        Discount amount
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        type="number"
                                                        id="adj-amt"
                                                        disabled
                                                        value={
                                                            profileData.adjusted_amount ===
                                                            0
                                                                ? 0
                                                                : (profileData.adjusted_amount &&
                                                                      parseFloat(
                                                                          profileData.adjusted_amount
                                                                      ).toFixed(
                                                                          2
                                                                      )) ||
                                                                  ''
                                                        }
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="adj-amt"
                                                    >
                                                        Adj. amount
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
PatientArrangementDetailsMobile.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}

export default PatientArrangementDetailsMobile
