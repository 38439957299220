import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import '../../static/stylesheets/admin.scss'
import AdminHeader from './AdminHeader'
import AdminFooter from './AdminFooter'
import AdminSideBarNavigation from './AdminSideBarNavigation'
import AdminBreadCrumb from './AdminBreadCrumb'
import { getAdminDashboardData } from 'actions/adminActions'
import { numberWithCommas } from 'utils/helper'
import { FaStethoscope } from 'react-icons/fa'
import { MdPersonOutline } from 'react-icons/md'
import { AiOutlineTeam } from 'react-icons/ai'
import { FaMoneyBillAlt } from 'react-icons/fa'

function AdminDashboard(props) {
    const dispatch = useDispatch()
    const { history } = props
    useEffect(() => {
        dispatch(getAdminDashboardData())
    }, [''])
    const dashboardDetails = useSelector((state) => state.adminDashboardDetails)
    return (
        <div className="dashboard-main-wrapper admin-dashboard">
            <AdminHeader history={history} />
            <AdminSideBarNavigation history={history} />
            <div className="dashboard-wrapper">
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header">
                                <h3 className="mb-2 text-left">
                                    Pompay Dashboard{' '}
                                </h3>
                                <AdminBreadCrumb
                                    routes={[
                                        {
                                            path: '/admin-dashboard',
                                            name: 'Dashboard',
                                        },
                                        {
                                            name: 'All data',
                                            isActive: true,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-body text-left px-3">
                                    <div className="d-inline-block">
                                        <h5 className="text-muted">
                                            Total Physician
                                        </h5>
                                        <h2 className="mb-0">
                                            {numberWithCommas(
                                                dashboardDetails.total_physician
                                            )}
                                        </h2>
                                    </div>
                                    <div className="float-right icon-circle-medium  icon-box-lg  bg-pompay-light mt-1">
                                        <FaStethoscope className="text-pompay font24" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-body text-left px-3">
                                    <div className="d-inline-block">
                                        <h5 className="text-muted">
                                            Total Patient
                                        </h5>
                                        <h2 className="mb-0">
                                            {numberWithCommas(
                                                dashboardDetails.total_patient
                                            )}
                                        </h2>
                                    </div>
                                    <div className="float-right icon-circle-medium  icon-box-lg  bg-pompay-light mt-1">
                                        <MdPersonOutline
                                            className="text-pompay font32"
                                            style={{ marginTop: '-5px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-body text-left px-3">
                                    <div className="d-inline-block">
                                        <h5 className="text-muted">
                                            Total Team Members
                                        </h5>
                                        <h2 className="mb-0">
                                            {numberWithCommas(
                                                dashboardDetails.total_physician_team
                                            )}
                                        </h2>
                                    </div>
                                    <div className="float-right icon-circle-medium  icon-box-lg  bg-pompay-light mt-1">
                                        <AiOutlineTeam
                                            className="text-pompay font30"
                                            style={{ marginTop: '-3px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="card">
                                <div className="card-body text-left px-3">
                                    <div className="d-inline-block">
                                        <h5 className="text-muted">
                                            Total Discount
                                        </h5>
                                        <h2 className="mb-0">
                                            {' '}
                                            {numberWithCommas(
                                                dashboardDetails.total_prompmtpay_discount
                                            )}
                                        </h2>
                                    </div>
                                    <div className="float-right icon-circle-medium  icon-box-lg  bg-pompay-light mt-1">
                                        <FaMoneyBillAlt
                                            className="text-pompay font30"
                                            style={{ marginTop: '-3px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- ============================ footer ================================= --> */}
                <AdminFooter />
                {/* <!-- ============================ end footer ================================= --> */}
            </div>
        </div>
    )
}

AdminDashboard.propTypes = {
    isOpen: PropTypes.bool,
    history: PropTypes.object,
}

export default AdminDashboard
