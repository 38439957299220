import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from '../utils/helper'
import { fetchTeamMember } from './authActions'
import { triggerNotifier } from 'shared/notifier'

export function cardModal(bool) {
    return {
        type: 'CARD_MODAL',
        cardModal: bool,
    }
}
export function fetchPhysicianStripeAccount(bool) {
    return {
        type: 'FETCH_PHYSICIAN_STRIPE_ACCOUNT',
        isStripeAccount: bool,
    }
}

export function fetchCardListing(cardListing) {
    return {
        type: 'FETCH_CARD_LISTING',
        cardListing,
    }
}

export function fetchTransDetails(transDetails) {
    return {
        type: 'FETCH_TRANS_DETAILS',
        transDetails,
    }
}
export function fetchTotalAmount(totalAmountStripe) {
    return {
        type: 'FETCH_TOTAL_AMOUNT',
        totalAmountStripe,
    }
}

export function getPhysicianAccountExists() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/account/exists',
            headers: {
                'content-type': 'multipart/form-data',
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPhysicianStripeAccount(res.data.account_exists))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function sendBankingAuthDeatils(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/stripe/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'multipart/form-data',
            },
            data: data,
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPhysicianAccountExists())
                dispatch(getCardListing())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function sendCardToken(id) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/card',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(id) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getCardListing())
                dispatch(cardModal(true))
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getCardListing() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/cardList',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchCardListing(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function removeCard(id) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + '/physician/card/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                // dispatch(applicationIsLoading(false));
                dispatch(getCardListing())
                return res
            })
            .catch((error) => {
                // dispatch(applicationIsLoading(false));
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function transactionDetails() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/transaction',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchTransDetails(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Physician funds tranfers

export function sendPhysicianTransferData(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/transfer/fund',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getTotalAmount())
                dispatch(transactionDetails())
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getTotalAmount() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/total/balance',
            headers: {
                'content-type': 'multipart/form-data',
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchTotalAmount(res.data))
                dispatch(
                    fetchTeamMember(
                        res.data &&
                            res.data.is_team_member &&
                            res.data.is_team_member
                            ? res.data.is_team_member
                            : 0
                    )
                )
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// send error for bank account

export function sendErrorAction(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/sendStripeError',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}
