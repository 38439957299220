import React from 'react'

function SubFooter() {
    return (
        <div className="footer admin-dashboard">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left">
                        Copyright © 2021 Concept. All rights reserved by{' '}
                        <a href="#">Pompay</a>.
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubFooter
