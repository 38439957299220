import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
const unAuthorizedRoutes = ['/admin']

const AdminRoutes = ({ component, path }) => {
    const isAdmin = useSelector((state) => state.isAdmin)
    if (!isAdmin) {
        if (!unAuthorizedRoutes.includes(path)) {
            return <Redirect to="/admin" />
        } else {
            return <Route exact path={path} component={component} />
        }
    }
    if (isAdmin) {
        if (unAuthorizedRoutes.includes(path)) {
            return <Redirect to="/admin" />
        } else {
            return <Route exact path={path} component={component} />
        }
    }
}

export default AdminRoutes
