import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
// import PatientSuccessReceipt from './PatientSuccessReceipt';
import AddBankCard from 'components/account/AddBankCard'
// import { BsThreeDots } from 'react-icons/bs';
import { BsCreditCard } from 'react-icons/bs'
import {
    getAccountExists,
    sendCustomerPatientAccount,
    getPatientCardListing,
    // sendTransferFundData
} from 'actions/patientBankingActions'
import Loading from 'shared/Loader'
// import { currencyFormatter } from 'utils/helper';
import { RiCalendar2Fill } from 'react-icons/ri'
import { MdInfoOutline } from 'react-icons/md'
import PromisePayModal from 'shared/PromisePayModal'
import SelectBillDateMobile from 'components/patient/mobile/SelectBillDateMobile'
import FinalAmountToPayMobile from './FinalAmountToPayMobile'
import SuccessAlert from 'shared/SuccessAlert'
import FinalFullAmountToPayMobile from './FinalFullAmountToPayMobile'

const PatientPayMethod = (props) => {
    const dispatch = useDispatch()
    const {
        payMethodPage,
        handlePayMethodPage,
        history,
        outStandingBalance,
        installmentDetails,
        physicianDetails,
        remaningBalancePageDetails,
        paymentActivityData,
        isRemainingPage,
        globalPayment,
        fullPayment,
    } = props
    const [isAddBankCard, setIsAddBankCard] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [openPromptInfo, setOpenPromptInfo] = useState(false)
    const [openSelectDate, setOpenSelectDate] = useState(false)
    const [patientFinalPay, setPatientFinalPay] = useState(false)
    const [selectedCardDetails, setSelectedCardDetails] = useState({})
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [selectedCardError, setSelectedCardError] = useState(false)
    const [physicainData, setPhysicainData] = useState()
    const patientCardListing = useSelector((state) => state.patientCardListing)
    const transferFundData = useSelector((state) => state.transferFundData)
    const paymentScheduleDetails = useSelector(
        (state) => state.paymentScheduleDetails
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getAccountExists()).then((res) => {
            if (res.statusCode === 200) {
                if (!res.data.account_exists) {
                    dispatch(sendCustomerPatientAccount())
                } else {
                    dispatch(getPatientCardListing())
                }
            }
        })
        if (globalPayment) {
            const data = []
            for (let i = 0; i < transferFundData.length; i++) {
                data.push({
                    physician_name: `${transferFundData[i].physician.first_name} ${transferFundData[i].physician.last_name}`,
                    paidFees: transferFundData[i].payment_schedules[0].amount,
                })
            }
            setPhysicainData(data)
        }
    }, [''])
    const addbankCardFunc = () => {
        setIsAddBankCard(!isAddBankCard)
    }
    const addbankCardBackFunc = () => {
        setIsAddBankCard(!isAddBankCard)
    }
    const handleSelectCard = (data) => {
        setSelectedCardDetails(data)
        setSelectedCardError(false)
    }
    const handlePatientSuccessReceipt = () => {
        if (globalPayment) {
            setPatientFinalPay(!patientFinalPay)
        } else if (Object.keys(selectedCardDetails).length === 0) {
            setSelectedCardError(true)
        } else {
            setPatientFinalPay(!patientFinalPay)
        }
    }
    const closeFinalPayAmount = () => {
        setPatientFinalPay(!patientFinalPay)
    }
    const handlePromisePay = () => {
        if (
            remaningBalancePageDetails &&
            remaningBalancePageDetails.enable_promise_to_pay
        ) {
            if (
                remaningBalancePageDetails &&
                remaningBalancePageDetails.days_past_count
            ) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'You are not eligible for promise to pay'
                )
            } else {
                setOpenSelectDate(!openSelectDate)
            }
        } else {
            setSuccessAlert(true)
            setSuccessAlertMessage(
                'This physician has not enabled promise to pay'
            )
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const handlePompayInfo = () => {
        setOpenPromptInfo(!openPromptInfo)
    }
    const enabled =
        patientCardListing &&
        patientCardListing?.cardList?.length > 0 &&
        Object.keys(selectedCardDetails).length > 0
    return (
        <>
            {payMethodPage &&
                !isAddBankCard &&
                !openPromptInfo &&
                !openSelectDate &&
                !patientFinalPay && (
                    <div className="mobile_account_info payment-method-page">
                        <div id="content" className="mb-0">
                            <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => handlePayMethodPage()}
                                />
                                <label className="nav-heading">
                                    Payment Method
                                </label>
                                {/* <BsThreeDots className="color_dark_grey font20 mr-2" /> */}
                            </nav>
                            <div className="row no-gutters banking  mobile-content-height">
                                <div className="col-md-12">
                                    <div className="page-label p-3">
                                        Select payment method
                                    </div>
                                    <div
                                        style={{
                                            maxHeight: '58.8vh',
                                            overflow: 'auto',
                                        }}
                                    >
                                        <table className="table table-borderless office_info mb-0">
                                            <tbody className="border-0 linked-accounts">
                                                {selectedCardError && (
                                                    <div className="my-3">
                                                        <small className="alert alert-danger alert-error-msg">
                                                            Please select
                                                            account
                                                        </small>
                                                    </div>
                                                )}
                                                {isLoading && (
                                                    <div className="p-3 d-flex justify-content-center">
                                                        <Loading />
                                                    </div>
                                                )}
                                                {!isLoading &&
                                                    patientCardListing &&
                                                    patientCardListing.cardList.map(
                                                        (carList, id) => {
                                                            return (
                                                                <tr
                                                                    className="table-border"
                                                                    key={id}
                                                                >
                                                                    <td className="border-0 d-flex align-items-center table-row-padding">
                                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                                            <div className="d-flex align-items-center">
                                                                                <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                                                <div className="ml-3">
                                                                                    <div className="office_address">
                                                                                        {
                                                                                            carList.card_type
                                                                                        }{' '}
                                                                                        ****
                                                                                        {
                                                                                            carList.card_number
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <input
                                                                                type="radio"
                                                                                className="account-radio-btn mr-2"
                                                                                name="card"
                                                                                value={
                                                                                    carList.id
                                                                                }
                                                                                id={
                                                                                    id
                                                                                }
                                                                                onClick={() =>
                                                                                    handleSelectCard(
                                                                                        carList
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                {isRemainingPage === true ? (
                                                    ''
                                                ) : (
                                                    <tr>
                                                        <td className="px-0 d-flex align-items-center">
                                                            <div className="d-flex justify-content-between align-items-center w-100 px-3">
                                                                <div className="d-flex align-items-center">
                                                                    <RiCalendar2Fill className="account-icon font22" />
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="office_name mr-2 pointer"
                                                                            onClick={() =>
                                                                                handlePromisePay()
                                                                            }
                                                                        >
                                                                            Promise
                                                                            to
                                                                            Pay
                                                                        </div>
                                                                        <MdInfoOutline
                                                                            className="info-icon pointer"
                                                                            onClick={
                                                                                handlePompayInfo
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <button
                                        className="btn btn-link px-3 py-2 add_element_link"
                                        onClick={addbankCardFunc}
                                    >
                                        Add a Card…
                                    </button>
                                </div>
                            </div>
                            <div className="px-3">
                                <button
                                    disabled={!enabled}
                                    className="btn mobile-green-btn w-100"
                                    onClick={handlePatientSuccessReceipt}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            {isAddBankCard && (
                <div
                    className="mobile_account_info payment-method-page"
                    id="content"
                >
                    <AddBankCard
                        addbankCardFunc={addbankCardFunc}
                        isVisiable={true}
                        addbankCardBackFunc={addbankCardBackFunc}
                    />
                </div>
            )}
            {openPromptInfo && (
                <PromisePayModal
                    isOpen={openPromptInfo}
                    handlePompayInfo={handlePompayInfo}
                />
            )}
            {openSelectDate && (
                <SelectBillDateMobile
                    isOpen={openSelectDate}
                    closeSelectBill={handlePromisePay}
                    paymentScheduleDetails={paymentScheduleDetails}
                    history={history}
                    promisePayKey="promisePay"
                    installBillDetails={installmentDetails}
                    outStandingBalance={outStandingBalance}
                    physicianDetails={physicianDetails}
                />
            )}
            {!fullPayment && patientFinalPay && (
                <FinalAmountToPayMobile
                    isOpen={patientFinalPay}
                    installBillDetails={installmentDetails}
                    history={history}
                    closeFinalPayAmount={closeFinalPayAmount}
                    selectedCardDetails={selectedCardDetails}
                    physicianDetails={physicianDetails}
                    remaningBalancePageDetails={remaningBalancePageDetails}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                    description="Please consult your physician."
                />
            )}
            {fullPayment && patientFinalPay && !globalPayment && (
                <FinalFullAmountToPayMobile
                    isOpen={patientFinalPay}
                    installBillDetails={installmentDetails}
                    history={history}
                    closeFinalPayAmount={closeFinalPayAmount}
                    selectedCardDetails={selectedCardDetails}
                    physicianDetails={physicianDetails}
                    remaningBalancePageDetails={remaningBalancePageDetails}
                    fullAmount={fullPayment}
                    paymentActivityData={paymentActivityData}
                    globalPayment={globalPayment}
                />
            )}
            {fullPayment && patientFinalPay && globalPayment && (
                <FinalFullAmountToPayMobile
                    isOpen={patientFinalPay}
                    installBillDetails={installmentDetails}
                    history={history}
                    closeFinalPayAmount={closeFinalPayAmount}
                    selectedCardDetails={selectedCardDetails}
                    physicianDetails={physicainData}
                    remaningBalancePageDetails={remaningBalancePageDetails}
                    fullAmount={fullPayment}
                    paymentActivityData={paymentActivityData}
                    globalPayment={globalPayment}
                />
            )}
        </>
    )
}
PatientPayMethod.propTypes = {
    payMethodPage: PropTypes.bool,
    handlePayMethodPage: PropTypes.func,
    history: PropTypes.object,
    physicianDetails: PropTypes.object,
    individualPayKey: PropTypes.string,
    paymentScheduleDetails: PropTypes.object,
    outStandingBalance: PropTypes.number,
    transferFundData: PropTypes.array,
    installmentDetails: PropTypes.object,
    remaningBalancePageDetails: PropTypes.object,
    isRemainingPage: PropTypes.bool,
    globalPayment: PropTypes.bool,
    paymentActivityData: PropTypes.object,
    fullPayment: PropTypes.object,
}
export default PatientPayMethod
