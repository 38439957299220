import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
    getPatientOutstandingBalance,
    getTransferFundsData,
    sendTransferFundData,
} from 'actions/patientBankingActions'
import { calculateTransactionFee, currencyFormatter } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'
import Loading from 'shared/Loader'

function TransferPayment(props) {
    const dispatch = useDispatch()
    const [selectedCard, setSelectedCard] = useState('')
    const [selectedCardError, setSelectedCardError] = useState(false)
    const [isConfirmToPay, setIsConfirmToPay] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const patientCardListing = props.patientCardListing
    const obj = {
        physicianNameArray: [],
        // patientInstallmentArray: [],
    }
    const transferFundData = useSelector((state) => state.transferFundData)
    const patientOutstandingBalance = useSelector(
        (state) => state.patientOutstandingBalance
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getTransferFundsData())
        dispatch(getPatientOutstandingBalance())
    }, [''])
    const handleChangeOptions = (event) => {
        setSelectedCard(event.target.value)
        setSelectedCardError(false)
    }
    const allCardList = patientCardListing?.sort().map((data, index) => (
        <option key={index} value={data.card_id}>
            {data.card_type} {'****' + data.card_number}
        </option>
    ))
    const physicianFilterData =
        transferFundData &&
        transferFundData.map((d) => {
            return d.physician
        })
    physicianFilterData &&
        physicianFilterData.map((data) => {
            return obj.physicianNameArray.push(
                data.first_name + ' ' + data.last_name
            )
        })
    const uniqueChars = [...new Set(obj.physicianNameArray)]

    var result =
        transferFundData &&
        transferFundData.map((data) => ({
            amount: data.totalInstallment,
            physician_id: data.id,
            account_id: data.stripe_account_id,
            payment_schedules: data.payment_schedules,
        }))
    const confirmSubmit = () => {
        if (selectedCard !== '') {
            setIsConfirmToPay(!isConfirmToPay)
        } else {
            setSelectedCardError(true)
        }
    }
    const handleSubmit = () => {
        const data = { physician_detail: result, card_id: selectedCard }
        if (selectedCard !== '') {
            dispatch(sendTransferFundData(data)).then((res) => {
                if (res.statusCode === 200) {
                    setSuccessAlert(true)
                    dispatch(getTransferFundsData())
                    dispatch(getPatientOutstandingBalance())
                }
            })
        } else {
            setSelectedCardError(true)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const enabled =
        patientOutstandingBalance &&
        patientOutstandingBalance.total_remaining_balance > 0

    const totalRemainingBalance =
        patientOutstandingBalance?.total_remaining_balance ?? 0

    const transactionFee = calculateTransactionFee(totalRemainingBalance)

    const totalAmount = transactionFee + totalRemainingBalance

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && (
                <div className="col-md-6 transfer-payment">
                    <div className="card tranfer_fund_card d-block m-auto">
                        <div className="card-body">
                            <h5 className="card-title text-left">
                                Pay Remaining Balance
                            </h5>
                            <form>
                                <div className="form-row flex-column">
                                    <div className="col-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <select
                                                    className="form-control account_info_field"
                                                    id="select-card"
                                                    name={selectedCard}
                                                    value={selectedCard}
                                                    onChange={
                                                        handleChangeOptions
                                                    }
                                                >
                                                    <option defaultValue="select card">
                                                        {' '}
                                                        Select card
                                                    </option>
                                                    {allCardList}
                                                </select>
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="select-card"
                                                >
                                                    From
                                                </label>
                                            </div>
                                        </div>
                                        {selectedCardError && (
                                            <small className="text-danger">
                                                please select card
                                            </small>
                                        )}
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <textarea
                                                    type="text"
                                                    id="text-area"
                                                    // cols="40"
                                                    // rows="5"
                                                    disabled
                                                    className="form-control account_info_field to-textbox"
                                                    defaultValue={uniqueChars.join(
                                                        '\r\n'
                                                    )}
                                                />
                                                <label
                                                    className="input-group-text account_info_label to-label"
                                                    htmlFor="text-area"
                                                >
                                                    To
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-icon left">
                                                    <input
                                                        type="text"
                                                        id="amt"
                                                        disabled
                                                        className="form-control account_info_field amount-field"
                                                        defaultValue={
                                                            patientOutstandingBalance.total_remaining_balance
                                                                ? patientOutstandingBalance.total_remaining_balance.toFixed(
                                                                      2
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="amt"
                                                    >
                                                        Amount
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-icon left">
                                                    <input
                                                        type="text"
                                                        id="amt"
                                                        disabled
                                                        className="form-control account_info_field amount-field"
                                                        defaultValue={transactionFee.toFixed(
                                                            2
                                                        )}
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="amt"
                                                    >
                                                        Transaction fee
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {isConfirmToPay && (
                                <>
                                    <p className="pending_tranfer_label text-left mt-2">
                                        Are you sure want to pay the remaining
                                        balance of{' '}
                                        {currencyFormatter.format(
                                            patientOutstandingBalance.total_remaining_balance ||
                                                0
                                        )}{' '}
                                        +{' '}
                                        {currencyFormatter.format(
                                            transactionFee
                                        )}
                                    </p>
                                    <button
                                        className="btn review_transfer_btn d-flex justify-content-center align-items-center"
                                        onClick={handleSubmit}
                                        disabled={!enabled}
                                    >
                                        Confirm Pay{' '}
                                        {currencyFormatter.format(totalAmount)}
                                    </button>
                                    <button
                                        className="btn btn-link cancel_link"
                                        onClick={() => confirmSubmit()}
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                            {!isConfirmToPay && (
                                <button
                                    className="btn review_transfer_btn d-flex justify-content-center align-items-center  mt-2"
                                    onClick={confirmSubmit}
                                    disabled={!enabled}
                                >
                                    Pay {currencyFormatter.format(totalAmount)}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    role="Fund"
                    message="Your fund has been transfered successfully"
                />
            )}
        </>
    )
}

TransferPayment.propTypes = {
    getTransferFundsData: PropTypes.func,
    sendTransferFundData: PropTypes.func,
    handleSubmit: PropTypes.func,
    transferFundData: PropTypes.array,
    patientCardListing: PropTypes.array,
}

export default TransferPayment
