import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import Loading from 'shared/Loader'
const PatientArrangementDetailsModal = (props) => {
    const {
        input,
        updateReviewArrangementFunc,
        handleArrangementChange,
        isOpen,
        closeModal,
    } = props
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={MODAL_STYLES}
                contentLabel="Example Modal"
                ariaHideApp={false}
                id="EditArrangement"
            >
                <button
                    type="button"
                    className="close modal_close_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ top: '20px' }}
                    onClick={() => closeModal()}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="mb-2  ">
                    <h5 className="modal-heading mb-4">Edit Arrangement</h5>
                </div>
                {isModalLoading && (
                    <div className="text-center">
                        <Loading />
                    </div>
                )}
                {!isModalLoading && (
                    <div>
                        <form>
                            <div className="form-row flex-column">
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                id="fname"
                                                name="first_name"
                                                value={input.first_name || ''}
                                                disabled
                                                className="form-control account_info_field"
                                                placeholder="first name"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="fname"
                                            >
                                                First name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                id="lname"
                                                name="last_name"
                                                value={input.last_name || ''}
                                                disabled
                                                className="form-control account_info_field"
                                                placeholder="last name"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="lname"
                                            >
                                                Last name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                id="dob"
                                                name="date_of_birth"
                                                disabled
                                                defaultValue={
                                                    input.date_of_birth || ''
                                                }
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="dob"
                                            >
                                                Date of birth
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="number"
                                                id="patient-id"
                                                name="patient_appointment_id"
                                                value={
                                                    input.patient_appointment_id ||
                                                    ''
                                                }
                                                onChange={
                                                    handleArrangementChange
                                                }
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="patient-id"
                                            >
                                                Patient I.D
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="number"
                                                id="o-amt"
                                                name="original_amount"
                                                className="form-control account_info_field"
                                                // value={ input.original_amount === 0 ? 0 : input.original_amount && parseFloat(input.original_amount).toFixed(2) || ''  }
                                                value={
                                                    input.original_amount === 0
                                                        ? 0
                                                        : input.original_amount &&
                                                          input.original_amount
                                                }
                                                onChange={
                                                    handleArrangementChange
                                                }
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="o-amt"
                                            >
                                                Original amount
                                            </label>
                                        </div>
                                    </div>
                                    {/* <small className="text-danger">{errors.zipcode || ""}</small> */}
                                </div>
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="number"
                                                id="disc-amt"
                                                name="discount_amount"
                                                disabled
                                                className="form-control account_info_field"
                                                value={
                                                    input.discount_amount === 0
                                                        ? 0
                                                        : parseFloat(
                                                              input.discount_amount
                                                          ).toFixed(2) || 0
                                                }
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="disc-amt"
                                            >
                                                Discount applied
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="number"
                                                id="adj-amt"
                                                name="originalAmount"
                                                disabled
                                                className="form-control account_info_field"
                                                // defaultValue={ input.adjusted_amount === 0 ? 0 : input.adjusted_amount && parseFloat(input.adjusted_amount).toFixed(2) || ''  }
                                                value={
                                                    input.adjusted_amount === 0
                                                        ? 0
                                                        : (input.adjusted_amount &&
                                                              parseFloat(
                                                                  input.adjusted_amount
                                                              ).toFixed(2)) ||
                                                          ''
                                                }
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="adj-amt"
                                            >
                                                Adj. amount
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="text-center mb-2">
                            <button
                                type="submit"
                                className="btn save_team_member_btn mt-4 d-block mr-auto ml-auto"
                                onClick={() => updateReviewArrangementFunc()}
                            >
                                Update & Accept
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    )
}

PatientArrangementDetailsModal.propTypes = {
    sendNotificationData: PropTypes.func,
    handleArrangementChange: PropTypes.func,
    updateReviewArrangementFunc: PropTypes.func,
    arrangementDetails: PropTypes.object,
    input: PropTypes.object,
    // profileDetail: PropTypes.object,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
}

export default PatientArrangementDetailsModal
