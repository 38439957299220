import React from 'react'
import PropTypes from 'prop-types'
import '../static/stylesheets/common.scss'
import { MODAL_STYLES } from 'constants/Constant'
import Modal from 'react-modal'

function ProcessRegModal(props) {
    const { inProcessRegModal, closeInProcessModal } = props
    return (
        <Modal
            isOpen={inProcessRegModal}
            onRequestClose={closeInProcessModal}
            style={MODAL_STYLES}
            ariaHideApp={false}
        >
            <button
                type="button"
                className="close modal_close_icon modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeInProcessModal}
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="react-modal">
                <div className="modal-body mb-0">
                    <p>
                        Registration is in process, please complete few more
                        steps.
                    </p>
                </div>
            </div>
        </Modal>
    )
}
ProcessRegModal.propTypes = {
    closeInProcessModal: PropTypes.func,
    inProcessRegModal: PropTypes.bool,
}
export default ProcessRegModal
