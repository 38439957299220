import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import { MdKeyboardArrowRight } from 'react-icons/md'
// import { createEmiPayment } from 'actions/patientBankingActions'
// import moment from 'moment';
// import { yyyymmddFormat } from 'constants/Constant'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'
import SelectBillDateMobile from './SelectBillDateMobile'
function BillPaymentOptionsMobile(props) {
    // const dispatch = useDispatch();
    const { isOpen, closeBillPayOptions, billAmount, physicianDetails } = props
    const [selectBillDate, setSelectBillDate] = useState(false)
    const [pompayDisountId, setPompayDiscountId] = useState('')

    const paymentScheduleDetails = useSelector(
        (state) => state.paymentScheduleDetails
    )
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const closeSelectBill = () => {
        setSelectBillDate(!selectBillDate)
    }
    const openSelectBillPay = (id) => {
        setPompayDiscountId(id)
        setSelectBillDate(!selectBillDate)
    }
    return (
        <>
            {isOpen && !selectBillDate && (
                <div className="mobile_account_info payment-options-page">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closeBillPayOptions()}
                            />
                            <label className="nav-heading">
                                {physicianDetails.physician_name ||
                                    physicianDetails.physician_full_name}
                            </label>

                            <button
                                type="button"
                                className="close close-bold-icon z-index1"
                                onClick={() => (window.location = '/home')}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </nav>
                        <div id="nav-tabContent">
                            <div className="heading-wrapper">
                                <span className="heading">
                                    How do you want to pay
                                    <br /> your bill of{' '}
                                    {currencyFormatter.format(billAmount || 0)}?
                                </span>
                            </div>
                            {isModalLoading && <ModalLoader />}
                            {!isModalLoading && (
                                <>
                                    <div className="dash-box">
                                        <button
                                            className="discount-btn"
                                            onClick={() =>
                                                openSelectBillPay(
                                                    'pompayDiscount'
                                                )
                                            }
                                        >
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex green-text">
                                                        <div className="d-flex flex-column text-left text-height mr-3">
                                                            <span className="bold-label">
                                                                {
                                                                    paymentScheduleDetails.discount_by
                                                                }
                                                                %
                                                            </span>
                                                            <span className="bold-label">
                                                                OFF
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column text-left text-height">
                                                            <span className="medium-label">
                                                                Pompay Discount
                                                            </span>
                                                            <span className="regular-label">
                                                                You pay{' '}
                                                                {currencyFormatter.format(
                                                                    paymentScheduleDetails.instant_pay_amount ||
                                                                        0
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <MdKeyboardArrowRight
                                                        onClick={() =>
                                                            setSelectBillDate(
                                                                !selectBillDate
                                                            )
                                                        }
                                                        className="float-right font24 color_light_green"
                                                    />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="emi-options">
                                        {paymentScheduleDetails.installment_option &&
                                            paymentScheduleDetails.installment_option.map(
                                                (data, id) => {
                                                    return (
                                                        <button
                                                            className="btn outline-btn d-flex align-items-center justify-content-between box-height"
                                                            key={id}
                                                            onClick={() =>
                                                                openSelectBillPay(
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <div className="d-flex flex-column text-left text-height">
                                                                <span className="pay-btn">
                                                                    Pay{' '}
                                                                    <span className="bold-text">
                                                                        {currencyFormatter.format(
                                                                            data.first_installment_amount ||
                                                                                0
                                                                        )}
                                                                        /mo
                                                                    </span>{' '}
                                                                    for
                                                                </span>
                                                                <span className="bold-text">
                                                                    {data.month ===
                                                                        2 &&
                                                                        Boolean(
                                                                            data.last_installment_amount
                                                                        ) &&
                                                                        '1st month'}
                                                                    {(data.month !==
                                                                        2 ||
                                                                        !data.last_installment_amount) &&
                                                                        (data.month >
                                                                        1
                                                                            ? `${
                                                                                  data.last_installment_amount &&
                                                                                  data.last_installment_amount !=
                                                                                      data.first_installment_amount
                                                                                      ? data.month -
                                                                                        1
                                                                                      : data.month
                                                                              } months`
                                                                            : `${data.month} month`)}
                                                                </span>
                                                                {Boolean(
                                                                    data.last_installment_amount
                                                                ) &&
                                                                    data.last_installment_amount !=
                                                                        data.first_installment_amount && (
                                                                        <span>
                                                                            <span className="pay-btn">
                                                                                &
                                                                                Pay{' '}
                                                                                <span className="bold-text">
                                                                                    {currencyFormatter.format(
                                                                                        data.last_installment_amount
                                                                                    )}
                                                                                </span>{' '}
                                                                                for
                                                                                <br />
                                                                            </span>{' '}
                                                                            <span className="bold-text">
                                                                                {toOrdinalSuffix(
                                                                                    data.month
                                                                                )}{' '}
                                                                                month
                                                                            </span>
                                                                        </span>
                                                                    )}
                                                            </div>
                                                            <MdKeyboardArrowRight
                                                                className="float-right font24 green"
                                                                // onClick={ () => handleBillRecordModal(data) }
                                                            />
                                                        </button>
                                                    )
                                                }
                                            )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {selectBillDate && (
                <SelectBillDateMobile
                    isOpen={selectBillDate}
                    closeSelectBill={closeSelectBill}
                    physicianDetails={physicianDetails}
                    history={props.history}
                    pompayDisountId={pompayDisountId}
                    paymentScheduleDetails={paymentScheduleDetails}
                    billAmount={billAmount}
                />
            )}
        </>
    )
}

BillPaymentOptionsMobile.propTypes = {
    closeBillPayOptions: PropTypes.func,
    isOpen: PropTypes.bool,
    billAmount: PropTypes.string,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default BillPaymentOptionsMobile
