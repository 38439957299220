import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import logo from '../../static/images/logo.svg'
import { adminLogoutAction } from 'actions/adminActions'
function AdminHeader(props) {
    const { history } = props
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(adminLogoutAction(history))
    }
    return (
        <div className="dashboard-header admin-dashboard">
            <nav className="navbar navbar-expand-lg bg-white fixed-top">
                <a className="navbar-brand" href="index.html">
                    <img className="logo-img" src={logo} alt="logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse "
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav ml-auto navbar-right-top">
                        <button
                            className="btn btn-link logout-link"
                            onClick={() => handleLogout()}
                        >
                            Log Out
                        </button>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

AdminHeader.propTypes = {
    history: PropTypes.object,
}
export default AdminHeader
