import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { FiChevronLeft } from 'react-icons/fi'
import PhysicianBillAmount from './PhysicianBillAmount'
import ModalLoader from 'shared/ModalLoader'
import { getPhysicianAppointmentId } from 'actions/patientPhysicianActions'

function PatientAccountNumberModal(props) {
    // const dispatch = useDispatch();
    const {
        isOpen,
        closePatientAccountNumber,
        patientAppointmentId,
        physicianDetails,
        getPhysicianAppointmentIdReq,
    } = props
    // const newPatientAppointId = useSelector(
    //     (state) => state.patientAppointmentId,

    // );

    const modalIsLoading = useSelector((state) => state.modalIsLoading)
    const [addBillAmount, setAddBillAmount] = useState(false)
    const [appointmentId, setAppointmentId] = useState(patientAppointmentId)
    const closeBillAmountModal = () => {
        setAddBillAmount(false)
    }
    useEffect(() => {
        getPhysicianAppointmentIdReq(physicianDetails.physician_id).then(
            (response) => {
                setAppointmentId(response.patient_appointment_id)
            }
        )
        // dispatch(getPhysicianAppointmentId(physicianDetails.physician_id));
    }, [''])
    return (
        <>
            {!addBillAmount && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closePatientAccountNumber}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="patient-account-number-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (window.location = '/home')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            {modalIsLoading && (
                                <div className="text-center mt-5">
                                    <ModalLoader />
                                </div>
                            )}
                            {!modalIsLoading && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center modal-header-border">
                                        <FiChevronLeft
                                            className="back-icon pointer"
                                            onClick={() =>
                                                closePatientAccountNumber()
                                            }
                                        />
                                        <span className="patient-name">
                                            {physicianDetails.physician_name ||
                                                physicianDetails.physician_full_name}
                                        </span>
                                        <span></span>
                                    </div>
                                    <div className="modal-body">
                                        <h4 className="heading">
                                            Enter your patient
                                            <br /> account number
                                        </h4>
                                        <input
                                            type="number"
                                            className="patient-id-field"
                                            name="accessCode"
                                            autoComplete="off"
                                            value={appointmentId}
                                            onChange={(event) =>
                                                setAppointmentId(
                                                    event.target.value
                                                )
                                            }
                                            data-dismiss="modal"
                                            placeholder="Patient I.D"
                                        />
                                        {/* {accessCodeError  && (
                                <small className="text-danger">
                                    {accessCodeError}
                                </small>
                            )} */}
                                        <p className="content">
                                            This will be saved for future bills
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            className="btn next-btn pointer"
                                            onClick={() =>
                                                setAddBillAmount(!addBillAmount)
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {
                <PhysicianBillAmount
                    isOpen={addBillAmount}
                    closeBillAmountModal={closeBillAmountModal}
                    appointmentId={appointmentId}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            }
        </>
    )
}

PatientAccountNumberModal.propTypes = {
    closePatientAccountNumber: PropTypes.func,
    openAddModal: PropTypes.func,
    sendPhysicianAccessCode: PropTypes.func,
    isOpen: PropTypes.bool,
    patientAppointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    getPhysicianAppointmentIdReq: PropTypes.func,
}
const mapDispatchToProps = (dispatch) => ({
    getPhysicianAppointmentIdReq: (data) =>
        dispatch(getPhysicianAppointmentId(data)),
})
export default connect(undefined, mapDispatchToProps)(PatientAccountNumberModal)
