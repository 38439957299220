import React from 'react'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import { currencyFormatter } from 'utils/helper'

function PaidBillDetails(props) {
    const {
        isOpen,
        handleCloseBillPaidFunc,
        billPaidPageDetails,
        physicianDetails,
    } = props
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy
    return (
        <>
            {isOpen && (
                <div className="mobile_account_info bill-details-page">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => handleCloseBillPaidFunc()}
                            />
                            <label className="nav-heading">Bill Details</label>
                            <span></span>
                        </nav>
                        <div className="d-flex justify-content-center flex-column balance-wrapper">
                            <span className="balance-label">Total Paid</span>
                            <span className="balance">
                                {currencyFormatter.format(
                                    billPaidPageDetails.paid_amount || 0
                                )}
                            </span>
                        </div>
                        <div className="bill-details-label">Bill details:</div>
                        <div className="details-wrapper">
                            <div className="d-flex justify-content-between mb-4">
                                <span className="data">Physician</span>
                                <span className="data">
                                    {physicianDetails.physician_full_name ??
                                        `Dr. ${physicianDetails.first_name} ${physicianDetails.last_name}`}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <span className="data">Date</span>
                                <span className="data">{formattedToday}</span>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <span className="data">Bill amount</span>
                                <span className="data">
                                    {currencyFormatter.format(
                                        billPaidPageDetails.paid_amount
                                    )}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <span className="data">From</span>
                                <span className="data">Checking ****7664</span>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <span className="bold-data">Total</span>
                                <span className="bold-data">
                                    {currencyFormatter.format(
                                        billPaidPageDetails.paid_amount
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
PaidBillDetails.propTypes = {
    handleCloseBillPaidFunc: PropTypes.func,
    isOpen: PropTypes.bool,
    billPaidPageDetails: PropTypes.object,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}
export default PaidBillDetails
