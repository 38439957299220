import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CardSection from './CardSection'
import { connect } from 'react-redux'
import { sendPatientCardToken } from 'actions/patientBankingActions'
import { CgChevronLeft } from 'react-icons/cg'
import { isMobile } from 'constants/Constant'
import SuccessAlert from 'shared/SuccessAlert'
import debounce from 'lodash/debounce'

class CheckoutForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardHolderName: '',
            cardHolderNameError: false,
            successAlert: false,
            successAlertMessage: '',
            isDisabled: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange = (event) => {
        this.setState({
            cardHolderName: event.target.value,
            cardHolderNameError: false,
        })
    }
    closeSuccessAlert = () => {
        this.setState({
            successAlert: !this.state.successAlert,
            successAlertMessage: '',
        })
        this.props.closeModal()
    }
    handleSubmit = debounce(async (event) => {
        event.preventDefault()
        const { stripe, elements } = this.props
        if (this.state.cardHolderName === '') {
            this.setState({
                cardHolderNameError: true,
            })
        } else {
            if (!stripe || !elements) {
                return
            }
            const card = elements.getElement(CardElement)
            const result = await stripe.createToken(card, { currency: 'usd' })
            if (result.error) {
                // this.setState({ successAlert: true, successAlertMessage: result.error.message })
            } else {
                const card_id = { card_id: result.token.id }
                this.setState({
                    isDisabled: true,
                })
                this.props.sendPatientCardToken(card_id).then((res) => {
                    if (res.statusCode === 200) {
                        if (!isMobile) {
                            this.setState({
                                successAlert: true,
                                isDisabled: false,
                                successAlertMessage:
                                    'Your card has successfully been added.',
                            })
                        } else {
                            if (this.props?.totalBalancePage === 'one') {
                                setTimeout(() => {
                                    this.props?.goToPaymentMethodPageFunc()
                                }, 2000)
                            } else if (
                                this.props?.remainingBalancePage === 'yes'
                            ) {
                                setTimeout(() => {
                                    this.props?.goToPaymentMethodPageFromPastDueFunc()
                                }, 2000)
                            } else if (this.props?.pastDuePage === 'pastDue') {
                                setTimeout(() => {
                                    this.props?.goToPayMethodPageFunc()
                                }, 2000)
                            } else {
                                this.setState({
                                    successAlert: true,
                                    isDisabled: false,
                                })
                                this.props.addbankCardFunc()
                            }
                        }
                    }
                })
            }
        }
    }, 500)

    render() {
        const {
            cardHolderName,
            cardHolderNameError,
            successAlert,
            successAlertMessage,
        } = this.state
        return (
            <>
                {!isMobile && (
                    <div className="material-design-ui payment-method-page">
                        <form>
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="form-group mx-4">
                                        <input
                                            type="text"
                                            value={cardHolderName}
                                            onChange={this.handleChange}
                                        />
                                        <label className="control-label">
                                            Name on Card
                                        </label>
                                        <i className="bar"></i>
                                    </div>
                                </div>
                            </div>
                            <CardSection />
                            {cardHolderNameError && (
                                <div>
                                    <small className="alert alert-danger d-flex justify-content-center mx-3">
                                        Please Enter Name
                                    </small>
                                </div>
                            )}
                        </form>
                        <div className="px-3 pb-3">
                            <button
                                className="btn add-card-btn"
                                data-dismiss="modal"
                                onClick={this.handleSubmit}
                                disabled={this.state.isDisabled}
                            >
                                Add Card
                            </button>
                        </div>
                    </div>
                )}
                {isMobile && (
                    <div className="material-design-ui mobile_account_info payment-method-page">
                        <div id="content" className="mb-0">
                            <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                                {this.props?.totalBalancePage === 'one' ? (
                                    <>
                                        <CgChevronLeft
                                            className="back_icon"
                                            onClick={() =>
                                                this.props.goToDebitCreditCardPageFunc()
                                            }
                                        />
                                    </>
                                ) : this.props.pastDuePage === 'pastDue' ? (
                                    <>
                                        <CgChevronLeft
                                            className="back_icon"
                                            onClick={() =>
                                                this.props.goToDebitCreditCardPageFromPastDueFunc()
                                            }
                                        />
                                    </>
                                ) : this.props.remainingBalancePage ===
                                  'yes' ? (
                                    <>
                                        <CgChevronLeft
                                            className="back_icon"
                                            onClick={() =>
                                                this.props.goToDebitCreditCardPageFromRemainingBalanceFunc()
                                            }
                                        />
                                    </>
                                ) : this.props.isVisiable ? (
                                    <>
                                        <CgChevronLeft
                                            className="back_icon"
                                            onClick={() =>
                                                this.props.addbankCardBackFunc()
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <CgChevronLeft
                                            className="back_icon"
                                            onClick={() =>
                                                this.props.addbankCardPageFunc()
                                            }
                                        />
                                    </>
                                )}
                                <label className="nav-heading">Add Card</label>
                            </nav>
                            <form className="mobile-content-height">
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <div className="form-group mx-4">
                                            <input
                                                type="text"
                                                value={cardHolderName}
                                                onChange={this.handleChange}
                                            />
                                            <label className="control-label">
                                                Name on Card
                                            </label>
                                            <i className="bar"></i>
                                        </div>
                                    </div>
                                </div>
                                <CardSection />
                                {cardHolderNameError && (
                                    <div>
                                        <small className="alert alert-danger d-flex justify-content-center mx-3">
                                            Please Enter Name
                                        </small>
                                    </div>
                                )}
                            </form>
                            <div className="px-3 pb-3">
                                <button
                                    className="btn mobile-green-btn w-100"
                                    data-dismiss="modal"
                                    disabled={this.state.isDisabled}
                                    onClick={this.handleSubmit}
                                >
                                    Add Card
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        role="Card"
                        message={successAlertMessage}
                        description="You can now use this card to pay your bills."
                    />
                )}
            </>
        )
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        sendPatientCardToken: (id) => dispatch(sendPatientCardToken(id)),
    }
}

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
)

CheckoutForm.propTypes = {
    elements: PropTypes.object,
    stripe: PropTypes.object,
    // sendCardToken: PropTypes.func,
    sendPatientCardToken: PropTypes.func,
    addbankCardFunc: PropTypes.func,
    addbankCardPageFunc: PropTypes.func,
    addbankCardBackFunc: PropTypes.func,
    closeModal: PropTypes.func,
    patient: PropTypes.bool,
    isVisiable: PropTypes.bool,
    totalBalancePage: PropTypes.string,
    goToDebitCreditCardPageFunc: PropTypes.func,
    goToPaymentMethodPageFunc: PropTypes.func,
    goToPaymentMethodPageFromPastDueFunc: PropTypes.func,
    remainingBalancePage: PropTypes.string,
    goToDebitCreditCardPageFromPastDueFunc: PropTypes.func,
    pastDuePage: PropTypes.string,
    goToPayMethodPageFunc: PropTypes.func,
    goToDebitCreditCardPageFromRemainingBalanceFunc: PropTypes.func,
}

export default connect(null, mapDispachToProps)(InjectedCheckoutForm)
