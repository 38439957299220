import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES, yyyymmddFormat } from 'constants/Constant'
import { MdChevronLeft } from 'react-icons/md'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import { dayMonthDateYearFormat } from 'constants/Constant'
import SetUpPlanPaymentModal from './SetUpPlanPaymentModal'
import { addDateFunc } from 'utils/helper'
import ThankYouModal from 'shared/ThankYouModal'
import { promiseToPayAction } from 'actions/patientBankingActions'
import { getPatientHomePageDetails } from 'actions/patientActions'
// import styled from 'styled-components';

function SelectBillDate(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closeSelectBill,
        physicianDetails,
        history,
        pompayDisountId,
        paymentScheduleDetails,
        billAmount,
        promisePayKey,
        installBillDetails,
    } = props
    const [value, setValue] = useState(new Date())
    const [setUpBillPayment, setSetUpBillPayment] = useState(false)
    const [closeAllModal, setCloseAllModal] = useState(false)
    const [isComplete, setIsComplete] = useState(false)

    const onChange = (date) => {
        setValue(date)
    }
    const selectedDate = moment(value).format(dayMonthDateYearFormat)
    const handleSetUpPlanFunc = () => {
        setSetUpBillPayment(!setUpBillPayment)
    }
    const closeAllModalFunc = () => {
        setCloseAllModal(!closeAllModal)
    }
    const submitPromiseRequest = () => {
        const dueInstallmentSchedule =
            installBillDetails?.full_payment_schedule?.find(
                (x) => x.due_amount_status === 'Due'
            )
        const data = {
            physician_id: physicianDetails.physician_id,
            payment_schedule_id: dueInstallmentSchedule?.payment_schedule_id,
            due_amount: dueInstallmentSchedule?.due_amount,
            due_amount_date: moment(value).format(yyyymmddFormat),
        }
        dispatch(promiseToPayAction(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getPatientHomePageDetails())
                setIsComplete(!isComplete)
            }
        })
    }
    const minMaxDateRange =
        paymentScheduleDetails.time_period_to_choose_start_date || 14

    // const CalendarContainer = styled.div`
    //     .react-calendar__navigation__next-button {
    //         height: 40px;
    //         font-size: 32px;
    //         position: absolute;
    //         right: 20px;
    //         top: 106px;

    //     }
    //     .react-calendar__navigation__prev-button {
    //         height: 40px;
    //         font-size: 32px;
    //         position: absolute;
    //         left: 20px;
    //         top: 106px;
    //     }

    //     .react-calendar__tile--active abbr{
    //         background-color: #ffffff !important;
    //         color: #444B48 !important;
    //         padding: 8px 12px;
    //         border-radius: 20px;
    //     }

    // `;

    return (
        <>
            {!setUpBillPayment && !closeAllModal && !isComplete && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeSelectBill}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="select-date-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (window.location = '/home')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <MdChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeSelectBill()}
                                />
                                <span className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </span>
                                <span></span>
                            </div>
                            <div className="modal-body d-flex flex-column align-items-center">
                                <h4 className="modal-heading mb-0">
                                    Select a date to make
                                    <br /> your payment
                                </h4>
                                {/* <CalendarContainer> */}
                                <Calendar
                                    view={'month'}
                                    onChange={onChange}
                                    // value={ value }
                                    minDate={new Date()}
                                    maxDate={addDateFunc(minMaxDateRange)}
                                    navigationLabel={({ date }) =>
                                        date.toLocaleString('default', {
                                            month: 'long',
                                        })
                                    }
                                />
                                {/* </CalendarContainer> */}
                                <div className="monthly-pay-text mt-3">
                                    You have selected:
                                </div>
                                <div className="monthly-pay-date">
                                    {selectedDate}
                                </div>
                            </div>
                            {promisePayKey ? (
                                <button
                                    className="btn next-btn"
                                    onClick={submitPromiseRequest}
                                >
                                    {' '}
                                    Continue{' '}
                                </button>
                            ) : (
                                <button
                                    className="btn next-btn"
                                    onClick={handleSetUpPlanFunc}
                                >
                                    {' '}
                                    Next{' '}
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {setUpBillPayment && !closeAllModal && (
                <SetUpPlanPaymentModal
                    isOpen={setUpBillPayment}
                    handleSetUpPlanFunc={handleSetUpPlanFunc}
                    physicianDetails={physicianDetails}
                    history={history}
                    billAmount={billAmount}
                    paymentScheduleDetails={paymentScheduleDetails}
                    pompayDisountId={pompayDisountId}
                    selectedDate={selectedDate}
                    dateValue={value}
                    closeAllModalFunc={closeAllModalFunc}
                />
            )}
            {isComplete && !closeAllModal && (
                <ThankYouModal
                    role="promisePay"
                    isComplete={isComplete}
                    closeAllModalFunc={closeAllModalFunc}
                    value={selectedDate}
                    installBillDetails={installBillDetails}
                    physicianDetails={physicianDetails}
                />
            )}
        </>
    )
}

SelectBillDate.propTypes = {
    closeSelectBill: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    pompayDisountId: PropTypes.string,
    billAmount: PropTypes.string,
    promisePayKey: PropTypes.string,
    paymentScheduleDetails: PropTypes.object,
}

export default SelectBillDate
