export function physicianDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_DETAILS':
            return action.physicianDetails
        default:
            return state
    }
}
export function physicianList(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_LIST':
            return action.physicianList
        default:
            return state
    }
}
export function patientAppointmentId(state = 0, action) {
    switch (action.type) {
        case 'FETCH_PATIENT_APPOINTMENT_ID':
            return action.patientAppointmentId
        default:
            return state
    }
}
export function physicianProfileDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_PROFILE_DETAILS':
            return action.physicianProfileDetails
        default:
            return state
    }
}
