/* eslint-disable react/jsx-indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import {
    getCardListing,
    removeCard,
    transactionDetails,
    getPhysicianAccountExists,
    sendBankingAuthDeatils,
} from 'actions/bankingActions'
import AddCard from './AddCard'
import 'static/stylesheets/banking.scss'
import StripeAccountCheck from './StripeAccountCheck'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import PhysicianTransferSection from './PhysicianTransferSection'
import ModalLoader from 'shared/ModalLoader'
import ConfirmDelete from 'shared/ConfirmDelete'
import { currencyFormatter } from 'utils/helper'
import { RiBankFill } from 'react-icons/ri'
import { FiClock } from 'react-icons/fi'
import SuccessAlert from 'shared/SuccessAlert'

export class Banking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAuthDetails: false,
            openCardModal: false,
            isConfirmDelete: false,
            successAlert: false,
            successAlertMessage: '',
        }
    }

    componentDidMount() {
        this.props.getPhysicianAccountExists()
        this.props.getCardListing()
        this.props.transactionDetails()
    }

    confirmBankAccountRemove = (data) => {
        const id = data && data.id
        this.setState({
            isConfirmDelete: !this.state.isConfirmDelete,
            confirmEditId: id,
        })
    }

    handleRemoveCard = () => {
        const { isTeam } = this.props
        const { confirmEditId } = this.state
        if (isTeam) {
            //   if (cardListing.is_locked) {
            this.setState({
                successAlert: true,
                successAlertMessage:
                    'You dont have access to perform this action',
            })
            //   }
        } else {
            this.props.removeCard(confirmEditId).then((res) => {
                if (res.statusCode === 200) {
                    this.props.getCardListing()
                    this.setState({ isConfirmDelete: false })
                }
            })
        }
    }

    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    closeModal = () => {
        this.setState({ openCardModal: false })
    }

    openModal = () => {
        const { isTeam } = this.props
        if (isTeam) {
            //   if (cardListing.is_locked) {
            this.setState({
                successAlert: true,
                successAlertMessage:
                    'You dont have access to perform this action',
            })
            //   }
        } else {
            this.props.isStripeAccount
                ? this.setState({ openCardModal: true })
                : this.setState({ openAuthDetails: true })
        }
    }

    closeStripeModal = () => {
        this.setState({ openAuthDetails: false })
    }

    render() {
        const {
            openAuthDetails,
            openCardModal,
            isConfirmDelete,
            successAlert,
            successAlertMessage,
        } = this.state
        const { isLoading, cardListing, transDetails, isTeam, setToAccount } =
            this.props

        return (
            <div>
                {openAuthDetails && (
                    <StripeAccountCheck
                        isOpen={openAuthDetails}
                        closeStripeModal={this.closeStripeModal}
                        sendBankingAuthDeatils={
                            this.props.sendBankingAuthDeatils
                        }
                    />
                )}

                <div className="row">
                    <div className="col-md-5">
                        {isLoading && <ModalLoader />}
                        {!isLoading && (
                            <div>
                                <div className="d-flex">
                                    <h2 className="tab_heading">
                                        Linked Accounts
                                    </h2>
                                </div>
                                <table className="table table-borderless office_info">
                                    {cardListing &&
                                        cardListing.cardList &&
                                        cardListing.cardList.map((data, id) => {
                                            return (
                                                <tbody
                                                    key={id}
                                                    className="border-0 linked-accounts"
                                                >
                                                    <tr>
                                                        <td className="px-0 border-0 d-flex align-items-center">
                                                            <RiBankFill className="mr-3 font20 color_dark_grey" />
                                                            <div>
                                                                <div className="office_name">
                                                                    {
                                                                        data.card_type
                                                                    }{' '}
                                                                    {'****' +
                                                                        data.card_number}
                                                                </div>
                                                                {/* <div className="office_address">
                                                {data.card_type} {'****' + data.card_number}
                                            </div> */}
                                                            </div>
                                                        </td>
                                                        <td className="px-0 border-0 text-right">
                                                            <button
                                                                className="btn remove_element_btn"
                                                                onClick={() =>
                                                                    this.confirmBankAccountRemove(
                                                                        data
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })}
                                </table>
                                <div className="d-flex align-items-center">
                                    <RiBankFill className="mr-3 font20 color_light_green" />
                                    <button
                                        className="btn btn-link pl-0 add_element_link"
                                        data-toggle="modal"
                                        data-target="#newMemberModal"
                                        onClick={() => this.openModal()}
                                    >
                                        Add Bank Account...
                                    </button>
                                </div>
                            </div>
                        )}
                        <Modal
                            id="addBankAccountModal"
                            isOpen={openCardModal}
                            onRequestClose={this.closeModal}
                            style={MODAL_STYLES}
                            contentLabel="Example Modal"
                            ariaHideApp={false}
                        >
                            <button
                                type="button"
                                className="close modal_close_icon z-index1"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.closeModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="modal-body">
                                {/* <h5 className="modal-title font18 color_dark_grey mb-4">
                                    Add Card
                                </h5> */}
                                <AddCard
                                    closeModal={this.closeModal}
                                    setToAccount={setToAccount}
                                />
                            </div>
                        </Modal>

                        {isLoading && <ModalLoader />}
                        <div className="d-flex pt-5 pb-1">
                            <h2 className="tab_heading">Completed Transfers</h2>
                        </div>
                        {!isLoading && (
                            <div>
                                {transDetails &&
                                    transDetails.completedTransaction &&
                                    transDetails.completedTransaction.map(
                                        (data, id) => {
                                            return (
                                                <table
                                                    className="table table-borderless office_info"
                                                    key={id}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td className="px-0 border-0">
                                                                <div className="pending_tranfer_label">
                                                                    Transferred
                                                                    to Bank
                                                                </div>
                                                                <div className="office_address pt-1">
                                                                    {moment(
                                                                        data.updated_at
                                                                    ).format(
                                                                        'l'
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="px-0 text-center border-0 pending-transfer-container">
                                                                <div className="pending_tranfer_amount">
                                                                    {currencyFormatter.format(
                                                                        (data &&
                                                                            data.amount &&
                                                                            data.amount) /
                                                                            100
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    )}
                                {transDetails.completedTransaction &&
                                    transDetails.completedTransaction.length ===
                                        0 && (
                                        <div className="d-flex align-items-center">
                                            <FiClock className="mr-3 font16 color_dark_black" />
                                            <label className="no-transfer-activity">
                                                No transfer activity
                                            </label>
                                        </div>
                                    )}{' '}
                            </div>
                        )}
                    </div>
                    <PhysicianTransferSection
                        cardListing={cardListing}
                        isTeam={isTeam}
                    />
                </div>

                {isConfirmDelete && (
                    <ConfirmDelete
                        isConfirmDelete={isConfirmDelete}
                        confirmRemove={this.confirmBankAccountRemove}
                        handleRemoveFunc={this.handleRemoveCard}
                        role="bank account"
                        mode="bank account"
                    />
                )}

                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        restrict={true}
                        message={successAlertMessage}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        banking: state.banking,
        isLoading: state.applicationIsLoading,
        cardModal: state.cardModal,
        cardListing: state.cardListing,
        transDetails: state.transDetails,
        isStripeAccount: state.isStripeAccount,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCardListing: () => dispatch(getCardListing()),
        transactionDetails: () => dispatch(transactionDetails()),
        getPhysicianAccountExists: () => dispatch(getPhysicianAccountExists()),
        removeCard: (id) => dispatch(removeCard(id)),
        sendBankingAuthDeatils: (id) => dispatch(sendBankingAuthDeatils(id)),
    }
}

Banking.propTypes = {
    history: PropTypes.object,
    getCardListing: PropTypes.func,
    transactionDetails: PropTypes.func,
    sendBankingAuthDeatils: PropTypes.func,
    getPhysicianAccountExists: PropTypes.func,
    removeCard: PropTypes.func,
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isStripeAccount: PropTypes.bool,
    totalAmountStripe: PropTypes.array,
    cardListing: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    transDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    setToAccount: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Banking)
