import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'react-avatar'
import PompayTopLogo from 'shared/PompayTopLogo'
import PatientSidebarNavigation from '../account/PatientSidebarNavigation'
import PropTypes from 'prop-types'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { GoPlus } from 'react-icons/go'
import FindPhysicianModal from './FindPhysicianModal'
import PhysicianBillRecord from './PhysicianBillRecord'
import { getPhysicianList } from 'actions/patientPhysicianActions'
import Loading from 'shared/Loader'
import { alphabets } from 'constants/Constant'
import { GoDotFill } from 'react-icons/go'
import { currencyFormatter } from 'utils/helper'
import { isMobile } from 'constants/Constant'
import PatientBottombarNavigation from '../account/PatientBottombarNavigation'
import PhysicianBillRecordMobile from '../mobile/PhysicianBillRecordMobile'
import { getPatientHomePageDetails } from 'actions/patientActions'
import { getPatientOutstandingBalance } from 'actions/patientBankingActions'

function PhysicianListView(props) {
    const dispatch = useDispatch()
    const [findModal, setFindModal] = useState(false)
    const [openBillRecordModal, setOpenBillRecordModal] = useState(false)
    const [openBillRecordPage, setOpenBillRecordPage] = useState(false)
    const [physicianDetails, setPhysicianDetails] = useState('')
    const [patientId, setPatientId] = useState('')
    const physicianList = useSelector((state) => state.physicianList)
    const patientOutstandingBalance = useSelector(
        (state) => state.patientOutstandingBalance
    )
    const patientHomePageDetails = useSelector(
        (state) => state.patientHomePageDetails
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    // const transferFundData = useSelector((state) => state.transferFundData);

    useEffect(() => {
        dispatch(getPhysicianList())
        dispatch(getPatientHomePageDetails())
        // dispatch(getTransferFundsData())
        dispatch(getPatientOutstandingBalance())
    }, [''])
    const closeModal = () => {
        setFindModal(!findModal)
    }
    const billRecordModal = () => {
        setOpenBillRecordModal(!openBillRecordModal)
    }
    const closeBillRecordModal = () => {
        setOpenBillRecordModal(!openBillRecordModal)
    }
    const handleBillRecordModal = (data) => {
        billRecordModal(!openBillRecordModal)
        setPhysicianDetails(data)
        setPatientId(physicianList.patient_id)
    }
    const handleOpenBillRecordPage = (data) => {
        setOpenBillRecordPage(!openBillRecordPage)
        setPhysicianDetails(data)
        setPatientId(physicianList.patient_id)
    }

    const handleCloseBillRecordPage = () => {
        setOpenBillRecordPage(!openBillRecordPage)
    }
    const sortedUser =
        physicianList &&
        physicianList.physician_list &&
        physicianList.physician_list.sort((a, b) =>
            a.last_name.localeCompare(b.last_name)
        )
    const physicianIds = []
    const pastActivePhysicians = []
    const upcomingActivePhysicians = []
    patientHomePageDetails &&
        patientHomePageDetails.pending_approval &&
        patientHomePageDetails.pending_approval.map((data) => {
            return physicianIds.push(data.physician_id)
        })
    patientHomePageDetails &&
        patientHomePageDetails.past_due &&
        patientHomePageDetails.past_due.map((data) => {
            return pastActivePhysicians.push(data.physician_id)
        })
    patientHomePageDetails &&
        patientHomePageDetails.upcoming_due &&
        patientHomePageDetails.upcoming_due.map((data) => {
            return upcomingActivePhysicians.push(data.physician_id)
        })
    const activePhysicians = [
        ...pastActivePhysicians,
        ...upcomingActivePhysicians,
    ]
    return (
        <>
            {!openBillRecordPage && (
                <div>
                    <div className="physician-list">
                        {!isMobile && (
                            <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar justify-content-between">
                                <div className="d-flex">
                                    <PompayTopLogo
                                        className="small_logo patient_list_logo"
                                        ancorClassName="pompay_small_logo mr-0"
                                    />
                                </div>
                                <div className="d-flex patient_right_nav">
                                    <div className="d-flex margin_left_right20 font12 align-items-center">
                                        <span className="mr-4 balance-label">
                                            Total Balance
                                        </span>
                                        <span className="pay-amount">
                                            {isLoading ? (
                                                <Loading />
                                            ) : (
                                                currencyFormatter.format(
                                                    patientOutstandingBalance.total_remaining_balance ||
                                                        0
                                                )
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex font12 align-items-center">
                                        <button
                                            className="pay-btn pointer"
                                            onClick={() =>
                                                props.history.push({
                                                    pathname:
                                                        '/patient-account',
                                                    state: {
                                                        paymentMethods: true,
                                                    },
                                                })
                                            }
                                        >
                                            Pay Balance
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        )}
                        <div className="wrapper">
                            <PatientSidebarNavigation />
                            <div id="content">
                                <div>
                                    <div className="header-section d-flex justify-content-between align-items-center">
                                        <h5>My Physicians</h5>
                                        {!isMobile && (
                                            <div className="d-flex align-items-center">
                                                <GoDotFill className="font16 color_light_green mx-1" />
                                                <span className="active-label">
                                                    = Active bill
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {isLoading && <Loading />}
                                    {!isLoading &&
                                        alphabets.map((dx) => {
                                            let checkCount = 0
                                            return (
                                                sortedUser &&
                                                sortedUser.map((d, idx) => {
                                                    if (
                                                        dx.toLowerCase() ==
                                                        d.last_name
                                                            .charAt(0)
                                                            .toLowerCase()
                                                    ) {
                                                        checkCount++
                                                        return (
                                                            <div key={idx}>
                                                                {checkCount ==
                                                                    1 && (
                                                                    <div className="alphabet-divider d-flex align-items-center">
                                                                        <span>
                                                                            {dx}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className="d-flex justify-content-between physician-row align-items-center"
                                                                    onClick={() => {
                                                                        isMobile &&
                                                                            handleOpenBillRecordPage(
                                                                                d
                                                                            )
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        {!isMobile &&
                                                                            activePhysicians &&
                                                                            activePhysicians.includes(
                                                                                d.physician_id
                                                                            ) && (
                                                                                <GoDotFill className="font16 color_light_green active-status" />
                                                                            )}
                                                                        <Avatar
                                                                            // name={ d.physician_name }
                                                                            name={
                                                                                d.physician_name &&
                                                                                d.physician_name.split(
                                                                                    '.'
                                                                                )[1]
                                                                            }
                                                                            className="physician-image"
                                                                            size="50"
                                                                            round={
                                                                                true
                                                                            }
                                                                            src={
                                                                                d.profile_pic
                                                                            }
                                                                        />
                                                                        <div className="text-left">
                                                                            <span className="physician-name">
                                                                                {
                                                                                    d.physician_name
                                                                                }
                                                                            </span>
                                                                            <span className="physician-speciality">
                                                                                {d.speciality ||
                                                                                    'General Physician'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {isMobile &&
                                                                        activePhysicians &&
                                                                        activePhysicians.includes(
                                                                            d.physician_id
                                                                        ) && (
                                                                            <GoDotFill className="font16 color_light_green active-status position-absolute" />
                                                                        )}
                                                                    {!isMobile && (
                                                                        <MdKeyboardArrowRight
                                                                            className="float-right font24 pointer"
                                                                            onClick={() =>
                                                                                handleBillRecordModal(
                                                                                    d
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                    {isMobile && (
                                                                        <MdKeyboardArrowRight
                                                                            className="float-right font24 pointer"
                                                                            onClick={() =>
                                                                                handleOpenBillRecordPage(
                                                                                    d
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                        {!isMobile && (
                            <div
                                className="float pointer"
                                onClick={() => setFindModal(!findModal)}
                            >
                                <GoPlus className="font28 my-float" />
                            </div>
                        )}
                        {isMobile && (
                            <div
                                className="fab-container"
                                onClick={() =>
                                    props.history.push('/add-physician-mobile')
                                }
                            >
                                <div className="fab fab-icon-holder">
                                    <GoPlus className="font24 my-float text-white" />
                                </div>
                            </div>
                        )}
                    </div>
                    {isMobile && <PatientBottombarNavigation />}
                </div>
            )}
            {findModal && (
                <FindPhysicianModal
                    isOpen={findModal}
                    closeModal={closeModal}
                />
            )}
            {openBillRecordModal && (
                <PhysicianBillRecord
                    isOpen={openBillRecordModal}
                    closeBillRecordModal={closeBillRecordModal}
                    physicianDetails={physicianDetails}
                    patientId={patientId}
                    history={props.history}
                    physicianIds={physicianIds}
                    isHideNewBill
                />
            )}
            {openBillRecordPage && (
                <PhysicianBillRecordMobile
                    isOpen={openBillRecordPage}
                    physicianDetails={physicianDetails}
                    patientId={patientId}
                    handleCloseBillRecordPage={handleCloseBillRecordPage}
                    history={props.history}
                    physicianIds={physicianIds}
                />
            )}
        </>
    )
}

PhysicianListView.propTypes = {
    closeModal: PropTypes.func,
    billRecordModal: PropTypes.func,
    closeBillRecordModal: PropTypes.func,
    history: PropTypes.object,
}

export default PhysicianListView
