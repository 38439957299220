import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { CgChevronLeft } from 'react-icons/cg'
import PatientLinkedCardsMobile from './PatientLinkedCardsMobile'
import { useHistory } from 'react-router-dom'
// import { useSelector } from 'react-redux';

const PatientPaymentMobile = (props) => {
    const history = useHistory()
    const [isLinkedCards, setIsLinkedCards] = useState(false)
    // const patientCardListing = useSelector((state) => state.patientCardListing);

    const handleLinkedCardsFunc = () => {
        setIsLinkedCards(!isLinkedCards)
    }
    const goToBackPageFunc = () => {
        history.push('/patient-account')
    }
    const goToDebitCreditCardPageFunc = () => {
        setIsLinkedCards(true)
    }
    useEffect(() => {
        if (props?.location?.state === true) {
            setIsLinkedCards(true)
        } else {
            setIsLinkedCards(false)
        }
    }, [''])
    return (
        <>
            {!isLinkedCards && (
                <div
                    className="mobile_account_info account-balance-page"
                    id="content"
                >
                    <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                        {history.location.pathname ===
                        '/patient-payments-mobile' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        history.push('/patient-account')
                                    }
                                />
                            </>
                        ) : props?.remainingBalancePage === 'back' &&
                          props?.totalBalancePage === 'one' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        props.goTotalBalancePageFunc()
                                    }
                                />
                            </>
                        ) : props?.remainingBalancePage === 'back' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        props.goRemainingBalancePageFunc()
                                    }
                                />
                            </>
                        ) : props?.pastDuePage === 'pastDue' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        props.goPastDueDetailPageFunc()
                                    }
                                />
                            </>
                        ) : props?.totalBalancePage === 'one' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        props.goTotalBalancePageFunc()
                                    }
                                />
                            </>
                        ) : props?.remainingBalancePage === 'yes' ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        props.goRemainingBalancePageFunc()
                                    }
                                />
                            </>
                        ) : props?.location?.state === true ? (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => props.history.goBack()}
                                />
                            </>
                        ) : (
                            <>
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => goToBackPageFunc()}
                                />
                            </>
                        )}
                        <label className="nav-heading">Payment methods</label>
                        <span></span>
                    </nav>
                    <div>
                        <div
                            className="tab-pane fade show active banking"
                            id="nav-banking"
                            role="tabpanel"
                            aria-labelledby="nav-banking-tab"
                        >
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <table className="table table-borderless office_info">
                                        <tbody>
                                            <tr
                                                className="row-border"
                                                onClick={handleLinkedCardsFunc}
                                            >
                                                <td className="border-0 d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <div className="transfer-label">
                                                            Debit or Credit card
                                                        </div>
                                                        <div className="transfer-info">
                                                            No fee for using
                                                            debit or credit
                                                            <br /> card
                                                        </div>
                                                    </div>
                                                    <MdChevronRight className="float-right font24 color_dark_grey" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* {isAddCardPage && <AddCardFormMobile isAddCardPage={ isAddCardPage } handleAddCardPageFunc={ handleAddCardPageFunc }/>} */}
            {isLinkedCards && (
                <PatientLinkedCardsMobile
                    isLinkedCards={isLinkedCards}
                    handleLinkedCardsFunc={handleLinkedCardsFunc}
                    totalBalancePage={props?.totalBalancePage}
                    goToDebitCreditCardPageFunc={goToDebitCreditCardPageFunc}
                    goTotalBalancePageFunc={props.goTotalBalancePageFunc}
                    goToPaymentMethodPageFunc={props?.goToPaymentMethodPageFunc}
                    goToPaymentMethodPageFromPastDueFunc={
                        props.goToPaymentMethodPageFromPastDueFunc
                    }
                    remainingBalancePage={props.remainingBalancePage}
                    goRemainingBalancePageFunc={
                        props.goRemainingBalancePageFunc
                    }
                    goPastDueDetailPageFunc={props.goPastDueDetailPageFunc}
                    pastDuePage={props.pastDuePage}
                    goToPayMethodPageFunc={props.goToPayMethodPageFunc}
                />
            )}
        </>
    )
}
PatientPaymentMobile.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    totalBalancePage: PropTypes.string,
    goTotalBalancePageFunc: PropTypes.func,
    goToPaymentMethodPageFunc: PropTypes.func,
    remainingBalancePage: PropTypes.string,
    goRemainingBalancePageFunc: PropTypes.func,
    goToPaymentMethodPageFromPastDueFunc: PropTypes.func,
    goPastDueDetailPageFunc: PropTypes.func,
    pastDuePage: PropTypes.string,
    goToPayMethodPageFunc: PropTypes.func,
}
export default PatientPaymentMobile
