import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import { dayMonthDateYearFormat, yyyymmddFormat } from 'constants/Constant'
import { addDateFunc } from 'utils/helper'
import SetUpPlanPaymentMobile from './SetUpPlanPaymentMobile'
import { promiseToPayAction } from 'actions/patientBankingActions'
import ThankYouModal from 'shared/ThankYouModal'
import { getPatientHomePageDetails } from 'actions/patientActions'
// import styled from 'styled-components';

function SelectBillDateMobile(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closeSelectBill,
        physicianDetails,
        history,
        pompayDisountId,
        paymentScheduleDetails,
        billAmount,
        promisePayKey,
        installBillDetails,
        // transferFundData,
        outStandingBalance,
    } = props
    const [value, setValue] = useState(new Date())
    const [isComplete, setIsComplete] = useState(false)
    const onChange = (date) => {
        setValue(date)
    }
    const [setUpBillPayment, setSetUpBillPayment] = useState(false)
    const selectedDate = moment(value).format(dayMonthDateYearFormat)
    const closeAllModalFunc = () => {
        history.push('/patient-account')
    }
    const handleSetUpPlanFunc = () => {
        setSetUpBillPayment(!setUpBillPayment)
    }
    const submitPromiseRequest = () => {
        const dueInstallmentSchedule =
            installBillDetails?.full_payment_schedule?.find(
                (x) => x.due_amount_status === 'Due'
            )
        const data = {
            physician_id: physicianDetails.physician_id,
            payment_schedule_id: dueInstallmentSchedule?.payment_schedule_id,
            due_amount: dueInstallmentSchedule?.due_amount,
            due_amount_date: moment(value).format(yyyymmddFormat),
        }
        dispatch(promiseToPayAction(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getPatientHomePageDetails())
                setIsComplete(!isComplete)
            }
        })
    }
    const minMaxDateRange =
        paymentScheduleDetails.time_period_to_choose_start_date || 14

    // const CalendarContainer = styled.div`
    //     .react-calendar__navigation__next-button {
    //         height: 40px;
    //         font-size: 32px;
    //         position: absolute;
    //         right: 20px;
    //         top: 106px;

    //     }
    //     .react-calendar__navigation__prev-button {
    //         height: 40px;
    //         font-size: 32px;
    //         position: absolute;
    //         left: 20px;
    //         top: 106px;
    //     }
    // `;
    return (
        <>
            {isOpen && !setUpBillPayment && !isComplete && (
                <div className="mobile_account_info monthly-payment-page">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closeSelectBill()}
                            />
                            {promisePayKey ? (
                                <label className="nav-heading">
                                    New Payment Date
                                </label>
                            ) : (
                                <label className="nav-heading">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </label>
                            )}
                            {!promisePayKey && (
                                <button
                                    type="button"
                                    className="close close-bold-icon z-index1"
                                    onClick={() => (window.location = '/home')}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            )}
                        </nav>
                        <div
                            id="nav-tabContent"
                            className="mobile-content-height"
                        >
                            <div className="heading-wrapper">
                                {promisePayKey ? (
                                    <span className="heading">
                                        Select a new date to make
                                        <br /> your payment
                                    </span>
                                ) : (
                                    <span className="heading">
                                        Select a date to make
                                        <br /> your payment
                                    </span>
                                )}
                            </div>
                            <div className="d-flex justify-content-center">
                                {/* <CalendarContainer> */}
                                <Calendar
                                    view={'month'}
                                    onChange={onChange}
                                    // value={ value }
                                    minDate={new Date()}
                                    maxDate={addDateFunc(minMaxDateRange)}
                                    calendarType={'US'}
                                    navigationLabel={({ date }) =>
                                        date.toLocaleString('default', {
                                            month: 'long',
                                        })
                                    }
                                />
                                {/* </CalendarContainer> */}
                            </div>
                            <div className="light-text">You have selected:</div>
                            <div className="selected-date">{selectedDate}</div>
                        </div>
                        <div className="px-3">
                            {promisePayKey ? (
                                <button
                                    className="btn mobile-green-btn w-100"
                                    onClick={submitPromiseRequest}
                                >
                                    {' '}
                                    Continue{' '}
                                </button>
                            ) : (
                                <button
                                    className="btn mobile-green-btn w-100"
                                    onClick={handleSetUpPlanFunc}
                                >
                                    {' '}
                                    Next{' '}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {setUpBillPayment && (
                <SetUpPlanPaymentMobile
                    isOpen={setUpBillPayment}
                    handleSetUpPlanFunc={handleSetUpPlanFunc}
                    physicianDetails={physicianDetails}
                    history={history}
                    billAmount={billAmount}
                    paymentScheduleDetails={paymentScheduleDetails}
                    pompayDisountId={pompayDisountId}
                    selectedDate={selectedDate}
                    dateValue={value}
                />
            )}
            {isComplete && (
                <ThankYouModal
                    role="promisePay"
                    isComplete={isComplete}
                    closeAllModalFunc={closeAllModalFunc}
                    value={selectedDate}
                    installBillDetails={installBillDetails}
                    outStandingBalance={outStandingBalance}
                />
            )}
        </>
    )
}

SelectBillDateMobile.propTypes = {
    closeSelectBill: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    // transferFundData: PropTypes.object,
    pompayDisountId: PropTypes.string,
    billAmount: PropTypes.string,
    promisePayKey: PropTypes.string,
    paymentScheduleDetails: PropTypes.object,
    outStandingBalance: PropTypes.number,
}

export default SelectBillDateMobile
