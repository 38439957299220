import React, { useState } from 'react'
import logo from '../../static/images/logo.svg'
import PropTypes from 'prop-types'
import AdminOtpModal from './AdminOtpModal'
import { useDispatch } from 'react-redux'
import { sendUpdateEmail } from 'actions/authActions'

function AdminEmailUpdate(props) {
    const dispatch = useDispatch()
    const { openUpdateNumberPage, handleUpdateNumberFunc, mobileNumber } = props
    const [emailId, setEmailId] = useState('')
    const [emailIdError, setEmailIdError] = useState(false)
    const [openOtpModal, setOpenOtpModal] = useState(false)
    const handleChange = (event) => {
        setEmailId(event.target.value)
        setEmailIdError(false)
    }
    const closeOtpModal = () => {
        setOpenOtpModal(false)
    }
    const handleSubmit = () => {
        const emailData = {
            email_id: emailId,
        }
        if (emailId) {
            dispatch(sendUpdateEmail(emailData)).then((res) => {
                if (res.statusCode === 200) {
                    setOpenOtpModal(!openOtpModal)
                }
            })
        } else {
            setEmailIdError(true)
        }
    }
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            handleSubmit()
        }
    }
    return (
        <>
            {openUpdateNumberPage && !openOtpModal && (
                <div className="splash-container admin-dashboard d-flex justify-content-center align-items-center">
                    <div className="card mb-0 update-mobile-number">
                        <div className="card-header text-center border-0">
                            <img className="logo-img" src={logo} alt="logo" />
                            <h3>Update your mobile number</h3>
                            <span className="splash-description">
                                This will just take a few steps. First, please
                                enter the email that is linked to your Pompay
                                account.
                            </span>
                        </div>
                        <div className="card-body d-flex justify-content-center">
                            <form>
                                <div className="form-group mt-0">
                                    <input
                                        className="form-control form-control-lg"
                                        type="email"
                                        value={emailId}
                                        name="emailId"
                                        placeholder="Email"
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block btn-radius"
                                    onClick={handleSubmit}
                                    onKeyDown={onKeyDown}
                                >
                                    Continue
                                </button>
                                {emailIdError && (
                                    <div className="alert alert-danger mb-0 mt-3 text-center">
                                        Please enter your registered email.
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="card-footer bg-white p-0 d-flex justify-content-center">
                            <div className="card-footer-item card-footer-item-bordered">
                                <a
                                    onClick={() => handleUpdateNumberFunc()}
                                    className="footer-link"
                                >
                                    Sign In
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {openOtpModal && (
                <AdminOtpModal
                    isOpen={openOtpModal}
                    history={history}
                    mobileNumber={mobileNumber}
                    emailId={emailId}
                    closeOtpModal={closeOtpModal}
                />
            )}
        </>
    )
}
AdminEmailUpdate.propTypes = {
    mobileNumber: PropTypes.string,
    handleUpdateNumberFunc: PropTypes.func,
    openUpdateNumberPage: PropTypes.bool,
}
export default AdminEmailUpdate
