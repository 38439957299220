import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import logo from '../../static/images/logo.svg'
import { updatePhoneNumber } from 'actions/authActions'
import AdminLogin from './AdminLogin'
import SuccessAlert from 'shared/SuccessAlert'

const UpdateAdminNumber = (props) => {
    const dispatch = useDispatch()
    const { updateMobileNumber, emailId } = props
    const [mobileNumber, setMobileNumber] = useState('')
    const [isLoginOpen, setIsLoginOpen] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [mobileNumberError, setMobileNumberError] = useState(false)
    const handleChange = (event) => {
        let obj = event.target.value
        var numbers = obj.replace(/\D/g, ''),
            char = { 0: '(', 3: ') ', 6: '-' }
        obj = ''
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i]
            obj
        }
        setMobileNumber(obj)
        setMobileNumberError(false)
    }
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            this.handleSubmit()
        }
    }
    const handleOtpModalFunc = () => {
        const data = {
            phone_number: mobileNumber,
            email_id: emailId,
        }
        if (mobileNumber) {
            dispatch(updatePhoneNumber(data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsLoginOpen(true)
                    setSuccessAlert(true)
                }
            })
        } else {
            setMobileNumberError(true)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    return (
        <>
            {updateMobileNumber && !isLoginOpen && (
                <div className="splash-container update-mobile-number  d-flex justify-content-center align-items-center admin-dashboard">
                    <div className="card mb-0">
                        <div className="card-header text-center border-0">
                            <img className="logo-img" src={logo} alt="logo" />
                            <h3>Update your mobile number</h3>
                            <span className="splash-description">
                                This will just take a few steps. First, please
                                enter the updated mobile number.
                            </span>
                        </div>
                        <div className="card-body d-flex justify-content-center">
                            <form>
                                <div className="form-group mt-0">
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        maxLength="14"
                                        onChange={handleChange}
                                        value={mobileNumber}
                                        onKeyDown={onKeyDown}
                                        id="mobile-number"
                                        placeholder="Updated mobile number"
                                        autoComplete="off"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block btn-radius"
                                    onClick={handleOtpModalFunc}
                                >
                                    Continue
                                </button>
                                {mobileNumberError && (
                                    <div className="alert alert-danger mb-0 mt-3 text-center">
                                        Invalid number
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {isLoginOpen && <AdminLogin />}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="The Mobile number is updated"
                />
            )}
        </>
    )
}
UpdateAdminNumber.propTypes = {
    updateMobileNumber: PropTypes.bool,
    emailId: PropTypes.string,
    history: PropTypes.object,
}
export default UpdateAdminNumber
