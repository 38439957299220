import React, { useState } from "react";
import PropTypes from "prop-types";
import BottomBarNavigation from "components/account/mobile/BottomBarNavigation";
import { Doughnut, Bar } from "react-chartjs-2";
import { GoDotFill } from "react-icons/go";
import { FiActivity } from "react-icons/fi";
import DoughnutCharIcon from "./../../../../../src/static/images/doughnut-chart-icon.jpeg";
import BarChartIcon from "./../../../../../src/static/images/bar-chart-icon.jpeg";
import { currencyFormatter, numberFormatter } from "utils/helper";
import PhysicianActivityMobile from "./PhysicianActivityMobile";

function PhysicianDashboardMobile(props) {
  const {
    finalBardata,
    totalAmount,
    availableToPayOutAmount,
    paidAmount,
    unPaidAmount,
    totalAmountOnDashboard,
    history,
    pastDuePayments,
    donutData,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenBar, setIsOpenBar] = useState(false);
  const handleOpenActivity = () => {
    setIsOpen(!isOpen);
  };
  const handleBarGraph = () => {
    setIsOpenBar(!isOpenBar);
  };
  return (
    <>
      {!isOpen && (
        <div className="physician-dashboard-mobi">
          <div className="header_sction text-white p-4">
            <div className="lb-balance">Account Balance</div>
            <div className="vl-amount mb-2">
              {currencyFormatter.format(totalAmount || 0)}
            </div>
            <button
              className="btn transfer-btn"
              onClick={() =>
                history.push({
                  pathname: "/balance-mobile",
                  state: {
                    totalAmount,
                    availableToPayOutAmount,
                  },
                })
              }
            >
              Transfer
            </button>
          </div>
          <span className="feather-icon" onClick={() => handleOpenActivity()}>
            <FiActivity className="font24" />
          </span>
          <div className="body_sction">
            <div className="row no-gutters">
              <div className="col-12">
                <div className="text-right">
                  <div onClick={handleBarGraph} className="chart-switch-button">
                    <img
                      src={isOpenBar ? BarChartIcon : DoughnutCharIcon}
                      width="24"
                      height="24"
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isOpenBar && (
              <div className="row no-gutters d-flex justify-content-between align-items-center px-3">
                <div className="d-flex flex-column text-left">
                  <div className="lb-projected">Projected total</div>
                  <div className="vl-total">
                    {currencyFormatter.format(totalAmountOnDashboard)}
                  </div>
                </div>
                <div className="form-group m-0">
                  <select className="form-control select-item-dropdown m-1">
                    <option>Week-to-date</option>
                  </select>
                </div>
              </div>
            )}
            {!isOpenBar ? (
              <div>
                <div className="row no-gutters px-3 py-4 d-flex justify-content-evently">
                  <div className="d-flex align-items-center">
                    <GoDotFill className="font24 paid-dot mx-1" />
                    <span className="nav-label mr-2">Paid &nbsp;</span>
                    <span className="specific_amount">
                      {numberFormatter(paidAmount)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <GoDotFill className="font24 unpaid-dot mx-1" />
                    <span className="nav-label">Unpaid &nbsp;</span>
                    <span className="specific_amount">
                      {numberFormatter(unPaidAmount)}
                    </span>
                  </div>
                </div>
                <div className="row no-gutters px-3 ">
                  <div className="w-100">
                    <Bar
                      pointstyle="star"
                      data={{
                        labels: finalBardata.labels,
                        responsive: true,
                        offset: true,
                        datasets: [
                          {
                            label: "paid",
                            pointstyle: "rectRounded",
                            backgroundColor: "#5164F4",
                            barThickness: 10,
                            categoryPercentage: 1,
                            data: finalBardata.previousDate.dataSet, //From API
                          },
                          {
                            label: "unpaid",
                            backgroundColor: "#F2F5F4",
                            barThickness: 10,
                            categoryPercentage: 1,
                            pointstyle: "triangle",
                            data: finalBardata.currentDate.dataSet, //From API
                          },
                        ],
                      }}
                      height={350}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                return (
                                  context.label +
                                  ": " +
                                  currencyFormatter.format(
                                    parseFloat(context.formattedValue)
                                  )
                                );
                              },
                            },
                          },
                        },
                        offsetGridLines: true,
                        drawTicks: true,
                        layout: {
                          padding: {
                            top: 30,
                            right: 40,
                            bottom: 40,
                          },
                        },
                        legend: {
                          display: true,
                          position: "top",
                          align: "start",
                          labels: {
                            usePointStyle: true,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          xAxes: [
                            {
                              stacked: true,
                              ticks: {
                                padding: 5,
                              },
                              gridLines: {
                                display: false,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              stacked: false,
                              gridLines: {
                                drawBorder: false,
                              },
                              ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 6,
                                padding: 20,
                                callback(n) {
                                  if (n < 1e3) return n;
                                  if (n >= 1e3)
                                    return +(n / 1e3).toFixed(1) + "K";
                                },
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row no-gutters px-3">
                <div className="w-full">
                  <div>
                    <div className="text-left flex-with-space-between mb-8">
                      <div className="d-flex align-items-end">
                        <h6 className="bold-text">Past Due Payments</h6>
                      </div>
                      <div className="mt-1">
                        <h6 className="total-label">
                          Total: {currencyFormatter.format(pastDuePayments)}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center w-full">
                      <div className="Doughnut-chart-wrapper-mobile">
                        <Doughnut
                          data={donutData}
                          options={{
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: function (context) {
                                    return (
                                      context.label +
                                      ": " +
                                      currencyFormatter.format(
                                        parseFloat(context.formattedValue)
                                      )
                                    );
                                  },
                                },
                              },
                            },
                          }}
                          className="Doughnut-chart"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <BottomBarNavigation />
        </div>
      )}
      {isOpen && (
        <PhysicianActivityMobile handleOpenActivity={handleOpenActivity} />
      )}
    </>
  );
}
PhysicianDashboardMobile.propTypes = {
  barAmountArray: PropTypes.array,
  barPaidArray: PropTypes.array,
  barUnpaidArray: PropTypes.array,
  totalAmount: PropTypes.string,
  availableToPayOutAmount: PropTypes.number,
  finalBardata: PropTypes.object,
  paidAmount: PropTypes.number,
  unPaidAmount: PropTypes.number,
  totalAmountOnDashboard: PropTypes.number,
  history: PropTypes.object,
  donutData: PropTypes.object,
  pastDuePayments: PropTypes.number,
};

export default PhysicianDashboardMobile;
