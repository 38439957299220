import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'

function PayDateInfo(props) {
    const { handlePayDateInfo } = props
    return (
        <div className="mobile_account_info Pay-date-page">
            <div id="content">
                <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                    <CgChevronLeft
                        className="back_icon"
                        onClick={() => handlePayDateInfo()}
                    />
                    <label className="nav-heading">Payment Start Date</label>
                    <span></span>
                </nav>
                <div>
                    <h4 className="main-heading">
                        Allow your patients to choose their payment start date.
                        For patients that use payment plans, this date will be
                        used as the monthly due date until the balance is paid.
                    </h4>
                    <div className="main-content">
                        Providing your patient’s with the flexibility to choose
                        when to make their payment will increase the chances of
                        bills being paid in full and on-time.
                    </div>
                </div>
            </div>
            <div className="px-3 pb-3">
                <button
                    type="submit"
                    className="btn mobile-green-btn"
                    onClick={() => handlePayDateInfo()}
                >
                    Got it
                </button>
            </div>
        </div>
    )
}

PayDateInfo.propTypes = {
    handlePayDateInfo: PropTypes.func,
}

export default PayDateInfo
