import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import '../../../static/stylesheets/account.scss'
import AccountTabsHeader from 'components/account/AccountTabsHeader'
import PatientSidebarNavigation from './PatientSidebarNavigation'
import PatientAccountTabs from './PatientAccountTabs';
import PatientAccountDetails from './PatientAccountDetails';
import PaymentMethods from './PaymentMethods';
import Notification from './Notification';
import Transaction from './Transaction';
import { isMobile } from 'constants/Constant';
import PatientBottombarNavigation from './PatientBottombarNavigation';
import { MdChevronRight } from 'react-icons/md';
import { getPatientOutstandingBalance } from 'actions/patientBankingActions';
import { currencyFormatter } from 'utils/helper';
import { getPatientCardListing } from 'actions/patientBankingActions';
function PatientAccount(props) {
    const dispatch = useDispatch()
    const payTab =
        props.location &&
        props.location.state &&
        props.location.state.paymentMethods
    const [input, setInput] = useState({
        patientAccountDetails: true,
        paymentMethods: false,
        notification: false,
        transaction: false,
    })
    useEffect(() => {
        dispatch(getPatientOutstandingBalance())
        dispatch(getPatientCardListing())
        setInput({
            paymentMethods: payTab ? payTab : '',
            patientAccountDetails: payTab ? false : true,
        })
    }, [ '' ])
    const patientOutstandingBalance = useSelector(state => state.patientOutstandingBalance)
    const cardListing = useSelector(state => state.patientCardListing )
    const outStandingBalance = patientOutstandingBalance &&  patientOutstandingBalance.total_remaining_balance
    const showSection = (e) => {
        setInput({
            patientAccountDetails: e.target.id === 'nav-account-tab',
            paymentMethods: e.target.id === 'nav-pay-tab',
            notification: e.target.id === 'nav-notification-tab',
            transaction: e.target.id === 'nav-trans-tab',
        })
    }
    return (
        <>
            <AccountTabsHeader history={ props.history } />

            {!isMobile && <div className="wrapper">
                <PatientSidebarNavigation />

                <div id="content">
                    <PatientAccountTabs showSection={ showSection } payTab={ payTab }/>
                    <div className="tab-content dashboard_tab_content_Wrapper" id="nav-tabContent">
                        {input.patientAccountDetails && <PatientAccountDetails />}
                        {input.paymentMethods && <PaymentMethods />}
                        {input.notification && <Notification />}
                        {input.transaction && <Transaction />}
                    </div>
                </div>
            </div>}

            {isMobile && <div className="wrapper mobile-navbar-page">
                <div id="content">
                    <div className="d-flex align-items-center justify-content-between mx-3 mt-4 mb-5 d-md-none d-lg-none d-xl-none"
                        onClick={ () =>  props.history.push({
                            pathname: '/patient-pay-balance',
                        }) }
                    >
                        <h3 className="main-heading mb-0">
                            Your outstanding<br /> balance is {currencyFormatter.format(outStandingBalance || 0 )}
                        </h3>
                        <MdChevronRight className="float-right font24 color_dark_grey" />
                    </div>
                    <PatientAccountTabs showSection={ showSection } history={ props.history } isMobile={ isMobile } cardListing={ cardListing }/>
                </div>
                <PatientBottombarNavigation />
            </div>}
        </>
    )
}

PatientAccount.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}

export default PatientAccount
