import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sendOtp, sendOtpEmail, sendUpdateEmail } from 'actions/authActions'
import { connect } from 'react-redux'
import MobileNumberModal from './MobileNumberModal'
import Modal from 'react-modal'
import { isMobile, MODAL_STYLES } from 'constants/Constant'
import { maxLengthCheck, onlyNumber } from 'utils/helper'
import { loginAction, sendFormData } from 'actions/authActions'

export class OtpModal extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            verification_otp: '',
            verificationOtpError: false,
            isOtpModal: true,
            updateNumberModal: false,
            openVerify: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    handleOpenModal = (event) => {
        event.preventDefault()
        const { userRole, formData, emailDetails, phone } = this.props
        this.setState({ isOtpModal: true, openVerify: false })
        const data = {
            user_role: userRole,
            phone_number: this.props && formData && formData.phone_number,
        }
        const loginData = {
            phone_number: formData ? formData.phone_number : phone,
        }
        const emailData = {
            email_id: emailDetails && emailDetails.email_id,
        }
        this.setState({ setIsOpen: true })
        if (data.user_role) {
            this.props.sendFormData(data)
        } else if (emailData.email_id) {
            this.props.sendUpdateEmail(emailData)
        } else {
            this.props.loginAction(loginData)
        }
    }

    closeMobileNumberModal = () => {
        this.setState({ updateNumberModal: false })
    }
    openVerificationModal = () => {
        this.setState({
            openVerify: !this.state.openVerify,
            isOtpModal: !this.state.isOtpModal,
            verification_otp: '',
        })
    }
    closeVerificationModal = () => {
        if (this.props.userRole) {
            this.props.history.push('/signup')
        }
        this.setState({ openVerify: !this.state.openVerify })
        this.props.closeOtpModal()
    }

    handleChange = (event) => {
        const { emailDetails } = this.props
        this.setState({
            [event.target.name]: event.target.value,
            verificationOtpError: false,
        })
        if (event.target.value.length === 4) {
            if (event.target.value) {
                const pattern = new RegExp(/^[0-9]*$/)
                if (!pattern.test(event.target.value)) {
                    this.setState({
                        verificationOtpError: true,
                    })
                }
            }
            event.preventDefault()
            const emailData = {
                verification_otp: event.target.value,
                email_id:
                    emailDetails && emailDetails.email_id
                        ? emailDetails.email_id
                        : this.props.email_id,
                phone_number: this.props.phone,
            }
            const data = {
                verification_otp: event.target.value,
                phone_number:
                    this.props.formData && this.props.formData.phone_number,
                user_role: this.props.userRole,
            }
            this.props.phone
                ? setTimeout(() => {
                      this.props.sendOtpEmail(emailData).then((response) => {
                          if (response.statusCode === 200) {
                              this.props.history.push({
                                  pathname: '/success',
                                  state: {
                                      phoneNumber: this.props.phone,
                                  },
                              })
                          }
                      })
                  }, 500)
                : emailDetails && emailDetails.email_id
                ? setTimeout(() => {
                      this.props.sendOtpEmail(emailData).then((response) => {
                          if (response.statusCode === 200) {
                              this.setState({
                                  updateNumberModal: true,
                                  isOtpModal: false,
                              })
                          }
                      })
                  }, 500)
                : setTimeout(() => {
                      this.props.sendOtp(data).then((response) => {
                          if (response.statusCode === 200) {
                              this.setState({ isOtpModal: false })
                              if (response.data.is_team_member) {
                                  this.props.history.push(
                                      '/physician-dashboard'
                                  )
                              } else if (response.data.is_verified) {
                                  if (isMobile) {
                                      this.props.history.push(
                                          '/physician-dashboard'
                                      )
                                  } else {
                                      if (response.data.is_passcode) {
                                          this.props.history.push(
                                              '/physician-dashboard'
                                          )
                                      } else {
                                          this.props.history.push(
                                              '/create-passcode'
                                          )
                                      }
                                  }
                              } else if (response.data.is_verified === 0) {
                                  response.data.is_identity_completed
                                      ? this.props.history.push({
                                            pathname: '/verified',
                                            state: {
                                                response,
                                            },
                                        })
                                      : response.data.is_profile_completed
                                      ? this.props.history.push('/basic-info')
                                      : response.data.is_registered
                                      ? this.props.history.push('/basic-info')
                                      : this.props.history.push('/basic-info')
                              } else {
                                  response.data.is_profile_completed
                                      ? this.props.history.push('/home')
                                      : this.props.history.push(
                                            '/patient-basic-profile'
                                        )
                              }
                          }
                      })
                  }, 500)
        }
    }
    render() {
        const {
            verificationOtpError,
            isOtpModal,
            updateNumberModal,
            openVerify,
            verification_otp,
        } = this.state
        const {
            isOtpCorrect,
            isOpen,
            closeOtpModal,
            emailModal,
            emailDetails,
            formData,
            phone,
        } = this.props
        return (
            <div>
                {isOtpModal && !updateNumberModal && (
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => closeOtpModal()}
                        style={MODAL_STYLES}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <button
                            type="button"
                            className="close modal_close_icon"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => closeOtpModal()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div
                            className="modal-dialog modal-dialog-centered signin-otp-modal"
                            role="document"
                        >
                            <div className="modal-content otp_modal">
                                <div className="modal-body">
                                    {verificationOtpError && (
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            Invalid verification code. Please
                                            enter numbers only.
                                        </div>
                                    )}
                                    {!verificationOtpError && isOtpCorrect && (
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            Invalid verification code. Please
                                            try again.
                                        </div>
                                    )}
                                    <h4>We just texted you</h4>
                                    <p className="font-weight400 font17">
                                        Please enter the verification code we
                                        just sent to{' '}
                                        {emailDetails && emailDetails.email_id
                                            ? emailDetails.email_id
                                            : formData
                                            ? formData.phone_number
                                            : phone}
                                    </p>
                                    <input
                                        type="number"
                                        maxLength="4"
                                        autoFocus
                                        onInput={maxLengthCheck}
                                        className="otp_text_field"
                                        name="verification_otp"
                                        autoComplete="off"
                                        value={verification_otp || ''}
                                        onChange={this.handleChange}
                                        onKeyDown={(event) => onlyNumber(event)}
                                        placeholder="----"
                                        data-dismiss="modal"
                                    />
                                    <a
                                        //   href="/#"
                                        onClick={this.openVerificationModal}
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                    >
                                        Didn’t get a code?
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                {openVerify && (
                    <Modal
                        isOpen={openVerify}
                        onRequestClose={this.closeVerificationModal}
                        style={MODAL_STYLES}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <button
                            type="button"
                            className="close modal_close_icon"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.closeVerificationModal}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="verify_modal" role="document">
                            <div className="text-center">
                                <div className="modal-body">
                                    <h4>
                                        {emailModal
                                            ? 'Verify your email details'
                                            : 'Verify your phone number'}
                                    </h4>
                                    <p className="font-weight400 font17">
                                        {emailModal
                                            ? "We'll mail you at"
                                            : "We'll text you at"}{' '}
                                        {emailDetails && emailDetails.email_id
                                            ? emailDetails.email_id
                                            : formData
                                            ? formData.phone_number
                                            : phone}
                                        .
                                    </p>
                                    <div className="d-flex flex-column">
                                        <button
                                            type="button"
                                            onClick={this.handleOpenModal}
                                            className="btn btn_custom_outline"
                                        >
                                            {emailModal
                                                ? 'Email me'
                                                : 'Text me'}
                                        </button>
                                        <button
                                            className="btn btn_custom_outline btn-margin"
                                            data-dismiss="modal"
                                            onClick={
                                                this.closeVerificationModal
                                            }
                                        >
                                            {emailModal
                                                ? 'Edit email address'
                                                : 'Edit phone number'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                {updateNumberModal && (
                    <MobileNumberModal
                        handleOpenModal={this.handleOpenModal}
                        email_id={
                            this.props.emailDetails &&
                            this.props.emailDetails.email_id
                        }
                        history={this.props.history}
                        isOpen={updateNumberModal}
                        closeMobileNumberModal={this.closeMobileNumberModal}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        isOtpCorrect: state.isOtpCorrect,
        // fcmToken: state.fcmToken
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendOtp: (data) => dispatch(sendOtp(data)),
        loginAction: (data) => dispatch(loginAction(data)),
        sendFormData: (data) => dispatch(sendFormData(data)),
        sendUpdateEmail: (data) => dispatch(sendUpdateEmail(data)),
        sendOtpEmail: (data) => dispatch(sendOtpEmail(data)),
    }
}

OtpModal.propTypes = {
    sendOtp: PropTypes.func,
    sendOtpEmail: PropTypes.func,
    closeOtpModal: PropTypes.func,
    sendUpdateEmail: PropTypes.func,
    loginAction: PropTypes.func,
    sendFormData: PropTypes.func,
    history: PropTypes.object,
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isOtpCorrect: PropTypes.bool,
    isOpen: PropTypes.bool,
    emailModal: PropTypes.bool,
    emailDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    email_id: PropTypes.string,
    phone: PropTypes.string,
    userRole: PropTypes.string,
    id: PropTypes.string,
    input: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal)
