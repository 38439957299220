import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'

export class AdminBreadCrumb extends Component {
    mapRoutesToItems = (routes) => {
        const items = []
        routes.map((route) => {
            items.push(
                route.isActive ? (
                    <li
                        className="breadcrumb-list-item active"
                        aria-current="page"
                    >
                        {route.name}
                    </li>
                ) : (
                    <li className="breadcrumb-list-item d-flex align-items-center">
                        {' '}
                        <a href={route.path}>{route.name}</a>{' '}
                        <MdChevronRight className="font16" />
                    </li>
                )
            )
        })

        return <React.Fragment> {items} </React.Fragment>
    }

    render() {
        return (
            <div className="page-breadcrumb admin-dashboard">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {this.mapRoutesToItems(this.props.routes)}
                    </ol>
                </nav>
            </div>
        )
    }
}
AdminBreadCrumb.propTypes = {
    routes: PropTypes.array,
}

export default AdminBreadCrumb
