import React from 'react'
import PropTypes from 'prop-types'
import PompayWhiteLogo from 'static/images/PompayWhiteLogo.svg'
import '../static/stylesheets/common.scss'

const PompayTopWhiteLogo = (props) => (
    <a className="navbar-brand big_logo d-none d-md-block d-lg-block d-xl-block">
        <img
            src={PompayWhiteLogo}
            className={props.className}
            alt="PompayLogo"
        />
    </a>
)
PompayTopWhiteLogo.propTypes = {
    className: PropTypes.string,
}

export default PompayTopWhiteLogo
