import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
    getTotalAmount,
    sendPhysicianTransferData,
} from 'actions/bankingActions'
import { currencyFormatter, calculatePayoutFee } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'

function PhysicianTransferSection(props) {
    const dispatch = useDispatch()
    const { cardListing, isTeam } = props
    const [selectedCard, setSelectedCard] = useState('')
    const [amount, setAmount] = useState('')
    const [selectedCardError, setSelectedCardError] = useState(false)
    const [amountError, setAmountError] = useState(false)
    const [confirmPay, setConfirmPay] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const totalAmountStripe = useSelector((state) => state.totalAmountStripe)
    useEffect(() => {
        dispatch(getTotalAmount())
    }, [''])
    const handleChangeOptions = (event) => {
        setSelectedCard(event.target.value)
        setSelectedCardError(false)
    }
    const handleChange = (event) => {
        setAmount(event.target.value)
        setAmountError(false)
    }

    // const setInputFilter = (document.getElementById("uintTextBox"), function(value) {
    //     return /^\d*$/.test(value);
    // });
    // // Restricts input for the given textbox to the given inputFilter.
    // const setInputFilter = (textbox, inputFilter) => {
    //     ["input"].forEach(function(event) {
    //     textbox.addEventListener(event, function() {
    //         if (inputFilter(this.value)) {
    //         this.oldValue = this.value;
    //         this.oldSelectionStart = this.selectionStart;
    //         this.oldSelectionEnd = this.selectionEnd;
    //         } else if (this.hasOwnProperty("oldValue")) {
    //         this.value = this.oldValue;
    //         this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
    //         } else {
    //         this.value = "";
    //         }
    //     });
    //     });
    // }
    const allCardList =
        cardListing &&
        cardListing.cardList &&
        cardListing.cardList.sort().map((data, index) => (
            <option key={index} value={data.card_id}>
                {data.card_type} {'****' + data.card_number}
            </option>
        ))
    const handleConfirm = () => {
        if (selectedCard === '') {
            setSelectedCardError(true)
            setConfirmPay(false)
        } else if (amount === '') {
            setAmountError('Please enter amount')
            setConfirmPay(false)
        } else if (
            parseInt(amount) > parseInt(totalAmountStripe.total_balance)
        ) {
            setAmountError('Insufficient funds in account')
            setConfirmPay(false)
        } else {
            setConfirmPay(true)
        }
    }
    const handleSubmit = () => {
        const data = {
            amount: amount,
            card_id: selectedCard,
            account_id: totalAmountStripe.stripe_account_id,
        }
        dispatch(sendPhysicianTransferData(data)).then((res) => {
            if (res.statusCode === 200) {
                setSuccessAlert(true)
                setSelectedCard('')
                setAmount('')
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
        setConfirmPay(!confirmPay)
    }
    const handleCancel = () => {
        setConfirmPay(!confirmPay)
        setAmount('')
        setSelectedCard('')
    }

    const payoutFee = calculatePayoutFee(amount)

    const availableToPayOutAmount = totalAmountStripe.available_to_payout
        ? totalAmountStripe.available_to_payout
        : 0

    const availableSoonAmount = totalAmountStripe.total_balance
        ? totalAmountStripe.total_balance
        : 0

    return (
        <>
            <div className="col-md-6">
                <div className="card tranfer_fund_card d-block m-auto">
                    <div className="card-body">
                        <h5 className="card-title text-left">Transfer Funds</h5>
                        <form>
                            <div className="form-row flex-column">
                                <div className="col-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                id="tf-from"
                                                disabled
                                                className="form-control account_info_field"
                                                defaultValue="Pompay"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="tf-from"
                                            >
                                                From
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            {/* <input
                                                type="text"
                                                className="form-control account_info_field"
                                                defaultValue="Chase Business"
                                            /> */}
                                            <select
                                                className="form-control account_info_field w-100"
                                                name={selectedCard}
                                                value={selectedCard}
                                                onChange={handleChangeOptions}
                                                id="select-card"
                                            >
                                                <option defaultValue="select card">
                                                    {' '}
                                                    Select account
                                                </option>
                                                {allCardList}
                                            </select>
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="select-card"
                                            >
                                                To
                                            </label>
                                        </div>
                                    </div>
                                    {selectedCardError && (
                                        <small className="text-danger float-left">
                                            Please select account
                                        </small>
                                    )}
                                </div>
                                <div className="col-12 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-icon left">
                                                <input
                                                    type="number"
                                                    id="tf-amount"
                                                    className="form-control account_info_field amount-field"
                                                    name={amount}
                                                    value={amount || ''}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="tf-amount"
                                                >
                                                    Amount
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    {amountError && (
                                        <small className="text-danger float-left">
                                            {amountError}
                                        </small>
                                    )}
                                </div>
                                <p
                                    className="available_amt"
                                    style={{ marginBottom: '0px' }}
                                >
                                    Available soon:{' '}
                                    {currencyFormatter.format(
                                        availableSoonAmount -
                                            availableToPayOutAmount
                                    )}
                                </p>
                                <p className="available_amt">
                                    Available to pay out:{' '}
                                    {currencyFormatter.format(
                                        availableToPayOutAmount
                                    )}
                                </p>
                            </div>
                        </form>
                        {!isTeam && !confirmPay && (
                            <button
                                className="btn review_transfer_btn"
                                onClick={handleConfirm}
                                disabled={
                                    confirmPay ||
                                    !totalAmountStripe.available_to_payout
                                }
                            >
                                Review Transfer
                            </button>
                        )}
                        {confirmPay && (
                            <div>
                                {' '}
                                <p className="pending_tranfer_label text-left mt-3">
                                    The funds will be deposited into your bank
                                    account in 1-3 business days. And transfer
                                    fee is {currencyFormatter.format(payoutFee)}
                                    , you will receive{' '}
                                    {currencyFormatter.format(
                                        amount - payoutFee
                                    )}
                                </p>
                                <button
                                    className="btn review_transfer_btn mt-1 d-flex justify-content-center align-items-center"
                                    onClick={handleSubmit}
                                >
                                    Transfer Funds
                                </button>{' '}
                                <button
                                    className="btn btn-link cancel_link"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="Fund transfer is completed successfully"
                />
            )}
        </>
    )
}

PhysicianTransferSection.propTypes = {
    cardListing: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    totalAmountStripe: PropTypes.array,
    isTeam: PropTypes.bool,
}

export default PhysicianTransferSection
