/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AdminHeader from './AdminHeader'
import AdminSideBarNavigation from './AdminSideBarNavigation'
import SubFooter from './SubFooter'
import AdminBreadCrumb from './AdminBreadCrumb'
import {
    getAdminPhysicianList,
    physicianVerifyAction,
} from 'actions/adminActions'
import { Table } from 'antd'
import Loading from 'shared/Loader'
import SuccessAlert from 'shared/SuccessAlert'

function AdminPhysicianList(props) {
    const { history } = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAdminPhysicianList())
    }, [''])
    const [serchValue, setSerchValue] = useState('')
    const [successAlert, setSuccessAlert] = useState(false)
    const physicianDetails = useSelector((state) => state.adminPhysicianDetails)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const hanldeVerify = (row) => {
        const data = {
            id: row.id,
        }
        dispatch(physicianVerifyAction(data)).then((res) => {
            if (res.statusCode === 200) {
                setSuccessAlert(true)
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Email',
            dataIndex: 'email_id',
        },
        {
            title: 'Mobile number',
            dataIndex: 'phone_number',
        },
        {
            title: 'NPI number',
            // dataIndex: 'npi_number',
            render: (key) => (
                <div className="patient-name text-center">
                    {' '}
                    {(key && key.npi_number) || ' - '}
                </div>
            ),
        },
        {
            title: 'Tax id',
            // dataIndex: 'tax_id',
            render: (key) => (
                <div className="patient-name text-center">
                    {' '}
                    {(key && key.tax_id) || ' - '}
                </div>
            ),
        },
        {
            title: 'Specialist',
            // dataIndex: 'speciality',
            render: (key) => (
                <div className="patient-name text-center">
                    {' '}
                    {(key && key.speciality) || ' - '}
                </div>
            ),
        },
        {
            title: 'Zip code',
            // dataIndex: 'zipcode',
            render: (key) => (
                <div className="patient-name text-center">
                    {' '}
                    {(key && key.zipcode) || ' - '}
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (key) => (
                <>
                    {!key.is_verified ? (
                        <button
                            className="btn accept-btn"
                            onClick={() => hanldeVerify(key)}
                        >
                            Verify
                        </button>
                    ) : (
                        <span className="accepted-label">Verified</span>
                    )}
                </>
            ),
        },
    ]
    const updateSearch = (event) => {
        setSerchValue(event.target.value.toLowerCase().substr(0, 20))
    }
    const handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }
    const result =
        physicianDetails && physicianDetails.filter((o) => o.first_name)
    const filteredData =
        result &&
        result.filter((d) => {
            return (
                (d.full_name &&
                    d.full_name.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.speciality &&
                    d.speciality.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.email_id &&
                    d.email_id.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.phone_number && d.phone_number.indexOf(serchValue) !== -1) ||
                (d.phone_number &&
                    d.phone_number
                        .replace(/[- )(]/g, '')
                        .indexOf(serchValue) !== -1) ||
                (d.zipcode && d.zipcode.indexOf(serchValue) !== -1) ||
                (d.tax_id && d.tax_id.indexOf(serchValue) !== -1) ||
                (d.npi_number && d.npi_number.indexOf(serchValue) !== -1)
            )
        })
    return (
        <>
            <div className="dashboard-main-wrapper admin-dashboard">
                <AdminHeader />
                <AdminSideBarNavigation history={history} />
                <div className="dashboard-wrapper">
                    <div className="list-page physician-list">
                        <div className="container-fluid dashboard-content">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="page-header">
                                        <h3 className="mb-2 text-left">
                                            Physician List{' '}
                                        </h3>
                                        <AdminBreadCrumb
                                            routes={[
                                                {
                                                    path: '/admin-dashboard',
                                                    name: 'Dashboard',
                                                },
                                                {
                                                    path: '/admin-physician',
                                                    name: 'Physician list',
                                                    isActive: true,
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="card">
                                        <h5 className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col-12">
                                                    <form>
                                                        <input
                                                            className="form-control form-control-lg search-input"
                                                            type="search"
                                                            name="patientSearchParam"
                                                            value={
                                                                serchValue || ''
                                                            }
                                                            onChange={
                                                                updateSearch
                                                            }
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </h5>
                                        <div className="card-body">
                                            {/* <div className="table-responsive"> */}
                                            {isLoading && (
                                                <div className="mt-5 mx-4">
                                                    <Loading />
                                                </div>
                                            )}
                                            {!isLoading && (
                                                <Table
                                                    columns={columns}
                                                    dataSource={filteredData}
                                                    onChange={handleChange}
                                                />
                                            )}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SubFooter />
                    </div>
                </div>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="Physician successfully verified"
                />
            )}
        </>
    )
}

AdminPhysicianList.propTypes = {
    history: PropTypes.object,
}
export default AdminPhysicianList
