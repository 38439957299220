import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isMobile, yyyymmddFormat } from 'constants/Constant'
import { createEmiPayment } from 'actions/patientBankingActions'
import PompayTopLogo from 'shared/PompayTopLogo'
import ConfirmDelete from 'shared/ConfirmDelete'
import { currencyFormatter } from 'utils/helper'

function SetUpPlanPaymentMobile(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        history,
        handleSetUpPlanFunc,
        physicianDetails,
        dateValue,
        billAmount,
        paymentScheduleDetails,
        pompayDisountId,
        selectedDate,
        // closeAllModalFunc
    } = props
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const [closeAll, setCloseAll] = useState(false)
    const confirmRemove = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const cancelPayment = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const handleRemoveFunc = () => {
        setIsConfirmDelete(!isConfirmDelete)
        setCloseAll(true)
        history.push('/physician-list-view')
    }
    const handleConfirm = () => {
        const data = {
            original_amount: billAmount,
            discount_amount: pompayDisountId
                ? paymentScheduleDetails.saved_amount.toString()
                : '0',
            discount_by: pompayDisountId
                ? parseInt(paymentScheduleDetails.discount_by)
                : 0,
            adjusted_amount: pompayDisountId
                ? paymentScheduleDetails.instant_pay_amount.toString()
                : billAmount,
            months: pompayDisountId
                ? 0
                : paymentScheduleDetails.installment_option[0].month,
            first_installment_amount:
                paymentScheduleDetails.installment_option[0].first_installment_amount.toString(),
            last_installment_amount: pompayDisountId
                ? '0'
                : (paymentScheduleDetails.installment_option[0]
                      .last_installment_amount &&
                      paymentScheduleDetails.installment_option[0].last_installment_amount.toString()) ||
                  '0',
            physician_id: physicianDetails.physician_id,
            due_amount_date: moment(dateValue).format(yyyymmddFormat),
            patient_appointment_id:
                paymentScheduleDetails.patient_appointment_id,
        }
        dispatch(createEmiPayment(data))
        {
            isMobile && history.push('/physician-list-view')
        }
    }
    return (
        <>
            {isOpen && !closeAll && (
                <div className="mobile_account_info thank-you-page">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-start">
                            <a
                                className="edit-link"
                                href="#"
                                onClick={() => handleSetUpPlanFunc()}
                            >
                                Edit
                            </a>
                            <PompayTopLogo
                                className="small_logo patient_list_logo"
                                ancorClassName=""
                            />
                            <button
                                type="button"
                                className="close close-bold-icon z-index1"
                                onClick={() => cancelPayment()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </nav>
                        <div
                            id="nav-tabContent"
                            className="mobile-content-height"
                        >
                            <div className="text-left mb-4">
                                {pompayDisountId ? (
                                    <h3 className="page-heading text-left mt-2 mb-3">
                                        Thank you for choosing
                                        <br /> the Pompay discount.
                                    </h3>
                                ) : (
                                    <h3 className="page-heading text-left mt-2 mb-3">
                                        You have set up a new
                                        <br /> payment plan.{' '}
                                    </h3>
                                )}
                                {pompayDisountId ? (
                                    <span className="page-paragraph">
                                        Your physician will be notified of your
                                        new payment arrangement. We will send
                                        you a text once your physician has
                                        accepted the arrangement or if any edits
                                        were made.
                                    </span>
                                ) : (
                                    <span className="page-paragraph">
                                        Your physician will be notified of your
                                        new payment arrangement. We will send
                                        you a text once your physician has
                                        accepted the arrangement or if any edits
                                        were made
                                    </span>
                                )}
                            </div>
                            <div className="bill-details">
                                <h3 className="details-label">Details</h3>
                                <div className="details-wrappers">
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">Physician</span>
                                        <span className="value">
                                            {physicianDetails.physician_name ||
                                                physicianDetails.physician_full_name}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">Date</span>
                                        <span className="value">
                                            {selectedDate}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">
                                            Patient I.D.
                                        </span>
                                        <span className="value">
                                            {
                                                paymentScheduleDetails.patient_appointment_id
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">Amount</span>
                                        <span className="value">
                                            {currencyFormatter.format(
                                                billAmount
                                            )}
                                        </span>
                                    </div>
                                    {pompayDisountId ? (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="label">
                                                Discount
                                            </span>
                                            <span className="value">
                                                -
                                                {currencyFormatter.format(
                                                    paymentScheduleDetails.saved_amount
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="label">
                                                Installments:
                                            </span>
                                            <span className="value">
                                                {
                                                    paymentScheduleDetails
                                                        .installment_option[0]
                                                        .month
                                                }
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {pompayDisountId ? (
                                    <div className="d-flex justify-content-between mt-3 mb-1">
                                        <span className="bold-label">
                                            Total
                                        </span>
                                        <span className="bold-value">
                                            {currencyFormatter.format(
                                                paymentScheduleDetails.instant_pay_amount
                                            )}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="bold-label">
                                            1st Installment:
                                        </span>
                                        <span className="bold-value">
                                            {currencyFormatter.format(
                                                paymentScheduleDetails
                                                    .installment_option[0]
                                                    .first_installment_amount
                                            )}
                                        </span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between my-2">
                                    <span className="bold-label">Due date</span>
                                    <span className="bold-value">
                                        {selectedDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={handleConfirm}
                            >
                                Finish
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmDelete && !closeAll && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={confirmRemove}
                    mode="bill"
                    handleRemoveFunc={handleRemoveFunc}
                />
            )}
        </>
    )
}

SetUpPlanPaymentMobile.propTypes = {
    handleSetUpPlanFunc: PropTypes.func,
    createEmiPayment: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    pompayDisountId: PropTypes.string,
    billAmount: PropTypes.string,
    paymentScheduleDetails: PropTypes.array,
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateValue: PropTypes.oneOfType([PropTypes.date, PropTypes.number]),
    closeAllModalFunc: PropTypes.func,
    closeModal: PropTypes.func,
}

export default SetUpPlanPaymentMobile
