import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { MdChevronLeft } from 'react-icons/md'
import Loading from 'shared/Loader'
import { MdLock } from 'react-icons/md'
import { deletePatient } from 'actions/patientActions'
import ConfirmDelete from 'shared/ConfirmDelete'
import { getPatientList } from 'actions/patient/patient.actions'

const PatientProfileModal = (props) => {
    const dispatch = useDispatch()
    const { input, updateProfileInfo, handleProfileChange, onlyView } = props
    const [isEdit, setIsEdit] = useState(false)
    // const [ failError, setFailError ] = useState(false)
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const removePatientProfile = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const handleRemoveFunc = () => {
        const id = props.input && props.input.patient_id
        dispatch(deletePatient(id)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getPatientList(''))
                props.closeModal()
                window.location.reload()
            }
        })
    }
    const OpenEdit = () => {
        setIsEdit(!isEdit)
    }
    const enabled = !isEdit
    return (
        <>
            {!isConfirmDelete && (
                <Modal
                    isOpen={props.isOpen}
                    onRequestClose={props.closeModal}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    id="patient-profile-modal"
                >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <MdChevronLeft
                            className="dark_icon back_icon color_light_green pointer font40"
                            onClick={() => props.closeModal()}
                        />
                        {/* {failError && <div className="alert alert-danger" role="alert">
                        Not able to delete the patient.
                    </div>} */}
                        <h5 className="modal-heading mb-0">Patient Profile</h5>
                        {onlyView ? (
                            <button
                                type="button"
                                className="close modal_close_custom_icon"
                                data-dismiss="modal"
                                style={{ top: '-7px' }}
                                aria-label="Close"
                                onClick={props.closeModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        ) : (
                            <button
                                type="button"
                                className={
                                    !isEdit
                                        ? 'btn btn-link edit'
                                        : 'btn btn-link cancel'
                                }
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={OpenEdit}
                            >
                                {!isEdit ? 'Edit' : 'Cancel'}
                            </button>
                        )}
                    </div>
                    {isModalLoading && (
                        <div className="text-center">
                            <Loading />
                        </div>
                    )}
                    {!isModalLoading && (
                        <div>
                            <form>
                                <div className="form-row flex-column">
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    id="fname"
                                                    value={input.first_name}
                                                    // onChange={ (event) => handleProfileChange(event) }
                                                    disabled
                                                    className="form-control account_info_field"
                                                    placeholder="first name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="fname"
                                                >
                                                    First name
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.first_name}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    id="lname"
                                                    name="last_name"
                                                    value={input.last_name}
                                                    disabled
                                                    // onChange={ (event) => handleProfileChange(event) }
                                                    className="form-control account_info_field"
                                                    placeholder="last name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="lname"
                                                >
                                                    Last name
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.last_name}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    id="patient-id"
                                                    name="patient_appointment_id"
                                                    value={
                                                        input.patient_appointment_id ||
                                                        ''
                                                    }
                                                    disabled={enabled}
                                                    onChange={
                                                        handleProfileChange
                                                    }
                                                    className="form-control account_info_field"
                                                    // placeholder="last name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="patient-id"
                                                >
                                                    Patient I.D
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.patientId}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    id="dob"
                                                    name="date_of_birth"
                                                    disabled
                                                    value={input.date_of_birth}
                                                    className="form-control account_info_field"
                                                />
                                                <MdLock className="textfield-lock-icon" />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="dob"
                                                >
                                                    Date of birth
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.date_of_birth}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    id="mobi-number"
                                                    maxLength="14"
                                                    name="phone_number"
                                                    disabled
                                                    value={input.phone_number}
                                                    // onChange={ (event) => handleProfileChange(event) }
                                                    className="form-control account_info_field"
                                                />
                                                <MdLock className="textfield-lock-icon" />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="mobi-number"
                                                >
                                                    Mobile number
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.phone_number}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="email"
                                                    name="email_id"
                                                    id="email"
                                                    autoComplete="off"
                                                    disabled
                                                    value={input.email_id}
                                                    // onChange={ (event) => handleProfileChange(event) }
                                                    className="form-control account_info_field"
                                                />
                                                <MdLock className="textfield-lock-icon" />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.email_id}</small> */}
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    id="zipcode"
                                                    name="zipcode"
                                                    disabled
                                                    className="form-control account_info_field"
                                                    value={input.zipcode}
                                                    // onChange={ (event) => handleProfileChange(event) }
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="zipcode"
                                                >
                                                    Zipcode
                                                </label>
                                            </div>
                                        </div>
                                        {/* <small className="text-danger">{errors.zipcode || ""}</small> */}
                                    </div>
                                </div>
                            </form>
                            <div className="text-center pt-5 mb-4">
                                {!isEdit ? (
                                    <button
                                        onClick={() => removePatientProfile()}
                                        className="btn btn-link delete-link"
                                    >
                                        Delete Patient
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="btn save_team_member_btn d-block mr-auto ml-auto"
                                        onClick={() => updateProfileInfo()}
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </Modal>
            )}
            {isConfirmDelete && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={removePatientProfile}
                    handleRemoveFunc={handleRemoveFunc}
                    role="patient profile"
                    mode="patient profile"
                />
            )}
        </>
    )
}

PatientProfileModal.propTypes = {
    sendNotificationData: PropTypes.func,
    handleProfileChange: PropTypes.func,
    updateProfileInfo: PropTypes.func,
    input: PropTypes.object,
    onlyView: PropTypes.bool,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
}

export default PatientProfileModal
