import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { MdChevronLeft } from 'react-icons/md'
import { BsCreditCard } from 'react-icons/bs'
import {
    getAccountExists,
    sendCustomerPatientAccount,
    getPatientCardListing,
} from 'actions/patientBankingActions'
import AddBankCard from 'components/account/AddBankCard'
import FinalAmountToPay from './FinalAmountToPay'
import FinalFullAmountToPay from './FinalFullAmountToPay'
import SelectBillDate from './SelectBillDate'
import { MdInfoOutline } from 'react-icons/md'
import PromisePayModal from 'shared/PromisePayModal'
import { RiCalendar2Fill } from 'react-icons/ri'
import Loading from 'shared/Loader'
import SuccessAlert from 'shared/SuccessAlert'

function SelectPaymentMethods(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closePaymentMethod,
        physicianDetails,
        selectedPayDetails,
        openRemaningPayDetailsModalDetails,
        history,
        installBillDetails,
        transferFundData,
        paymentActivityData,
        fullPayment,
    } = props
    const [openCard, setOpenCard] = useState(false)
    const [openSelectDate, setOpenSelectDate] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [selectedCardDetails, setSelectedCardDetails] = useState({})
    const [selectedCardError, setSelectedCardError] = useState(false)
    const [openPromptInfo, setOpenPromptInfo] = useState(false)
    const [openFinalPaymentModal, setOpenFinalPaymentModal] = useState(false)
    const patientCardListing = useSelector((state) => state.patientCardListing)
    const paymentScheduleDetails = useSelector(
        (state) => state.paymentScheduleDetails
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getAccountExists()).then((res) => {
            if (res.statusCode === 200) {
                if (!res.data.account_exists) {
                    dispatch(sendCustomerPatientAccount())
                } else {
                    dispatch(getPatientCardListing())
                }
            }
        })
    }, [''])
    const closeModal = () => {
        setOpenCard(!openCard)
    }
    const handlePayment = () => {
        if (Object.keys(selectedCardDetails).length === 0) {
            setSelectedCardError(true)
        } else {
            setOpenFinalPaymentModal(!openFinalPaymentModal)
        }
    }
    const handleSelectCard = (data) => {
        setSelectedCardDetails(data)
        setSelectedCardError(false)
        // handlePayment()
    }
    const closeFinalPayAmount = () => {
        setOpenFinalPaymentModal(!openFinalPaymentModal)
    }
    const handlePromisePay = () => {
        // if (installBillDetails &&  installBillDetails.full_payment_schedule && installBillDetails.full_payment_schedule[ 0 ] && installBillDetails.full_payment_schedule[ 0 ].late_fee ){
        if (
            openRemaningPayDetailsModalDetails &&
            openRemaningPayDetailsModalDetails.enable_promise_to_pay
        ) {
            if (
                openRemaningPayDetailsModalDetails &&
                openRemaningPayDetailsModalDetails.days_past_count
            ) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'You are not eligible for promise to pay'
                )
            } else {
                setOpenSelectDate(!openSelectDate)
            }
        } else {
            setSuccessAlert(true)
            setSuccessAlertMessage(
                'This physician has not enabled promise to pay'
            )
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const handlePompayInfo = () => {
        setOpenPromptInfo(!openPromptInfo)
    }
    return (
        <>
            {!openCard &&
                !openFinalPaymentModal &&
                !openSelectDate &&
                !openPromptInfo && (
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => closePaymentMethod()}
                        style={MODAL_STYLES}
                        ariaHideApp={false}
                        id="payment-method-modal"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                        >
                            <div className="modal-content otp_modal">
                                <div className="d-flex justify-content-between align-items-center modal-header-border">
                                    <MdChevronLeft
                                        className="back-icon pointer"
                                        onClick={() => closePaymentMethod()}
                                    />
                                    <span className="patient-name">
                                        Payment Methods
                                    </span>
                                    <span></span>
                                </div>
                                <div className="modal-body">
                                    <h5 className="page-heading">
                                        Select a payment method{' '}
                                    </h5>
                                    <table className="table table-borderless office_info">
                                        <tbody className="border-0 linked-accounts">
                                            {selectedCardError && (
                                                <div className="my-2">
                                                    <small className="alert alert-danger alert-error-msg d-flex">
                                                        Please select card
                                                    </small>
                                                </div>
                                            )}
                                            {isLoading && (
                                                <div className="text-center">
                                                    <Loading />
                                                </div>
                                            )}
                                            {!isLoading &&
                                                patientCardListing &&
                                                patientCardListing?.cardList?.map(
                                                    (card, id) => {
                                                        return (
                                                            <tr key={id}>
                                                                <td className="px-0 d-flex align-items-center">
                                                                    <div
                                                                        className="d-flex justify-content-between align-items-center w-100"
                                                                        onClick={() =>
                                                                            handleSelectCard(
                                                                                card
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                                            <div>
                                                                                <div className="office_address">
                                                                                    {
                                                                                        card.card_type
                                                                                    }{' '}
                                                                                    {'****' +
                                                                                        card.card_number}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <input
                                                                            type="radio"
                                                                            className="account-radio-btn mr-2"
                                                                            name="card"
                                                                            value={
                                                                                card.id
                                                                            }
                                                                            id={
                                                                                id
                                                                            }
                                                                            onClick={() =>
                                                                                handleSelectCard(
                                                                                    card
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            <tr>
                                                <td className="px-0 d-flex align-items-center">
                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                        <div className="d-flex align-items-center">
                                                            <RiCalendar2Fill className="account-icon font22" />
                                                            <div className="d-flex">
                                                                <div
                                                                    className="office_name mr-2 pointer"
                                                                    onClick={() =>
                                                                        handlePromisePay()
                                                                    }
                                                                >
                                                                    Promise to
                                                                    Pay
                                                                </div>
                                                                <MdInfoOutline
                                                                    className="info-icon pointer"
                                                                    onClick={
                                                                        handlePompayInfo
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button
                                        className="btn btn-link add-card-link"
                                        onClick={() => setOpenCard(true)}
                                    >
                                        Add a card...
                                    </button>
                                </div>
                                <button
                                    className="btn next-btn"
                                    onClick={handlePayment}
                                >
                                    {' '}
                                    Continue{' '}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            {openCard && (
                <Modal
                    isOpen={openCard}
                    onRequestClose={closeModal}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <button
                        type="button"
                        className="close modal_close_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModal}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="modal-body">
                        <AddBankCard closeModal={closeModal} patient={true} />
                    </div>
                </Modal>
            )}
            {!fullPayment && openFinalPaymentModal && (
                <FinalAmountToPay
                    isOpen={openFinalPaymentModal}
                    closeFinalPayAmount={closeFinalPayAmount}
                    physicianDetails={physicianDetails}
                    selectedCardDetails={selectedCardDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    selectedPayDetails={selectedPayDetails}
                    history={history}
                    installBillDetails={installBillDetails}
                    fullAmount={props.fullPayment}
                />
            )}
            {fullPayment && (
                <FinalFullAmountToPay
                    isOpen={openFinalPaymentModal}
                    closeFinalPayAmount={closeFinalPayAmount}
                    physicianDetails={physicianDetails}
                    selectedCardDetails={selectedCardDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    selectedPayDetails={selectedPayDetails}
                    history={history}
                    installBillDetails={installBillDetails}
                    fullAmount={props.fullPayment}
                    paymentActivityData={paymentActivityData}
                />
            )}
            {openSelectDate && (
                <SelectBillDate
                    isOpen={openSelectDate}
                    closeSelectBill={handlePromisePay}
                    paymentScheduleDetails={paymentScheduleDetails}
                    history={history}
                    physicianDetails={physicianDetails}
                    promisePayKey="promisePay"
                    installBillDetails={installBillDetails}
                    transferFundData={transferFundData}
                />
            )}
            {openPromptInfo && (
                <PromisePayModal
                    isOpen={openPromptInfo}
                    handlePompayInfo={handlePompayInfo}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                    description="Please consult your physician."
                />
            )}
        </>
    )
}

SelectPaymentMethods.propTypes = {
    closePaymentMethod: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    selectedPayDetails: PropTypes.object,
    openRemaningPayDetailsModalDetails: PropTypes.object,
    transferFundData: PropTypes.object,
    paymentActivityData: PropTypes.object,
    fullPayment: PropTypes.object,
}

export default SelectPaymentMethods
