import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { dateFormat } from 'constants/Constant'
import moment from 'moment'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'

const PatientBalanceBillDetailsHistory = (props) => {
    const { isOpenBillHistory, openBillDetailsHistory , installmentDetails } = props;
    return (
        <>
            {isOpenBillHistory  && <div className="bill_details mobile-bill-details mobile_account_info">
                <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                    <CgChevronLeft
                        className="back_icon"
                        onClick={ ()=> openBillDetailsHistory() }
                    />
                    <label className="nav-heading">Bill Details</label>
                    <span></span>
                </nav>
                <div className="heading_section d-flex flex-column text-center mt-5">
                    <span className="main-balanceheading">Remaining Balance</span>
                    <span className="main-amount">{currencyFormatter.format(installmentDetails.totalInstallment || 0 )}</span>
                </div>
                <div className="my-4 text-center">
                    <span className="content-heading">Full payment schedule:</span>
                </div>
                {installmentDetails && installmentDetails.installment_detail && installmentDetails.installment_detail.map((installment, id)=>{
                    return    <div className="row no-gutters my-4" key ={ id }>
                        <div className="col-2 col-sm-2">
                            <form className="radio_form">
                                <label className="radio_btn" htmlFor="sms">
                                    <input type="radio" defaultChecked={ id == 0 && true }
                                        name= 'account' />
                                    <span className="checkmark"></span>
                                </label>
                            </form>
                        </div>
                        <div className="col-7 col-sm-7 text-left">
                            <div className="d-flex flex-column ml-2">
                                <span className="installment-label">
                                    {toOrdinalSuffix(id + 1 )} installment
                                </span>
                                <span className="installment-date">
                                    {installment.due_amount_status}: {moment(installment.due_amount_date).format(dateFormat)} &nbsp;
                                    {installment.is_late_fee_applied === 1 ? <span className="installment-label text-danger">LATE</span> : '' }
                                </span>
                            </div>
                        </div>
                        <div className="col-3 col-sm-3">
                            <div className="d-flex flex-column">
                                <span className="installment-label">
                                    {currencyFormatter.format(installment.amount || 0 )}
                                </span>
                                <span>
                                    {
                                        installment.is_late_fee_applied === 1 ? <span className="installment-label text-danger">+{currencyFormatter.format(installment.late_fee)}</span> : ''
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                })}
            </div>}
        </>
    )
}
PatientBalanceBillDetailsHistory.propTypes = {
    isOpenBillHistory: PropTypes.bool,
    openBillDetailsHistory: PropTypes.func,
    installmentDetails: PropTypes.object,
    // history: PropTypes.object,
    // transferFundData: PropTypes.object,
    // outStandingBalance: PropTypes.number
}
export default PatientBalanceBillDetailsHistory
