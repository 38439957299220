import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'

function VarianceInfo(props) {
    const { handleVarianceInfo } = props
    return (
        <div className="mobile_account_info amount-variance-page">
            <div id="content">
                <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                    <CgChevronLeft
                        className="back_icon"
                        onClick={() => handleVarianceInfo()}
                    />
                    <label className="nav-heading">Amount Variance</label>
                    <span></span>
                </nav>
                <div>
                    <h4 className="main-heading">
                        This makes it possible for you to accept payments less
                        than your minimum monthly payment.
                    </h4>
                    <div className="main-content">
                        <strong>Example:</strong> Your minimum monthly payment
                        is set at $25 and the max # of months is 3. Your patient
                        has a bill that is $74.
                        <br />
                        <br />
                        $74 ÷ 3 = $24.66, which is less than your $25 minimum.
                        Setting a deviation makes it possible for you to accept
                        this arrangement.
                    </div>
                </div>
            </div>
            <div className="px-3 pb-3">
                <button
                    type="submit"
                    className="btn mobile-green-btn"
                    onClick={() => handleVarianceInfo()}
                >
                    Got it
                </button>
            </div>
        </div>
    )
}

VarianceInfo.propTypes = {
    handleVarianceInfo: PropTypes.func,
}

export default VarianceInfo
