import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { isMobile } from 'constants/Constant'
import { CgChevronLeft } from 'react-icons/cg'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'
import ModalLoader from 'shared/ModalLoader'
//import ViewReceiptMobile from './ViewReceiptMobile'
import PatientPayMethod from '../account/mobile/PatientPayMethod'
import { getTransferFundsData } from 'actions/patientBankingActions'
import moment from 'moment'
import { IoMdSync } from 'react-icons/io'
import { Switch } from 'antd'
import PatientPaymentMobile from '../account/mobile/PatientPaymentMobile'
import { updateAutoPayStatus } from '../../../actions/patientActions'

const RemaningBalanceBillDetails = (props) => {
    const dispatch = useDispatch()
    const {
        physicianDetails,
        handleCloseRemaningBalanceFunc,
        remaningBalancePageDetails,
    } = props
    const installBillDetails = useSelector((state) => ({
        ...state.installBillDetails?.remaining_balance,
        isAutoPay: state.installBillDetails?.payment_details?.is_auto_pay,
        patientPaymentId:
            state.installBillDetails?.payment_details?.patient_payment_id,
    }))
    const transferFundData = useSelector((state) => state.transferFundData)
    const patientCardListing = useSelector((state) => state.patientCardListing)
    const [payMethodPage, setPayMethodPage] = useState(false)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    //const [openReceipt, setOpenReceipt] = useState(false)
    //const [receiptDetails, setReceiptDetails] = useState({})
    const [isEmptyList, setIsEmptyList] = useState(false)
    useEffect(() => {
        dispatch(getTransferFundsData())
    }, [''])
    // const viewReceiptFunc = (data) => {
    //     setReceiptDetails(data)
    //     setOpenReceipt(!openReceipt)
    // }
    // const closeViewReceiptFunc = () => {
    //     setOpenReceipt(!openReceipt)
    // }
    const handlePayMethodPage = () => {
        if (Object.keys(patientCardListing).length === 0) {
            setIsEmptyList(!isEmptyList)
        } else {
            setPayMethodPage(!payMethodPage)
            setIsEmptyList(false)
        }
    }
    const tempArray = []
    installBillDetails &&
        installBillDetails.full_payment_schedule &&
        installBillDetails.full_payment_schedule.map((pay) => {
            return tempArray.push(parseFloat(pay.due_amount))
        })

    const goPastDueDetailPageFunc = () => {
        setIsEmptyList(!isEmptyList)
    }
    const goToPayMethodPageFunc = () => {
        setPayMethodPage(!payMethodPage)
    }

    const toggleAutoPayStatus = () => {
        if (!installBillDetails) {
            return
        }
        dispatch(
            updateAutoPayStatus(
                installBillDetails.patientPaymentId,
                !installBillDetails.isAutoPay
            )
        )
    }

    return (
        <>
            {/*{isMobile && !openReceipt && !payMethodPage && !isEmptyList && (*/}
            {isMobile && !payMethodPage && !isEmptyList && (
                <div className="bill_details mobile-bill-details mobile_account_info">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => handleCloseRemaningBalanceFunc()}
                            />
                            <label className="nav-heading">Bill Details</label>
                            <span></span>
                        </nav>
                        <div className="mobile-content-height">
                            <div className="heading_section d-flex flex-column text-center pt-5">
                                <span className="main-balanceheading">
                                    Remaining Amount
                                </span>
                                {/* {isModalLoading && <ModalLoader />} */}
                                {/* <span className="main-amount">{currencyFormatter.format(totalRemaningBalance || 0 )}</span> */}
                                {!isModalLoading && installBillDetails && (
                                    <h3
                                        className="amount"
                                        style={{
                                            fontSize: '45px',
                                            fontFamily: 'Helvetica Neue Medium',
                                        }}
                                    >
                                        {currencyFormatter.format(
                                            (installBillDetails &&
                                                installBillDetails.remaining_balance) ||
                                                0
                                        )}
                                    </h3>
                                )}
                            </div>
                            <div className="d-flex justify-content-evently align-items-center dashed-border px-5">
                                <IoMdSync className="back-icon" />
                                <div className="d-flex flex-column text-left green-content">
                                    <div>
                                        <span>AutoPay: </span>
                                        <span>
                                            {installBillDetails?.isAutoPay
                                                ? 'On'
                                                : 'Off'}
                                        </span>
                                    </div>
                                    <span>Turn on to never miss a payment</span>
                                </div>
                                <Switch
                                    onChange={toggleAutoPayStatus}
                                    checked={Boolean(
                                        installBillDetails?.isAutoPay
                                    )}
                                />
                            </div>
                            <div className="my-4 text-center">
                                <span className="content-heading">
                                    Your full payment schedule is:
                                </span>
                            </div>
                            {isModalLoading && <ModalLoader />}
                            {!isModalLoading &&
                                installBillDetails &&
                                installBillDetails.full_payment_schedule &&
                                installBillDetails.full_payment_schedule.map(
                                    (data, idx) => {
                                        return (
                                            <div
                                                className="row no-gutters my-4 align-items-baseline installment-row"
                                                key={idx}
                                            >
                                                <div className="col-2">
                                                    <form className="radio_form">
                                                        <label
                                                            className="radio_btn"
                                                            htmlFor="radio"
                                                        >
                                                            <input
                                                                type="radio"
                                                                defaultChecked={
                                                                    data.due_amount_status ===
                                                                    'Paid'
                                                                }
                                                                name="account"
                                                                disabled
                                                            />
                                                            <span className="checkmark"></span>
                                                            <div className="v-border"></div>
                                                        </label>
                                                    </form>
                                                </div>
                                                <div className="col-10 d-flex justify-content-between align-items-center">
                                                    <div className="d-flex flex-column ml-2 text-left">
                                                        <span className="installment-label">
                                                            {toOrdinalSuffix(
                                                                idx + 1
                                                            )}{' '}
                                                            installment
                                                        </span>
                                                        {/* <span className="installment-date">
                                        {data.due_amount_status}: {currencyFormatter.format(data.due_amount)}
                                    </span> */}
                                                        {data.due_amount_status ===
                                                        'Paid' ? (
                                                            <span className="paid-label">
                                                                Paid
                                                            </span>
                                                        ) : (
                                                            <span className="paid-label">
                                                                Due:{' '}
                                                                {moment(
                                                                    data.due_amount_date
                                                                ).format('l')}
                                                                &nbsp;
                                                                {/* {data.due_amount_date}  */}
                                                                {Boolean(
                                                                    data.late
                                                                ) && (
                                                                    <span className="text-danger">
                                                                        {
                                                                            data.late
                                                                        }
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )}
                                                        {/* {data.due_amount_status !==
                                                        'Due' && (
                                                        <a
                                                            className="paid-label underline"
                                                            onClick={() =>
                                                                viewReceiptFunc(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            View receipt
                                                        </a>
                                                    )} */}
                                                    </div>
                                                    <div className="d-flex flex-column mr-4">
                                                        <span className="paid-amount">
                                                            {currencyFormatter.format(
                                                                data.due_amount
                                                            )}
                                                        </span>
                                                        {Boolean(
                                                            data.late_fee
                                                        ) && (
                                                            <span className="paid-amount text-danger">
                                                                +
                                                                {currencyFormatter.format(
                                                                    data.late_fee
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </div>
                        <div className="px-3">
                            {/* <button className="btn mobile-green-btn" onClick={ () => props.history.push('/patient-pay-balance') }>Payment Options</button> */}
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={() => handlePayMethodPage()}
                            >
                                Make a payment
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* {openReceipt && (
                <ViewReceiptMobile
                    isOpen={openReceipt}
                    closeViewReceiptFunc={closeViewReceiptFunc}
                    receiptDetails={receiptDetails}
                    physicianDetails={physicianDetails}
                />
            )} */}
            {payMethodPage && (
                <PatientPayMethod
                    payMethodPage={payMethodPage}
                    handlePayMethodPage={handlePayMethodPage}
                    history={props.history}
                    individualPayKey="individualPayKey"
                    installmentDetails={installBillDetails}
                    physicianDetails={physicianDetails}
                    remaningBalancePageDetails={remaningBalancePageDetails}
                    transferFundData={transferFundData}
                />
            )}
            {isEmptyList && (
                <PatientPaymentMobile
                    pastDuePage={'pastDue'}
                    goPastDueDetailPageFunc={goPastDueDetailPageFunc}
                    goToPayMethodPageFunc={goToPayMethodPageFunc}
                />
            )}
        </>
    )
}
RemaningBalanceBillDetails.propTypes = {
    // billPaidPage: PropTypes.bool,
    handleCloseRemaningBalanceFunc: PropTypes.func,
    remaningBalancePageDetails: PropTypes.object,
    history: PropTypes.object,
    physicianDetails: PropTypes.object,
}
export default RemaningBalanceBillDetails
