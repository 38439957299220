import React from 'react'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'

const PatientAccountTabs = (props) => {
    const { payTab, history } = props
    return (
        <nav className="nav_tab_bar">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                    className={
                        payTab
                            ? 'nav-item nav-link'
                            : 'nav-item nav-link active'
                    }
                    id="nav-account-tab"
                    data-toggle="tab"
                    href="#nav-account"
                    role="tab"
                    aria-controls="nav-account"
                    aria-selected="true"
                    onClick={
                        props.isMobile
                            ? () => history.push('/account-details-mobile')
                            : props.showSection
                    }
                >
                    Account
                    <MdChevronRight className="float-right font24 my-1 color_dark_grey d-md-none d-lg-none d-xl-none" />
                </a>
                {props.isMobile && (
                    <a
                        className={
                            payTab
                                ? 'nav-item nav-link active'
                                : 'nav-item nav-link'
                        }
                        id="nav-pay-tab"
                        data-toggle="tab"
                        href="#nav-pay"
                        role="tab"
                        aria-controls="nav-pay"
                        aria-selected="false"
                        // onClick={ props.isMobile ? () => history.push('/patient-payments-mobile') : props.showSection }
                        onClick={
                            props?.cardListing?.cardList?.length > 0
                                ? () =>
                                      history.push({
                                          pathname: '/patient-payments-mobile',
                                          state: true,
                                      })
                                : () =>
                                      history.push({
                                          pathname: '/patient-payments-mobile',
                                          state: false,
                                      })
                        }
                    >
                        Payment Methods
                        <MdChevronRight className="float-right font24 my-1 color_dark_grey d-md-none d-lg-none d-xl-none" />
                    </a>
                )}
                {!props.isMobile && (
                    <a
                        className={
                            payTab
                                ? 'nav-item nav-link active'
                                : 'nav-item nav-link'
                        }
                        id="nav-pay-tab"
                        data-toggle="tab"
                        href="#nav-pay"
                        role="tab"
                        aria-controls="nav-pay"
                        aria-selected="false"
                        onClick={props.showSection}
                    >
                        Payment Methods
                        <MdChevronRight className="float-right font24 my-1 color_dark_grey d-md-none d-lg-none d-xl-none" />
                    </a>
                )}

                <a
                    className="nav-item nav-link"
                    id="nav-notification-tab"
                    data-toggle="tab"
                    href="#nav-notification"
                    role="tab"
                    aria-controls="nav-notification"
                    aria-selected="false"
                    onClick={
                        props.isMobile
                            ? () => history.push('/patient-notification-mobile')
                            : props.showSection
                    }
                >
                    Notifications
                    <MdChevronRight className="float-right font24 my-1 color_dark_grey d-md-none d-lg-none d-xl-none" />
                </a>
                <a
                    className="nav-item nav-link"
                    id="nav-trans-tab"
                    data-toggle="tab"
                    href="#nav-trans"
                    role="tab"
                    aria-controls="nav-trans"
                    aria-selected="false"
                    onClick={
                        props.isMobile
                            ? () => history.push('/patient-transaction-mobile')
                            : props.showSection
                    }
                >
                    Transactions
                    <MdChevronRight className="float-right font24 my-1 color_dark_grey d-md-none d-lg-none d-xl-none" />
                </a>
            </div>
        </nav>
    )
}

PatientAccountTabs.propTypes = {
    showSection: PropTypes.func,
    history: PropTypes.object,
    isMobile: PropTypes.bool,
    payTab: PropTypes.bool,
    cardListing: PropTypes.object,
}

export default PatientAccountTabs
