import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SidebarNavigation from 'components/account/SidebarNavigation'
import { getPhysicianNotifications } from 'actions/notificationActions'
import moment from 'moment'
import Loading from 'shared/Loader'
import { FaDollarSign } from 'react-icons/fa'
import { MdPerson } from 'react-icons/md'
import { AiFillStar } from 'react-icons/ai'

function PhysicianActivityMobile(props) {
    const dispatch = useDispatch()
    const { handleOpenActivity } = props
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getPhysicianNotifications(''))
    }, [])
    const phyNotification = useSelector((state) => state.phyNotification)
    return (
        <div>
            <div className="notifications-page mobile-notifications-page">
                <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar justify-content-between">
                    <span className="label">Activity</span>
                    <button
                        type="button"
                        className="close z-index1"
                        onClick={() => handleOpenActivity()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </nav>
                <div className="wrapper">
                    <SidebarNavigation history={props.history} />
                    <div id="content">
                        <div
                            className="tab-content dashboard_tab_content_Wrapper"
                            id="nav-tabContent"
                        >
                            {isLoading && <Loading />}
                            {!isLoading && (
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-borderless text-left">
                                            {phyNotification &&
                                                phyNotification.map(
                                                    (data, id) => {
                                                        return (
                                                            <tbody
                                                                key={id}
                                                                className="border-0"
                                                            >
                                                                <tr>
                                                                    <td className="px-0 border-0">
                                                                        <div className="notify-time">
                                                                            {moment(
                                                                                data.datetime
                                                                            ).format(
                                                                                'LT'
                                                                            )}
                                                                        </div>
                                                                        <div className="notify-date">
                                                                            {moment(
                                                                                data.datetime
                                                                            ).format(
                                                                                'l'
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="notify-line"></span>
                                                                        {data.notification_type ===
                                                                        2 ? (
                                                                            <FaDollarSign className="icon-wrapper payemnt" />
                                                                        ) : data.notification_type ===
                                                                          1 ? (
                                                                            <MdPerson className="icon-wrapper patient" />
                                                                        ) : data.notification_type ===
                                                                          3 ? (
                                                                            <AiFillStar className="icon-wrapper arrangemnt" />
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </td>
                                                                    <td className="p-0 border-0">
                                                                        <div className="light-border">
                                                                            <div className="notify-name">
                                                                                {
                                                                                    data.notification_name
                                                                                }
                                                                            </div>
                                                                            <div className="notify-message">
                                                                                {
                                                                                    data.message
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                )}
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
PhysicianActivityMobile.propTypes = {
    isOpen: PropTypes.bool,
    phyNotification: PropTypes.array,
    getPhysicianNotifications: PropTypes.func,
    handleOpenActivity: PropTypes.func,
    history: PropTypes.object,
}

export default PhysicianActivityMobile
