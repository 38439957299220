import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FiChevronLeft } from 'react-icons/fi'
import {
    updateOfficeInfoDetails,
    getOfficeAddressDetails,
} from 'actions/accountActions'
import Loading from 'shared/Loader'
import validator from 'validator'
import { maxLengthCheck } from 'utils/helper'
import 'antd/dist/antd.css'
import SuccessAlert from 'shared/SuccessAlert'

export class OfficeAddressDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            successAlert: false,
            isUpdateLoading: false,
        }
    }
    componentDidMount() {
        this.props.getOfficeAddressDetails(this.props.officeId).then((res) => {
            if (res.statusCode === 200) {
                const responseData = res.data.physician_office
                const startTime =
                    responseData &&
                    responseData.office_hours &&
                    responseData.office_hours.split('-')[0].split(' ')[0]
                const endTime =
                    responseData &&
                    responseData.office_hours &&
                    responseData.office_hours.split('-')[1].split(' ')[0]
                responseData.office_hours_start = startTime
                responseData.office_hours_end = endTime
                this.setState({
                    input: responseData,
                })
            }
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const input = this.state.input
        const data = {
            // physician: input.physician,
            speciality: input.speciality,
            address1: input.address1,
            address2: '',
            city: input.city,
            state: input.state,
            country: '',
            zipcode: input.zipcode,
            office_hours: `${input.office_hours_start} AM-${input.office_hours_end} PM`,
            website: input.website || '',
            office_days: input.office_days,
            fee: '',
            phone_number: input.phone_number,
        }
        if (this.validate()) {
            this.setState({
                isUpdateLoading: true,
            })
            this.props
                .updateOfficeInfoDetails(this.state.input.id, data)
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({
                            successAlert: true,
                            isUpdateLoading: false,
                        })
                    } else {
                        this.setState({ isUpdateLoading: false })
                    }
                })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
        this.props.openOfficeDetails()
    }

    handleChange = (event, time, timeString) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.id === 'office_hoursedit') {
            let obj = event.target.value
            var numbersTime = obj.replace(/\D/g, ''),
                charTime = { 2: ':' }
            obj = ''
            for (var j = 0; j < numbersTime.length; j++) {
                obj += (charTime[j] || '') + numbersTime[j]
                input[event.target.name] = obj
            }
        }
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        // }
        console.log(time, timeString)
        this.setState({
            input,
            errors: {},
        })
    }
    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['speciality']) {
            isValid = false
            errors['speciality'] = 'Please enter speciality'
        }
        if (typeof input['speciality'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z]+$/)
            if (!pattern.test(input['speciality'])) {
                isValid = false
                errors['speciality'] = 'Please enter alphabets only'
            }
        }

        if (!input['address1']) {
            isValid = false
            errors['address1'] = 'Please enter address'
        }

        if (!input['city']) {
            isValid = false
            errors['city'] = 'Please enter city'
        }
        if (typeof input['city'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['city'])) {
                isValid = false
                errors['city'] = 'Please enter alphabets only'
            }
        }
        if (!input['state']) {
            isValid = false
            errors['state'] = 'Please enter state'
        }
        if (typeof input['state'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['state'])) {
                isValid = false
                errors['state'] = 'Please enter alphabets only'
            }
        }

        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter zipcode'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['office_hours_start']) {
            isValid = false
            errors['office_hours_start'] = 'Please enter office hours'
        }
        if (!input['office_hours_end']) {
            isValid = false
            errors['office_hours_end'] = 'Please enter office hours'
        }
        if (input['website'] && !validator.isURL(input['website'])) {
            isValid = false
            errors['website'] = 'Please enter valid url'
        }

        if (!input['office_days']) {
            isValid = false
            errors['office_days'] = 'Please enter office days'
        }
        if (typeof input['office_days'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z -]+$/)
            if (!pattern.test(input['office_days'])) {
                isValid = false

                errors['office_days'] = 'Please enter in monday-friday format.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    disableFunc = () => {}

    render() {
        const { input, errors, successAlert, isUpdateLoading } = this.state
        const { isLoading } = this.props
        //  const startTime =  input && input.office_hours && input.office_hours.split('-')[ 0 ].split(' ')[ 0 ]
        // const endTime = input && input.office_hours && input.office_hours.split('-')[ 1 ].split(' ')[ 0 ];
        return (
            <>
                {(isUpdateLoading || isLoading) && (
                    <div className="row no-gutters">
                        <div className="col-12 mt-5">
                            <Loading />
                        </div>
                    </div>
                )}
                {!isUpdateLoading && !isLoading && (
                    <div className="office-address-details location-details-page">
                        <div className="d-flex heading-strip">
                            <FiChevronLeft
                                className="back-green-arrow"
                                onClick={() => this.props.openOfficeDetails()}
                            />{' '}
                            <h6 className="tab_heading">Location Details</h6>
                        </div>
                        <form>
                            <div className="form-row flex-column">
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="speciality"
                                                id="speciality"
                                                value={input.speciality || ''}
                                                onChange={this.handleChange}
                                                placeholder="Speciality"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="speciality"
                                            >
                                                Speciality
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.speciality}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="address1"
                                                id="address1"
                                                value={input.address1 || ''}
                                                onChange={this.handleChange}
                                                placeholder="Address 1"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="address1"
                                            >
                                                Address
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.address1}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={input.city || ''}
                                                onChange={this.handleChange}
                                                placeholder="City"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="city"
                                            >
                                                City
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.city}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={input.state || ''}
                                                onChange={this.handleChange}
                                                placeholder="State"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="state"
                                            >
                                                State
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.state}
                                    </small>
                                </div>

                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="number"
                                                maxLength="5"
                                                onInput={maxLengthCheck}
                                                name="zipcode"
                                                id="zipcode"
                                                value={input.zipcode || ''}
                                                onChange={this.handleChange}
                                                placeholder="Zipcode"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="zipcode"
                                            >
                                                Zipcode
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.zipcode}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                maxLength="14"
                                                name="phone_number"
                                                id="phone_number"
                                                value={input.phone_number || ''}
                                                onChange={this.handleChange}
                                                placeholder="Phone number"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="phone_number"
                                            >
                                                Phone number
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.phone_number}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="website"
                                                id="website"
                                                value={input.website || ''}
                                                onChange={this.handleChange}
                                                placeholder="Website"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="website"
                                            >
                                                Website (optional)
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.website}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <fieldset className="input-group-prepend  account-office-loc">
                                            <span className="AM-label left">
                                                <input
                                                    type="type"
                                                    name="office_hours_start"
                                                    id="office_hoursedit"
                                                    defaultValue={
                                                        input.office_hours_start ||
                                                        ''
                                                    }
                                                    onChange={this.handleChange}
                                                    placeholder="00:00"
                                                    maxLength="5"
                                                    value={
                                                        input.office_hours_start ||
                                                        ''
                                                    }
                                                    className="form-control account_info_field  AM-field"
                                                />
                                            </span>
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="website"
                                            >
                                                Office hours(ST)
                                            </label>
                                        </fieldset>
                                    </div>
                                    <small className="text-danger float-left">
                                        {' '}
                                        {errors.office_hours_start}{' '}
                                    </small>
                                </div>
                                <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <fieldset className="input-group-prepend  account-office-loc">
                                            <span className="PM-label left">
                                                <input
                                                    type="type"
                                                    name="office_hours_end"
                                                    id="office_hoursedit"
                                                    defaultValue={
                                                        input.office_hours_end ||
                                                        ''
                                                    }
                                                    onChange={this.handleChange}
                                                    placeholder="00:00"
                                                    maxLength="5"
                                                    value={
                                                        input.office_hours_end ||
                                                        ''
                                                    }
                                                    className="form-control account_info_field  PM-field"
                                                />
                                            </span>
                                            <label
                                                className="input-group-text account_info_label "
                                                htmlFor="website"
                                            >
                                                Office hours(ET)
                                            </label>
                                        </fieldset>
                                    </div>
                                    <small className="text-danger float-left">
                                        {' '}
                                        {errors.office_hours_end}{' '}
                                    </small>
                                </div>
                                {/* <div className="col-sm-4 my-2">
                                 <div className="input-group">
                                     <fieldset className="input-group-prepend account-office-loc">
                                         <span className="AM-label left">
                                             <input
                                                 type="type"
                                                 name="office_hours_start"
                                                 id="office_hours"
                                                 defaultValue={ startTime || '' }
                                                 onChange={ this.handleChange }
                                                 placeholder="00:00"
                                                 maxLength='5'
                                                 className="form-control account_info_field AM-field"
                                             />
                                         </span>
                                         <span className="PM-label left">
                                             <input
                                                 type="type"
                                                 name="office_hours_end"
                                                 id="office_hours"
                                                 defaultValue={ endTime || '' }
                                                 onChange={ this.handleChange }
                                                 placeholder="00:00"
                                                 maxLength='5'
                                                 className="form-control account_info_field PM-field"
                                             />
                                         </span>
                                         <label className="input-group-text account_info_label" htmlFor="office-hours">
                                             Office hours
                                         </label>
                                     </fieldset>
                                     <small className="text-danger float-left">
                                         {errors.office_hours_start}
                                     </small>
                                     <small className="text-danger float-left">
                                         {errors.office_hours_end}
                                     </small>
                                 </div>
                             </div> */}
                                <div className="col-sm-4 mt-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <input
                                                type="text"
                                                name="office_days"
                                                id="office_days"
                                                value={input.office_days || ''}
                                                onChange={this.handleChange}
                                                placeholder="Monday-Friday"
                                                className="form-control account_info_field"
                                            />
                                            <label
                                                className="input-group-text account_info_label"
                                                htmlFor="office_days"
                                            >
                                                Days open
                                            </label>
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">
                                        {errors.office_days}
                                    </small>
                                </div>
                                <div className="col-sm-4">
                                    <div className="btn-card text-left">
                                        <button
                                            type="submit"
                                            className="btn custom_btn green_btn"
                                            onClick={this.handleSubmit}
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={() =>
                                                this.props.openOfficeDetails()
                                            }
                                            className="btn btn-link cancel-link"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        role="Office"
                        message="The Office info has been updated."
                    />
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        details: state.officeAddressDetails,
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOfficeInfoDetails: (id, data) =>
            dispatch(updateOfficeInfoDetails(id, data)),
        getOfficeAddressDetails: (id) => dispatch(getOfficeAddressDetails(id)),
    }
}

OfficeAddressDetails.propTypes = {
    updateOfficeInfoDetails: PropTypes.func,
    getOfficeAddressDetails: PropTypes.func,
    openOfficeDetails: PropTypes.func,
    officeId: PropTypes.number,
    details: PropTypes.object,
    isLoading: PropTypes.bool,
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfficeAddressDetails)
