/* eslint-disable react/jsx-indent */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import PompayTopLogo from 'shared/PompayTopLogo'
import PatientSidebarNavigation from '../account/PatientSidebarNavigation'
import ChooseModal from './ChooseModal'
import {
    getInstallBillDetails,
    getPatientHomePageDetails,
} from 'actions/patientActions'
import ModalLoader from 'shared/ModalLoader'
import { GoPlus } from 'react-icons/go'
import Avatar from 'react-avatar'
import PatientBottombarNavigation from '../account/PatientBottombarNavigation'
import { isMobile } from 'constants/Constant'
import { currencyFormatter } from 'utils/helper'
import { MdChevronRight, MdPersonAdd } from 'react-icons/md'
import { AiFillDollarCircle } from 'react-icons/ai'
import { getPatientOutstandingBalance } from 'actions/patientBankingActions'
import CurrentRemaningPayDetails from '../physician/CurrentRemaningPayDetails'
import SuccessAlert from 'shared/SuccessAlert'
import RemaningBalanceBillDetails from '../mobile/RemaningBalanceBillDetails'
// import styled from 'styled-components';

function PatientHomePage(props) {
    const dispatch = useDispatch()
    const [chooseOption, setChooseOption] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [physicianDetails, setPhysicianDetails] = useState({})
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const patientHomePageDetails = useSelector(
        (state) => state.patientHomePageDetails
    )
    const patientOutstandingBalance = useSelector(
        (state) => state.patientOutstandingBalance
    )

    const [
        openRemaningPayDetailsModalDetails,
        setOpenRemaningPayDetailsModalDetails,
    ] = useState({})
    const [openRemaningPayModal, setOpenRemaningPayModal] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')

    const handleRemaningBalanceFunc = (data) => {
        setPhysicianDetails(data)
        if (data && data.stripe_account_id) {
            dispatch(
                getInstallBillDetails(
                    data.patient_payment_id,
                    data.physician_id
                )
            )
            setOpenRemaningPayModal(!openRemaningPayModal)
            setOpenRemaningPayDetailsModalDetails(data)
        } else {
            setSuccessAlert(true)
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const closeRemaningPaymentFunc = () => {
        setOpenRemaningPayModal(!openRemaningPayModal)
    }

    const physicianIds = []
    patientHomePageDetails &&
        patientHomePageDetails.pending_approval &&
        patientHomePageDetails.pending_approval.map((data) => {
            return physicianIds.push(data.physician_id)
        })
    useEffect(() => {
        dispatch(getPatientHomePageDetails())
        dispatch(getPatientOutstandingBalance())
    }, [''])

    const closeOptionModal = () => {
        setChooseOption(!chooseOption)
    }
    const togglebutton = () => {
        setToggle(!toggle)
        var element = document.getElementById('overlay')
        element.classList.toggle('show')
        var element2 = document.getElementById('plusIcon')
        element2.classList.toggle('show')
    }
    const removeClass = () => {
        setToggle(!toggle)
        var element = document.getElementById('overlay')
        element.classList.toggle('show')
        var element2 = document.getElementById('plusIcon')
        element2.classList.toggle('show')
    }
    const openNewBillMobileFunc = () => {
        props.history.push({
            pathname: '/select-physician-mobile',
            state: {
                physicianIds,
            },
        })
    }

    return (
        <>
            {((!openRemaningPayModal && isMobile) || !isMobile) && (
                <div className="physician-list">
                    {!isMobile && (
                        <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar justify-content-between">
                            <div className="d-flex">
                                <PompayTopLogo
                                    className="small_logo patient_list_logo"
                                    ancorClassName="pompay_small_logo mr-0"
                                />
                            </div>
                            <div className="d-flex patient_right_nav">
                                <div className="d-flex margin_left_right20 font12 align-items-center">
                                    <span className="mr-4 balance-label">
                                        Total Balance
                                    </span>
                                    <span className="pay-amount">
                                        {isLoading ? (
                                            <ModalLoader />
                                        ) : (
                                            currencyFormatter.format(
                                                patientOutstandingBalance.total_remaining_balance ||
                                                    0
                                            )
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex font12 align-items-center">
                                    <button
                                        className="pay-btn pointer"
                                        onClick={() =>
                                            props.history.push({
                                                pathname: '/patient-account',
                                                state: {
                                                    paymentMethods: true,
                                                },
                                            })
                                        }
                                    >
                                        Pay Balance
                                    </button>
                                </div>
                            </div>
                        </nav>
                    )}
                    <div className="wrapper">
                        {!isMobile && <PatientSidebarNavigation />}
                        <div
                            id="content"
                            className="d-flex flex-column align-items-center"
                        >
                            <div className="patient-home-page">
                                {isMobile && (
                                    <div className="d-flex font12 align-items-center">
                                        <button
                                            className="btn mobile-pay-btn pointer"
                                            onClick={() =>
                                                props.history.push(
                                                    '/patient-pay-balance'
                                                )
                                            }
                                        >
                                            {currencyFormatter.format(
                                                patientOutstandingBalance.total_remaining_balance ||
                                                    0
                                            )}
                                        </button>
                                    </div>
                                )}
                                {/* <Calendar onChange={ onChange } value={ value } defaultValue={ new Date(2021, 4, 3) }/> */}
                                {/* <CalendarContainer> */}
                                <Calendar
                                    view={'month'}
                                    value={new Date()}
                                    tileClassName={({ date }) => {
                                        if (
                                            patientHomePageDetails &&
                                            patientHomePageDetails.due_emi_dates &&
                                            patientHomePageDetails.due_emi_dates.find(
                                                (x) =>
                                                    x ===
                                                    moment(date).format(
                                                        'YYYY-MM-DD'
                                                    )
                                            )
                                        ) {
                                            return 'highlight'
                                        }
                                    }}
                                    navigationLabel={({ date }) =>
                                        date.toLocaleString('default', {
                                            month: 'long',
                                        })
                                    }
                                    tileDisabled={({ date }) =>
                                        date.getDay() === 0 || 1
                                    }
                                    calendarType={'US'}
                                />
                                {/* </CalendarContainer> */}
                                <h4 className="strip-heading">Past Due </h4>
                                {isLoading && <ModalLoader />}
                                {!isLoading && (
                                    <div>
                                        {patientHomePageDetails &&
                                            patientHomePageDetails.past_due &&
                                            patientHomePageDetails.past_due.map(
                                                (data, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters row-margin"
                                                            key={id}
                                                            onClick={() =>
                                                                handleRemaningBalanceFunc(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            <div className="col-2 d-flex justify-content-center align-items-center">
                                                                <Avatar
                                                                    name={
                                                                        data.physician_full_name &&
                                                                        data.physician_full_name.split(
                                                                            '.'
                                                                        )[1]
                                                                    }
                                                                    className="profile-image"
                                                                    size="43"
                                                                    src={
                                                                        data.avatar
                                                                    }
                                                                    round={true}
                                                                />
                                                            </div>
                                                            <div className="col-6 text-left left_row_padding">
                                                                <div className="d-flex h-full align-items-center">
                                                                    <div className="d-flex flex-column">
                                                                        <p className="doctor-name">
                                                                            {
                                                                                data.physician_full_name
                                                                            }
                                                                        </p>
                                                                        <span className="detail-info lite-text">
                                                                            Due
                                                                            <span className="bold-text">
                                                                                {' '}
                                                                                {moment(
                                                                                    data.due_amount_date
                                                                                ).format(
                                                                                    'dddd, MMMM Do'
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        {/* <span className="detail-info lite-text">
                                                                            Remaining
                                                                            amount{' '}
                                                                            <span className="bold-text">
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount ||
                                                                                        0
                                                                                )}
                                                                            </span>
                                                                        </span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 right_row_padding d-flex align-items-center row-reverse">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="amount">
                                                                            {currencyFormatter.format(
                                                                                data.remaining_amount ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                        <span className="red-text">
                                                                            {
                                                                                data.days_past_count
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        {patientHomePageDetails.past_due &&
                                            patientHomePageDetails.past_due
                                                .length === 0 && (
                                                <p className="no-items text-center">
                                                    No Past Due Payments
                                                </p>
                                            )}
                                    </div>
                                )}
                                <h4 className="strip-heading"> Upcoming </h4>
                                {isLoading && <ModalLoader />}
                                {!isLoading && (
                                    <div>
                                        {patientHomePageDetails &&
                                            patientHomePageDetails.upcoming_due &&
                                            patientHomePageDetails.upcoming_due.map(
                                                (data, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters row-margin"
                                                            key={id}
                                                            onClick={() =>
                                                                handleRemaningBalanceFunc(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            <div className="col-2 d-flex justify-content-center align-items-center">
                                                                <Avatar
                                                                    name={
                                                                        data.physician_full_name.split(
                                                                            '.'
                                                                        )[1]
                                                                    }
                                                                    className="profile-image"
                                                                    size="43"
                                                                    src={
                                                                        data.avatar
                                                                    }
                                                                    round={true}
                                                                />
                                                            </div>
                                                            <div className="col-6 text-left left_row_padding">
                                                                <div className="d-flex  h-full align-items-center">
                                                                    <div className="d-flex flex-column">
                                                                        <p className="doctor-name">
                                                                            {
                                                                                data.physician_full_name
                                                                            }
                                                                        </p>
                                                                        <span className="detail-info lite-text">
                                                                            Due{' '}
                                                                            <span className="bold-text">
                                                                                {moment(
                                                                                    data.due_amount_date
                                                                                ).format(
                                                                                    'dddd, MMMM Do'
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        {/* <span className="detail-info lite-text">
                                                                            Remaining
                                                                            amount{' '}
                                                                            <span className="bold-text">
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount ||
                                                                                        0
                                                                                )}
                                                                            </span>
                                                                        </span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 right_row_padding d-flex align-items-center row-reverse">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="amount">
                                                                            {currencyFormatter.format(
                                                                                data.days_past_due ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                        <span className="detail-info lite-text">
                                                                            {data.payment_left &&
                                                                                data.payment_left +
                                                                                    ' payments left'}
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        {patientHomePageDetails.upcoming_due &&
                                            patientHomePageDetails.upcoming_due
                                                .length === 0 && (
                                                <p className="no-items text-center">
                                                    No Upcoming Payment
                                                </p>
                                            )}
                                    </div>
                                )}
                                <h4 className="strip-heading">
                                    Pending Approval{' '}
                                </h4>
                                {isLoading && <ModalLoader />}
                                {!isLoading && (
                                    <div>
                                        {patientHomePageDetails &&
                                            patientHomePageDetails.pending_approval &&
                                            patientHomePageDetails.pending_approval.map(
                                                (data, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters row-margin"
                                                            key={id}
                                                            onClick={() =>
                                                                handleRemaningBalanceFunc(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            <div className="col-2 d-flex justify-content-center align-items-center">
                                                                <Avatar
                                                                    name={
                                                                        data.physician_full_name.split(
                                                                            '.'
                                                                        )[1]
                                                                    }
                                                                    className="profile-image"
                                                                    size="43"
                                                                    src={
                                                                        data.avatar
                                                                    }
                                                                    round={true}
                                                                />
                                                            </div>
                                                            <div className="col-6 text-left left_row_padding">
                                                                <div className="d-flex h-full align-items-center">
                                                                    <div className="d-flex flex-column">
                                                                        <p className="doctor-name">
                                                                            {
                                                                                data.physician_full_name
                                                                            }
                                                                        </p>
                                                                        <span className="detail-info lite-text">
                                                                            {/* Due{' '} */}
                                                                            Total
                                                                            amount{' '}
                                                                            <span className="bold-text">
                                                                                {/* {moment(data.due_amount_date).format(
                                                              'dddd, MMMM Do'
                                                          )} */}
                                                                                {currencyFormatter.format(
                                                                                    data.total_amount
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        <span className="detail-info lite-text">
                                                                            {/* Total amount{' '} */}
                                                                            <span className="bold-text">
                                                                                {/* {currencyFormatter.format(data.total_amount)} */}
                                                                                {
                                                                                    data.payment_count
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 right_row_padding d-flex align-items-center row-reverse">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="amount">
                                                                            {
                                                                                data.Status
                                                                            }
                                                                            {/* {currencyFormatter.format(data.total_amount)} */}
                                                                        </span>
                                                                        <span className="red-text">
                                                                            {
                                                                                data.days_past_count
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        {patientHomePageDetails.pending_approval &&
                                            patientHomePageDetails
                                                .pending_approval.length ===
                                                0 && (
                                                <p className="no-items text-center">
                                                    No Pending Approval
                                                </p>
                                            )}
                                    </div>
                                )}
                            </div>
                            {!isMobile && (
                                <div
                                    className="float pointer"
                                    onClick={() =>
                                        setChooseOption(!chooseOption)
                                    }
                                >
                                    <GoPlus className="font28 my-float" />
                                </div>
                            )}
                            {isMobile && (
                                <div>
                                    <div className="fab-container">
                                        <div
                                            id="overlay"
                                            onClick={removeClass}
                                        ></div>
                                        <div
                                            className="fab fab-icon-holder"
                                            onClick={togglebutton}
                                        >
                                            <GoPlus
                                                id="plusIcon"
                                                className="font24 my-float text-white"
                                            />
                                        </div>
                                        {/* {toggle &&  <div className="fab fab-icon-holder">
                                            <ImCross
                                                onClick={ togglebutton }
                                                className="font24 my-float text-white"
                                            />
                                        </div>} */}
                                        {toggle && (
                                            <ul className="fab-options">
                                                <li>
                                                    <span className="fab-label">
                                                        New bill
                                                    </span>
                                                    <div className="fab-icon-holder">
                                                        <AiFillDollarCircle
                                                            onClick={
                                                                openNewBillMobileFunc
                                                            }
                                                            className="font26 my-float color_light_green"
                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className="fab-label">
                                                        Add physician
                                                    </span>
                                                    <div className="fab-icon-holder">
                                                        <MdPersonAdd
                                                            onClick={() =>
                                                                props.history.push(
                                                                    '/add-physician-mobile'
                                                                )
                                                            }
                                                            className="font24 my-float color_light_green"
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {isMobile && <PatientBottombarNavigation />}
                </div>
            )}
            {chooseOption && (
                <ChooseModal
                    isOpen={chooseOption}
                    closeOptionModal={closeOptionModal}
                    history={props.history}
                    physicianIds={physicianIds}
                    physicianDetails={physicianDetails}
                />
            )}
            {openRemaningPayModal && !isMobile && (
                <CurrentRemaningPayDetails
                    openRemaningPayModal={openRemaningPayModal}
                    closeRemaningPaymentFunc={closeRemaningPaymentFunc}
                    physicianDetails={physicianDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    history={history}
                />
            )}
            {openRemaningPayModal && isMobile && (
                <RemaningBalanceBillDetails
                    billPaidPage={true}
                    handleCloseRemaningBalanceFunc={closeRemaningPaymentFunc}
                    remaningBalancePageDetails={physicianDetails}
                    history={props.history}
                    physicianDetails={physicianDetails}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}

PatientHomePage.propTypes = {
    history: PropTypes.object,
}

export default PatientHomePage
