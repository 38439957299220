import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getGuidelines,
    // addGuidelines,
    updateGuidelines,
} from '../../../actions/accountActions'
// import Toggle from '../guidelines/Toggle';
import { Switch } from 'antd'
import { CgChevronLeft } from 'react-icons/cg'
import 'antd/dist/antd.css'
import Loading from '../../../shared/Loader'
import { MdInfoOutline } from 'react-icons/md'
import VarianceInfo from './VarianceInfo'
import PayDateInfo from './PayDateInfo'

export class GuidelinesMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            isEdit: false,
            isVarianceInfo: false,
            isPayDateInfo: false,
        }
        this.handleChange = this.handleChange.bind(this)
        // this.onToggleSwitchChange = this.onToggleSwitchChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.editDetails = this.editDetails.bind(this)
    }

    componentDidMount() {
        this.props.getGuidelines().then((response) => {
            if (response) {
                this.setState({
                    input:
                        response.data.physician_payment_guidelines &&
                        response.data.physician_payment_guidelines[0],
                    isLocked: response.is_locked,
                })
            }
        })
    }

    onToggleChange = (checked, x) => {
        const input = this.state.input
        input[x] = checked ? 1 : 0
        this.setState({ input })
    }

    handleChange(event) {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
            errors: {},
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        if (this.validate()) {
            const obj = this.state.input
            this.state.input &&
                this.state.input.id &&
                this.props
                    .updateGuidelines(this.state.input.id, obj)
                    .then((res) => {
                        if (res) {
                            this.props.history.push('/account-homepage-mobile')
                        }
                    })
            //   : this.props.addGuidelines(obj);
        }
    }
    handlePayDateInfo = () => {
        this.setState({
            isPayDateInfo: !this.state.isPayDateInfo,
        })
    }
    handleVarianceInfo = () => {
        this.setState({
            isVarianceInfo: !this.state.isVarianceInfo,
        })
    }

    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true
        const mp = Number(input['minimum_monthly_payment'])
        const av = Number(input['deviation_of_minimum_payment'])
        const mm = Number(input['max_number_of_months'])
        const p = Number(input['time_period_to_choose_start_date'])
        const ppd = Number(input['prompt_pay_discount'])

        if (!input['minimum_monthly_payment']) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Please enter minimum monthly payment.'
        }
        if (mp < 10) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Minimum monthly payment must be greater then $10.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Please enter only whole numbers.'
        }

        if (!input['deviation_of_minimum_payment']) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Please enter deviation minimum payment.'
        }
        if (av < 0) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Please enter only whole numbers.'
        }
        if (av > mp) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Amount variance must be less then Min. monthly payment'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Please enter only whole numbers.'
        }

        if (!input['max_number_of_months']) {
            isValid = false
            errors['max_number_of_months'] = 'required field.'
        }
        if (mm < 0) {
            isValid = false
            errors['max_number_of_months'] = 'Please enter only whole numbers.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['max_number_of_months'] = 'Please enter only whole numbers.'
        }

        if (!input['time_period_to_choose_start_date']) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter time period.'
        }
        if (p < 2) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter only whole numbers.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter only whole numbers.'
        }

        if (!input['prompt_pay_discount']) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter prompt pay discount.'
        }
        if (ppd < 0) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter only whole numbers.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter only whole numbers.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    editDetails() {
        this.setState({
            isEdit: !this.state.isEdit,
        })
    }
    render() {
        const { input, errors, isEdit, isPayDateInfo, isVarianceInfo } =
            this.state
        const { isLoading, history } = this.props
        const enabled = !isEdit
        return (
            <>
                {!isVarianceInfo && !isPayDateInfo && (
                    <div className="mobile_account_info">
                        <div id="content">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() =>
                                        history.push('/account-homepage-mobile')
                                    }
                                />
                                <label className="nav-heading">
                                    Guidelines
                                </label>
                                {!isEdit && (
                                    <a
                                        className="edit-navlink"
                                        onClick={this.editDetails}
                                    >
                                        Edit
                                    </a>
                                )}
                                {isEdit && (
                                    <a
                                        className="cancel-navlink"
                                        onClick={() =>
                                            history.push(
                                                '/account-homepage-mobile'
                                            )
                                        }
                                    >
                                        Cancel
                                    </a>
                                )}
                            </nav>
                            <div id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active guidelines"
                                    id="nav-guidlines"
                                    role="tabpanel"
                                    aria-labelledby="nav-guidlines-tab"
                                >
                                    {isLoading && <Loading />}
                                    {!isLoading && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form>
                                                    <div className="form-row flex-column">
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-icon left">
                                                                        <input
                                                                            type="number"
                                                                            id="min_monthly_payment"
                                                                            name="minimum_monthly_payment"
                                                                            value={
                                                                                input.minimum_monthly_payment ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="min_monthly_payment"
                                                                        >
                                                                            Minimum
                                                                            monthly
                                                                            payment
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small className="text-danger float-left">
                                                                {errors.minimum_monthly_payment ||
                                                                    ''}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-icon left">
                                                                        <input
                                                                            type="number"
                                                                            id="amount_variance"
                                                                            name="deviation_of_minimum_payment"
                                                                            value={
                                                                                input.deviation_of_minimum_payment ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="amount_variance"
                                                                        >
                                                                            Amount
                                                                            variance{' '}
                                                                            <MdInfoOutline
                                                                                className="info_icon"
                                                                                onClick={
                                                                                    this
                                                                                        .handleVarianceInfo
                                                                                }
                                                                            />
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small className="text-danger float-left">
                                                                {errors.deviation_of_minimum_payment ||
                                                                    ''}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-wrapper">
                                                                        <input
                                                                            type="number"
                                                                            id="max_months"
                                                                            name="max_number_of_months"
                                                                            value={
                                                                                input.max_number_of_months ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field mobile_field_padding"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="max_months"
                                                                        >
                                                                            Max
                                                                            number
                                                                            of
                                                                            months
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small className="text-danger float-left">
                                                                {errors.max_number_of_months ||
                                                                    ''}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-wrapper">
                                                                        <input
                                                                            type="number"
                                                                            id="choose_date"
                                                                            name="time_period_to_choose_start_date"
                                                                            value={
                                                                                input.time_period_to_choose_start_date ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field mobile_field_padding"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="choose_date"
                                                                        >
                                                                            Choose
                                                                            start
                                                                            date
                                                                            (in
                                                                            days){' '}
                                                                            <MdInfoOutline
                                                                                className="info_icon"
                                                                                onClick={
                                                                                    this
                                                                                        .handlePayDateInfo
                                                                                }
                                                                            />
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small className="text-danger float-left">
                                                                {errors.time_period_to_choose_start_date ||
                                                                    ''}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-wrapper">
                                                                        <input
                                                                            type="number"
                                                                            id="pompay_discount"
                                                                            name="prompt_pay_discount"
                                                                            value={
                                                                                input.prompt_pay_discount ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field mobile_field_padding"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="pompay_discount"
                                                                        >
                                                                            Pompay
                                                                            discount
                                                                            %
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small className="text-danger float-left">
                                                                {errors.prompt_pay_discount ||
                                                                    ''}
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-icon left">
                                                                        <input
                                                                            type="number"
                                                                            id="fees"
                                                                            name="late_fee"
                                                                            value={
                                                                                input.late_fee ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            className="form-control account_info_field"
                                                                        />
                                                                        <label
                                                                            className="input-group-text payment_guideline_label"
                                                                            htmlFor="fees"
                                                                        >
                                                                            Late
                                                                            fee
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <div className="form-check p-0 text-left">
                                                                <label
                                                                    className="form-check-label Promise_Pay d-flex align-items-center"
                                                                    htmlFor="Promise-To-Pay"
                                                                >
                                                                    <span className="mr-3 font16">
                                                                        Enable
                                                                        Promise-To-Pay
                                                                    </span>
                                                                    <Switch
                                                                        checked={
                                                                            input.enable_promise_to_pay ||
                                                                            ''
                                                                        }
                                                                        onChange={(
                                                                            checked
                                                                        ) =>
                                                                            this.onToggleChange(
                                                                                checked,
                                                                                'enable_promise_to_pay'
                                                                            )
                                                                        }
                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2 payment_para text-left">
                                                            <p className="font14 color_dark_grey font-weight500 line-height_18">
                                                                {' '}
                                                                When your
                                                                patients need
                                                                more time to
                                                                pay, make it
                                                                easy for them to
                                                                choose a new due
                                                                date for their
                                                                payment.{' '}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row"></div>
                                </div>
                            </div>
                        </div>
                        <div className="px-3 pb-3">
                            <button
                                type="submit"
                                className="btn mobile-green-btn"
                                onClick={this.handleSubmit}
                                disabled={enabled}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                )}
                {isVarianceInfo && (
                    <VarianceInfo
                        handleVarianceInfo={this.handleVarianceInfo}
                    />
                )}
                {isPayDateInfo && (
                    <PayDateInfo handlePayDateInfo={this.handlePayDateInfo} />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        // setGuideline: state.setGuideline,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuidelines: () => dispatch(getGuidelines()),
        // addGuidelines: (data) => dispatch(addGuidelines(data)),
        updateGuidelines: (id, data) => dispatch(updateGuidelines(id, data)),
    }
}

GuidelinesMobile.propTypes = {
    history: PropTypes.object,
    getGuidelines: PropTypes.func,
    // addGuidelines: PropTypes.func,
    updateGuidelines: PropTypes.func,
    isLoading: PropTypes.bool,
    cardListing: PropTypes.object,
    transDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidelinesMobile)
