import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import ConfirmEnterAmountMobile from './ConfirmEnterAmountMobile'

function EnterAmountMobile(props) {
    const { isOpen, appointmentId, closeBillAmountModal, physicianDetails } =
        props
    const [addBillAmount, setAddBillAmount] = useState('')
    const [addBillAmountError, setAddBillAmountError] = useState(false)
    const [confirmBillAount, setconfirmBillAount] = useState(false)

    const handleChange = (event) => {
        setAddBillAmount(event.target.value)
        setAddBillAmountError(false)
    }
    const handleNext = () => {
        if (addBillAmount !== '' && addBillAmount > 0) {
            setconfirmBillAount(!confirmBillAount)
        }
        // if (addBillAmount <= 0){
        //     setAddBillAmountError('Amount must be greater then 0');
        // }
        else {
            setAddBillAmountError('Please enter amount greater then 0')
        }
    }
    const closeConfirmBillAmount = () => {
        setconfirmBillAount(!confirmBillAount)
    }
    return (
        <>
            {isOpen && !confirmBillAount && (
                <div className="mobile_account_info Enter-amount-of-your-bill">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closeBillAmountModal()}
                            />
                            <label className="nav-heading">
                                {physicianDetails.physician_name ||
                                    physicianDetails.physician_full_name}
                            </label>
                            <button
                                type="button"
                                className="close close-bold-icon z-index1"
                                onClick={() => {
                                    window.location = '/home'
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </nav>
                        <div
                            id="nav-tabContent"
                            className="mobile-content-height"
                        >
                            <div className="heading-wrapper">
                                <span className="heading">
                                    Enter the amount
                                    <br /> of your bill
                                </span>
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-icon left">
                                        <input
                                            type="number"
                                            autoFocus
                                            placeholder="$0"
                                            className="balance-textfield"
                                            name="accessCode"
                                            autoComplete="off"
                                            value={addBillAmount}
                                            onChange={handleChange}
                                        />
                                        {addBillAmountError && (
                                            <small className="alert alert-danger error-msg d-flex justify-content-center w-100">
                                                {addBillAmountError}
                                            </small>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={handleNext}
                            >
                                {' '}
                                Next{' '}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {confirmBillAount && (
                <ConfirmEnterAmountMobile
                    addBillAmount={addBillAmount}
                    isOpen={confirmBillAount}
                    closeConfirmBillAmount={closeConfirmBillAmount}
                    appointmentId={appointmentId}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
        </>
    )
}

EnterAmountMobile.propTypes = {
    closeBillAmountModal: PropTypes.func,
    isOpen: PropTypes.bool,
    appointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default EnterAmountMobile
