export function applicationIsLoading(bool) {
    return {
        type: 'APPLICATION_IS_LOADING',
        isLoading: bool,
    }
}

export function modalIsLoading(bool) {
    return {
        type: 'MODAL_IS_LOADING',
        isModalLoading: bool,
    }
}

export function setCurrentUser(user) {
    return {
        type: 'SET_CURRENT_USER',
        currentUser: user,
    }
}

// export function setFCMToken(token) {
//     return {
//         type: 'SET_FCM_TOKEN',
//         fcmToken: token
//     };
// }
