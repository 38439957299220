import React from 'react'
import PropTypes from 'prop-types'
import PompayTopLogo from 'shared/PompayTopLogo'
import moment from 'moment'
import { currencyFormatter } from 'utils/helper'
const PatientSuccessReceipt = (props) => {
    const {
        patientSuccessReceiptPage,
        selectedCardDetails,
        history,
        outStandingBalance,
    } = props
    return (
        <>
            {patientSuccessReceiptPage && (
                <div className="mobile_account_info success-receipt-page">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-center">
                            <PompayTopLogo className="small_logo" />
                        </nav>
                        <div className="transfer-Content mobile-content-height">
                            <h4 className="heading">
                                Thank you for your payment.
                            </h4>
                            <h6 className="sub-heading">Payment Details</h6>
                            <div className="transfer-details">
                                <span className="transfer-light-label">
                                    Payment amount
                                </span>
                                <span className="transfer-value">
                                    {currencyFormatter.format(
                                        outStandingBalance
                                    )}
                                </span>
                            </div>
                            <div className="transfer-details">
                                <span className="transfer-light-label">
                                    Payment Method
                                </span>
                                <span className="transfer-value">
                                    {selectedCardDetails.card_type} ****
                                    {selectedCardDetails.card_number}
                                </span>
                            </div>
                            <div className="transfer-details">
                                <span className="transfer-light-label">
                                    Date
                                </span>
                                <span className="transfer-value">
                                    {moment(new Date()).format('MM/DD/YY')}
                                </span>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={() => history.push('/patient-account')}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
PatientSuccessReceipt.propTypes = {
    patientSuccessReceiptPage: PropTypes.bool,
    selectedCardDetails: PropTypes.object,
    history: PropTypes.object,
    outStandingBalance: PropTypes.number,
    paidFees: PropTypes.number,
}
export default PatientSuccessReceipt
