import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OtpModal from './OtpModal'
import { sendUpdateEmail } from '../../actions/authActions'
import PompayTopLogo from 'shared/PompayTopLogo'
import { MdChevronLeft } from 'react-icons/md'

export class UpdateMobileNumber extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openEmailOtpModal: false,
            input: {},
            errors: {},
            failError: false,
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input: input,
            failError: false,
        })
    }

    handleOtpSubmit = (event) => {
        event.preventDefault()
        const { input, openEmailOtpModal, failError } = this.state
        if (this.validate()) {
            this.props
                .sendUpdateEmail(input)
                .then()
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({ openEmailOtpModal: !openEmailOtpModal })
                    } else {
                        this.setState({ failError: !failError })
                    }
                })
        }
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true

        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }

        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'The email you entered is invalid.'
            }
        }
        this.setState({
            errors: errors,
        })

        return isValid
    }
    closeOtpModal = () => {
        this.setState({ openEmailOtpModal: !this.state.openEmailOtpModal })
    }
    render() {
        const { openEmailOtpModal, errors, input, failError } = this.state
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex justify-content-between">
                    <a>
                        <MdChevronLeft
                            className="back-chevronLeft"
                            onClick={() => this.props.history.goBack()}
                        />
                    </a>
                    <PompayTopLogo className="small_logo" />
                    <i></i>
                </nav>
                <div className="container update_mobile_number">
                    <div className="row">
                        <div className="offset-md-3 col-md-5 offset-lg-4 col-lg-4 offset-xl-4 col-xl-4 auth_update_number">
                            <div className="form_body">
                                {errors.email_id && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        {errors.email_id}
                                    </div>
                                )}
                                {failError && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        Email not registered with us. Please
                                        enter registered email.
                                    </div>
                                )}
                                <h4 className="page-heading">
                                    Update your mobile number
                                </h4>
                                <p className="page-content">
                                    This will just take a few steps. First,
                                    please enter the email that is linked to
                                    your Pompay account.
                                </p>
                                <form>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control input_field"
                                            placeholder="Email"
                                            autoComplete="off"
                                            name="email_id"
                                            value={input.email_id || ''}
                                            onChange={this.handleChange}
                                        />
                                        {/* <small className="text-danger">
                                            {this.state.errors.email_id}
                                        </small> */}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn green_btn custom_btn mb-4 mt-2"
                                        onClick={this.handleOtpSubmit}
                                    >
                                        <i
                                            className="fa fa-lock float-left lock_icon"
                                            aria-hidden="true"
                                        ></i>
                                        Continue
                                    </button>
                                </form>
                                <p className="text-center mb-2 page-content">
                                    We’ll send you a verification code via SMS.
                                </p>
                                <p className="text-center mb-2 page-content">
                                    By continuing, I agree to Pompay’s{' '}
                                    <a
                                        href="/#"
                                        className="link_hover font14 color_light_green"
                                    >
                                        terms of service.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {openEmailOtpModal && (
                    <OtpModal
                        formData={this.props.formData}
                        history={this.props.history}
                        emailDetails={this.props.verifyEmailDetails}
                        isOpen={openEmailOtpModal}
                        closeOtpModal={this.closeOtpModal}
                        emailModal={true}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        formData: state.formData,
        verifyEmailDetails: state.verifyEmailDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendUpdateEmail: (data) => dispatch(sendUpdateEmail(data)),
    }
}
UpdateMobileNumber.propTypes = {
    sendUpdateEmail: PropTypes.func,
    isLoading: PropTypes.bool,
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    location: PropTypes.object,
    history: PropTypes.object,
    verifyEmailDetails: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMobileNumber)
