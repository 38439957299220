export enum DueStatus {
	PastDue = "Past Due",
	Paid = "Paid",
	Posted = "Posted",
	Awaiting = "Awaiting"
}

export interface DuePaymentsDetails {
	amount: string;
	date_of_birth: string;
	days_left: number;
	due_date: string;
	paid_date: string;
	due_status: DueStatus;
	email_id: string;
	first_name: string;
	id: number;
	is_active: boolean;
	is_arrangement_accepted: number;
	is_payment_posted: number;
	key: number;
	last_name: string;
	patient_appointment_id: number;
	patient_id: number;
	phone_number: string;
	zipcode: string;
}

export interface PatientInfo {
	date_of_birth: string,
	due_payments_details: DuePaymentsDetails[],
	email_id: string,
	first_name: string,
	is_active: boolean,
	last_name: string,
	patient_id: number,
	phone_number: string,
	zipcode: string,
}

export interface PatientListResponse {
	accounts: number;
	paid_amount: number;
	paid_percentage: string;
	patient_info: PatientInfo[];
	total_amount: number;
	total_past_due_amount: number;
	unpaid_amount: number;
}
