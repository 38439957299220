import axios, { AxiosRequestConfig, Method } from 'axios'

const request = (url: string, method: Method, config: AxiosRequestConfig) => {
	return axios(url, {
		...config,
		method: method,
		headers: {
			...config.headers,
			Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
		}
	})
}

export const http = {
	// eslint-disable-next-line no-undef
	GET: (url: string, config: Omit<AxiosRequestConfig, 'method'>) => request(url, 'GET', config),
	// eslint-disable-next-line no-undef
	POST: (url: string, config: Omit<AxiosRequestConfig, 'method'>) => request(url, 'POST', config),
}