import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { isMobile } from 'constants/Constant'

function BankSection(props) {
    const {
        handleChange,
        input,
        errors,
        closeModal,
        failError,
        failErrorMessage,
    } = props
    return (
        <>
            {isMobile && (
                <div className="add-bank-account-page" id="content">
                    <nav className="navbar navbar-expand-lg navbar-light custom_navbar custom-alignment d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={closeModal}
                        />
                        {failError && (
                            <div className="alert alert-danger" role="alert">
                                {failErrorMessage}
                            </div>
                        )}
                        <label className="nav-heading">Add Bank Account</label>
                        <span></span>
                    </nav>
                    <div className="form-row flex-column">
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="country"
                                        autoComplete="off"
                                        id="country"
                                        defaultValue="US"
                                        disabled
                                        className="form-control account_info_field"
                                        placeholder="US"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="country"
                                    >
                                        Country
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="currency"
                                        autoComplete="off"
                                        id="currency"
                                        defaultValue="USD"
                                        disabled
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="currency"
                                    >
                                        Currency
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.currency}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="routing_number"
                                        id="routing_number"
                                        value={input.routing_number || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                        autoComplete="off"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="routing_number"
                                    >
                                        Routing Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.routing_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="account_number"
                                        autoComplete="off"
                                        id="account_number"
                                        value={input.account_number || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="account_number"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Account Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.account_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="confirm_account_number"
                                        autoComplete="off"
                                        id="confirm_account_number"
                                        value={
                                            input.confirm_account_number || ''
                                        }
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="confirm_account_number"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Confirm Account Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.confirm_account_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="account_holder_name"
                                        autoComplete="off"
                                        id="account_holder_name"
                                        value={input.account_holder_name || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="account_holder_name"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Account Holder Name
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.account_holder_name}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select
                                        className="form-control account_info_field"
                                        id="select-type"
                                        name="account_holder_type"
                                        onChange={handleChange}
                                    >
                                        <option defaultValue="select card">
                                            {' '}
                                            Select type
                                        </option>
                                        <option
                                            name="account_holder_type"
                                            value="individual"
                                        >
                                            Individual
                                        </option>
                                        <option
                                            name="account_holder_type"
                                            value="company"
                                        >
                                            Company
                                        </option>
                                    </select>
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="select-type"
                                    >
                                        <span className="text-danger">
                                            &nbsp;&nbsp;&nbsp;
                                        </span>
                                        Account Holder Type
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="add-bank-account-page">
                    {failError && (
                        <div className="alert alert-danger" role="alert">
                            {failErrorMessage}
                        </div>
                    )}
                    <label className="modal-heading">Add Bank Account</label>
                    <div className="form-row flex-column">
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="country"
                                        autoComplete="off"
                                        id="country"
                                        // value={ input.country || '' }
                                        defaultValue="US"
                                        disabled
                                        // onChange={ handleChange }
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="country"
                                    >
                                        Country
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="currency"
                                        autoComplete="off"
                                        id="currency"
                                        // value={ input.country || '' }
                                        defaultValue="USD"
                                        disabled
                                        // onChange={ handleChange }
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="currency"
                                    >
                                        Currency
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="routing_number"
                                        id="routing_number"
                                        value={input.routing_number || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                        autoComplete="off"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="routing_number"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Routing Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.routing_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="account_number"
                                        autoComplete="off"
                                        id="account_number"
                                        value={input.account_number || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="account_number"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Account Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.account_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="confirm_account_number"
                                        autoComplete="off"
                                        id="confirm_account_number"
                                        value={
                                            input.confirm_account_number || ''
                                        }
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="confirm_account_number"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Confirm Account Number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.confirm_account_number}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="account_holder_name"
                                        autoComplete="off"
                                        id="account_holder_name"
                                        value={input.account_holder_name || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="account_holder_name"
                                    >
                                        <span className="text-danger">
                                            *&nbsp;
                                        </span>
                                        Account Holder Name
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.account_holder_name}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select
                                        className="form-control account_info_field"
                                        id="select-type"
                                        name="account_holder_type"
                                        onChange={handleChange}
                                    >
                                        <option defaultValue="select card">
                                            {' '}
                                            Select type
                                        </option>
                                        <option
                                            name="account_holder_type"
                                            value="individual"
                                        >
                                            Individual
                                        </option>
                                        <option
                                            name="account_holder_type"
                                            value="company"
                                        >
                                            Company
                                        </option>
                                    </select>
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="select-type"
                                    >
                                        <span className="text-danger">
                                            &nbsp;&nbsp;&nbsp;
                                        </span>
                                        Account Holder Type
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
BankSection.propTypes = {
    handleChange: PropTypes.func,
    closeModal: PropTypes.func,
    input: PropTypes.object,
    failError: PropTypes.bool,
    failErrorMessage: PropTypes.string,
    errors: PropTypes.object,
}
export default BankSection
