import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import { getPhysicianAppointmentId } from 'actions/patientPhysicianActions'
import EnterAmountMobile from './EnterAmountMobile'

function EnterPatientAccountNumberMobile(props) {
    const dispatch = useDispatch()
    const {
        openAccountNumber,
        closePatientAccountNumber,
        // patientAppointmentId,
        physicianDetails,
    } = props
    const [appointmentId, setAppointmentId] = useState('')
    const [addBillAmount, setAddBillAmount] = useState(false)
    useEffect(() => {
        dispatch(getPhysicianAppointmentId(physicianDetails.physician_id)).then(
            (res) => {
                setAppointmentId(res && res.patient_appointment_id)
            }
        )
    }, [''])

    const closeBillAmountModal = () => {
        setAddBillAmount(false)
    }

    return (
        <>
            {openAccountNumber && !addBillAmount && (
                <div className="mobile_account_info Enter-patient-account-number-page">
                    <div id="content" className="mb-0 d-flex d-flex-column">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closePatientAccountNumber()}
                            />
                            <label className="nav-heading">
                                {physicianDetails.physician_name ||
                                    physicianDetails.physician_full_name}
                            </label>

                            <button
                                type="button"
                                className="close close-bold-icon z-index1"
                                onClick={() => closePatientAccountNumber()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </nav>
                        <div
                            id="nav-tabContent"
                            className="mobile-content-height"
                        >
                            <div className="heading-wrapper">
                                <span className="heading">
                                    Enter your patient
                                    <br /> account number
                                </span>
                            </div>
                            <input
                                type="number"
                                className="balance-textfield"
                                name="accessCode"
                                autoComplete="off"
                                value={appointmentId}
                                onChange={(event) =>
                                    setAppointmentId(event.target.value)
                                }
                            />
                            <div className="available-balance mt-3 mb-5">
                                <span className="light-gray">
                                    This will be saved for future bills
                                </span>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={() => setAddBillAmount(!addBillAmount)}
                            >
                                {' '}
                                Next{' '}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {addBillAmount && (
                <EnterAmountMobile
                    isOpen={addBillAmount}
                    closeBillAmountModal={closeBillAmountModal}
                    appointmentId={appointmentId}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
        </>
    )
}

EnterPatientAccountNumberMobile.propTypes = {
    closePatientAccountNumber: PropTypes.func,
    openAddModal: PropTypes.func,
    sendPhysicianAccessCode: PropTypes.func,
    openAccountNumber: PropTypes.bool,
    patientAppointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default EnterPatientAccountNumberMobile
