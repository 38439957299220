import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendNotificationData } from '../../actions/notificationActions'
import SidebarNavigation from '../account/SidebarNavigation'
import PompayTopLogo from 'shared/PompayTopLogo'
// import { MdLock } from 'react-icons/md';
import SendPushMessage from './SendPushMessage'
import SuccessAlert from 'shared/SuccessAlert'

export class Notification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            phone_number_2: '',
            bill_amount: '',
            message: '',
            successAlert: false,
            successAlertMessage: '',
            actionFailed: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validate = this.validate.bind(this)
    }

    handleChange(event) {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        if (this.validate()) {
            const input = {}
            input['phone_number'] = ''
            input['patient_id'] = ''
            this.setState({ input: input })
            this.props.sendNotificationData(this.state.input).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({
                        successAlert: true,
                        successAlertMessage:
                            'We have sent a link on patient mobile number along with your access code.',
                    })
                } else {
                    this.setState({
                        successAlert: true,
                        actionFailed: true,
                        successAlertMessage: 'Already Registered',
                    })
                }
            })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your mobile number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        this.setState({
            errors: errors,
        })

        return isValid
    }

    render() {
        const { successAlert, input, successAlertMessage, actionFailed } =
            this.state
        const enabled =
            input && input.phone_number && input.phone_number.length === 14
        return (
            <>
                <div className="notification-message-page">
                    <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex">
                        <PompayTopLogo className="small_logo" />
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <a
                                    className="nav-link font14 nav_link active"
                                    id="home-tab"
                                    data-toggle="tab"
                                    href="#home"
                                    role="tab"
                                    aria-controls="home"
                                    aria-selected="true"
                                >
                                    Sign-up
                                </a>
                            </li>
                            {/*TODO: Hide it for now, but we need to fix the logic*/}
                            {/* <li className="nav-item">
                                <a
                                    className="nav-link font14 nav_link"
                                    id="profile-tab"
                                    data-toggle="tab"
                                    href="#profile"
                                    role="tab"
                                    aria-controls="profile"
                                    aria-selected="false"
                                >
                                    New Bill
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    <div className="wrapper">
                        <SidebarNavigation history={this.props.history} />
                        <div id="content">
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="home"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                >
                                    <div className="row no-gutters">
                                        <div className="col-md-12">
                                            <div className="message_form padding_left100_right375">
                                                {/* <h3 className="text-center message-label">
                                                    Message:
                                                </h3> */}
                                                <p
                                                    className="d-flex message-content"
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Make it easy for your
                                                    patient’s to pay! Send them
                                                    a link to sign up today.
                                                    {/* <MdLock className="font26 color_dark_grey ml-2 nav_lock_icon" /> {' '} */}
                                                </p>
                                                {/* <hr className="line_divider"></hr> */}
                                                <form>
                                                    {/* <div className="form-group">
                                                    <input
                                                        type="number"
                                                        name="patient_id"
                                                        onChange={ this.handleChange }
                                                        className="form-control input_field font-family_lite font-weight600 letter-spacing_point3"
                                                        placeholder="Patient I.D. (optional)"
                                                    />
                                                </div> */}
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            maxLength="14"
                                                            autoComplete="off"
                                                            name="phone_number"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control input_field font-family_regular"
                                                            placeholder="Enter mobile number"
                                                            value={
                                                                this.state.input
                                                                    .phone_number ||
                                                                ''
                                                            }
                                                        />
                                                        <small className="text-danger">
                                                            {this.state.errors
                                                                .phone_number ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn custom_btn green_btn"
                                                        onClick={
                                                            this.handleSubmit
                                                        }
                                                        disabled={!enabled}
                                                    >
                                                        Send link
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <div className="row no-gutters">
                                        <SendPushMessage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successAlertMessage}
                        restrict={actionFailed ? true : false}
                    />
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendNotificationData: (data) => dispatch(sendNotificationData(data)),
    }
}

Notification.propTypes = {
    sendNotificationData: PropTypes.func,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
