import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import Loading from '../../../shared/Loader'
import { getUserActivity, getTeams } from '../../../actions/accountActions'
import { DatePicker, Space } from 'antd'
import { MdTune, MdClose } from 'react-icons/md'
import { CgChevronLeft } from 'react-icons/cg'
const dateFormat = 'MM/DD/YYYY'
const { RangePicker } = DatePicker

export class UserActivityMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            dateRange: [],
            openFilters: false,
            role_name: '',
        }
        this.handleDateRange = this.handleDateRange.bind(this)
        this.handleOptions = this.handleOptions.bind(this)
        this.handleApplyFilters = this.handleApplyFilters.bind(this)
        this.hanldeFilters = this.hanldeFilters.bind(this)
    }

    componentDidMount() {
        this.props.getUserActivity('', '', 'All')
        this.props.getTeams()
    }
    handleDateRange(date, dateString) {
        this.setState({ dateRange: dateString })
    }

    handleOptions(event) {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleApplyFilters() {
        const startDate = this.state.dateRange && this.state.dateRange[0]
        const endDate = this.state.dateRange && this.state.dateRange[1]
        const role = this.state.role_name
        this.props.getUserActivity(
            startDate ? startDate : '',
            endDate ? endDate : '',
            role
        )
        this.setState({
            openFilters: !this.state.openFilters,
        })
    }
    hanldeFilters() {
        this.setState({
            openFilters: !this.state.openFilters,
        })
    }
    handleRole = () => {
        this.setState({
            role_name: '',
        })
        this.props.getUserActivity('', '', 'All')
    }
    handleReset = () => {
        this.setState({
            dateRange: [],
            role_name: '',
        })
    }
    render() {
        const { isLoading, teams } = this.props
        const { openFilters } = this.state
        const teamMemberNames =
            teams &&
            teams.finalTeamMember &&
            teams.finalTeamMember.map((data, id) => {
                return (
                    <option
                        key={id}
                        id={data.team_id}
                        name={data.full_name}
                        value={data.full_name}
                    >
                        {data.full_name}
                    </option>
                )
            })
        return (
            <>
                {!openFilters && (
                    <div className="mobile_account_info" id="content">
                        <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() =>
                                    this.props.history.push(
                                        '/account-homepage-mobile'
                                    )
                                }
                            />
                            <label className="nav-heading">User Activity</label>
                            <MdTune
                                className="color_dark_black mr-1 float-right font24 z-index1"
                                onClick={this.hanldeFilters}
                            />
                        </nav>
                        <div id="nav-tabContent">
                            <div
                                className="tab-pane fade show active user_activity"
                                id="nav-activity"
                                role="tabpanel"
                                aria-labelledby="nav-activity-tab"
                            >
                                {isLoading && <Loading />}
                                {!isLoading && (
                                    <div className="row no-gutters">
                                        {!openFilters && (
                                            <div className="col-12 recent_activity">
                                                <div>
                                                    {this.state.role_name !==
                                                        '' && (
                                                        <span className="badge badge-pill badge-green d-flex justify-content-between align-items-center float-left">
                                                            <label className="mb-0">
                                                                {
                                                                    this.state
                                                                        .role_name
                                                                }
                                                            </label>
                                                            <MdClose
                                                                onClick={
                                                                    this
                                                                        .handleRole
                                                                }
                                                                className="font16 ml-2"
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <table className="table table-borderless office_info">
                                                    {this.props.userActivity &&
                                                        this.props
                                                            .userActivity &&
                                                        this.props.userActivity.map(
                                                            (activity, id) => {
                                                                return (
                                                                    <tbody
                                                                        key={id}
                                                                        className="border-0"
                                                                    >
                                                                        <tr>
                                                                            <td className="border-0">
                                                                                <div className="recent_activity_regular_font">
                                                                                    {moment(
                                                                                        activity.datetime
                                                                                    ).format(
                                                                                        'LT'
                                                                                    )}
                                                                                </div>
                                                                                <div className="recent_activity_bold_font">
                                                                                    {moment(
                                                                                        activity.datetime
                                                                                    ).format(
                                                                                        'l'
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="border-0">
                                                                                <div className="recent_activity_regular_font">
                                                                                    {
                                                                                        activity.full_name
                                                                                    }
                                                                                </div>
                                                                                <div className="recent_activity_bold_font">
                                                                                    {
                                                                                        activity.message
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            }
                                                        )}
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {openFilters && (
                    <div className="mobile_account_info user_activity">
                        <div id="content">
                            <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex justify-content-between align-items-center bg_primary_color">
                                <CgChevronLeft
                                    className="back_icon text-white"
                                    onClick={() => this.hanldeFilters()}
                                />
                                <label className="nav-heading text-white">
                                    Filter
                                </label>
                                <span
                                    onClick={this.handleReset}
                                    style={{ zIndex: 1 }}
                                    className="text-white clear-all-link"
                                >
                                    Clear all
                                </span>
                            </nav>
                            <form className="filter-form">
                                <table className="sort_filter_table">
                                    <tbody>
                                        <tr className="">
                                            <td className="px-0">
                                                <div className="sort_label">
                                                    Date range:
                                                </div>
                                            </td>
                                            <td className="">
                                                <Space
                                                    direction="vertical"
                                                    size={12}
                                                >
                                                    <RangePicker
                                                        defaultValue={[
                                                            moment(new Date()),
                                                            moment(new Date()),
                                                        ]}
                                                        format={dateFormat}
                                                        onChange={
                                                            this.handleDateRange
                                                        }
                                                    />
                                                </Space>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="px-0">
                                                <div className="sort_label">
                                                    Team member:
                                                </div>
                                            </td>
                                            <td className="py-4">
                                                <select
                                                    className="form-control sort_team_member"
                                                    name="role_name"
                                                    onChange={
                                                        this.handleOptions
                                                    }
                                                >
                                                    <option
                                                        name=""
                                                        value="none"
                                                    >
                                                        Select a Name
                                                    </option>
                                                    {teamMemberNames}
                                                </select>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                                        <td>
                                                            <label htmlFor="sort" className="sortby_heading">
                                                                Sort by:
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-0">
                                                            <div className="sortby_label my-3 px-2">Location</div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="pending_tranfer_label my-3 px-2">
                                                                Payment
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-0">
                                                            <div className="sortby_label my-3 px-2">Location</div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="pending_tranfer_label my-3 px-2">
                                                                New Arrangements
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-0">
                                                            <div className="sortby_label my-3 px-2">Location</div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="pending_tranfer_label my-3 px-2">
                                                                Settings
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-0">
                                                            <div className="sortby_label my-3 px-2">Time</div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="pending_tranfer_label my-3 px-2">
                                                                After hours
                                                            </div>
                                                        </td>
                                                    </tr> */}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn"
                                onClick={this.handleApplyFilters}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userActivity: state.userActivity,
        isLoading: state.applicationIsLoading,
        teams: state.teams,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserActivity: (startDate, endDate, role) =>
            dispatch(getUserActivity(startDate, endDate, role)),
        getTeams: () => dispatch(getTeams()),
    }
}

UserActivityMobile.propTypes = {
    getUserActivity: PropTypes.func,
    getTeams: PropTypes.func,
    isLoading: PropTypes.bool,
    teams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    userActivity: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    getTitles: PropTypes.func,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivityMobile)
