import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import FundInitiationConfirmation from './FundInitiationConfirmation'
import { BsCreditCard } from 'react-icons/bs'
import { getCardListing } from 'actions/bankingActions'
import ModalLoader from 'shared/ModalLoader'
import {
    sendPhysicianTransferData,
    getPhysicianAccountExists,
    getTotalAmount,
} from 'actions/bankingActions'
import { StripeAccountCheck } from '../StripeAccountCheck'
import AddCard from '../AddCard'
import { calculatePayoutFee, currencyFormatter } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'

const FundTransfer = (props) => {
    const dispatch = useDispatch()
    const { isOpen, closeFundTransferFunc, transferAmount } = props
    const [initationOpen, setInitationOpen] = useState(false)
    const [addCardSection, setAddCardSection] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [openAuthDetails, setOpenAuthDetails] = useState(false)
    const [selectedCard, setSelectedCard] = useState('')
    const [selectedCardNumber, setSelectedNumber] = useState('')
    const [selectedCardError, setSelectedCardError] = useState(false)
    const cardListing = useSelector((state) => state.cardListing)
    const isTeam = useSelector((state) => state.teamMember)
    const isStripeAccount = useSelector((state) => state.isStripeAccount)
    const totalAmountStripe = useSelector((state) => state.totalAmountStripe)
    const isLoading = useSelector((state) => state.applicationIsLoading)

    useEffect(() => {
        dispatch(getPhysicianAccountExists())
        dispatch(getCardListing())
        dispatch(getTotalAmount())
    }, [''])
    const closeInitiationFunc = () => {
        setInitationOpen(false)
    }
    const handleBankAccountSection = () => {
        if (isTeam) {
            if (cardListing.is_locked) {
                setSuccessAlert(true)
            }
        } else {
            isStripeAccount
                ? setAddCardSection(!addCardSection)
                : setOpenAuthDetails(!openAuthDetails)
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const closeStripeModal = () => {
        setOpenAuthDetails(!openAuthDetails)
    }
    const handleSelectCard = (data) => {
        setSelectedCard(data.card_id)
        setSelectedNumber(data)
        setSelectedCardError(false)
    }
    const handleSubmit = () => {
        const data = {
            amount: transferAmount,
            card_id: selectedCard,
            account_id: totalAmountStripe.stripe_account_id,
        }
        if (selectedCard === '') {
            setSelectedCardError(true)
        } else {
            dispatch(sendPhysicianTransferData(data)).then((res) => {
                if (res.statusCode === 200) {
                    setInitationOpen(true)
                }
            })
        }
    }
    const enabled =
        cardListing &&
        cardListing.cardList &&
        cardListing.cardList.length > 0 &&
        selectedCard.length > 0

    const payoutFee = calculatePayoutFee(transferAmount)

    return (
        <>
            {isOpen && !addCardSection && !initationOpen && (
                <div className="mobile_account_info fund-transfer-page">
                    <div id="content" className="d-flex d-flex-column">
                        <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => closeFundTransferFunc()}
                            />
                            <label className="nav-heading">Transfer</label>
                            <span></span>
                        </nav>
                        {openAuthDetails && (
                            <StripeAccountCheck
                                isOpen={openAuthDetails}
                                closeStripeModal={closeStripeModal}
                            />
                        )}
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-center flex-column tranfer-wrapper">
                                    <span className="balance-label">
                                        Transfer Amount
                                    </span>
                                    <span className="balance">
                                        {currencyFormatter.format(
                                            transferAmount || ''
                                        )}
                                    </span>
                                </div>
                                {selectedCardError && (
                                    <div className="my-3">
                                        <small className="alert alert-danger alert-error-msg">
                                            Please select account
                                        </small>
                                    </div>
                                )}
                                <span className="transfer-to-label px-3 py-1">
                                    Transfer to
                                </span>
                                <table className="table table-borderless office_info mb-0">
                                    <tbody className="border-0 linked-accounts">
                                        {isLoading && (
                                            <div className="p-3">
                                                <ModalLoader />
                                            </div>
                                        )}
                                        <tr className="transfer-row-border">
                                            {!isLoading &&
                                                cardListing &&
                                                cardListing.cardList &&
                                                cardListing.cardList.map(
                                                    (data, id) => {
                                                        return (
                                                            <td
                                                                className="border-0 d-flex align-items-center"
                                                                key={id}
                                                            >
                                                                <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                    <div>
                                                                        <div className="office_address">
                                                                            {
                                                                                data.card_type
                                                                            }{' '}
                                                                            ****
                                                                            {
                                                                                data.card_number
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        type="radio"
                                                                        className="account-radio-btn"
                                                                        name="card"
                                                                        value={
                                                                            data.id
                                                                        }
                                                                        id={id}
                                                                        onClick={() =>
                                                                            handleSelectCard(
                                                                                data
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                )}
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    className="btn btn-link float-left px-3 py-1 add-card-link"
                                    onClick={handleBankAccountSection}
                                >
                                    Add bank account…
                                </button>
                            </div>
                        </div>
                        <div className="px-3 mt-auto">
                            <p className="transfer-text">
                                The funds will be deposited into your bank
                                account in 1-3 business days. And transfer fee
                                is {currencyFormatter.format(payoutFee)}, you
                                will receive{' '}
                                {currencyFormatter.format(
                                    transferAmount - payoutFee
                                )}
                            </p>
                            <button
                                className="btn mobile-green-btn d-flex justify-content-center align-items-center w-full"
                                disabled={!enabled}
                                onClick={handleSubmit}
                            >
                                <span className="ml-2">Transfer Funds</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {addCardSection && (
                <div className="mobile_account_info add-bank-account-page">
                    {/* <CgChevronLeft
                        className="back_icon"
                        onClick={ handleBankAccountSection }
                    /> */}
                    <AddCard closeModal={handleBankAccountSection} />
                </div>
            )}
            {initationOpen && (
                <FundInitiationConfirmation
                    isInitialOpen={initationOpen}
                    closeInitiationFunc={closeInitiationFunc}
                    transferAmount={transferAmount}
                    selectedCardNumber={selectedCardNumber}
                    history={props.history}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message="Please ask your physician to unlock profile"
                />
            )}
        </>
    )
}
FundTransfer.propTypes = {
    isOpen: PropTypes.bool,
    closeFundTransferFunc: PropTypes.func,
    getCardListing: PropTypes.func,
    history: PropTypes.object,
    transferAmount: PropTypes.string,
}
export default FundTransfer
