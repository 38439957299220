import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo'
import PompayTopLogo from 'shared/PompayTopLogo'
import { sendPasscodeData, logoutAction } from 'actions/authActions'
import { maxLengthCheck, onlyNumber } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'

function ReEnterPasscode(props) {
    const [reEnterOwnerPasscode, setReEnterOwnerPasscode] = useState('')
    const [error, setError] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setReEnterOwnerPasscode(event.target.value)
        setError(false)
    }
    const handleSubmit = () => {
        const data = { owner_passcode: reEnterOwnerPasscode }
        if (reEnterOwnerPasscode !== '' && reEnterOwnerPasscode.length !== 4) {
            setError('Passcode must be of 4 digits.')
        } else if (
            props.location.state.data.owner_passcode !== reEnterOwnerPasscode
        ) {
            setError('Passcode not matched')
        } else {
            dispatch(sendPasscodeData(data)).then((res) => {
                if (res.statusCode === 200) {
                    setSuccessAlert(true)
                    setTimeout(() => {
                        props.history.push('/account')
                    }, 1200)
                }
            })
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const handleLogout = () => {
        dispatch(logoutAction(props.history))
    }
    const enabled = reEnterOwnerPasscode && reEnterOwnerPasscode.length === 4
    return (
        <div>
            <div className="create_page_background"></div>
            <nav className="navbar navbar-light">
                <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block" />
                <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
            </nav>
            <div className="container-fluid create-passcode">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                        <div className="create_account_section">
                            <h4 className="auth-heading mb-3">
                                Already have an account?
                            </h4>
                            <p className="auth-content">
                                The easiest way to pay your medical bills.
                                Discounts, payment plans, and more!
                            </p>
                            <button
                                type="button"
                                className="btn white_btn custom_btn"
                                onClick={handleLogout}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-column align-items-center">
                        {error && (
                            <div
                                className="alert alert-danger w-auto passcode-error"
                                role="alert"
                            >
                                {error}
                            </div>
                        )}
                        <div className="form_body text-center">
                            <h4 className="bold-heading mb-4">
                                Re-Enter a 4-digit passcode{' '}
                            </h4>
                            <p
                                className="bold-content padding_left_right90"
                                style={{ marginBottom: '100px' }}
                            >
                                This passcode will prevent anyone from making
                                changes to information that is guarded.
                            </p>
                        </div>
                        <input
                            type="password"
                            maxLength="4"
                            onInput={maxLengthCheck}
                            className="passcode-textfield"
                            name="owner_passcode"
                            autoComplete="off"
                            value={reEnterOwnerPasscode || ''}
                            onChange={handleChange}
                            onKeyDown={(event) => onlyNumber(event)}
                            placeholder="----"
                            data-dismiss="modal"
                            style={{ marginBottom: '120px' }}
                        />
                        <button
                            type="button"
                            className="btn green_btn custom_btn"
                            disabled={!enabled}
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="The Passcode is created"
                />
            )}
        </div>
    )
}

ReEnterPasscode.propTypes = {
    sendPasscodeData: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
}

export default ReEnterPasscode
