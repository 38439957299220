import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { currencyFormatter } from 'utils/helper'
import moment from 'moment'

function ThankYouModal(props) {
    const {
        closeConfirmPayFunc,
        isComplete,
        selectedCardDetails,
        installBillDetails,
        role,
        value,
        closeAllModalFunc,
        outStandingBalance,
        paidFees,
    } = props

    const dueInstallmentSchedule =
        installBillDetails?.full_payment_schedule?.find(
            (x) => x.due_amount_status === 'Due'
        )

    const lateFees = dueInstallmentSchedule?.late_fee ?? 0
    const totalFees = dueInstallmentSchedule?.due_amount ?? 0
    const discountAmount = dueInstallmentSchedule?.discount_amount ?? 0
    const finalFees =
        parseFloat(totalFees) +
        parseFloat(lateFees) +
        parseFloat(discountAmount)

    return (
        <>
            <Modal
                isOpen={isComplete}
                // onRequestClose={ confirmDone }
                style={MODAL_STYLES}
                ariaHideApp={false}
                id="thank-you-for-payment"
            >
                <div className="modal-dialog-centered d-block" role="document">
                    {!role && (
                        <div className="">
                            <div className="modal-body py-0">
                                <h4 className="heading">
                                    Thank you for your
                                    <br /> payment.
                                </h4>
                                <div className="data-wrapper">
                                    <div className="payment-label">
                                        Payment details
                                    </div>
                                    <div className="d-flex justify-content-between my-2">
                                        <span className="label">
                                            Payment amount
                                        </span>
                                        <span className="value">
                                            {currencyFormatter.format(paidFees)}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-2">
                                        <span className="label">
                                            Payment method
                                        </span>
                                        <span className="value">
                                            {selectedCardDetails.card_type} ****
                                            {selectedCardDetails.card_number}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-2">
                                        <span className="label">Date</span>
                                        <span className="value">
                                            {moment(new Date()).format(
                                                'MM/DD/YY'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between btn-wrapper">
                                <button
                                    className="btn next-btn"
                                    onClick={() => closeConfirmPayFunc()}
                                >
                                    {' '}
                                    Done{' '}
                                </button>
                            </div>
                        </div>
                    )}
                    {role && (
                        <div className="">
                            <div className="modal-body py-0">
                                <h4 className="heading">
                                    Thank you for using <br /> Promise to Pay.
                                </h4>
                                <div className="data-wrapper">
                                    {/* <div className="payment-label">Payment details</div> */}
                                    <div className="d-flex justify-content-between my-2">
                                        {/* <span className="label">Date</span> */}
                                        <span className="value">
                                            Your payment of{' '}
                                            {outStandingBalance
                                                ? currencyFormatter.format(
                                                      outStandingBalance
                                                  )
                                                : currencyFormatter.format(
                                                      finalFees
                                                  )}{' '}
                                            is now due on <br /> {value}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between btn-wrapper">
                                <button
                                    className="btn next-btn"
                                    onClick={() => closeAllModalFunc()}
                                >
                                    {' '}
                                    Done{' '}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}
ThankYouModal.propTypes = {
    closeConfirmPayFunc: PropTypes.func,
    closeAllModalFunc: PropTypes.func,
    isComplete: PropTypes.bool,
    // physicianDetails: PropTypes.object,
    selectedCardDetails: PropTypes.object,
    installBillDetails: PropTypes.object,
    role: PropTypes.string,
    outStandingBalance: PropTypes.number,
    paidFees: PropTypes.number,
    value: PropTypes.string,
}
export default ThankYouModal
