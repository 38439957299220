/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { TiLockClosed, TiLockOpen } from 'react-icons/ti'
import Passcode from 'shared/Passcode'
import { getAccountDetails, getTeamDetails } from 'actions/accountActions'
// import Avatar from 'react-avatar'

export class AccountTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }
    componentDidMount() {
        !this.props.isTeam
            ? this.props.getAccountDetails()
            : this.props.getTeamDetails()
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.accountDetails.is_locked !==
            prevProps.accountDetails.is_locked
        ) {
            this.props.isTeam
                ? this.props.getTeamDetails()
                : this.props.getAccountDetails()
            this.forceUpdate()
        }
    }
    openPasscodeModal = () => {
        !this.props.isTeam &&
            this.setState({
                isOpen: true,
            })
    }
    closeModal = () => {
        this.setState({
            isOpen: false,
        })
    }
    handleOpenQrCode = (param) => {
        this.props.history.push({
            pathname: '/qr-mobile',
            state: {
                qrCode: param,
            },
        })
    }
    render() {
        const { bankTab, accountDetails, isMobile } = this.props
        const { isOpen } = this.state
        // const qrCode =
        //     accountDetails.physician &&
        //     accountDetails.physician[0] &&
        //     accountDetails.physician[0].qr_code
        return (
            <>
                <nav className="nav_tab_bar">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                            className="nav-item nav-link active"
                            id="nav-account-tab"
                            data-toggle="tab"
                            href="#nav-account"
                            role="tab"
                            aria-controls="nav-account"
                            aria-selected="true"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/details-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            Account
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-officeInfo-tab"
                            data-toggle="tab"
                            href="#nav-officeInfo"
                            role="tab"
                            aria-controls="nav-officeInfo"
                            aria-selected="false"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/office-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            Office Information
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-guidlines-tab"
                            data-toggle="tab"
                            href="#nav-guidlines"
                            role="tab"
                            aria-controls="nav-guidlines"
                            aria-selected="false"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/guidelines-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            Guidelines
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-team-tab"
                            data-toggle="tab"
                            href="#nav-team"
                            role="tab"
                            aria-controls="nav-team"
                            aria-selected="false"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/team-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            Team
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        <a
                            className={
                                bankTab
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            id="nav-banking-tab"
                            data-toggle="tab"
                            href="#nav-banking"
                            role="tab"
                            aria-controls="nav-banking"
                            aria-selected="false"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/banking-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            Banking
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-activity-tab"
                            data-toggle="tab"
                            href="#nav-activity"
                            role="tab"
                            aria-controls="nav-activity"
                            aria-selected="false"
                            onClick={
                                isMobile
                                    ? () =>
                                          this.props.history.push(
                                              '/useractivity-mobile'
                                          )
                                    : this.props.showSection
                            }
                        >
                            User Activity
                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey d-md-none d-lg-none d-xl-none" />
                        </a>
                        {!isMobile &&
                        accountDetails &&
                        accountDetails.is_locked ? (
                            <TiLockClosed
                                className="font32 color_dark_grey mr-4 nav_lock_icon"
                                onClick={() => this.openPasscodeModal()}
                            />
                        ) : (
                            !isMobile && (
                                <TiLockOpen
                                    tilockclosed="true"
                                    className="font32 color_dark_grey mr-4 nav_lock_icon"
                                    onClick={() => this.openPasscodeModal()}
                                />
                            )
                        )}
                    </div>
                </nav>
                {/*TODO: Should open this up when QR feature is ready*/}
                {/* {isMobile && (
                    <div>
                        <div className="barcode-wrapper">
                            <Avatar
                                size="50"
                                round={true}
                                src={qrCode}
                                onClick={() => this.handleOpenQrCode(qrCode)}
                            />
                        </div>
                    </div>
                )} */}
                <Passcode
                    isOpen={isOpen}
                    closeModal={this.closeModal}
                    props={this.props}
                    locked={accountDetails.is_locked}
                    physicianId={
                        accountDetails.physician &&
                        accountDetails.physician[0] &&
                        accountDetails.physician[0].physician_id
                    }
                />
            </>
        )
    }
}

AccountTabs.propTypes = {
    showSection: PropTypes.func,
    accountDetails: PropTypes.object,
    getAccountDetails: PropTypes.func,
    getTeamDetails: PropTypes.func,
    openPasscode: PropTypes.func,
    history: PropTypes.object,
    isMobile: PropTypes.bool,
    bankTab: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

const mapStateToProps = (state) => {
    return {
        accountDetails: state.accountDetails,
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountDetails: () => dispatch(getAccountDetails()),
        getTeamDetails: () => dispatch(getTeamDetails()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTabs)
