import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getAccountDetails,
    updateAccountDetails,
    getTeamDetails,
    updateTeamAccountDetails,
    addProfileImageAction,
    checkValidEmail,
} from 'actions/accountActions'
import Avatar from 'react-avatar'
import Loading from 'shared/Loader'
import { CgChevronLeft } from 'react-icons/cg'
import { maxLengthCheck } from 'utils/helper'
import moment from 'moment'
import { MdLock } from 'react-icons/md'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdChevronRight } from 'react-icons/md'
import camera from '../../../static/images/camera.png'

export class DetailsMobile extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            isEdit: false,
        }
    }
    componentDidMount() {
        const { isTeam } = this.props
        this._isMounted = true
        isTeam
            ? this.props.getTeamDetails().then((res) => {
                  if (res.statusCode === 200) {
                      this.setState({
                          input:
                              res &&
                              res.data.physician_team &&
                              res.data.physician_team,
                      })
                  }
              })
            : this.props.getAccountDetails().then((res) => {
                  if (res.statusCode === 200) {
                      this.setState({
                          input: res.data.physician && res.data.physician[0],
                      })
                  }
              })
    }
    componentWillUnmount() {
        this._isMounted = false
        this.setState({
            input: {},
            errors: {},
        })
    }
    uploadImage = (event) => {
        const input = this.state.input
        const { isTeam } = this.props
        const role = isTeam ? 'Teams' : 'Physicians'
        input[event.target.name] = event.target.files[0]
        var data = new FormData()
        data.append('file', input.profileImage)
        if (event.target.files[0]) {
            this.props.addProfileImageAction(role, data).then((result) => {
                if (result.statusCode === 200) {
                    setTimeout(() => {
                        this.props.getAccountDetails().then((res) => {
                            if (res.statusCode === 200) {
                                this.setState({
                                    input:
                                        res.data.physician &&
                                        res.data.physician[0],
                                })
                            }
                        })
                    }, 500)
                }
            })
        }
    }
    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { isTeam } = this.props
        const input = this.state.input
        const id = input.physician_id
        const teamId = input.id
        const teamData = {
            email_id: input.email_id,
            first_name: input.first_name,
            last_name: input.last_name,
            phone_number: input.phone_number,
            zipcode: input.zipcode,
        }
        const data = {
            first_name: input.first_name,
            last_name: input.last_name,
            email_id: input.email_id,
            date_of_birth: input.date_of_birth,
            owner_passcode: input.owner_passcode || '',
            phone_number: input.phone_number,
            zipcode: input.zipcode,
        }
        const check_email_data = {
            email_id: input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            isTeam
                                ? this.props
                                      .updateTeamAccountDetails(
                                          teamId,
                                          teamData
                                      )
                                      .then((res) => {
                                          if (res) {
                                              this.props.history.push(
                                                  '/account-homepage-mobile'
                                              )
                                          }
                                      })
                                : this.props
                                      .updateAccountDetails(id, data)
                                      .then((res) => {
                                          if (res) {
                                              this.props.history.push(
                                                  '/account-homepage-mobile'
                                              )
                                          }
                                      })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }

    validate() {
        const input = this.state.input
        // const { isTeam } = this.props;
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter your first name.'
        }
        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter your last name.'
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your phone number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter zipcode'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }
        // if (!input[ 'owner_passcode' ] && !isTeam) {
        //     isValid = false;
        //     errors[ 'owner_passcode' ] = 'Please enter your owner passcode.';
        // }
        // if (
        //     input[ 'owner_passcode' ] &&
        // input[ 'owner_passcode' ].length !== 4 &&
        // !isTeam
        // ) {
        //     isValid = false;
        //     errors[ 'owner_passcode' ] = 'Owner passcode should be of 4 digit.';
        // }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    editDetails = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        })
    }
    disabledDate = (current) => {
        return current && current > moment().endOf('day')
    }

    render() {
        const { isLoading, history } = this.props
        const { input, errors, isEdit } = this.state
        const enabled = !isEdit
        return (
            <div className="mobile_account_info">
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() =>
                                history.push('/account-homepage-mobile')
                            }
                        />
                        <label className="nav-heading">Account</label>
                        {!isEdit && (
                            <a
                                className="edit-navlink"
                                onClick={this.editDetails}
                            >
                                Edit
                            </a>
                        )}
                        {isEdit && (
                            <a
                                className="cancel-navlink"
                                onClick={() =>
                                    history.push('/account-homepage-mobile')
                                }
                            >
                                Cancel
                            </a>
                        )}
                        {/* <a className={ !isEdit? 'edit-navlink' : 'cancel-navlink' } onClick={ this.editDetails }>{!isEdit ? 'Edit': 'Cancel'}</a> */}
                    </nav>
                    <div>
                        <div
                            className="tab-pane fade show active"
                            id="nav-account"
                            role="tabpanel"
                            aria-labelledby="nav-account-tab"
                        >
                            {isLoading && (
                                <div className="p-3">
                                    {' '}
                                    <Loading />{' '}
                                </div>
                            )}
                            {!isLoading && (
                                <>
                                    {' '}
                                    <div className="row no-gutters">
                                        <div className="col-md-12">
                                            <div className="d-flex mobi_profile_pic">
                                                <Avatar
                                                    name={
                                                        input &&
                                                        input.first_name +
                                                            ' ' +
                                                            input.last_name
                                                    }
                                                    className="profile_image"
                                                    size="100"
                                                    round={true}
                                                    src={input && input.avatar}
                                                />
                                                <div className="upload-image-wrapper">
                                                    <div className="image-upload">
                                                        <label htmlFor="profileImage">
                                                            <img
                                                                src={camera}
                                                                className=""
                                                            />
                                                        </label>
                                                        <input
                                                            id="profileImage"
                                                            name="profileImage"
                                                            className="file-upload"
                                                            type="file"
                                                            onChange={
                                                                this.uploadImage
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className="row no-gutters account-details-form">
                                        <div className="col-md-12 p-0">
                                            <form>
                                                <div className="flex-column">
                                                    <div className="form-row">
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        name="first_name"
                                                                        id="fname"
                                                                        type="text"
                                                                        className="form-control account_info_field"
                                                                        placeholder="Enter your first name"
                                                                        value={
                                                                            input.first_name ||
                                                                            ''
                                                                        }
                                                                        disabled={
                                                                            enabled
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="fname"
                                                                    >
                                                                        First
                                                                        name
                                                                    </label>
                                                                </div>
                                                                <small className="text-danger">
                                                                    {errors.first_name ||
                                                                        ''}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        name="last_name"
                                                                        id="lname"
                                                                        type="text"
                                                                        className="form-control account_info_field"
                                                                        placeholder="Enter your last name"
                                                                        value={
                                                                            input.last_name ||
                                                                            ''
                                                                        }
                                                                        disabled={
                                                                            enabled
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="lname"
                                                                    >
                                                                        Last
                                                                        name
                                                                    </label>
                                                                </div>
                                                                <small className="text-danger">
                                                                    {errors.last_name ||
                                                                        ''}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        disabled
                                                                        name="last_name"
                                                                        id="dob"
                                                                        type="text"
                                                                        className="form-control account_info_field"
                                                                        placeholder="Enter your last name"
                                                                        defaultValue={
                                                                            input.date_of_birth ||
                                                                            ''
                                                                        }
                                                                        // disabled={ enabled }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <MdLock className="lock-icon" />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="dob"
                                                                    >
                                                                        Date of
                                                                        birth
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        name="phone_number"
                                                                        type="text"
                                                                        id="pnumber"
                                                                        maxLength="14"
                                                                        className="form-control account_info_field"
                                                                        placeholder="Enter mobile number"
                                                                        value={
                                                                            input.phone_number ||
                                                                            ''
                                                                        }
                                                                        disabled={
                                                                            enabled
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <IoMdCheckmarkCircle className="lock-icon right-tick" />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="pnumber"
                                                                    >
                                                                        Phone
                                                                        number
                                                                    </label>
                                                                </div>
                                                                <small className="text-danger">
                                                                    {errors.phone_number ||
                                                                        ''}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        name="email_id"
                                                                        id="email"
                                                                        type="email"
                                                                        className="form-control account_info_field"
                                                                        placeholder="Enter email id"
                                                                        value={
                                                                            input.email_id ||
                                                                            ''
                                                                        }
                                                                        disabled={
                                                                            enabled
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <MdChevronRight className="lock-icon right-arrow" />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="email"
                                                                    >
                                                                        Email
                                                                    </label>
                                                                </div>
                                                                <small className="text-danger">
                                                                    {errors.email_id ||
                                                                        ''}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 my-2">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <input
                                                                        type="number"
                                                                        maxLength="5"
                                                                        onInput={
                                                                            maxLengthCheck
                                                                        }
                                                                        name="zipcode"
                                                                        autoComplete="off"
                                                                        placeholder="Enter zipcode"
                                                                        id="zipcode"
                                                                        disabled={
                                                                            enabled
                                                                        }
                                                                        value={
                                                                            input.zipcode ||
                                                                            ''
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                        className="form-control account_info_field"
                                                                    />
                                                                    <label
                                                                        className="input-group-text account_info_label"
                                                                        htmlFor="zipcode"
                                                                    >
                                                                        Zipcode
                                                                    </label>
                                                                </div>
                                                                <small className="text-danger">
                                                                    {errors.zipcode ||
                                                                        ''}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        margin: '40px 0px 0px',
                                                    }}
                                                >
                                                    <span className="mobile-remove-link">
                                                        Deactivate my account
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>{' '}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {isEdit && (
                    <div className="px-3 pb-3">
                        <button
                            disabled={enabled}
                            className="btn mobile-green-btn"
                            onClick={this.handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        accountDetails: state.accountDetails,
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountDetails: () => dispatch(getAccountDetails()),
        getTeamDetails: () => dispatch(getTeamDetails()),
        updateAccountDetails: (id, data) =>
            dispatch(updateAccountDetails(id, data)),
        updateTeamAccountDetails: (id, data) =>
            dispatch(updateTeamAccountDetails(id, data)),
        addProfileImageAction: (role, data) =>
            dispatch(addProfileImageAction(role, data)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

DetailsMobile.propTypes = {
    history: PropTypes.object,
    getAccountDetails: PropTypes.func,
    updateAccountDetails: PropTypes.func,
    addProfileImageAction: PropTypes.func,
    getTeamDetails: PropTypes.func,
    updateTeamAccountDetails: PropTypes.func,
    checkValidEmail: PropTypes.func,
    accountDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsMobile)
