import React, { ChangeEvent } from 'react';

export interface SelectorProps {
	name: string,
	onChange?: (event: ChangeEvent<HTMLSelectElement>) => void,
	defaultValue: string,
	options: {
		value: string,
		displayValue?: string
	}[]
}

export const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
	if (props.options === null || props.options.length === 0) return null;

	return (
		<div className="form-group m-0">
			<label className="wrap-select">
				<select
					className="form-control header_drop_down"
					name={props.name}
					defaultValue={props.defaultValue}
					onChange={props.onChange}
				>
					{props.options.map((it) => (
						<option
							key={it.value}
							value={it.value}
						>
							{it.displayValue ?? it.value}
						</option>
					))}
				</select>
			</label>
		</div>
	)
}