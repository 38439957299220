/* eslint-disable quotes */
/* eslint-disable no-debugger */
import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading, modalIsLoading } from './commonActions'
import { decryptFunc, encryptFunc } from '../utils/helper'
import { logoutAction } from './authActions'
import { triggerNotifier } from 'shared/notifier'
import { getPatientList } from './patient/patient.actions'

export function fetchPaymentActivitydata(paymentActivityData) {
    return {
        type: 'FETCH_PAYMENT_ACTIVITY_DATA',
        paymentActivityData,
    }
}

export function fetchInstallBillDetails(installBillDetails) {
    return {
        type: 'FETCH_INSTALL_BILL_DETAILS',
        installBillDetails,
    }
}

export function updateAutoPayStatusToState(isAutoPay) {
    return {
        type: 'UPDATE_AUTO_PAY_STATUS',
        isAutoPay,
    }
}

export function fetchPatientHomePageDetails(patientHomePageDetails) {
    return {
        type: 'FETCH_PATIENT_HOME_PAGE_DETAILS',
        patientHomePageDetails,
    }
}

export function getPatientProfileInfo(id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/profile/info?patient_id=' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updatePatientProfileInfo(data, id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/patient/account/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getPayReview() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/profile/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}
export function getPayActivity(patientId, physicianId) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/patient/payment/installment/activity?patient_id=' +
                patientId +
                '&' +
                'physician_id=' +
                physicianId,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPaymentActivitydata(res.data))
                dispatch(modalIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}
export function getInstallBillDetails(id, physicianId) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/patient/payment/installment/bill?patient_payment_id=' +
                id +
                '&' +
                'physician_id=' +
                physicianId,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchInstallBillDetails(res.data))
                dispatch(modalIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updateAutoPayStatus(patientPaymentId, isAutoPay) {
    return (dispatch) => {
        const isAutoPayValue = isAutoPay ? 1 : 0
        dispatch(updateAutoPayStatusToState(isAutoPayValue))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/payment/auto-pay',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: {
                data: {
                    newData: encryptFunc({
                        patient_payment_id: patientPaymentId,
                        is_auto_pay: isAutoPayValue,
                    }),
                },
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                return res.data
            })
            .catch((error) => {
                console.error(error)
                return error
            })
    }
}

// Patient home page

export function getPatientHomePageDetails() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/payment/arrangement/activity',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPatientHomePageDetails(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Review Arrangement

export function getPatientReviewListing(data, filterFlag) {
    const newUrl = filterFlag
        ? '/physician/dashboard/arrangements/list?start_date=' +
        data.start_date +
        '&end_date=' +
        data.end_date
        : data
            ? typeof data === 'string'
                ? '/physician/dashboard/arrangements/list?is_today=' + data
                : '/physician/dashboard/arrangements/list?start_date=' +
                data.startDate +
                '&end_date=' +
                data.endDate +
                '&sort_by_name=' +
                data.alphabeticalOrder +
                '&sort_by_amount=' +
                data.sortByAmount +
                '&sort_by_discount=' +
                data.sortByDiscount
            : '/physician/dashboard/arrangements/list'
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + newUrl,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function downloadArrangementPdf() {
    return (dispatch) => {
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/physician/dashboard/arrangements/export?is_excel_export=1',
            responseType: 'blob',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                Accept: 'application/pdf',
            },
        })
            .then((response) => {
                const url = URL.createObjectURL(response.data)
                window.open(url)
                dispatch(getPatientReviewListing())
            })
            .catch((error) => {
                console.log(error)
                return error
            })
    }
}

export function getReviewArrangementDetails(id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/physician/arrangement?patient_payment_id=' +
                id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function acceptPatientArrangement(data) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'post',
            url:
                REACT_APP_BASE_URL +
                '/physician/dashboard/arrangements/update/status',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPatientReviewListing())
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function updateArrangement(data, id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'put',
            url:
                REACT_APP_BASE_URL +
                '/physician/dashboard/arrangements/update/' +
                id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPatientReviewListing())
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Delete Patient

export function deletePatient(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + '/physician/remove/patient/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPatientList(null))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function deactivatePatientAction() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/deactivate/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(logoutAction())
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Send reminder action
export function sendReminderAction(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/Physician/pastdue/payment/reminder',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Mark posted action
export function markPostedAction(data) {
    return async (dispatch) => {
        dispatch(applicationIsLoading(true))
        try {
            const response = await axios({
                method: 'post',
                url: REACT_APP_BASE_URL + '/Physician/payment/mark/posted',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'content-Type': 'application/json',
                },
                data: { data: { newData: encryptFunc(data) } },
            })
            const res = decryptFunc(response.data)
            dispatch(applicationIsLoading(false))
            return res
        } catch (error) {
            dispatch(applicationIsLoading(false))
            console.log(error)
            return error
        }
    }
}
