import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { FiChevronLeft } from 'react-icons/fi'
import { MdKeyboardArrowRight } from 'react-icons/md'
import SelectBillDate from './SelectBillDate'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'
function BillPaymentOption(props) {
    const { isOpen, closeBillPayOptions, billAmount, physicianDetails } = props
    const [selectBillDate, setSelectBillDate] = useState(false)
    const [pompayDisountId, setPompayDiscountId] = useState('')
    const paymentScheduleDetails = useSelector(
        (state) => state.paymentScheduleDetails
    )
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const closeSelectBill = () => {
        setSelectBillDate(!selectBillDate)
    }
    const openSelectBillPay = (id) => {
        setPompayDiscountId(id)
        setSelectBillDate(!selectBillDate)
    }
    return (
        <>
            {!selectBillDate && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => closeBillPayOptions()}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="payments-types-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (window.location = '/home')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeBillPayOptions()}
                                />
                                <span className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </span>
                                <span></span>
                            </div>
                            <div className="modal-body">
                                <h4 className="heading">
                                    How do you want to pay
                                    <br /> your bill of{' '}
                                    {currencyFormatter.format(billAmount || 0)}?
                                </h4>
                                {/* {isModalLoading && <div className="text-center mt-2"><ModalLoader /></div> } */}
                                {/* {!isModalLoading && ( */}

                                {/* )} */}
                                {isModalLoading && (
                                    <div className="text-center mt-2">
                                        <ModalLoader />
                                    </div>
                                )}
                                {!isModalLoading && (
                                    <>
                                        <div className="dash-box">
                                            <a
                                                onClick={() =>
                                                    openSelectBillPay(
                                                        'pompayDiscount'
                                                    )
                                                }
                                            >
                                                <div className="row no-gutters">
                                                    <div className="col-6 d-flex flex-column box-height justify-content-center">
                                                        <span className="active-bold-box mt-4 box-border text-center color_light_green">
                                                            Pay{' '}
                                                            {currencyFormatter.format(
                                                                paymentScheduleDetails.instant_pay_amount ||
                                                                    0
                                                            )}
                                                        </span>
                                                        <span className="active-bold-box mb-4 box-border text-center color_light_green">
                                                            right now
                                                        </span>
                                                    </div>
                                                    <div className="col-6 d-flex flex-column box-height justify-content-center">
                                                        <span className="active-bold-box mt-4 text-center color_light_green">
                                                            Get{' '}
                                                            {paymentScheduleDetails.discount_by ||
                                                                0}
                                                            % off
                                                        </span>
                                                        <span className="active-light-box mb-4 text-center color_light_green">
                                                            Save{' '}
                                                            {currencyFormatter.format(
                                                                paymentScheduleDetails.saved_amount ||
                                                                    0
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="emi-options">
                                            {paymentScheduleDetails.installment_option &&
                                                paymentScheduleDetails.installment_option.map(
                                                    (data, id) => {
                                                        return (
                                                            <button
                                                                className="btn outline-btn d-flex align-items-center justify-content-between box-height"
                                                                key={id}
                                                                onClick={() =>
                                                                    openSelectBillPay(
                                                                        ''
                                                                    )
                                                                }
                                                            >
                                                                <div className="d-flex flex-column">
                                                                    <span className="pay-btn">
                                                                        Pay{' '}
                                                                        <span className="bold-text">
                                                                            {currencyFormatter.format(
                                                                                data.first_installment_amount
                                                                            )}
                                                                            /mo
                                                                        </span>{' '}
                                                                        for
                                                                    </span>
                                                                    <span className="bold-text">
                                                                        {data.month ===
                                                                            2 &&
                                                                            data.last_installment_amount &&
                                                                            '1st month'}
                                                                        {(data.month !==
                                                                            2 ||
                                                                            !data.last_installment_amount) &&
                                                                            (data.month >
                                                                            1
                                                                                ? `${
                                                                                      data.last_installment_amount &&
                                                                                      data.last_installment_amount !=
                                                                                          data.first_installment_amount
                                                                                          ? data.month -
                                                                                            1
                                                                                          : data.month
                                                                                  } months`
                                                                                : `${data.month} month`)}
                                                                    </span>
                                                                    {data.last_installment_amount &&
                                                                        data.last_installment_amount !=
                                                                            data.first_installment_amount && (
                                                                            <span>
                                                                                <span className="pay-btn">
                                                                                    &
                                                                                    Pay{' '}
                                                                                    <span className="bold-text">
                                                                                        {currencyFormatter.format(
                                                                                            data.last_installment_amount
                                                                                        )}
                                                                                    </span>{' '}
                                                                                    for
                                                                                    <br />
                                                                                </span>{' '}
                                                                                <span className="bold-text">
                                                                                    {toOrdinalSuffix(
                                                                                        data.month
                                                                                    )}{' '}
                                                                                    month
                                                                                </span>
                                                                            </span>
                                                                        )}
                                                                </div>
                                                                <MdKeyboardArrowRight
                                                                    className="float-right font24 mt-2 green"
                                                                    // onClick={ () => handleBillRecordModal(data) }
                                                                    onClick={() =>
                                                                        openSelectBillPay(
                                                                            ''
                                                                        )
                                                                    }
                                                                />
                                                            </button>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {selectBillDate && (
                <SelectBillDate
                    isOpen={selectBillDate}
                    closeSelectBill={closeSelectBill}
                    physicianDetails={physicianDetails}
                    history={props.history}
                    pompayDisountId={pompayDisountId}
                    paymentScheduleDetails={paymentScheduleDetails}
                    billAmount={billAmount}
                />
            )}
        </>
    )
}

BillPaymentOption.propTypes = {
    closeBillPayOptions: PropTypes.func,
    isOpen: PropTypes.bool,
    billAmount: PropTypes.string,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default BillPaymentOption
