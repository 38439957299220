import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { CgChevronLeft } from 'react-icons/cg'
import { isMobile } from 'constants/Constant'
import { maxLengthCheck } from 'utils/helper'
import { MdLock } from 'react-icons/md'
import { dateFormat } from 'constants/Constant'
import moment from 'moment'
import { deletePatient } from 'actions/patientActions'
import ConfirmDelete from 'shared/ConfirmDelete'

const PatientProfileMobile = (props) => {
    const dispatch = useDispatch()
    const profileData = props.location.state.data
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const removePatientProfile = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const handleRemoveFunc = () => {
        const id = profileData && profileData.patient_id
        dispatch(deletePatient(id)).then((res) => {
            if (res.statusCode === 200) {
                props.history.push('/patient-list')
            } else {
                setIsConfirmDelete(!isConfirmDelete)
            }
        })
    }
    return (
        <>
            {isMobile && !isConfirmDelete && (
                <div
                    className="patient-profile patient-profile-page mobile_account_info"
                    id="content"
                >
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">Patient Profile</label>
                        <span></span>
                    </nav>
                    <div className="row no-gutters">
                        <div className="col-md-12 p-0">
                            <form>
                                <div className=" flex-column">
                                    <div className="form-row p-0">
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.first_name ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="fname"
                                                        className="form-control account_info_field"
                                                        // placeholder="Sean"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="fname"
                                                    >
                                                        First name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.last_name ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="lname"
                                                        className="form-control account_info_field"
                                                        // placeholder="Livingston"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="lname"
                                                    >
                                                        Last name
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="input-group date_field d-flex">
                                                <div className="input-group-prepend">
                                                    {/* <Space direction="vertical">
                                                    <DatePicker
                                                        className="form-control account_info_field"
                                                        format={ dateFormat }
                                                        // onChange={ handleChange }
                                                        // disabledDate={ disabledDate }
                                                    />
                                                </Space> */}
                                                    <input
                                                        disabled
                                                        defaultValue={moment(
                                                            profileData.date_of_birth
                                                        ).format(dateFormat)}
                                                        type="text"
                                                        id="dob"
                                                        className="form-control account_info_field"
                                                        // placeholder="Livingston"
                                                    />
                                                    <MdLock className="lock-icon" />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="dob"
                                                        style={{ zIndex: '1' }}
                                                    >
                                                        Date of birth
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.phone_number ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="mobi-number"
                                                        maxLength="14"
                                                        className="form-control account_info_field"
                                                        // placeholder="(805) 782-8292"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="mobi-number"
                                                    >
                                                        Mobile number
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.email_id ||
                                                            ''
                                                        }
                                                        type="text"
                                                        id="email"
                                                        className="form-control account_info_field"
                                                        // placeholder="lbeltran@beltranskin.com"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="email"
                                                    >
                                                        Email
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <input
                                                        defaultValue={
                                                            profileData.zipcode ||
                                                            ''
                                                        }
                                                        type="number"
                                                        id="zipcode"
                                                        maxLength="5"
                                                        onInput={maxLengthCheck}
                                                        className="form-control account_info_field"
                                                    />
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="zipcode"
                                                    >
                                                        Zipcode
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div
                                className="delete-link"
                                onClick={() => removePatientProfile()}
                            >
                                Delete Patient
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmDelete && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={removePatientProfile}
                    handleRemoveFunc={handleRemoveFunc}
                    role="patient profile"
                    mode="patient profile"
                />
            )}
        </>
    )
}
PatientProfileMobile.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}

export default PatientProfileMobile
