import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
    getPatientNotifications,
    updatePatientUserDetails,
} from 'actions/notificationActions'
import 'antd/dist/antd.css'
import { Switch } from 'antd'
import Loading from 'shared/Loader'
import SuccessAlert from 'shared/SuccessAlert'

function Notification() {
    const dispatch = useDispatch()
    const [successAlert, setSuccessAlert] = useState(false)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const patNotification = useSelector((state) => state.patNotification)
    useEffect(() => {
        dispatch(getPatientNotifications())
    }, [''])
    const onChange = (checked, x) => {
        const id = patNotification && patNotification.id
        patNotification[x] = checked ? 1 : 0
        delete patNotification.id
        dispatch(updatePatientUserDetails(id, patNotification)).then((res) => {
            if (res.statusCode === 200) {
                setSuccessAlert(true)
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    return (
        <>
            <div className="notification-page">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex">
                            <h2 className="tab_heading">Notifications</h2>
                        </div>
                    </div>
                </div>
                {isLoading && (
                    <div className="text-center">
                        {' '}
                        <Loading />{' '}
                    </div>
                )}
                <div className="row no-gutters">
                    <div className="col-md-5">
                        <table className="table table-borderless office_info pat_notification">
                            {!isLoading && (
                                <tbody className="border-0">
                                    <tr>
                                        <th className="table-heading pl-0">
                                            Actions
                                        </th>
                                        <th className="table-heading">SMS</th>
                                        <th className="table-heading">Email</th>
                                    </tr>
                                    <tr>
                                        <td className="pl-0">
                                            {' '}
                                            Upcoming Payment{' '}
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_upcoming_payment_sms_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_upcoming_payment_sms_status'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_upcoming_payment_email_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_upcoming_payment_email_status'
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0">
                                            {' '}
                                            Payment Is 2 Days Past Due{' '}
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_payment_two_days_past_due_sms_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_payment_two_days_past_due_sms_status'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_payment_two_days_past_due_email_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_payment_two_days_past_due_email_status'
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0">
                                            {' '}
                                            An Edit Was Made{' '}
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_an_edit_was_made_sms_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_an_edit_was_made_sms_status'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_an_edit_was_made_email_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_an_edit_was_made_email_status'
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0">
                                            {' '}
                                            Attempted Login{' '}
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_attempt_login_sms_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_attempt_login_sms_status'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                defaultChecked={
                                                    patNotification.is_attempt_login_email_status ||
                                                    ''
                                                }
                                                onChange={(checked) =>
                                                    onChange(
                                                        checked,
                                                        'is_attempt_login_email_status'
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    role="Notification"
                    message="The Notification Setting has been updated."
                />
            )}
        </>
    )
}

Notification.propTypes = {
    getNotifications: PropTypes.func,
    updatePatientUserDetails: PropTypes.func,
}

export default Notification
