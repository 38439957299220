import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SidebarNavigation from 'components/account/SidebarNavigation'
import { getPhysicianNotifications } from 'actions/notificationActions'
import moment from 'moment'
import Loading from 'shared/Loader'
import PompayTopLogo from 'shared/PompayTopLogo'
import { FaDollarSign } from 'react-icons/fa'
import { MdPerson } from 'react-icons/md'
import { AiFillStar } from 'react-icons/ai'

function PhysicianNotification(props) {
    const dispatch = useDispatch()
    const [date, setDate] = useState('')
    console.log(date)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getPhysicianNotifications(''))
    }, [])
    const phyNotification = useSelector((state) => state.phyNotification)
    const handleFilter = (event) => {
        setDate(event.target.value)
        dispatch(getPhysicianNotifications(event.target.value))
    }
    const sortedData = phyNotification.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
    )
    return (
        <>
            <div>
                <div className="notifications-page">
                    <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar justify-content-between">
                        <div className="d-flex">
                            <PompayTopLogo
                                className="small_logo patient_list_logo"
                                ancorClassName="pompay_small_logo mr-0"
                            />
                        </div>
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <div className="form-group ml-5 my-0 mr-0">
                                    <label className="wrap-notify-select">
                                        <select
                                            className="form-control notify-select"
                                            name="isToday"
                                            onChange={(event) =>
                                                handleFilter(event)
                                            }
                                        >
                                            <option defaultValue="all">
                                                {' '}
                                                All
                                            </option>
                                            <option
                                                name="isToday"
                                                value="today"
                                            >
                                                Today
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </nav>

                    <div className="wrapper">
                        <SidebarNavigation history={props.history} />
                        <div id="content">
                            <div
                                className="tab-content dashboard_tab_content_Wrapper"
                                id="nav-tabContent"
                            >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex">
                                            <h2 className="tab_heading">
                                                Notifications
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                {isLoading && <Loading />}
                                {!isLoading && (
                                    <div className="row">
                                        <div className="col-12">
                                            <table className="table table-borderless text-left">
                                                {sortedData &&
                                                    sortedData.map(
                                                        (data, id) => {
                                                            return (
                                                                <tbody
                                                                    key={id}
                                                                    className="border-0"
                                                                >
                                                                    <tr>
                                                                        <td className="px-0 border-0">
                                                                            <div className="notify-time">
                                                                                {moment(
                                                                                    data.datetime
                                                                                ).format(
                                                                                    'LT'
                                                                                )}
                                                                            </div>
                                                                            <div className="notify-date">
                                                                                {moment(
                                                                                    data.datetime
                                                                                ).format(
                                                                                    'l'
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="notify-line"></span>
                                                                            {data.notification_type ===
                                                                            2 ? (
                                                                                <FaDollarSign className="icon-wrapper payemnt" />
                                                                            ) : data.notification_type ===
                                                                              1 ? (
                                                                                <MdPerson className="icon-wrapper patient" />
                                                                            ) : data.notification_type ===
                                                                              3 ? (
                                                                                <AiFillStar className="icon-wrapper arrangemnt" />
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </td>
                                                                        <td className="p-0 border-0">
                                                                            <div className="light-border">
                                                                                <div className="notify-name">
                                                                                    {
                                                                                        data.notification_name
                                                                                    }
                                                                                </div>
                                                                                <div className="notify-message">
                                                                                    {
                                                                                        data.message
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        }
                                                    )}
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
PhysicianNotification.propTypes = {
    isOpen: PropTypes.bool,
    phyNotification: PropTypes.array,
    getPhysicianNotifications: PropTypes.func,
    history: PropTypes.object,
}
export default PhysicianNotification
