function contains(input, searchString, ignoreCase = false) {
	const inputString = String(input);
	if (!inputString || !searchString) return false;

	return ignoreCase
		? inputString.toUpperCase().includes(searchString.toUpperCase())
		: inputString.includes(searchString);
}

export const stringUtils = {
	contains: (input, searchString) => contains(input, searchString, false),
	containsIC: (input, searchString) => contains(input, searchString, true),
}