import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from '../utils/helper'
import { triggerNotifier } from 'shared/notifier'

export function fetchPatientTransaction(patientTransactions) {
    return {
        type: 'FETCH_PATIENT_TRANSACTION',
        patientTransactions,
    }
}

// Account Details

export function getPatientUserDetails() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updatePatientUserDetails(id, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/patient/account/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPatientUserDetails())
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getPatientTransaction(data) {
    return (dispatch) => {
        const newUrl =
            Object.keys(data).length > 0
                ? data.start_date &&
                  data.end_date &&
                  data.physician &&
                  data.sort_by_amount &&
                  data.last_date
                    ? '/patient/transaction/activity?start_date=' +
                      data.start_date +
                      '&' +
                      'end_date=' +
                      data.end_date +
                      '&' +
                      'physician=' +
                      data.physician +
                      '&' +
                      'sort_by_amount=' +
                      data.sort_by_amount +
                      '&' +
                      'last_date=' +
                      data.last_date
                    : data.start_date && data.end_date
                    ? '/patient/transaction/activity?start_date=' +
                      data.start_date +
                      '&' +
                      'end_date=' +
                      data.end_date
                    : data.physician && data.sort_by_amount && data.last_date
                    ? '/patient/transaction/activity?physician=' +
                      data.physician +
                      '&' +
                      'sort_by_amount=' +
                      data.sort_by_amount +
                      '&' +
                      'last_date=' +
                      data.last_date
                    : data.physician && data.sort_by_amount
                    ? '/patient/transaction/activity?physician=' +
                      data.physician +
                      '&' +
                      'sort_by_amount=' +
                      data.sort_by_amount
                    : data.physician && data.last_date
                    ? '/patient/transaction/activity?physician=' +
                      data.physician +
                      '&' +
                      'last_date=' +
                      data.last_date
                    : data.sort_by_amount
                    ? '/patient/transaction/activity?sort_by_amount=' +
                      data.sort_by_amount
                    : data.last_date
                    ? '/patient/transaction/activity?last_date=' +
                      data.last_date
                    : data.physician
                    ? '/patient/transaction/activity?physician=' +
                      data.physician
                    : data.sort_by_date
                    ? '/patient/transaction/activity?sort_by_date=' +
                      data.sort_by_date
                    : ''
                : '/patient/transaction/activity'
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + newUrl,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPatientTransaction(res.data))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}
