import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import PropTypes from 'prop-types'
import { MdEdit } from 'react-icons/md'
import { triggerNotifier } from 'shared/notifier'

export class ScanCodePage extends Component {
    state = {
        result: '',
    }

    handleScan = (data) => {
        if (data) {
            this.setState({
                result: data,
            })
        }
    }
    handleError = (err) => {
        const message = err || 'Invalid QR Code'
        triggerNotifier({ type: 'error', message })
    }

    render() {
        if (this.state.result && this.state.result.length === 6) {
            this.props.openAddModal(this.state.result)
        }
        return (
            <>
                {this.props.isScan && (
                    <div className="Scan-code-page">
                        <div className="scan-code-wrapper">
                            <button
                                type="button"
                                className="close close-icon"
                                onClick={() => this.props.closePage()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="heading">
                                Scan a Pompay code to <br /> add a physician
                            </h3>
                            <QrReader
                                delay={300}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                style={{ width: '100%' }}
                            />
                        </div>
                        {/* <p className="position-absolute">{this.state.result}</p> */}
                        <div className="edit-code-footer">
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn scan-btn">
                                    <MdEdit
                                        className="scan-icon"
                                        onClick={() => this.props.closePage()}
                                    />
                                </button>
                                <label>
                                    Enter Code <br /> Manually
                                </label>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

ScanCodePage.propTypes = {
    isScan: PropTypes.bool,
    closePage: PropTypes.func,
    openAddModal: PropTypes.func,
    history: PropTypes.object,
}

export default ScanCodePage
