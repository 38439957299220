import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Loading from '../../../shared/Loader';
import { getUserActivity, getTeams } from '../../../actions/accountActions';
import { DatePicker, Space } from 'antd';
import { mmdyyyFormat } from '../../../constants/Constant';
import { MdClose } from 'react-icons/md';

const { RangePicker } = DatePicker
export class UserActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: '',
            endDate: '',
            role_name: '',
        }
        this.handleDateRange = this.handleDateRange.bind(this)
        this.handleOptions = this.handleOptions.bind(this)
        this.handleApplyFilters = this.handleApplyFilters.bind(this)
    }

    componentDidMount() {
        this.props.getUserActivity('', '', 'All')
        this.props.getTeams()
    }
    // eslint-disable-next-line no-unused-vars
    handleDateRange(date, dateString) {
        const startDate = moment(date[0]).format('l')
        const endDate = moment(date[1]).format('l')
        this.setState({ startDate, endDate })
    }

    handleOptions(event) {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleApplyFilters() {
        const startDate = this.state.startDate
        const endDate = this.state.endDate
        const role = this.state.role_name
        this.props.getUserActivity(
            startDate ? startDate : '',
            endDate ? endDate : '',
            role ? role : 'All'
        )
    }
    handleRole = () => {
        this.setState({
            role_name: '',
        })
        this.props.getUserActivity('', '', 'All')
    }
    render() {
        const { isLoading, teams, userActivity } = this.props
        const teamMemberNames =
            teams &&
            teams.finalTeamMember &&
            teams.finalTeamMember.map((data, id) => {
                return (
                    <option
                        key={id}
                        id={data.team_id}
                        name={data.full_name}
                        value={data.full_name}
                    >
                        {data.full_name}
                    </option>
                )
            })
        return (
            <div
                className="user_activity"
                // id="nav-activity"
                // role="tabpanel"
                // aria-labelledby="nav-activity-tab"
            >
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 recent_activity">
                        <div className="d-flex justify-content-between align-items-baseline">
                            <span className="d-flex">
                                <h2 className="tab_heading">Recent Activity</h2>
                            </span>
                            {/* <h2 className="tab_heading">{ this.state.role_name }</h2> */}
                            {this.state.role_name !== '' && (
                                <span className="badge badge-pill badge-green d-flex justify-content-between align-items-center">
                                    <label className="mb-0">
                                        {this.state.role_name}
                                    </label>
                                    <MdClose
                                        onClick={this.handleRole}
                                        className="font16 pointer ml-2"
                                    />
                                </span>
                            )}
                        </div>
                        {isLoading && <Loading />}
                        {!isLoading && (
                            <table className="table table-borderless office_info">
                                {userActivity &&
                                    userActivity.map((activity, id) => {
                                        return (
                                            <tbody
                                                key={id}
                                                className="border-0"
                                            >
                                                <tr>
                                                    <td className="px-0 border-0">
                                                        <div className="recent_activity_regular_font">
                                                            {moment(
                                                                activity.datetime
                                                            ).format('LT')}
                                                        </div>
                                                        <div className="recent_activity_bold_font">
                                                            {moment(
                                                                activity.datetime
                                                            ).format('l')}
                                                        </div>
                                                    </td>
                                                    <td className="px-0 border-0">
                                                        <div className="recent_activity_regular_font">
                                                            {activity.full_name}
                                                        </div>
                                                        <div className="recent_activity_bold_font">
                                                            {activity.message}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                            </table>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 sort_column d-none d-md-block d-lg-block d-xl-block">
                        <h2 className="tab_heading">Sort/Filter</h2>
                        <form>
                            <table className="sort_filter_table">
                                <tbody>
                                    <tr className="">
                                        <td className="px-0">
                                            <div className="sort_label">
                                                Date range:
                                            </div>
                                        </td>
                                        <td className="">
                                            <Space
                                                direction="vertical"
                                                size={12}
                                            >
                                                <RangePicker
                                                    defaultValue={[
                                                        moment(
                                                            new Date(),
                                                            mmdyyyFormat
                                                        ),
                                                        moment(
                                                            new Date(),
                                                            mmdyyyFormat
                                                        ),
                                                    ]}
                                                    format={mmdyyyFormat}
                                                    onChange={
                                                        this.handleDateRange
                                                    }
                                                />
                                            </Space>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-0">
                                            <div className="sort_label">
                                                Team member:
                                            </div>
                                        </td>
                                        <td className="pt-3 pb-4">
                                            <select
                                                className="form-control sort_team_member"
                                                name="role_name"
                                                onChange={this.handleOptions}
                                            >
                                                <option name="" value="None">
                                                    Select a Name
                                                </option>
                                                {teamMemberNames}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        <div>
                            <button
                                className="btn green_btn custom_btn my-5"
                                style={{ width: '415px' }}
                                onClick={this.handleApplyFilters}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userActivity: state.userActivity,
        isLoading: state.applicationIsLoading,
        teams: state.teams,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserActivity: (startDate, endDate, role) =>
            dispatch(getUserActivity(startDate, endDate, role)),
        getTeams: () => dispatch(getTeams()),
    }
}

UserActivity.propTypes = {
    getUserActivity: PropTypes.func,
    getTeams: PropTypes.func,
    isLoading: PropTypes.bool,
    teams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    userActivity: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity)
