import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OtpModal from './OtpModal'
import { connect } from 'react-redux'
import { sendFormData } from '../../actions/authActions'
import PompayTopLogo from 'shared/PompayTopLogo'
import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo'
import { FiChevronRight } from 'react-icons/fi'
import { isMobile } from 'constants/Constant'
import { CgChevronLeft } from 'react-icons/cg'

export class SelectUserRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openVerify: false,
            user_role: '',
            failError: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        const spanValue =
            event.target.innerText === 'I am a Patient' ||
            event.target.innerText === 'Patient'
                ? '2'
                : event.target.innerText === 'I am a Provider' ||
                  event.target.innerText === 'Provider'
                ? '1'
                : event.target.parentNode.value
        this.setState({
            user_role: event.target.value ? event.target.value : spanValue,
            // openVerify: !this.state.openVerify
            failError: false,
        })
        const data = {
            user_role: event.target.value ? event.target.value : spanValue,
            phone_number: this.props.location.state.formData.phone_number,
        }
        this.props.sendFormData(data).then((res) => {
            if (res.statusCode === 200) {
                this.setState({ openVerify: !this.state.openVerify })
            } else {
                this.setState({ failError: true })
            }
        })
    }
    closeOtpModal = () => {
        this.setState({ openVerify: !this.state.openVerify })
    }
    render() {
        const { openVerify, failError } = this.state
        const { history, location } = this.props
        const enabled =
            location.state &&
            location.state.formData &&
            location.state.formData.phone_number
        return (
            <div>
                <div>
                    <div className="create_page_background"></div>
                    {!isMobile && (
                        <nav className="navbar navbar-light">
                            <PompayTopWhiteLogo />
                            <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
                        </nav>
                    )}
                    {isMobile && (
                        <div className="mobile_account_info">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => history.goBack()}
                                />
                                <label className="nav-heading">User Type</label>
                            </nav>
                        </div>
                    )}
                    <div className="container-fluid user_page">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                                <div className="top_margin">
                                    <h4 className="auth-heading mb-3">
                                        Already have an account?
                                    </h4>
                                    <p className="auth-content">
                                        The easiest way to pay your medical
                                        bills. Discounts, payment plans, and
                                        more!
                                    </p>
                                    <button
                                        type="button"
                                        className="btn white_btn custom_btn"
                                        onClick={() => history.push('/')}
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center">
                                <div className="d-flex flex-column">
                                    {failError && (
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            you are already registered with us.
                                        </div>
                                    )}
                                    <h4 className="patient_or_provider_heading">
                                        Are you a patient or a provider?
                                    </h4>
                                    <button
                                        className="btn btn_user_custom_outline d-flex align-items-center justify-content-between"
                                        name="patient"
                                        value="2"
                                        type="button"
                                        onClick={this.handleSubmit}
                                        disabled={!enabled}
                                    >
                                        <span>
                                            I am a <strong>Patient</strong>{' '}
                                        </span>
                                        <FiChevronRight className="float-right" />
                                    </button>
                                    <button
                                        className="btn btn_user_custom_outline d-flex align-items-center justify-content-between"
                                        name="provider"
                                        value="1"
                                        type="button"
                                        onClick={this.handleSubmit}
                                        disabled={!enabled}
                                    >
                                        <span>
                                            I am a <strong>Provider</strong>{' '}
                                        </span>
                                        <FiChevronRight className="float-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openVerify && (
                    <OtpModal
                        formData={location.state.formData}
                        userRole={this.state.user_role}
                        history={history}
                        closeOtpModal={this.closeOtpModal}
                        isOpen={openVerify}
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendFormData: (data) => dispatch(sendFormData(data)),
    }
}

SelectUserRole.propTypes = {
    sendFormData: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
}

export default connect(null, mapDispatchToProps)(SelectUserRole)
