import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { logoutAction } from 'actions/authActions'
import PompayTopLogo from 'shared/PompayTopLogo'
import { isMobile } from 'constants/Constant'

const AccountTabsHeader = (props) => {
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logoutAction(props.history))
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal d-flex justify-content-between align-item-end">
            <PompayTopLogo className="small_logo" ancorClassName="pompay_small_logo acc-page" />

            {isMobile && <a className="logout" onClick={ handleLogout }>Log Out</a>}

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/#">
                            {' '}
                        </a>
                    </li>
                </ul>
                <a className="logout" onClick={handleLogout}>
                    Log Out
                </a>
            </div>
        </nav>
    )
}

AccountTabsHeader.propTypes = {
    history: PropTypes.object,
}

export default AccountTabsHeader
