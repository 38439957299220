import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendBasicInformation } from 'actions/authActions'
import { checkValidEmail } from 'actions/accountActions'
import 'antd/dist/antd.css'
import { DatePicker, Space } from 'antd'
import { dateFormat } from 'constants/Constant'
import moment from 'moment'
import { maxLengthCheck } from 'utils/helper'
export class ProfileForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
        }
        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.disabledDate = this.disabledDate.bind(this)
    }
    handleChange(event, date) {
        const input = this.state.input
        if (date) {
            input['date_of_birth'] = date
        } else if (event === null) {
            input['date_of_birth'] = date
        } else {
            input[event.target.name] = event.target.value
        }
        this.setState({
            input,
            errors: {},
        })
    }

    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter your first name.'
        }

        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }

        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter your last name.'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (input['email_id'] && this.props.emailError) {
            isValid = false
            errors['email_id'] = 'email already registered'
        }

        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }

        if (!input['date_of_birth']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your zipcode.'
        }

        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }

    validationVerified() {
        const verifiedInput = this.state.verifiedInput
        const verifiedErrors = {}
        let isValid = true
        if (!verifiedInput['speciality']) {
            isValid = false
            verifiedErrors['speciality'] = 'Please enter your speciality.'
        }
        if (typeof verifiedInput['speciality'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(verifiedInput['speciality'])) {
                isValid = false

                verifiedErrors['speciality'] = 'Please enter alphabets only.'
            }
        }
        if (!verifiedInput['npi_number']) {
            isValid = false
            verifiedErrors['npi_number'] = 'Please enter your NPI number.'
        }
        if (!verifiedInput['tax_id']) {
            isValid = false
            verifiedErrors['tax_id'] = 'Please enter your Tax I.D.'
        }

        this.setState({
            verifiedErrors: verifiedErrors,
        })

        return isValid
    }

    handleSubmit(event) {
        event.preventDefault()
        const check_email_data = {
            email_id: this.state.input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            this.props
                                .sendBasicInformation(this.state.input)
                                .then((res) => {
                                    if (res) {
                                        this.props.history.push('/home')
                                    }
                                })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }

    disabledDate = (current) => {
        return current && current > moment().endOf('day')
    }
    render() {
        const { errors, input } = this.state
        const enabled = Object.keys(input).length > 4

        return (
            <>
                <div className="form_body">
                    <h4 className="bold-heading text-center mb-4">
                        Basic Information{' '}
                    </h4>
                    <p className="mb-5 text-center light-content">
                        Let’s get you set up so you can start paying the way you
                        want.
                    </p>
                    <form className="basic_info_form">
                        <div className="form-row flex-column">
                            <div className="col-sm-12 my-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            className="form-control account_info_field"
                                            placeholder="Enter first name"
                                            autoComplete="off"
                                            value={input.first_name || ''}
                                            onChange={this.handleChange}
                                        />
                                        <label
                                            className="input-group-text account_info_label"
                                            htmlFor="first_name"
                                        >
                                            First name
                                        </label>
                                    </div>
                                </div>
                                <small className="text-danger">
                                    {errors.first_name || ''}
                                </small>
                            </div>
                            <div className="col-sm-12 my-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            className="form-control account_info_field"
                                            placeholder="Enter last name"
                                            autoComplete="off"
                                            value={input.last_name || ''}
                                            onChange={this.handleChange}
                                        />
                                        <label
                                            className="input-group-text account_info_label"
                                            htmlFor="last_name"
                                        >
                                            Last name
                                        </label>
                                    </div>
                                </div>
                                <small className="text-danger">
                                    {errors.last_name || ''}
                                </small>
                            </div>
                            <div className="col-sm-12 my-2">
                                <div className="input-group date_field">
                                    <fieldset className="input-group-prepend">
                                        <Space direction="vertical">
                                            <DatePicker
                                                className="form-control account_info_field"
                                                id="dob"
                                                format={dateFormat}
                                                onChange={this.handleChange}
                                                disabledDate={this.disabledDate}
                                            />
                                        </Space>
                                        <label
                                            className="input-group-text account_info_label"
                                            htmlFor="dob"
                                        >
                                            Date of birth
                                        </label>
                                    </fieldset>
                                </div>
                                <small className="text-danger">
                                    {errors.date_of_birth || ''}
                                </small>
                            </div>
                            <div className="col-sm-12 my-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <input
                                            type="email"
                                            name="email_id"
                                            id="email_id"
                                            className="form-control account_info_field"
                                            placeholder="Enter email"
                                            autoComplete="off"
                                            value={input.email_id || ''}
                                            onChange={this.handleChange}
                                        />
                                        <label
                                            className="input-group-text account_info_label"
                                            htmlFor="email_id"
                                        >
                                            Email
                                        </label>
                                    </div>
                                </div>
                                <small className="text-danger">
                                    {errors.email_id || ''}
                                </small>
                            </div>
                            <div className="col-sm-12 my-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <input
                                            type="number"
                                            maxLength="5"
                                            onInput={maxLengthCheck}
                                            name="zipcode"
                                            id="zipcode"
                                            className="form-control account_info_field"
                                            placeholder="Enter zipcode"
                                            autoComplete="off"
                                            value={input.zipcode || ''}
                                            onChange={this.handleChange}
                                        />
                                        <label
                                            className="input-group-text account_info_label"
                                            htmlFor="zipcode"
                                        >
                                            Zipcode
                                        </label>
                                    </div>
                                </div>
                                <small className="text-danger">
                                    {errors.zipcode || ''}
                                </small>
                            </div>
                        </div>
                    </form>
                </div>
                <button
                    type="button"
                    className="btn green_btn custom_btn mt-5"
                    onClick={this.handleSubmit}
                    disabled={!enabled}
                >
                    Continue
                </button>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        emailError: state.emailError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendBasicInformation: (data) => dispatch(sendBasicInformation(data)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}
ProfileForm.propTypes = {
    sendBasicInformation: PropTypes.func,
    checkValidEmail: PropTypes.func,
    emailError: PropTypes.bool,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
