import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isMobile } from 'constants/Constant'
import { CgChevronLeft } from 'react-icons/cg'
import { ddmmyyFormat } from 'constants/Constant'
import moment from 'moment'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'
import ModalLoader from 'shared/ModalLoader'

const BillDetailsTotal = (props) => {
    const installBillDetails = useSelector((state) => state.installBillDetails)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const totalPaid = installBillDetails && installBillDetails.total_paid
    return (
        <>
            {isMobile && (
                <div className="bill_details mobile-bill-details mobile_account_info">
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">Bill Details</label>
                        <span></span>
                    </nav>
                    <div className="heading_section d-flex flex-column text-center mt-5">
                        <span className="main-balanceheading">Total paid</span>
                        {isModalLoading && <ModalLoader />}
                        {!isModalLoading && (
                            <span className="main-amount">
                                {totalPaid &&
                                    currencyFormatter.format(
                                        totalPaid.total_paid || 0
                                    )}
                            </span>
                        )}
                    </div>
                    <div className="my-4 text-center">
                        <span className="content-heading">
                            Full payment schedule:
                        </span>
                    </div>
                    {isModalLoading && <ModalLoader />}
                    {!isModalLoading &&
                        totalPaid &&
                        totalPaid.full_payment_schedule &&
                        totalPaid.full_payment_schedule.map((data, id) => {
                            return (
                                <div className="row no-gutters my-4" key={id}>
                                    <div className="col-2 col-sm-2">
                                        <form className="radio_form">
                                            <label
                                                className="radio_btn"
                                                htmlFor="sms"
                                            >
                                                <input
                                                    type="radio"
                                                    name="radio"
                                                    checked="checked"
                                                    id="sms"
                                                    onChange={() => {}}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </form>
                                    </div>
                                    <div className="col-7 col-sm-7 text-left">
                                        <div className="d-flex flex-column ml-2">
                                            <span className="installment-label">
                                                {toOrdinalSuffix(id + 1)}{' '}
                                                installment
                                            </span>
                                            <span className="installment-date">
                                                {data.payment_status}:{' '}
                                                {moment(
                                                    data.payment_date
                                                ).format(ddmmyyFormat)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3">
                                        <span className="installment-label">
                                            {currencyFormatter.format(
                                                data.paid_amount || 0
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            )}
        </>
    )
}
BillDetailsTotal.propTypes = {
    history: PropTypes.object,
}
export default BillDetailsTotal
