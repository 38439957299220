import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { CgChevronLeft } from 'react-icons/cg'
import PaymentTransfer from './PaymentTransfer'
import { currencyFormatter } from 'utils/helper'
const Balance = (props) => {
    const [payTransfer, setPayTransfer] = useState(false)
    const openPayTransfer = () => {
        setPayTransfer(true)
    }
    const closePaymentTransfer = () => {
        setPayTransfer(false)
    }
    const totalAmount = props.location?.state?.totalAmount

    const availableToPayOutAmount =
        props.location?.state?.availableToPayOutAmount
    return (
        <>
            {!payTransfer && (
                <div
                    className="mobile_account_info account-balance-page"
                    id="content"
                >
                    <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">Balance</label>
                        <span></span>
                    </nav>
                    <div>
                        <div
                            className="tab-pane fade show active banking"
                            id="nav-banking"
                            role="tabpanel"
                            aria-labelledby="nav-banking-tab"
                        >
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-center flex-column balance-wrapper">
                                        <span className="balance-label">
                                            Account Balance
                                        </span>
                                        <span className="balance">
                                            {currencyFormatter.format(
                                                totalAmount || 0
                                            )}
                                        </span>
                                    </div>
                                    <table className="table table-borderless office_info">
                                        <tbody>
                                            <tr className="row-border">
                                                <td className="border-0">
                                                    <div className="transfer-label">
                                                        Transfer to Your Bank
                                                    </div>
                                                    <div className="transfer-info">
                                                        Transfer money from your
                                                        Pompay account <br />
                                                        to your bank
                                                    </div>
                                                </td>
                                                <td className="border-0 text-right">
                                                    <MdChevronRight
                                                        className="float-right font22 mt-2 color_dark_grey"
                                                        onClick={
                                                            openPayTransfer
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {payTransfer && (
                <PaymentTransfer
                    isOpen={payTransfer}
                    closePaymentTransfer={closePaymentTransfer}
                    totalAmount={totalAmount}
                    availableToPayOutAmount={availableToPayOutAmount}
                    history={props.history}
                />
            )}
        </>
    )
}
Balance.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}
export default Balance
