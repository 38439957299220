import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { useSelector } from 'react-redux'
import { IoMdSync } from 'react-icons/io'
import { Switch } from 'antd'
import { currencyFormatter, toOrdinalSuffix } from 'utils/helper'
import ModalLoader from 'shared/ModalLoader'
import { updateAutoPayStatus } from '../../../actions/patientActions'
// import ViewReceipt from './ViewReceipt';
import SelectPaymentMethods from './SelectPaymentMethods'
import moment from 'moment'

const CurrentRemaningPayDetails = (props) => {
    const dispatch = useDispatch()
    const {
        openRemaningPayModal,
        closeRemaningPaymentFunc,
        physicianDetails,
        openRemaningPayDetailsModalDetails,
        history,
    } = props
    // const [ openReceipt, setOpenReceipt ] = useState(
    //     false
    // );
    const [paymentOption, setPaymentOption] = useState(false)
    // const [ selectedPayError, setSelectedPayError ] = useState(
    //     false
    // );
    // const [ receiptDetails, setReceiptDetails ] = useState({})
    const [selectedPayDetails, setSelectedPayDetails] = useState({})
    const installBillDetails = useSelector((state) => ({
        ...state.installBillDetails?.remaining_balance,
        isAutoPay: state.installBillDetails?.payment_details?.is_auto_pay,
        patientPaymentId:
            state.installBillDetails?.payment_details?.patient_payment_id,
    }))
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    // const viewReceiptFunc = (data) => {
    //     setReceiptDetails(data)
    //     setOpenReceipt(!openReceipt)
    // }
    // const closeViewReceiptFunc = () => {
    //     setOpenReceipt(!openReceipt)
    // }
    const closePaymentMethod = () => {
        // if (Object.keys(selectedPayDetails).length === 0) {
        //     setSelectedPayError(true)
        // }
        // else {
        setPaymentOption(!paymentOption)
        // }
    }
    const handleSelectEmi = (data) => {
        setSelectedPayDetails(data)
        // setSelectedPayError(false)
    }
    // const tempArray = []
    // installBillDetails && installBillDetails.full_payment_schedule && installBillDetails.full_payment_schedule.map((pay)=> {
    //     return  tempArray.push((parseFloat(pay.due_amount)))
    // })
    // const totalRemaningBalance =  tempArray.reduce((a, b) => a + b, 0)

    const toggleAutoPayStatus = () => {
        if (!installBillDetails) {
            return
        }
        dispatch(
            updateAutoPayStatus(
                installBillDetails.patientPaymentId,
                !installBillDetails.isAutoPay
            )
        )
    }

    return (
        <>
            {openRemaningPayModal && !paymentOption && (
                <Modal
                    isOpen={openRemaningPayModal}
                    onRequestClose={closeRemaningPaymentFunc}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    id="total-amount-modal"
                    ariaHideApp={false}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeRemaningPaymentFunc()}
                                />
                                <label className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </label>
                                <span></span>
                            </div>
                            <div className="modal-body total-amount-content align-items-center">
                                <div className="text-center">
                                    <span className="total-amount">
                                        Remaining Balance
                                    </span>
                                    {isModalLoading && <ModalLoader />}
                                    {!isModalLoading && (
                                        <h3 className="amount">
                                            {currencyFormatter.format(
                                                (installBillDetails &&
                                                    installBillDetails.remaining_balance) ||
                                                    0
                                            )}
                                        </h3>
                                    )}
                                </div>
                                <div className="d-flex justify-content-evently align-items-center dashed-border px-5">
                                    <IoMdSync className="back-icon" />
                                    <div className="d-flex flex-column text-left green-content">
                                        <div>
                                            <span>AutoPay: </span>
                                            <span>
                                                {installBillDetails.isAutoPay
                                                    ? 'On'
                                                    : 'Off'}
                                            </span>
                                        </div>
                                        <span>
                                            Turn on to never miss a payment
                                        </span>
                                    </div>
                                    <Switch
                                        onChange={toggleAutoPayStatus}
                                        checked={Boolean(
                                            installBillDetails.isAutoPay
                                        )}
                                    />
                                </div>
                                <div className="schedule-line text-center">
                                    Your full payment schedule is:
                                </div>
                                <div className="installment-wrapper">
                                    {isModalLoading && (
                                        <div className="d-flex justify-content-center mt-5">
                                            <ModalLoader />
                                        </div>
                                    )}
                                    {!isModalLoading &&
                                        installBillDetails &&
                                        installBillDetails.full_payment_schedule &&
                                        installBillDetails.full_payment_schedule.map(
                                            (data, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="installment-row"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between padding_left_right45 my-3">
                                                            <div className="d-flex align-items-center">
                                                                {/* <input type="radio"  className="radio-btn" defaultChecked={ idx == 0 && true } disabled name= 'account' /> */}
                                                                <form className="radio_form">
                                                                    <label
                                                                        className="radio_btn"
                                                                        htmlFor="radio"
                                                                    >
                                                                        <input
                                                                            type="radio"
                                                                            defaultChecked={
                                                                                data.due_amount_status ===
                                                                                'Paid'
                                                                            }
                                                                            name="account"
                                                                            disabled
                                                                            onClick={() =>
                                                                                handleSelectEmi(
                                                                                    data
                                                                                )
                                                                            }
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <div className="v-border"></div>
                                                                    </label>
                                                                </form>
                                                                <div className="d-flex flex-column">
                                                                    <span className="installment-label">
                                                                        {' '}
                                                                        {toOrdinalSuffix(
                                                                            idx +
                                                                                1
                                                                        )}{' '}
                                                                        installment{' '}
                                                                    </span>
                                                                    {data.due_amount_status ===
                                                                    'Paid' ? (
                                                                        <span className="paid-label">
                                                                            Paid
                                                                        </span>
                                                                    ) : (
                                                                        <span className="paid-label">
                                                                            Due:
                                                                            {moment(
                                                                                data.due_amount_date
                                                                            ).format(
                                                                                'l'
                                                                            )}
                                                                            {/* {data.due_amount_date} */}
                                                                            &nbsp;
                                                                            {Boolean(
                                                                                data.late
                                                                            ) && (
                                                                                <span className="text-danger">
                                                                                    (
                                                                                    {
                                                                                        data.late
                                                                                    }

                                                                                    )
                                                                                </span>
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                    {/* <a  className="paid-label underline" onClick={ ()=> viewReceiptFunc(data) }>View receipt</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column text-right">
                                                                <span className="paid-amount">
                                                                    {currencyFormatter.format(
                                                                        data.due_amount
                                                                    )}
                                                                </span>
                                                                {Boolean(
                                                                    data.late_fee
                                                                ) && (
                                                                    <span className="paid-amount text-danger">
                                                                        +
                                                                        {currencyFormatter.format(
                                                                            data.late_fee
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                            </div>
                            <button
                                className="btn next-btn"
                                onClick={closePaymentMethod}
                            >
                                Payment Options
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {/* {openReceipt && <ViewReceipt isOpen={ openReceipt } closeViewReceiptFunc={ closeViewReceiptFunc } receiptDetails= { receiptDetails } physicianDetails={ physicianDetails }/>} */}
            {paymentOption && (
                <SelectPaymentMethods
                    isOpen={paymentOption}
                    closePaymentMethod={closePaymentMethod}
                    physicianDetails={physicianDetails}
                    selectedPayDetails={selectedPayDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    history={history}
                    installBillDetails={installBillDetails}
                />
            )}
        </>
    )
}
CurrentRemaningPayDetails.propTypes = {
    openRemaningPayModal: PropTypes.bool,
    closeRemaningPaymentFunc: PropTypes.func,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    openRemaningPayDetailsModalDetails: PropTypes.object,
}
export default CurrentRemaningPayDetails
