import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { MdChevronRight } from 'react-icons/md'
import { BsCreditCard } from 'react-icons/bs'

import {
    getAccountExists,
    sendCustomerPatientAccount,
    getPatientCardListing,
} from 'actions/patientBankingActions'
import RemoveCard from 'components/account/mobile/RemoveCard'
import AddBankCard from 'components/account/AddBankCard'
import ModalLoader from 'shared/ModalLoader'
import { useHistory } from 'react-router-dom'
import PatientPaymentMobile from './PatientPaymentMobile'
const PatientLinkedCardsMobile = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isRemoveCard, setIsRemoveCard] = useState(false)
    const [isAddBankCard, setIsAddBankCard] = useState(true)
    const [isCardList, setIsCardList] = useState(false)
    const [isVisiable, setIsVisiable] = useState(false)
    const [isEmptyList, setIsEmptyList] = useState(false)
    const [isEmptyCardList, setIsEmptyCardList] = useState(false)
    const [
        isEmptyCardListFromRemainingBalance,
        setIsEmptyCardListFromRemainingBalance,
    ] = useState(false)
    const [removeCardDetails, setRemoveCardDetails] = useState({})

    // const { isLinkedCards } = props
    const patientCardListing = useSelector((state) => state.patientCardListing)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getAccountExists()).then((res) => {
            if (res.statusCode === 200) {
                if (!res.data.account_exists) {
                    dispatch(sendCustomerPatientAccount())
                } else {
                    dispatch(getPatientCardListing())
                }
            }
        })
        if (Object.keys(patientCardListing).length === 0) {
            setIsRemoveCard(false)
            setIsAddBankCard(true)
            setIsCardList(false)
        } else {
            setIsRemoveCard(false)
            setIsAddBankCard(false)
            setIsCardList(true)
        }
        // isLinkedCards === true ? setIsCardList(true) : setIsCardList(false);
    }, [''])
    const handleRemoveCard = (data) => {
        setIsRemoveCard(!isRemoveCard)
        setRemoveCardDetails(data)
    }
    const closeRemoveCard = () => {
        if (patientCardListing.cardList.length === 1) {
            setIsRemoveCard(false)
            setIsCardList(false)
            setIsEmptyList(true)
            // history.push('/patient-payments-mobile')
        } else {
            setIsRemoveCard(false)
        }
    }
    const closeRemoveCardPage = () => {
        setIsRemoveCard(false)
        setIsVisiable(false)
    }
    const addbankCardFunc = () => {
        setIsAddBankCard(!isAddBankCard)
        setIsCardList(!isCardList)
    }
    const addbankCardPageFunc = () => {
        setIsAddBankCard(!isAddBankCard)
        setIsEmptyList(true)
    }
    const addbankCardLinkFunc = () => {
        setIsCardList(!isCardList)
        setIsVisiable(!isVisiable)
    }
    const addbankCardBackFunc = () => {
        setIsCardList(!isCardList)
        setIsVisiable(!isVisiable)
    }
    const goToDebitCreditCardPageFunc = () => {
        setIsAddBankCard(!isAddBankCard)
        setIsEmptyList(true)
    }
    const goToDebitCreditCardPageFromPastDueFunc = () => {
        setIsAddBankCard(!isAddBankCard)
        setIsEmptyCardList(!isEmptyCardList)
    }
    const goToDebitCreditCardPageFromRemainingBalanceFunc = () => {
        setIsAddBankCard(!isAddBankCard)
        setIsEmptyCardListFromRemainingBalance(
            !isEmptyCardListFromRemainingBalance
        )
    }
    return (
        <>
            {Object.keys(patientCardListing).length === 0 ? (
                <>
                    {isAddBankCard && (
                        <div className="mobile_account_info payment-method-page">
                            <AddBankCard
                                addbankCardFunc={addbankCardFunc}
                                addbankCardPageFunc={addbankCardPageFunc}
                                totalBalancePage={props?.totalBalancePage}
                                goToDebitCreditCardPageFunc={
                                    goToDebitCreditCardPageFunc
                                }
                                goToPaymentMethodPageFunc={
                                    props?.goToPaymentMethodPageFunc
                                }
                                goToPaymentMethodPageFromPastDueFunc={
                                    props?.goToPaymentMethodPageFromPastDueFunc
                                }
                                remainingBalancePage={
                                    props.remainingBalancePage
                                }
                                goToDebitCreditCardPageFromPastDueFunc={
                                    goToDebitCreditCardPageFromPastDueFunc
                                }
                                pastDuePage={props.pastDuePage}
                                goToPayMethodPageFunc={
                                    props.goToPayMethodPageFunc
                                }
                                goToDebitCreditCardPageFromRemainingBalanceFunc={
                                    goToDebitCreditCardPageFromRemainingBalanceFunc
                                }
                            />
                        </div>
                    )}
                </>
            ) : (
                <>
                    {isCardList && !isRemoveCard && !isAddBankCard && (
                        <div className="mobile_account_info payment-method-page">
                            <div id="content">
                                <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                                    <CgChevronLeft
                                        className="back_icon"
                                        onClick={() =>
                                            history.push('/patient-account')
                                        }
                                    />
                                    <label className="nav-heading">
                                        Payment Methods
                                    </label>
                                </nav>
                                <div className="row no-gutters banking">
                                    <div className="col-md-12">
                                        <div className="page-label p-3">
                                            Linked Card(s)
                                        </div>
                                        {isLoading && (
                                            <div className="p-3">
                                                <ModalLoader />
                                            </div>
                                        )}
                                        <table className="table table-borderless office_info mb-0">
                                            {!isLoading &&
                                                patientCardListing &&
                                                patientCardListing.cardList.map(
                                                    (carList, id) => {
                                                        return (
                                                            <tbody
                                                                className="border-0 linked-accounts"
                                                                key={id}
                                                            >
                                                                <tr
                                                                    className="table-border"
                                                                    onClick={() =>
                                                                        handleRemoveCard(
                                                                            carList
                                                                        )
                                                                    }
                                                                >
                                                                    <td className="border-0 d-flex align-items-center table-row-padding">
                                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                                            <div className="d-flex align-items-center">
                                                                                <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                                                <div className="ml-3">
                                                                                    <div className="office_address">
                                                                                        {
                                                                                            carList.card_type
                                                                                        }{' '}
                                                                                        ****
                                                                                        {
                                                                                            carList.card_number
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <MdChevronRight className="float-right font24 mt-2 color_dark_grey" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                )}
                                        </table>
                                        <button
                                            className="btn btn-link px-3 py-2 add_element_link"
                                            onClick={addbankCardLinkFunc}
                                        >
                                            Add a card…
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {isRemoveCard && (
                <RemoveCard
                    removeCardDetails={removeCardDetails}
                    closeRemoveCard={closeRemoveCard}
                    closeRemoveCardPage={closeRemoveCardPage}
                    userRole="patient"
                />
            )}
            {isVisiable && (
                <div className="mobile_account_info payment-method-page">
                    <AddBankCard
                        addbankCardFunc={addbankCardBackFunc}
                        addbankCardBackFunc={addbankCardBackFunc}
                        isVisiable={isVisiable}
                    />
                </div>
            )}
            {isEmptyList && (
                <PatientPaymentMobile
                    totalBalancePage={'one'}
                    goTotalBalancePageFunc={props.goTotalBalancePageFunc}
                    remainingBalancePage={'back'}
                    goRemainingBalancePageFunc={
                        props.goRemainingBalancePageFunc
                    }
                />
            )}
            {isEmptyCardList && (
                <PatientPaymentMobile
                    pastDuePage={'pastDue'}
                    goPastDueDetailPageFunc={props.goPastDueDetailPageFunc}
                />
            )}
            {isEmptyCardListFromRemainingBalance && (
                <PatientPaymentMobile
                    remainingBalancePage={'yes'}
                    goToDebitCreditCardPageFromRemainingBalanceFunc={
                        goToDebitCreditCardPageFromRemainingBalanceFunc
                    }
                    goRemainingBalancePageFunc={
                        props.goRemainingBalancePageFunc
                    }
                />
            )}
        </>
    )
}
PatientLinkedCardsMobile.propTypes = {
    isLinkedCards: PropTypes.bool,
    handleLinkedCardsFunc: PropTypes.func,
    totalBalancePage: PropTypes.string,
    goToDebitCreditCardPageFunc: PropTypes.func,
    goTotalBalancePageFunc: PropTypes.func,
    goToPaymentMethodPageFunc: PropTypes.func,
    goToPaymentMethodPageFromPastDueFunc: PropTypes.func,
    remainingBalancePage: PropTypes.string,
    goRemainingBalancePageFunc: PropTypes.func,
    goPastDueDetailPageFunc: PropTypes.func,
    pastDuePage: PropTypes.string,
    goToPayMethodPageFunc: PropTypes.func,
}
export default PatientLinkedCardsMobile
