/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import {
    sendCardToken,
    sendErrorAction,
    getCardListing,
} from 'actions/bankingActions'
import BankSection from './BankSection'
import { isMobile } from 'constants/Constant'
import Loading from 'shared/Loader'
import SuccessAlert from 'shared/SuccessAlert'
class AddBankForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            loading: false,
            successAlert: false,
            successAlertMessage: '',
            failError: false,
            failErrorMessage: '',
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
            errors: {},
        })
    }
    handleSubmit = async (event) => {
        event.preventDefault()
        if (this.validate()) {
            const { stripe, elements, setToAccount } = this.props
            const { input } = this.state
            if (!stripe || !elements) {
                return
            }
            var bankAccountParams = {
                country: 'US',
                currency: 'USD',
                account_number: input.account_number,
                account_holder_name: input.account_holder_name,
                account_holder_type: input.account_holder_type,
                routing_number: input.routing_number,
            }
            this.setState({
                loading: true,
            })
            await stripe
                .createToken('bank_account', bankAccountParams)
                .then((res) => {
                    if (res.error) {
                        const data = {
                            error_message: res.error && res.error.message,
                        }
                        this.props.sendErrorAction(data)
                        this.setState({
                            failError: true,
                            failErrorMessage: res.error.message,
                            loading: false,
                        })
                    } else {
                        const card_id = { card_id: res.token.id }
                        this.props.sendCardToken(card_id).then((response) => {
                            if (response.statusCode === 200) {
                                this.setState({
                                    successAlert: true,
                                    successAlertMessage:
                                        'Your bank account has been added successfully',
                                })
                                this.props.closeModal()
                                this.setState({
                                    loading: false,
                                })
                                this.props.getCardListing()
                                setToAccount && setToAccount()
                            }
                        })
                    }
                })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
        this.props.closeModal()
    }
    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['account_holder_name']) {
            isValid = false
            errors['account_holder_name'] = 'Please enter account holder name'
        }
        if (!input['routing_number']) {
            isValid = false
            errors['routing_number'] = 'Please enter routing number'
        }
        if (!input['account_number']) {
            isValid = false
            errors['account_number'] = 'Please enter account number'
        }
        if (!input['confirm_account_number']) {
            isValid = false
            errors['confirm_account_number'] =
                'Please confirm your account number'
        }
        if (input['account_number'] && input['confirm_account_number']) {
            if (input['account_number'] !== input['confirm_account_number']) {
                isValid = false
                errors['confirm_account_number'] = 'Account number not matched'
            }
        }
        this.setState({
            errors: errors,
        })
        return isValid
    }

    render() {
        const {
            input,
            errors,
            successAlert,
            successAlertMessage,
            failError,
            failErrorMessage,
            loading,
        } = this.state
        const { isLoading } = this.props
        return (
            <>
                <div>
                    {isLoading || loading ? (
                        <div className="row no-gutters">
                            <div className="col-12 mt-5">
                                <Loading />
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={this.handleSubmit}>
                            <BankSection
                                handleChange={this.handleChange}
                                input={input}
                                errors={errors}
                                closeModal={this.props.closeModal}
                                failError={failError}
                                failErrorMessage={failErrorMessage}
                            />
                            {isMobile && (
                                <button
                                    className="btn mobile-green-btn"
                                    data-dismiss="modal"
                                    onClick={this.handleSubmit}
                                >
                                    Add Account
                                </button>
                            )}
                            {!isMobile && (
                                <button
                                    loading={isLoading}
                                    className="btn add_account_btn mt-3"
                                    data-dismiss="modal"
                                    onClick={this.handleSubmit}
                                >
                                    Add Account
                                </button>
                            )}
                        </form>
                    )}
                </div>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        sendCardToken: (id) => dispatch(sendCardToken(id)),
        sendErrorAction: (data) => dispatch(sendErrorAction(data)),
        getCardListing: () => dispatch(getCardListing()),
    }
}

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <AddBankForm stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
)

AddBankForm.propTypes = {
    elements: PropTypes.object,
    stripe: PropTypes.object,
    sendCardToken: PropTypes.func,
    sendErrorAction: PropTypes.func,
    getCardListing: PropTypes.func,
    isLoading: PropTypes.bool,
    closeModal: PropTypes.func,
    patient: PropTypes.bool,
    setToAccount: PropTypes.func,
}

export default connect(mapStateToProps, mapDispachToProps)(InjectedCheckoutForm)
