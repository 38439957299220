import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getOfficeInfo,
    addOfficeDetails,
    removeOfficeAddress,
} from 'actions/accountActions'
import { isMobile } from 'constants/Constant'
import validator from 'validator'
import { OFFICE_MODAL_STYLES } from 'constants/Constant'
import OfficeInfoList from './OfficeInfoList'
import SuccessAlert from 'shared/SuccessAlert'

export class OfficeInfo extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            setIsOpen: false,
            openDetails: false,
            address: '',
            input: {},
            errors: {},
            timeRange: {},
            isConfirmDelete: false,
            successAlert: false,
            successAlertMessage: '',
            restrict: false,
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.props.getOfficeInfo()
    }
    componentWillUnmount() {
        this._isMounted = false
        this.setState({ input: {} })
    }

    openModal = () => {
        const { isTeam, OfficeInfoData, accountDetails } = this.props
        if (isTeam) {
            if (
                OfficeInfoData.is_locked
                    ? OfficeInfoData.is_locked
                    : accountDetails.is_locked
            ) {
                this.setState({
                    setIsOpen: false,
                    successAlert: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.setState({ setIsOpen: true })
            }
        } else {
            this.setState({ setIsOpen: true })
        }
    }

    closeModal = () => {
        this.setState({ setIsOpen: false })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        if (this.validate()) {
            const input = {}
            this.setState({ input: input })
            const {
                address1,
                city,
                office_days,
                office_hours_start,
                office_hours_end,
                phone_number,
                speciality,
                state,
                zipcode,
                website,
            } = this.state.input
            const data = {
                address1: address1,
                city: city,
                office_days: office_days,
                office_hours: `${office_hours_start} AM-${office_hours_end} PM`,
                phone_number: phone_number,
                speciality: speciality,
                state: state,
                zipcode: zipcode,
                website: website,
            }
            this.props.addOfficeDetails(data).then((res) => {
                if (res) {
                    this.setState({
                        setIsOpen: false,
                        successAlert: true,
                        successAlertMessage:
                            'Office info has been added successfully',
                    })
                }
            })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.id === 'office_hours') {
            let obj = event.target.value
            var numbersTime = obj.replace(/\D/g, ''),
                charTime = { 2: ':' }
            obj = ''
            for (var j = 0; j < numbersTime.length; j++) {
                obj += (charTime[j] || '') + numbersTime[j]
                input[event.target.name] = obj
            }
        }
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }

    confirmOfficeRemove = (data) => {
        const id = data && data.id
        const { isTeam, OfficeInfoData } = this.props
        if (isTeam) {
            if (OfficeInfoData.is_locked) {
                this.setState({
                    setIsOpen: false,
                    successAlert: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            }
        } else {
            this.setState({
                isConfirmDelete: !this.state.isConfirmDelete,
                confirmEditId: id,
            })
        }
    }

    handleRemoveOffice = () => {
        const { isTeam, OfficeInfoData } = this.props
        const { confirmEditId } = this.state
        if (isTeam) {
            if (OfficeInfoData.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.props.removeOfficeAddress(confirmEditId).then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({ isConfirmDelete: false })
                    }
                })
            }
        } else {
            this.props.removeOfficeAddress(confirmEditId).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({ isConfirmDelete: false })
                }
            })
        }
    }

    openOfficeDetails = (data) => {
        const { isTeam, OfficeInfoData } = this.props
        if (isTeam) {
            if (OfficeInfoData.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.setState({
                    openDetails: !this.state.openDetails,
                    officeId: data && data.id,
                })
            }
        } else {
            this.setState({
                openDetails: !this.state.openDetails,
                officeId: data && data.id,
            })
        }
    }
    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['speciality']) {
            isValid = false
            errors['speciality'] = 'Please enter speciality'
        }
        if (typeof input['speciality'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z]+$/)
            if (!pattern.test(input['speciality'])) {
                isValid = false
                errors['speciality'] = 'Please enter alphabets only'
            }
        }

        if (!input['address1']) {
            isValid = false
            errors['address1'] = 'Please enter address'
        }

        if (!input['city']) {
            isValid = false
            errors['city'] = 'Please enter city'
        }
        if (typeof input['city'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['city'])) {
                isValid = false
                errors['city'] = 'Please enter alphabets only'
            }
        }
        if (!input['state']) {
            isValid = false
            errors['state'] = 'Please enter state'
        }
        if (typeof input['state'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['state'])) {
                isValid = false
                errors['state'] = 'Please enter alphabets only'
            }
        }

        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter zipcode'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone_number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['office_hours_start']) {
            isValid = false
            errors['office_hours_start'] = 'Please enter office hours'
        }
        if (!input['office_hours_end']) {
            isValid = false
            errors['office_hours_end'] = 'Please enter office hours'
        }

        if (input['website'] && !validator.isURL(input['website'])) {
            isValid = false
            errors['website'] = 'Please enter valid url'
        }

        if (!input['office_days']) {
            isValid = false
            errors['office_days'] = 'Please enter office days'
        }
        if (typeof input['office_days'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z -]+$/)
            if (!pattern.test(input['office_days'])) {
                isValid = false

                errors['office_days'] = 'Please enter in monday-friday format.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    disableFunc = (event) => {
        event.preventDefault()
        this.setState({ setIsOpen: !this.state.setIsOpen })
    }

    render() {
        const enabled = Object.keys(this.state.input).length > 8
        const { successAlert, successAlertMessage, restrict } = this.state
        return (
            <>
                {!isMobile && (
                    <OfficeInfoList
                        state={this.state}
                        props={this.props}
                        enabled={enabled}
                        openOfficeDetails={this.openOfficeDetails}
                        handleRemoveOffice={this.handleRemoveOffice}
                        confirmOfficeRemove={this.confirmOfficeRemove}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        openModal={this.openModal}
                        closeModal={this.closeModal}
                        disableFunc={this.disableFunc}
                        OFFICE_MODAL_STYLES={OFFICE_MODAL_STYLES}
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        restrict={restrict ? true : false}
                        role={restrict ? false : 'Office'}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        OfficeInfoData: state.OfficeInfo,
        accountDetails: state.accountDetails,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOfficeInfo: () => dispatch(getOfficeInfo()),
        addOfficeDetails: (data) => dispatch(addOfficeDetails(data)),
        removeOfficeAddress: (id) => dispatch(removeOfficeAddress(id)),
    }
}

OfficeInfo.propTypes = {
    history: PropTypes.object,
    details: PropTypes.object,
    getOfficeInfo: PropTypes.func,
    addOfficeDetails: PropTypes.func,
    removeOfficeAddress: PropTypes.func,
    OfficeInfoData: PropTypes.object,
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    accountDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeInfo)
