import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getTeams,
    getTitles,
    addTeam,
    removeTeamMember,
    getTeamMemberInfo,
    updateTeamMemberInfo,
    checkValidEmail,
} from 'actions/accountActions';
import Loading from 'shared/Loader';
import Modal from 'react-modal';
import moment from 'moment';
import { dateListing, MODAL_STYLES, monthListing, yearListing } from 'constants/Constant';
import AddTeamModal from './AddTeamModal';
import { EditTeamMember } from './EditTeamMember';
import ConfirmDelete from 'shared/ConfirmDelete';
import SuccessAlert from 'shared/SuccessAlert';
export class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setIsOpen: false,
            input: {},
            errors: {},
            roles: [],
            value: '',
            suggestions: [],
            editTeam: false,
            isConfirmDelete: false,
            successAlert: false,
            successAlertMessage: '',
            restrict: false,
        }
        this.closeModal = this.closeModal.bind(this)
        this.getSuggestions = this.getSuggestions.bind(this)
        this.onValueChange = this.onValueChange.bind(this)
        this.onSuggestionsFetchRequested =
            this.onSuggestionsFetchRequested.bind(this)
        this.onSuggestionsClearRequested =
            this.onSuggestionsClearRequested.bind(this)
        this.openModal = this.openModal.bind(this)
        this.validate = this.validate.bind(this)
        this.handleRemoveTeam = this.handleRemoveTeam.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.confirmTeamRemove = this.confirmTeamRemove.bind(this)
        this.submitTeamData = this.submitTeamData.bind(this)
    }

    componentDidMount() {
        this.props.getTeams()
        this.props.getTitles().then((res) => {
            if (res) {
                this.setState({ roles: res })
            }
        })
    }
    closeModal() {
        this.setState({ setIsOpen: false })
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length
        return inputLength === 0
            ? []
            : this.state &&
                  this.state.roles &&
                  this.state.roles.filter(
                      (lang) =>
                          lang.role_name.toLowerCase().slice(0, inputLength) ===
                          inputValue
                  )
    }

    getSuggestionValue = (suggestion) => suggestion.role_name

    renderSuggestion = (suggestion) => <div>{suggestion.role_name}</div>

    onValueChange(event, { newValue }) {
        this.setState({
            value: newValue,
        })
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        })
    }

    openModal() {
        const { isTeam, teams } = this.props
        if (isTeam) {
            if (teams.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.setState({ setIsOpen: true })
            }
        } else {
            this.setState({ setIsOpen: true })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    handleChange(event) {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }

        this.setState({
            input,
            errors: {},
        })
    }

    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter first name'
        }
        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter last name'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['dateName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['monthName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['yearName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your zipcode.'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }

        if (this.state.value === '') {
            isValid = false
            errors['speciality'] = 'Please enter title'
        }
        if (this.state.value !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(this.state.value)) {
                isValid = false

                errors['speciality'] = 'Please enter alphabets only.'
            }
            if (this.state.value.toLowerCase() == 'physician') {
                isValid = false
                errors['speciality'] =
                    "You can't assign physician role to team member."
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    confirmTeamRemove(data) {
        const id = data && data.team_id
        const { isTeam, teams } = this.props
        if (isTeam) {
            if (teams.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.setState({ setIsOpen: true })
            }
        } else {
            this.setState({
                isConfirmDelete: !this.state.isConfirmDelete,
                confirmEditId: id,
            })
        }
        //   this.setState({
        //       isConfirmDelete: !this.state.isConfirmDelete,
        //       confirmEditId: id,
        //   });
    }

    handleRemoveTeam() {
        //   const id = data && data.team_id;
        const { isTeam, teams } = this.props
        const { confirmEditId } = this.state
        if (isTeam) {
            if (teams.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.props.removeTeamMember(confirmEditId).then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({ isConfirmDelete: false })
                    }
                })
            }
        } else {
            this.props.removeTeamMember(confirmEditId).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({ isConfirmDelete: false })
                }
            })
        }
    }

    submitTeamData(event) {
        event.preventDefault()
        const check_email_data = {
            email_id: this.state.input.email_id,
        }

      if (this.validate()) {
          const input = {};
          this.setState({ input: input, value: '' });
          const obj = this.state.input;
          obj[ 'speciality' ] = this.state.value;
          this.setState({ setIsOpen: false });
          const { first_name , last_name , phone_number , zipcode , email_id , dateName, monthName, yearName } = this.state.input
          const newData = {
              first_name: first_name,
              last_name: last_name,
              phone_number: phone_number,
              email_id: email_id,
              zipcode: zipcode,
              date_of_birth: `${ monthName }/${ dateName }/${ yearName }`,
              speciality: this.state.value,
          }
          const errors = {};
          this.props.checkValidEmail(check_email_data).then((resValidEmail) => {
              if (resValidEmail.statusCode === 200) {
                  if (resValidEmail.data.is_valid) {
                      this.props.addTeam(newData).then((res) => {
                          if (res.statusCode === 200 ) {
                              this.setState({ setIsOpen: false, successAlert: true });
                          }
                      });
                  } else {
                      // email not valid
                      errors[ 'email_id' ] = 'Please enter valid email address.';
                  }
              } else {
                  // email not valid
                  var errResponse = resValidEmail.response;
                  if (errResponse && errResponse.status === 400) {
                      errors[ 'email_id' ] = 'Please enter valid email address.';
                  }
              }
              this.setState({
                  errors: errors,
              });
          });

      }
  }
  closeSuccessAlert = () => {
      this.setState({ successAlert: false })
  }
  openEditTeam = (data) => {
      const { isTeam, teams } = this.props;
      if (isTeam) {
          if (teams.is_locked) {
              this.setState({ successAlert: true, restrict: true, successAlertMessage: 'Please ask your physician to unlock profile' })
          } else {
              this.setState({
                  editTeam: !this.state.editTeam,
                  editTeamId: data && data.team_id,
              });
          }
      } else {
          this.setState({
              editTeam: !this.state.editTeam,
              editTeamId: data && data.team_id,
          });
      }
  };
  disabledDate = (current) => {
      return current && current > moment().endOf('day');
  };
  render() {
      const {
          setIsOpen,
          input,
          errors,
          value,
          suggestions,
          editTeam,
          roles,
          editTeamId,
          isConfirmDelete,
          successAlert,
          successAlertMessage,
          restrict
      } = this.state;
      const { isLoading, teams } = this.props;
      const enabled =
      Object.keys(input).length > 3 &&
      this.state.value.length > 0 &&
      input.phone_number &&
      input.phone_number.length === 14;
      const inputProps = {
          placeholder: 'Enter title',
          value,
          onChange: this.onValueChange,
      // onKeyPress: this.onKeyPress,
      };
      const monthList =
      monthListing.map((month, idx) => {
          return (
              <option
                  key={ idx }
                  id={ month }
                  name={ month }
                  value={ idx + 1 }
              >
                  {month}
              </option>
          );
      });
      const yearList =
      yearListing.map((year, idx) => {
          return (
              <option
                  key={ idx }
                  id={ year }
                  name={ year }
                  value={ year }
              >
                  {year}
              </option>
          );
      });
      const dateList =
      dateListing.map((date, idx) => {
          return (
              <option
                  key={ idx }
                  id={ date }
                  name={ date }
                  value={ date }
              >
                  {date}
              </option>
          );
      });
      return (
          <div
              className="team"
          >
              {!editTeam && (
                  <div>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="d-flex">
                                  <h2 className="tab_heading">Team Members</h2>
                              </div>
                          </div>
                      </div>
                      {isLoading && <div className="row no-gutters"><div className="col-12 mt-5"><Loading /></div></div>}
                      {!isLoading && (
                          <div className="row">
                              <div className="col-md-5">
                                  <table className="table table-borderless office_info">
                                      {teams.finalTeamMember &&
                      teams.finalTeamMember.slice(1) &&
                      teams.finalTeamMember.slice(1).map((data, i) => {
                          return (
                              <tbody key={ i } className="border-0">
                                  <tr>
                                      <td className="px-0 border-0">
                                          <div className="office_name">
                                              {data.full_name}
                                          </div>
                                          <div className="office_address">
                                              {data.speciality}
                                          </div>
                                      </td>
                                      <td className="px-0 border-0 text-center d-flex align-items-center">
                                          <a
                                              className="edit-link"
                                              onClick={ this.openEditTeam.bind(this, data) }
                                          >
                                              {' '}
                                              Edit{' '}
                                          </a>
                                          <button
                                              className="btn remove_element_btn d-none d-md-block d-lg-block d-xl-block"
                                              onClick={ this.confirmTeamRemove.bind(
                                                  this,
                                                  data
                                              ) }
                                          >
                                              Remove
                                          </button>
                                          <i
                                              className="fa fa-angle-right float-right d-md-none d-lg-none d-xl-none"
                                              aria-hidden="true"
                                          ></i>
                                      </td>
                                  </tr>
                              </tbody>
                          );
                      })}
                                  </table>
                                  <button
                                      className="btn btn-link pl-0 add_element_link"
                                      data-toggle="modal"
                                      data-target="#newMemberModal"
                                      onClick={ this.openModal }
                                  >
                                      Add new team member…
                                  </button>
                              </div>
                          </div>
                      )}
                  </div>
              )}
              {setIsOpen && (
                  <div>
                      <Modal
                          isOpen={ setIsOpen }
                          onRequestClose={ this.closeModal }
                          style={ MODAL_STYLES }
                          contentLabel="Example Modal"
                          ariaHideApp={ false }
                          id= 'team-member-modal'
                      >
                          <button
                              type="button"
                              className="close modal_close_icon"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={ this.closeModal }
                          >
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <AddTeamModal
                              handleChange={ this.handleChange }
                              submitTeamData={ this.submitTeamData }
                              inputProps={ inputProps }
                              enabled={ enabled }
                              input={ input }
                              errors={ errors }
                              disabledDate={ this.disabledDate }
                              suggestions={ suggestions }
                              onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
                              onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                              getSuggestionValue={ this.getSuggestionValue }
                              renderSuggestion={ this.renderSuggestion }
                              monthList = { monthList }
                              dateList = { dateList }
                              yearList = { yearList }
                          />
                      </Modal>
                  </div>
              )}
              {editTeam && (
                  <EditTeamMember
                      openEditTeam={ this.openEditTeam }
                      editTeamId={ editTeamId }
                      roles={ roles }
                      //   disabledDate={ this.disabledDate }
                      getTeamMemberInfo={ this.props.getTeamMemberInfo }
                      updateTeamMemberInfo={ this.props.updateTeamMemberInfo }
                      checkValidEmail={ this.props.checkValidEmail }
                      isLoading={ isLoading }
                      errors={ errors }
                  />
              )}
              {isConfirmDelete && (
                  <ConfirmDelete
                      isConfirmDelete={ isConfirmDelete }
                      confirmRemove={ this.confirmTeamRemove }
                      handleRemoveFunc={ this.handleRemoveTeam }
                      role="team member"
                  />
              )}
              {successAlert && <SuccessAlert isOpen={ successAlert } closeSuccessAlert ={ this.closeSuccessAlert } restrict= { restrict ? true : false } role={ restrict ? false : 'Team' } message={ successAlertMessage ? successAlertMessage : 'The team member has been added.' } description='The team member can now use their phone number to sign into Pompay.'/>}
          </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        teams: state.teams,
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeams: () => dispatch(getTeams()),
        getTitles: () => dispatch(getTitles()),
        addTeam: (data) => dispatch(addTeam(data)),
        removeTeamMember: (id) => dispatch(removeTeamMember(id)),
        updateTeamMemberInfo: (id, data) =>
            dispatch(updateTeamMemberInfo(id, data)),
        getTeamMemberInfo: (id) => dispatch(getTeamMemberInfo(id)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

Team.propTypes = {
    getTeams: PropTypes.func,
    getTitles: PropTypes.func,
    addTeam: PropTypes.func,
    getTeamMemberInfo: PropTypes.func,
    removeTeamMember: PropTypes.func,
    updateTeamMemberInfo: PropTypes.func,
    checkValidEmail: PropTypes.func,
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    teams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
