import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getTeams,
    getTitles,
    addTeam,
    removeTeamMember,
    getTeamMemberInfo,
    updateTeamMemberInfo,
    checkValidEmail,
} from 'actions/accountActions'
import Loading from 'shared/Loader'
import moment from 'moment'
import Autosuggest from 'react-autosuggest'
import { MdChevronRight } from 'react-icons/md'
import { EditTeamMember } from '../team/EditTeamMember'
import { CgChevronLeft } from 'react-icons/cg'
import ConfirmDelete from 'shared/ConfirmDelete'
import SuccessAlert from 'shared/SuccessAlert'
import { maxLengthCheck } from 'utils/helper'
import { dateListing, monthListing, yearListing } from 'constants/Constant'

export class TeamMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setIsOpen: false,
            input: {},
            errors: {},
            roles: [],
            value: '',
            suggestions: [],
            editTeam: false,
            isConfirmDelete: false,
            successAlert: false,
            successAlertMessage: '',
            restrict: false,
        }
        this.closeModal = this.closeModal.bind(this)
        this.getSuggestions = this.getSuggestions.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSuggestionsFetchRequested =
            this.onSuggestionsFetchRequested.bind(this)
        this.onSuggestionsClearRequested =
            this.onSuggestionsClearRequested.bind(this)
        this.openModal = this.openModal.bind(this)
        this.validate = this.validate.bind(this)
        this.handleRemoveTeam = this.handleRemoveTeam.bind(this)
        this.confirmTeamRemove = this.confirmTeamRemove.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.props.getTeams()
        this.props.getTitles().then((res) => {
            if (res) {
                this.setState({ roles: res })
            }
        })
    }
    closeModal() {
        this.setState({ setIsOpen: false })
    }
    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length
        return inputLength === 0
            ? []
            : this.state &&
                  this.state.roles &&
                  this.state.roles.filter(
                      (lang) =>
                          lang.role_name.toLowerCase().slice(0, inputLength) ===
                          inputValue
                  )
    }

    getSuggestionValue = (suggestion) => suggestion.role_name

    renderSuggestion = (suggestion) => <div>{suggestion.role_name}</div>

    onChange(event, { newValue }) {
        this.setState({
            value: newValue,
        })
    }
    onSuggestionsFetchRequested({ value }) {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        })
    }

    openModal() {
        const { isTeam, teams } = this.props
        if (isTeam) {
            if (teams.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            }
        } else {
            this.setState({ setIsOpen: true })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false, successAlertMessage: '' })
    }
    handleChange(event) {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }
    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter first name'
        }
        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter last name'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['dateName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['monthName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['yearName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
            if (this.state.value.toLowerCase() == 'physician') {
                isValid = false
                errors['speciality'] =
                    "You can't assign physician role to team member."
            }
        }

        if (this.state.value === '') {
            isValid = false
            errors['speciality'] = 'Please enter title'
        }
        if (this.state.value !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(this.state.value)) {
                isValid = false

                errors['speciality'] = 'Please enter alphabets only.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    confirmTeamRemove() {
        this.setState({
            isConfirmDelete: !this.state.isConfirmDelete,
        })
    }

    handleRemoveTeam() {
        const { editTeam, editTeamId } = this.state
        const id = editTeamId
        const { isTeam, teams } = this.props
        if (isTeam) {
            if (teams.is_locked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            }
        } else {
            this.props.removeTeamMember(id).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({
                        editTeam: !editTeam,
                        isConfirmDelete: false,
                    })
                }
            })
        }
    }

    submitTeamData = (event) => {
        event.preventDefault()
        const check_email_data = {
            email_id: this.state.input.email_id,
        }
        if (this.validate()) {
            const input = {}
            input['first_name'] = ''
            input['last_name'] = ''
            input['phone_number'] = ''
            input['email_id'] = ''
            this.setState({ input: input, value: '' })
            const obj = this.state.input
            obj['speciality'] = this.state.value
            this.setState({ setIsOpen: false })
            const {
                first_name,
                last_name,
                phone_number,
                zipcode,
                email_id,
                dateName,
                monthName,
                yearName,
            } = obj
            const newData = {
                first_name: first_name,
                last_name: last_name,
                phone_number: phone_number,
                email_id: email_id,
                zipcode: zipcode,
                date_of_birth: `${monthName}/${dateName}/${yearName}`,
                speciality: this.state.value,
            }
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            this.props.addTeam(newData).then((res) => {
                                if (res) {
                                    this.setState({ setIsOpen: false })
                                }
                            })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }

    openEditTeam = (data) => {
        this.setState({
            editTeam: !this.state.editTeam,
            editTeamId: data && data.team_id,
        })
    }
    disabledDate = (current) => {
        return current && current > moment().endOf('day')
    }

    render() {
        const {
            setIsOpen,
            input,
            errors,
            value,
            suggestions,
            editTeam,
            roles,
            editTeamId,
            isConfirmDelete,
            successAlert,
            successAlertMessage,
            restrict,
        } = this.state
        const { isLoading, teams } = this.props
        const enabled =
            Object.keys(input).length > 3 &&
            this.state.value.length > 0 &&
            input.phone_number &&
            input.phone_number.length === 14
        const inputProps = {
            placeholder: 'Enter title',
            value,
            onChange: this.onChange,
        }
        const monthList = monthListing.map((month, idx) => {
            return (
                <option key={idx} id={month} name={month} value={idx + 1}>
                    {month}
                </option>
            )
        })
        const yearList = yearListing.map((year, idx) => {
            return (
                <option key={idx} id={year} name={year} value={year}>
                    {year}
                </option>
            )
        })
        const dateList = dateListing.map((date, idx) => {
            return (
                <option key={idx} id={date} name={date} value={date}>
                    {date}
                </option>
            )
        })
        return (
            <>
                {!editTeam && !setIsOpen && (
                    <div className="mobile_account_info" id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() =>
                                    this.props.history.push(
                                        '/account-homepage-mobile'
                                    )
                                }
                            />
                            <label className="nav-heading">Team</label>
                            <span></span>
                        </nav>
                        <div>
                            <div
                                className="tab-pane fade show active team"
                                id="nav-team"
                                role="tabpanel"
                                aria-labelledby="nav-team-tab"
                            >
                                {isLoading && (
                                    <div className="p-3">
                                        <Loading />
                                    </div>
                                )}
                                {!isLoading && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <table className="table table-borderless office_info mb-0">
                                                {teams.finalTeamMember &&
                                                    teams.finalTeamMember.slice(
                                                        1
                                                    ) &&
                                                    teams.finalTeamMember
                                                        .slice(1)
                                                        .map((data, i) => {
                                                            return (
                                                                <tbody
                                                                    key={i}
                                                                    className="border-0"
                                                                >
                                                                    <tr
                                                                        className="tabel-row-border"
                                                                        onClick={this.openEditTeam.bind(
                                                                            this,
                                                                            data
                                                                        )}
                                                                    >
                                                                        <td className="border-0">
                                                                            <div className="office_name">
                                                                                {
                                                                                    data.full_name
                                                                                }
                                                                            </div>
                                                                            <div className="office_address">
                                                                                {
                                                                                    data.speciality
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-0 text-center">
                                                                            <MdChevronRight
                                                                                className="float-right font24 mt-2 color_dark_grey"
                                                                                onClick={this.openEditTeam.bind(
                                                                                    this,
                                                                                    data
                                                                                )}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })}
                                            </table>
                                            <button
                                                className="btn btn-link add_element_link add-new-link"
                                                onClick={this.openModal}
                                            >
                                                Add team member…
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {/* {isLoading && <Loading />} */}
                {setIsOpen && (
                    <div className="mobile_account_info new-team-page">
                        <div id="content">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => this.closeModal()}
                                />
                                <label className="nav-heading">
                                    Add Team Member
                                </label>
                                <span></span>
                            </nav>
                            <form>
                                <div className="form-row flex-column add-team-form">
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={
                                                        input.first_name || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    autoComplete="off"
                                                    placeholder="Enter first name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="first_name"
                                                >
                                                    First name
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.first_name}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={
                                                        input.last_name || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter last name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="last_name"
                                                >
                                                    Last name
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.last_name}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <fieldset className="input-group-prepend">
                                                <select
                                                    className="form-control dob-field month-field rounded-0"
                                                    name="monthName"
                                                    onChange={this.handleChange}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        Month
                                                    </option>
                                                    {monthList}
                                                </select>
                                                <select
                                                    className="form-control dob-field rounded-0"
                                                    name="dateName"
                                                    onChange={this.handleChange}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        Date
                                                    </option>
                                                    {dateList}
                                                </select>
                                                <select
                                                    className="form-control dob-field rounded-right"
                                                    name="yearName"
                                                    onChange={this.handleChange}
                                                    style={{
                                                        borderRadius: '0px',
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        Year
                                                    </option>
                                                    {yearList}
                                                </select>
                                                <label
                                                    className="input-group-text account_info_label rounded-left"
                                                    htmlFor="dob"
                                                >
                                                    Date of birth
                                                </label>
                                            </fieldset>
                                            {/* <fieldset className="input-group-prepend">
                                                <Space direction="vertical">
                                                    <DatePicker
                                                        className="form-control account_info_field dob-field"
                                                        format={ dateFormat }
                                                        onChange={ this.handleChange }
                                                        placeholder="Date of birth"
                                                        id="dob"
                                                        disabledDate={ this.disabledDate }
                                                    />
                                                </Space>
                                                <label className="input-group-text account_info_label" htmlFor="dob">
                                                    Date of birth
                                                </label>
                                            </fieldset> */}
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.date_of_birth}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    maxLength="14"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    value={
                                                        input.phone_number || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="(xxx) xxx-xxxx"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="phone_number"
                                                >
                                                    Mobile number
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.phone_number}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="email"
                                                    name="email_id"
                                                    id="email_id"
                                                    value={input.email_id || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter email"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="email_id"
                                                >
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.email_id}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    maxLength="5"
                                                    name="zipcode"
                                                    id="zipcode"
                                                    value={input.zipcode || ''}
                                                    onChange={this.handleChange}
                                                    onInput={maxLengthCheck}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter email"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="email_id"
                                                >
                                                    Zipcode
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.zipcode}
                                        </small>
                                    </div>
                                    <div className="col-sm-12 my-2">
                                        <div className="input-group">
                                            <fieldset className="input-group-prepend">
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={
                                                        this
                                                            .onSuggestionsFetchRequested
                                                    }
                                                    onSuggestionsClearRequested={
                                                        this
                                                            .onSuggestionsClearRequested
                                                    }
                                                    getSuggestionValue={
                                                        this.getSuggestionValue
                                                    }
                                                    renderSuggestion={
                                                        this.renderSuggestion
                                                    }
                                                    inputProps={inputProps}
                                                    className="form-control account_info_field"
                                                />
                                                <div className="input-group-text account_info_label">
                                                    Title
                                                </div>
                                            </fieldset>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.speciality}
                                        </small>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="px-3 pb-3">
                            <button
                                type="submit"
                                className="btn mobile-green-btn"
                                onClick={this.submitTeamData}
                                disabled={!enabled}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                )}
                {editTeam && (
                    <EditTeamMember
                        openEditTeam={this.openEditTeam}
                        handleRemoveTeam={this.handleRemoveTeam}
                        confirmTeamRemove={this.confirmTeamRemove}
                        editTeamId={editTeamId}
                        roles={roles}
                        getTeamMemberInfo={this.props.getTeamMemberInfo}
                        updateTeamMemberInfo={this.props.updateTeamMemberInfo}
                        checkValidEmail={this.props.checkValidEmail}
                        isLoading={isLoading}
                        errors={errors}
                    />
                )}
                {isConfirmDelete && (
                    <ConfirmDelete
                        isConfirmDelete={isConfirmDelete}
                        confirmRemove={this.confirmTeamRemove}
                        handleRemoveFunc={this.handleRemoveTeam}
                        role="team member"
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        restrict={restrict ? true : false}
                        role={restrict ? false : 'Guidelines'}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        teams: state.teams,
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeams: () => dispatch(getTeams()),
        getTitles: () => dispatch(getTitles()),
        addTeam: (data) => dispatch(addTeam(data)),
        removeTeamMember: (id) => dispatch(removeTeamMember(id)),
        updateTeamMemberInfo: (id, data) =>
            dispatch(updateTeamMemberInfo(id, data)),
        getTeamMemberInfo: (id) => dispatch(getTeamMemberInfo(id)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

TeamMobile.propTypes = {
    history: PropTypes.object,
    getTeams: PropTypes.func,
    getTitles: PropTypes.func,
    addTeam: PropTypes.func,
    getTeamMemberInfo: PropTypes.func,
    removeTeamMember: PropTypes.func,
    updateTeamMemberInfo: PropTypes.func,
    checkValidEmail: PropTypes.func,
    isLoading: PropTypes.bool,
    teams: PropTypes.object,
    isTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMobile)
