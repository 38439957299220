import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'antd/dist/antd.css'
import {
    getPatientUserDetails,
    updatePatientUserDetails,
} from 'actions/patientAccountActions'
import { checkValidEmail } from 'actions/accountActions'
import { deactivatePatientAction } from 'actions/patientActions'
import Loading from 'shared/Loader'
import { maxLengthCheck } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'
import ConfirmDelete from 'shared/ConfirmDelete'

export class PatientAccountDetails extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            successAlert: false,
            isConfirmDelete: false,
            disableKey: false,
            //   failError: false
        }
    }
    componentDidMount() {
        this._isMounted = true
        this.props.getPatientUserDetails().then((res) => {
            if (res) {
                this.setState({
                    input: res ? res : [],
                })
            }
        })
    }
    componentWillUnmount() {
        this._isMounted = false
        this.setState({ input: [] })
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
            //   failError: false
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const input = this.state.input
        const id = input.patient_id
        const data = {
            first_name: input.first_name,
            last_name: input.last_name,
            date_of_birth: input.date_of_birth,
            phone_number: input.phone_number,
            email_id: input.email_id,
            zipcode: input.zipcode,
            patient_appointment_id: input.patient_appointment_id,
        }
        const check_email_data = {
            email_id: input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            this.props
                                .updatePatientUserDetails(id, data)
                                .then((res) => {
                                    if (res.statusCode === 200) {
                                        this.setState({ successAlert: true })
                                    }
                                })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter your first name.'
        }

        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }

        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter your last name.'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your phone number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (typeof input['phone_number'] !== 'undefined') {
            const pattern = new RegExp(/^[- +()]*[0-9][- +()0-9]*$/)
            if (!pattern.test(input['phone_number'])) {
                isValid = false

                errors['phone_number'] = 'Please enter valid numbers'
            }
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your owner passcode.'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Owner passcode should be of 5 digit.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    deActivateAccount = (event) => {
        event && event.preventDefault()
        this.setState({ isConfirmDelete: !this.state.isConfirmDelete })
    }
    handleRemoveProfile = () => {
        this.props.deactivatePatientAction()
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }
    disableFunc = (event) => {
        event.preventDefault()
        this.setState({ disableKey: !this.state.disableKey })
    }

    render() {
        const { isLoading } = this.props
        const { input, errors, successAlert, isConfirmDelete, disableKey } =
            this.state
        const enabled = disableKey
        return (
            <>
                <div
                    className="tab-pane fade show active patient-account-page"
                    id="nav-account"
                    role="tabpanel"
                    aria-labelledby="nav-account-tab"
                >
                    {/* {failError && (
                      <div className="alert alert-danger" role="alert">
                          Please provide unique email id, this email is already used by someone else.
                      </div>
                  )} */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2 className="tab_heading">
                                    Account Information
                                </h2>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="row no-gutters">
                            <div className="col-12 mt-5">
                                <Loading />
                            </div>
                        </div>
                    )}
                    {!isLoading && (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="mb-5">
                                        <div className="form-row flex-column">
                                            <div style={{ height: '0px' }}>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                name="first_name"
                                                                type="text"
                                                                id="fname"
                                                                className="form-control account_info_field"
                                                                placeholder="Enter your first name"
                                                                value={
                                                                    input.first_name ||
                                                                    ''
                                                                }
                                                                disabled={
                                                                    enabled
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="fname"
                                                            >
                                                                First name
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.first_name ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                name="last_name"
                                                                type="text"
                                                                id="lname"
                                                                className="form-control account_info_field"
                                                                placeholder="Enter your last name"
                                                                value={
                                                                    input.last_name ||
                                                                    ''
                                                                }
                                                                disabled={
                                                                    enabled
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="lname"
                                                            >
                                                                Last name
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.last_name ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                disabled
                                                                name="date_of_birth"
                                                                type="text"
                                                                id="dob"
                                                                className="form-control account_info_field"
                                                                placeholder="Enter date of birth"
                                                                defaultValue={
                                                                    input.date_of_birth ||
                                                                    ''
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="dob"
                                                            >
                                                                Date of birth
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.date_of_birth ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                name="phone_number"
                                                                type="text"
                                                                id="mobi-number"
                                                                maxLength="14"
                                                                className="form-control account_info_field"
                                                                placeholder="Enter mobile number"
                                                                value={
                                                                    input.phone_number ||
                                                                    ''
                                                                }
                                                                disabled={
                                                                    enabled
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="mobi-number"
                                                            >
                                                                Mobile number
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.phone_number ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                name="email_id"
                                                                id="email"
                                                                type="text"
                                                                className="form-control account_info_field"
                                                                placeholder="Enter email id"
                                                                value={
                                                                    input.email_id ||
                                                                    ''
                                                                }
                                                                disabled={
                                                                    enabled
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="email"
                                                            >
                                                                Email
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.email_id ||
                                                                ''}
                                                        </small>
                                                        {/* {failError && <small className="text-danger">
                                                          Please provide unique email id, this email is already used by someone else.
                                                      </small>} */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <input
                                                                name="zipcode"
                                                                id="zipcode"
                                                                maxLength="5"
                                                                onInput={
                                                                    maxLengthCheck
                                                                }
                                                                type="number"
                                                                className="form-control account_info_field"
                                                                value={
                                                                    input.zipcode ||
                                                                    ''
                                                                }
                                                                disabled={
                                                                    enabled
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <label
                                                                className="input-group-text account_info_label"
                                                                htmlFor="zipcode"
                                                            >
                                                                Zipcode
                                                            </label>
                                                        </div>
                                                        <small className="text-danger">
                                                            {errors.zipcode ||
                                                                ''}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0 link-wrapper">
                                                    <button
                                                        onClick={
                                                            this
                                                                .deActivateAccount
                                                        }
                                                        className="btn btn-link mt-4 delete-link"
                                                    >
                                                        Deactivate My Account
                                                    </button>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0">
                                                    <div className="my-5 text-left">
                                                        <button
                                                            className="btn custom_btn green_btn"
                                                            type="submit"
                                                            onClick={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className="btn btn-link cancel-link"
                                                            onClick={
                                                                this.disableFunc
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>{' '}
                        </>
                    )}
                </div>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        role="Profile"
                        message="Your profile info has been updated"
                    />
                )}
                {isConfirmDelete && (
                    <ConfirmDelete
                        isConfirmDelete={isConfirmDelete}
                        confirmRemove={this.deActivateAccount}
                        handleRemoveFunc={this.handleRemoveProfile}
                        role="Profile"
                        mode="Profile"
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientUserDetails: () => dispatch(getPatientUserDetails()),
        deactivatePatientAction: () => dispatch(deactivatePatientAction()),
        updatePatientUserDetails: (id, data) =>
            dispatch(updatePatientUserDetails(id, data)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

PatientAccountDetails.propTypes = {
    getPatientUserDetails: PropTypes.func,
    updatePatientUserDetails: PropTypes.func,
    deactivatePatientAction: PropTypes.func,
    checkValidEmail: PropTypes.func,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientAccountDetails)
