import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { maxLengthCheck, onlyNumber } from 'utils/helper'
import { adminVerifyOtp } from 'actions/adminActions'
import UpdateAdminNumber from './UpdateAdminNumber'
import { sendOtpEmail } from 'actions/authActions'

function AdminOtpModal(props) {
    const dispatch = useDispatch()
    const { isOpen, closeOtpModal, history, mobileNumber, emailId } = props
    const [verificationOtp, setVerificationOtp] = useState('')
    const [updateMobileNumber, setUpdateMobileNumber] = useState('')
    const handleChange = (event) => {
        setVerificationOtp(event.target.value)
        event.preventDefault()
        if (event.target.value.length === 4) {
            const data = {
                verification_otp: event.target.value,
                phone_number: mobileNumber,
            }
            const emailData = {
                verification_otp: event.target.value,
                email_id: emailId,
                phone_number: mobileNumber || '',
            }
            emailId
                ? setTimeout(() => {
                      dispatch(sendOtpEmail(emailData)).then((res) => {
                          if (res) {
                              setUpdateMobileNumber(true)
                          }
                      })
                  }, 500)
                : setTimeout(() => {
                      dispatch(adminVerifyOtp(data, history))
                  }, 500)
        }
    }
    return (
        <>
            {!updateMobileNumber && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeOtpModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="admin-sign-in-modal"
                >
                    <button
                        onClick={closeOtpModal}
                        type="button"
                        className="close"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="sign-otp admin-dashboard"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                        >
                            <div className="modal-content text-center">
                                <div className="modal-body">
                                    <h4>We just texted you</h4>
                                    <p>
                                        Please enter the verification code we
                                        just sent to {mobileNumber}
                                    </p>
                                    <input
                                        maxLength="4"
                                        autoFocus
                                        onInput={maxLengthCheck}
                                        className="otp_text_field"
                                        name="verification_otp"
                                        autoComplete="off"
                                        value={verificationOtp || ''}
                                        onChange={handleChange}
                                        onKeyDown={(event) => onlyNumber(event)}
                                        placeholder="----"
                                        data-dismiss="modal"
                                    />
                                    {/* <a
                                    // onClick={ () => history.push('/admin-dashboard') }
                                >
                                    Didn’t get a code?
                                </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {updateMobileNumber && (
                <UpdateAdminNumber
                    updateMobileNumber={updateMobileNumber}
                    history={history}
                    emailId={emailId}
                />
            )}
        </>
    )
}

AdminOtpModal.propTypes = {
    closeOtpModal: PropTypes.func,
    isOpen: PropTypes.bool,
    history: PropTypes.object,
    mobileNumber: PropTypes.string,
    emailId: PropTypes.string,
}

export default AdminOtpModal
