import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { calculateTransactionFee, currencyFormatter } from 'utils/helper'
import ThankYouModal from 'shared/ThankYouModal'
import { sendTransferFundData } from 'actions/patientBankingActions'
import { getTransferFundsData } from 'actions/patientBankingActions'
import { getPatientOutstandingBalance } from 'actions/patientBankingActions'
import SuccessAlert from 'shared/SuccessAlert'
import { triggerNotifier } from 'shared/notifier'

function FinalFullAmountToPay(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closeFinalPayAmount,
        physicianDetails,
        selectedCardDetails,
        selectedPayDetails,
        history,
        installBillDetails,
        paymentActivityData,
    } = props
    const [isComplete, setIsComplete] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [closeAllModal, setCloseAllModal] = useState(false)
    const transferFundData = useSelector((state) => state.transferFundData)
    // const lateFees = installBillDetails.full_payment_schedule[ 0 ] && installBillDetails.full_payment_schedule[ 0 ].late_fee ? installBillDetails.full_payment_schedule[ 0 ].late_fee : 0
    // const totalFees = installBillDetails.full_payment_schedule[ 0 ] && installBillDetails.full_payment_schedule[ 0 ].due_amount
    // const discountAmount = installBillDetails.full_payment_schedule[ 0 ] && installBillDetails.full_payment_schedule[ 0 ].discount_amount
    const paidFees = paymentActivityData?.total_balance
    // const originalAmount = parseFloat(totalFees) + parseFloat(discountAmount)
    // console.log("originalAmount",originalAmount)
    const transactionFee = calculateTransactionFee(paidFees)
    const totalAmount = transactionFee + paidFees
    useEffect(() => {
        dispatch(getTransferFundsData())
        dispatch(getPatientOutstandingBalance())
    }, [''])

    const confirmPayFunc = () => {
        // const data = {
        //     card_id: selectedCardDetails.card_id,
        //     physician_id: physicianDetails.physician_id,
        //     payment_schedule_id: installBillDetails.full_payment_schedule[ 0 ].payment_schedule_id,
        //     stripe_account_id: physicianDetails && physicianDetails.stripe_account_id,
        //     due_amount: paidFees,
        //     patient_payment_id: openRemaningPayDetailsModalDetails.patient_payment_id,
        //     month : installBillDetails.full_payment_schedule[ 0 ].month
        // }
        var result =
            transferFundData &&
            transferFundData.map((data) => ({
                amount: data.totalInstallment,
                physician_id: data.id,
                account_id: data.stripe_account_id,
                payment_schedules: data.payment_schedules,
            }))
        const data = {
            physician_detail: result,
            card_id: selectedCardDetails.card_id,
        }
        if (physicianDetails && physicianDetails.stripe_account_id) {
            dispatch(sendTransferFundData(data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsComplete(!isComplete)
                }
            })
        } else {
            const message = 'Selected physician has not yet added bank account'
            triggerNotifier({ type: 'error', message })
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert()
    }
    const closeConfirmPayFunc = () => {
        setIsComplete(!isComplete)
        setCloseAllModal(true)
    }
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy
    return (
        <>
            {isOpen && !isComplete && !closeAllModal && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeFinalPayAmount}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    id="receipt-modal"
                    ariaHideApp={false}
                >
                    <div className="modal-dialog modal-dialog-centered web-payment-details">
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeFinalPayAmount()}
                                />
                                <label className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </label>
                                <span></span>
                            </div>
                            <div className="modal-body text-center p-0">
                                <div className="modal-header-border total-amount-content mt-3">
                                    <span className="total-amount">
                                        Total Paid
                                    </span>
                                    <h3 className="amount">
                                        {currencyFormatter.format(totalAmount)}
                                    </h3>
                                </div>
                                <div className="details-info-wrapper">
                                    <label className="details-label mt-4 mb-3">
                                        Payment detail:
                                    </label>
                                    <div className="mt-4">
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Physician
                                            </span>
                                            <span className="data">
                                                {physicianDetails.physician_name ||
                                                    physicianDetails.physician_full_name}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">Date</span>
                                            <span className="data">
                                                {formattedToday}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Bill amount
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    paidFees || 0
                                                )}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Transaction fee
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    transactionFee
                                                )}
                                            </span>
                                        </div>
                                        {/* <div className="d-flex justify-content-between my-4">
                                        <span className="data">Discount applied</span>
                                        <span className="data">{currencyFormatter.format(  installBillDetails.full_payment_schedule[ 0 ].discount_amount || 0 )}</span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">Late fee</span>
                                        <span className="data">{currencyFormatter.format(lateFees)}</span>
                                    </div> */}
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">From</span>
                                            <span className="data">
                                                {selectedCardDetails.card_type}{' '}
                                                ****
                                                {
                                                    selectedCardDetails.card_number
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="bold-data">
                                                Total
                                            </span>
                                            <span className="bold-data">
                                                {currencyFormatter.format(
                                                    totalAmount
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn next-btn"
                                onClick={confirmPayFunc}
                            >
                                Pay {currencyFormatter.format(totalAmount)}
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {isComplete && !closeAllModal && (
                <ThankYouModal
                    isComplete={isComplete}
                    closeConfirmPayFunc={closeConfirmPayFunc}
                    selectedCardDetails={selectedCardDetails}
                    physicianDetails={physicianDetails}
                    selectedPayDetails={selectedPayDetails}
                    history={history}
                    installBillDetails={installBillDetails}
                    paidFees={totalAmount}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message="Payment fail please check your details"
                    description="please check payment details and amount"
                />
            )}
        </>
    )
}
FinalFullAmountToPay.propTypes = {
    closeFinalPayAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    selectedCardDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    openRemaningPayDetailsModalDetails: PropTypes.object,
    selectedPayDetails: PropTypes.object,
    paymentActivityData: PropTypes.object,
}
export default FinalFullAmountToPay
