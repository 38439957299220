import React from 'react'
import PropTypes from 'prop-types'
import { MdPersonOutline } from 'react-icons/md'
import { FaStethoscope } from 'react-icons/fa'
import { RiDashboardLine } from 'react-icons/ri'
import { AiOutlineTeam } from 'react-icons/ai'
import { FaMoneyCheck } from 'react-icons/fa'

function AdminSideBarNavigation(props) {
    const { history } = props
    const pathUrl = window.location.pathname
    return (
        <div className="nav-left-sidebar sidebar-dark admin-dashboard">
            <div className="menu-list">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="d-xl-none d-lg-none" href="#">
                        Dashboard
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-item text-left">
                                <a
                                    className={
                                        pathUrl === '/admin-dashboard'
                                            ? 'nav-link d-flex align-items-center active'
                                            : 'nav-link d-flex align-items-center'
                                    }
                                    onClick={() =>
                                        history.push('/admin-dashboard')
                                    }
                                >
                                    <RiDashboardLine className="font24 mr-2" />{' '}
                                    Dashboard
                                </a>
                            </li>
                            <li className="nav-item text-left">
                                <a
                                    className={
                                        pathUrl === '/admin-physician'
                                            ? 'nav-link d-flex align-items-center active'
                                            : 'nav-link d-flex align-items-center'
                                    }
                                    onClick={() =>
                                        history.push('/admin-physician')
                                    }
                                >
                                    <FaStethoscope className="font18 mr-2 ml-1" />{' '}
                                    Physician
                                </a>
                            </li>
                            <li className="nav-item text-left">
                                <a
                                    className={
                                        pathUrl === '/admin-patient'
                                            ? 'nav-link d-flex align-items-center active'
                                            : 'nav-link d-flex align-items-center'
                                    }
                                    onClick={() =>
                                        history.push('/admin-patient')
                                    }
                                >
                                    <MdPersonOutline className="font24 mr-2" />{' '}
                                    Patient
                                </a>
                            </li>
                            <li className="nav-item text-left">
                                <a
                                    className={
                                        pathUrl === '/admin-team-member'
                                            ? 'nav-link d-flex align-items-center active'
                                            : 'nav-link d-flex align-items-center'
                                    }
                                    onClick={() =>
                                        history.push('/admin-team-member')
                                    }
                                >
                                    <AiOutlineTeam className="font24 mr-2" />{' '}
                                    Team Member
                                </a>
                            </li>
                            <li className="nav-item text-left">
                                <a
                                    className={
                                        pathUrl === '/admin-transaction'
                                            ? 'nav-link d-flex align-items-center active'
                                            : 'nav-link d-flex align-items-center'
                                    }
                                    onClick={() =>
                                        history.push('/admin-transaction')
                                    }
                                >
                                    <FaMoneyCheck className="font24 mr-2" />
                                    All Transactions{' '}
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

AdminSideBarNavigation.propTypes = {
    history: PropTypes.object,
}

export default AdminSideBarNavigation
