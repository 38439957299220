import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { BiTachometer } from 'react-icons/bi'
import { MdCreditCard, MdRateReview } from 'react-icons/md'
import { IoMdPeople, IoMdNotifications } from 'react-icons/io'
import { IoPaperPlaneSharp } from 'react-icons/io5'
import { getAccountDetails, getTeamDetails } from 'actions/accountActions'
import { getTotalAmount } from 'actions/bankingActions'

function SidebarNavigation(props) {
    const pathUrl = window.location.pathname
    const dispatch = useDispatch()
    const isTeam = useSelector((state) => state.teamMember)
    useEffect(() => {
        isTeam ? dispatch(getTeamDetails()) : dispatch(getAccountDetails())
        dispatch(getTotalAmount())
    }, [''])
    const accountDetails = useSelector((state) => state.accountDetails)
    const accountHolderName =
        accountDetails && accountDetails.physician
            ? accountDetails.physician[0].first_name
            : accountDetails.physician_team &&
              accountDetails.physician_team.first_name
    const accountProfilePic =
        accountDetails && accountDetails.physician
            ? accountDetails.physician[0].avatar
            : accountDetails.physician_team &&
              accountDetails.physician_team.avatar
    return (
        <nav
            id="sidebar"
            className="d-flex flex-column justify-content-between"
        >
            <ul className="list-unstyled components mt-4">
                <li>
                    <a
                        href="/physician-dashboard"
                        className={
                            pathUrl === '/physician-dashboard' ? 'active' : ''
                        }
                    >
                        <BiTachometer className="react_font" />
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/payments"
                        className={pathUrl === '/payments' ? 'active' : ''}
                    >
                        <MdCreditCard className="react_font" />
                        <span>Payments</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/review-arrangement"
                        className={
                            pathUrl === '/review-arrangement' ? 'active' : ''
                        }
                    >
                        <MdRateReview className="arrangements-icon" />
                        <span>New Arrangements</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/patient-list"
                        className={pathUrl === '/patient-list' ? 'active' : ''}
                    >
                        <IoMdPeople className="react_font" />
                        <span>Patient List</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/notification"
                        className={pathUrl === '/notification' ? 'active' : ''}
                    >
                        <IoPaperPlaneSharp className="message-icon" />
                        <span>Message Center</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/physician-notification"
                        className={
                            pathUrl === '/physician-notification'
                                ? 'active'
                                : ''
                        }
                    >
                        <IoMdNotifications className="react_font" />
                        <span>Notifications</span>
                    </a>
                </li>
            </ul>
            <ul className="list-unstyled components">
                {accountHolderName && (
                    <li
                        className="setting-icon"
                        onClick={() => props.history.push('/account')}
                    >
                        <Avatar
                            name={accountHolderName}
                            size="40"
                            className="pointer"
                            round={true}
                            src={accountProfilePic}
                        />
                        <span className="setting-lbl">Settings</span>
                    </li>
                )}
            </ul>
        </nav>
    )
}

SidebarNavigation.propTypes = {
    history: PropTypes.object,
}

export default withRouter(SidebarNavigation)
