import jwtdecode from 'jwt-decode'

export function applicationIsLoading(state = false, action) {
    switch (action.type) {
        case 'APPLICATION_IS_LOADING':
            return action.isLoading
        default:
            return state
    }
}
export function modalIsLoading(state = false, action) {
    switch (action.type) {
        case 'MODAL_IS_LOADING':
            return action.isModalLoading
        default:
            return state
    }
}
export function currentUser(
    state = sessionStorage.accessToken
        ? jwtdecode(sessionStorage.accessToken)
        : {},
    action
) {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return action.currentUser
        default:
            return state
    }
}
