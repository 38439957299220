export function isAuthenticated(
    state = sessionStorage.accessToken ? true : false,
    action
) {
    switch (action.type) {
        case 'IS_AUTHENTICATED':
            return action.isAuthenticated
        default:
            return state
    }
}

export function otpModalOpen(state = false, action) {
    switch (action.type) {
        case 'OTP_MODAL_OPEN':
            return action.otpModalOpen
        default:
            return state
    }
}

export function teamMember(state = false, action) {
    switch (action.type) {
        case 'FETCH_TEAM_MEMBER':
            return action.teamMember
        default:
            return state
    }
}

export function updateOtpValue(state = false, action) {
    switch (action.type) {
        case 'UPDATE_OTP_MODAL':
            return action.updateOtpValue
        default:
            return state
    }
}

export function formData(state = [], action) {
    switch (action.type) {
        case 'SAVE_FORM_DATA':
            return action.formData
        default:
            return state
    }
}

export function emailRedirectionKey(state = [], action) {
    switch (action.type) {
        case 'EMAIL_REDIRECTION_KEYS':
            return action.emailRedirectionKey
        default:
            return state
    }
}

export function redirectionData(state = [], action) {
    switch (action.type) {
        case 'REDIRECTION_KEYS':
            return action.redirectionData
        default:
            return state
    }
}

export function verifyEmailDetails(state = [], action) {
    switch (action.type) {
        case 'SAVE_VERIFY_EMAIL_DATA':
            return action.verifyEmailDetails
        default:
            return state
    }
}

export function isOtpCorrect(state = false, action) {
    switch (action.type) {
        case 'VERIFICATION_OTP_ERROR':
            return action.isOtpCorrect
        default:
            return state
    }
}

export function isvalidPasscode(state = false, action) {
    switch (action.type) {
        case 'FETCH_VALID_PASSCODE_ERROR':
            return action.isvalidPasscode
        default:
            return state
    }
}

export function userAccountDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_USER_ACCOUNT_DETAILS':
            return action.userAccountDetails
        default:
            return state
    }
}

export function lockData(state = false, action) {
    switch (action.type) {
        case 'LOCK_DETAILS':
            return action.lockData
        default:
            return state
    }
}
