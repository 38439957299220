import React from 'react'
import { IoMdPeople } from 'react-icons/io'
import { IoMdSettings } from 'react-icons/io'
import { MdHome } from 'react-icons/md'

const PatientSidebarNavigation = () => {
    const pathUrl = window.location.pathname
    return (
        <nav
            id="sidebar"
            className="d-flex flex-column justify-content-between"
        >
            <ul className="list-unstyled components mt-4">
                <li className="active">
                    <a
                        href="/home"
                        className={pathUrl === '/home' ? 'active' : ''}
                    >
                        <MdHome className="react_font" />
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a
                        href="/physician-list-view"
                        className={
                            pathUrl === '/physician-list-view' ? 'active' : ''
                        }
                    >
                        <IoMdPeople className="react_font" />
                        <span>Physician list</span>
                    </a>
                </li>
            </ul>
            <ul className="list-unstyled components">
                <li>
                    <a
                        href="/patient-account"
                        className={
                            pathUrl === '/patient-account' ? 'active' : ''
                        }
                    >
                        <IoMdSettings className="react_font" />
                        <span>Settings</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default PatientSidebarNavigation
