import React from 'react'
import PropTypes from 'prop-types'
import '../../../static/stylesheets/auth.scss'
import PompayTopLogo from 'shared/PompayTopLogo'
import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo'
import ProfileForm from './ProfileForm'

function PatientBasicProfile(props) {
    return (
        <>
            <div className="create_page_background"></div>
            <nav className="navbar navbar-light">
                <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block" />
                <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
            </nav>
            <div className="container-fluid basic_info_page">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-6 p-0 text-center d-none d-md-block d-lg-block d-xl-block">
                        <div className="top_margin">
                            <h4 className="auth-heading mb-3">
                                Already have an account?
                            </h4>
                            <p className="auth-content">
                                Sign in to enjoy all the benefits Pompay <br />
                                provides to optimize the financial experience.
                            </p>
                            <button
                                type="button"
                                className="btn white_btn custom_btn"
                                onClick={() => history.push('/')}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center flex-column align-items-center p-0">
                        <ProfileForm history={props.history} />
                    </div>
                </div>
            </div>
        </>
    )
}

PatientBasicProfile.propTypes = {
    history: PropTypes.object,
}

export default PatientBasicProfile
