export function cardModal(state = false, action) {
    switch (action.type) {
        case 'CARD_MODAL':
            return action.cardModal
        default:
            return state
    }
}

export function isStripeAccount(state = false, action) {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_STRIPE_ACCOUNT':
            return action.isStripeAccount
        default:
            return state
    }
}

export function cardListing(state = [], action) {
    switch (action.type) {
        case 'FETCH_CARD_LISTING':
            return action.cardListing
        default:
            return state
    }
}

export function transDetails(state = [], action) {
    switch (action.type) {
        case 'FETCH_TRANS_DETAILS':
            return action.transDetails
        default:
            return state
    }
}
export function totalAmountStripe(state = {}, action) {
    switch (action.type) {
        case 'FETCH_TOTAL_AMOUNT':
            return action.totalAmountStripe
        default:
            return state
    }
}
