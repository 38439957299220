import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import { calculateTransactionFee, currencyFormatter } from 'utils/helper'
// import { singlePaymentAction } from 'actions/patientBankingActions';
import PatientSuccessReceipt from './PatientSuccessReceipt'
import { sendTransferFundData } from 'actions/patientBankingActions'
import { getTransferFundsData } from 'actions/patientBankingActions'
import { getPatientOutstandingBalance } from 'actions/patientBankingActions'
import SuccessAlert from 'shared/SuccessAlert'

function FinalFullAmountToPayMobile(props) {
    const dispatch = useDispatch()
    const {
        closeFinalPayAmount,
        physicianDetails,
        selectedCardDetails,
        history,
        paymentActivityData,
        globalPayment,
    } = props
    const [isComplete, setIsComplete] = useState(false)
    const [paidAmount, setPaidAmount] = useState(0)
    const [successAlert, setSuccessAlert] = useState(false)
    const transferFundData = useSelector((state) => state.transferFundData)
    const patientCardListData = useSelector((state) => state.patientCardListing)
    const patientOutstandingBalance = useSelector(
        (state) => state.patientOutstandingBalance
    )
    if (globalPayment) {
        //To get total amount
        // var totalAmount = 0;
        // for (let i = 0; i < physicianDetails.length; i++) {
        //     totalAmount += parseFloat(physicianDetails[ i ].paidFees);
        // }
        //to get unique physician name
        const physicianUniqueNameArray = Array.from(
            new Set(physicianDetails.map((a) => a.physician_name))
        ).map((physician_name) => {
            return physicianDetails.find(
                (a) => a.physician_name === physician_name
            )
        })
        var physicianNameData = physicianUniqueNameArray.map(
            (letter) => letter.physician_name
        )
    }
    const paidFees = paymentActivityData?.total_balance

    useEffect(() => {
        dispatch(getTransferFundsData())
        dispatch(getPatientOutstandingBalance())
    }, [''])

    const billAmount = patientOutstandingBalance.total_remaining_balance || 0

    const transactionFee = calculateTransactionFee(billAmount)

    const totalAmount = billAmount + transactionFee

    useEffect(() => {
        if (isComplete) {
            setPaidAmount(totalAmount)
        }
    }, [isComplete])

    const confirmPayFunc = () => {
        var result =
            transferFundData &&
            transferFundData.map((data) => ({
                amount: data.totalInstallment,
                physician_id: data.id,
                account_id: data.stripe_account_id,
                payment_schedules: data.payment_schedules,
            }))
        const data = {
            physician_detail: result,
            card_id: selectedCardDetails.card_id,
        }
        if (patientCardListData.stripe_customer_id) {
            dispatch(sendTransferFundData(data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsComplete(!isComplete)
                } else {
                    setSuccessAlert(true)
                }
            })
        } else {
            alert('Selected physician has not yet added bank account')
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy

    return (
        <>
            {!isComplete && (
                <div className="bill_details mobile-bill-details mobile_account_info mobireceipt-page">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon"
                                onClick={() => closeFinalPayAmount()}
                            />
                            <label className="nav-heading">
                                Make a payment
                            </label>
                        </nav>
                        <div className="mobile-content-height">
                            <div className="heading_section d-flex flex-column text-center mt-3">
                                <span className="main-balanceheading">
                                    Total Amount
                                </span>
                                {globalPayment ? (
                                    <span className="main-amount">
                                        {currencyFormatter.format(totalAmount)}
                                    </span>
                                ) : (
                                    <span className="main-amount">
                                        {currencyFormatter.format(
                                            paidFees || 0
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className="details-info-wrapper">
                                <div className="my-4 text-center">
                                    <span className="content-heading">
                                        Payment Details
                                    </span>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">Physician</span>
                                        {globalPayment ? (
                                            <span>
                                                {physicianNameData.map(
                                                    (data, index) => (
                                                        <span key={index}>
                                                            {data}
                                                            {index <
                                                            physicianNameData.length -
                                                                1
                                                                ? ','
                                                                : ''}
                                                        </span>
                                                    )
                                                )}{' '}
                                            </span>
                                        ) : (
                                            <span className="data">
                                                {physicianDetails.physician_name ||
                                                    physicianDetails.physician_full_name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">Date</span>
                                        <span className="data">
                                            {formattedToday}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">
                                            Bill amount
                                        </span>
                                        {globalPayment ? (
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    patientOutstandingBalance.total_remaining_balance ||
                                                        0
                                                )}
                                            </span>
                                        ) : (
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    paidFees || 0
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">
                                            Transaction fee
                                        </span>
                                        <span className="data">
                                            {currencyFormatter.format(
                                                transactionFee
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">From</span>
                                        <span className="data">
                                            {selectedCardDetails.card_type} ****
                                            {selectedCardDetails.card_number}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="bold-data">Total</span>
                                        {globalPayment ? (
                                            <span className="bold-data">
                                                {currencyFormatter.format(
                                                    totalAmount
                                                )}
                                            </span>
                                        ) : (
                                            <span className="bold-data">
                                                {currencyFormatter.format(
                                                    paidFees || 0
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={confirmPayFunc}
                            >
                                Pay{' '}
                                {globalPayment ? (
                                    <>{currencyFormatter.format(totalAmount)}</>
                                ) : (
                                    <>
                                        {currencyFormatter.format(
                                            paidFees || 0
                                        )}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isComplete && (
                <PatientSuccessReceipt
                    selectedCardDetails={selectedCardDetails}
                    history={history}
                    patientSuccessReceiptPage={isComplete}
                    outStandingBalance={globalPayment ? paidAmount : paidFees}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message="Payment failed"
                />
            )}
        </>
    )
}
FinalFullAmountToPayMobile.propTypes = {
    closeFinalPayAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    selectedCardDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    selectedPayDetails: PropTypes.object,
    globalPayment: PropTypes.bool,
    paymentActivityData: PropTypes.object,
}
export default FinalFullAmountToPayMobile
