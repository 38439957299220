import React from 'react'
import PropTypes from 'prop-types'
// import Modal from 'react-modal';
import OfficeAddressDetails from './OfficeAddressDetails';
import Loading from 'shared/Loader';
import AddOfficeInfoModal from './AddOfficeInfoModal';
import ConfirmDelete from 'shared/ConfirmDelete';
function OfficeInfoList(newData) {
    const {
        state: {
            openDetails,
            setIsOpen,
            input,
            errors,
            accountDetails,
            officeId,
            isConfirmDelete,
        },
        props: { OfficeInfoData, isLoading },
        handleChange,
        handleRemoveOffice,
        disableFunc,
        confirmOfficeRemove,
        handleSubmit,
        openModal,
        openOfficeDetails,
        enabled,
        closeModal,
        // OFFICE_MODAL_STYLES
    } = newData
    return (
        <>
            <div
                className="officeInfo"
                // id="nav-officeInfo"
                // role="tabpanel"
                // aria-labelledby="nav-officeInfo-tab"
            >
                {!openDetails && !setIsOpen && (
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex">
                                    <h2 className="tab_heading">Office Information</h2>
                                </div>
                            </div>
                        </div>
                        {isLoading && (
                            <div className="row no-gutters">
                                <div className="col-12 mt-5">
                                    <Loading />
                                </div>
                            </div>
                        )}
                        {!isLoading && (
                            <div className="row">
                                <div className="col-md-5">
                                    <table className="table table-borderless office_info">
                                        {OfficeInfoData &&
                                            OfficeInfoData.physician_office &&
                                            OfficeInfoData.physician_office.map(
                                                (data, id) => {
                                                    return (
                                                        <tbody
                                                            key={id}
                                                            className="border-0"
                                                        >
                                                            <tr>
                                                                <td className="px-0 border-0">
                                                                    <div className="office_name open-off-page">
                                                                        {
                                                                            data.address1
                                                                        }
                                                                    </div>
                                                                    <div className="office_address">
                                                                        {
                                                                            data.city
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="px-0 border-0 text-center d-flex align-items-center">
                                                                    <a
                                                                        className="edit-link"
                                                                        onClick={() =>
                                                                            openOfficeDetails(
                                                                                data
                                                                            )
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        Edit{' '}
                                                                    </a>
                                                                    <button
                                                                        className="btn remove_element_btn d-none d-md-block d-lg-block d-xl-block"
                                                                        onClick={() =>
                                                                            confirmOfficeRemove(
                                                                                data
                                                                            )
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                    <i
                                                                        className="fa fa-angle-right float-right d-md-none d-lg-none d-xl-none"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </td>
                                                                {/* <td className="px-0 text-center border-0">
                                          <a onClick={ () => openOfficeDetails(data) }>
                                              {' '}
                                              edit{' '}
                                          </a>
                                      </td>
                                      <td className="px-0 text-center border-0">
                                          <button
                                              className="btn remove_element_btn ml-auto d-none d-md-block d-lg-block d-xl-block"
                                              onClick={ () => handleRemoveOffice(data) }
                                          >
                                              Remove
                                          </button>
                                          <i
                                              className="fa fa-angle-right float-right d-md-none d-lg-none d-xl-none"
                                              aria-hidden="true"
                                          ></i>
                                      </td> */}
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }
                                            )}
                                    </table>
                                    <button
                                        className="btn btn-link pl-0 add_element_link"
                                        onClick={openModal}
                                        data-toggle="modal"
                                        // data-target="#addOfficeLocationModal"
                                    >
                                        Add new office location…
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {setIsOpen && (
                    <AddOfficeInfoModal
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        input={input}
                        errors={errors}
                        enabled={enabled}
                        closeModal={closeModal}
                        disableFunc={disableFunc}
                    />
                )}
            </div>
            {openDetails && (
                <OfficeAddressDetails
                    openOfficeDetails={openOfficeDetails}
                    accountDetails={accountDetails}
                    officeId={officeId}
                />
            )}
            {isConfirmDelete && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={confirmOfficeRemove}
                    handleRemoveFunc={handleRemoveOffice}
                    role="office"
                    mode="office"
                />
            )}
        </>
    )
}

OfficeInfoList.propTypes = {
    history: PropTypes.object,
    OfficeInfoData: PropTypes.object,
}

export default OfficeInfoList
