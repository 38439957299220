import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    sendBasicInformation,
    sendVerifiedData,
    checkDuplicateEmail,
} from '../../actions/authActions'
import { checkValidEmail } from '../../actions/accountActions'
import 'antd/dist/antd.css'
import moment from 'moment'
import PompayTopLogo from '../../shared/PompayTopLogo'
import PompayTopWhiteLogo from '../../shared/PompayTopWhiteLogo'
import BasicInfo from './forms/BasicInfo'
import VerificationForm from './forms/VerificationForm'
import ProcessRegModal from 'shared/ProcessRegModal'
import { CgChevronLeft } from 'react-icons/cg'
import {
    dateListing,
    isMobile,
    monthListing,
    yearListing,
} from 'constants/Constant'

export class BasicInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            verifiedInput: {},
            verifiedErrors: {},
            errors: {},
            addClass: false,
            inProcessRegModal: false,
            duplicateError: false,
            badEmail: false,
        }
    }

    handleVerifiedChange = (event) => {
        const verifiedInput = this.state.verifiedInput
        verifiedInput[event.target.name] = event.target.value
        this.setState({
            verifiedInput,
            verifiedErrors: {},
        })
    }
    enterEmailFunc = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        const data = {
            email_id: input.email_id,
        }
        this.props.checkValidEmail(data).then((res) => {
            if (res.statusCode === 200) {
                if (res.statusCode === 200) {
                    this.setState({
                        badEmail: !res.data.is_valid,
                    })
                } else {
                    var errResponse = res.response
                    if (errResponse && errResponse.status === 400) {
                        this.setState({
                            badEmail: true,
                        })
                    } else {
                        this.setState({
                            badEmail: false,
                        })
                    }
                }
            }
        })
        this.props.checkDuplicateEmail(data).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    duplicateError: res.data.is_email_id_exits ? true : false,
                })
            } else {
                this.setState({
                    duplicateError: false,
                })
            }
        })
    }

    handleChange = (event, date) => {
        const input = this.state.input
        // else {
        if (date) {
            input['date_of_birth'] = date
        } else if (event === null) {
            input['date_of_birth'] = date
        } else {
            input[event.target.name] = event.target.value
        }
        // }

        this.setState({
            input,
            //   duplicateError: false,
            errors: {},
        })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter your first name.'
        }

        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }

        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter your last name.'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (input['email_id'] && this.state.duplicateError) {
            isValid = false
            errors['email_id'] = 'Email already registered'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }

        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id']) || this.state.badEmail) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }
        if (!input['dateName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['monthName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        if (!input['yearName']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your date of birth.'
        }
        //   if (!input[ 'date_of_birth' ]) {
        //       isValid = false;
        //       errors[ 'date_of_birth' ] = 'Please enter your date of birth.';
        //   }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your zipcode.'
        }

        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }

    validationVerified = () => {
        const verifiedInput = this.state.verifiedInput
        const verifiedErrors = {}
        let isValid = true
        if (!verifiedInput['speciality']) {
            isValid = false
            verifiedErrors['speciality'] = 'Please enter your speciality.'
        }
        if (typeof verifiedInput['speciality'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(verifiedInput['speciality'])) {
                isValid = false

                verifiedErrors['speciality'] = 'Please enter alphabets only.'
            }
        }
        if (!verifiedInput['npi_number']) {
            isValid = false
            verifiedErrors['npi_number'] = 'Please enter your NPI number.'
        }
        if (typeof verifiedInput['npi_number'] !== 'undefined') {
            const pattern = new RegExp(/^[0-9]+$/)
            if (!pattern.test(verifiedInput['npi_number'])) {
                isValid = false
                verifiedErrors['npi_number'] = 'Please enter numbers only'
            }
        }
        if (!verifiedInput['tax_id']) {
            isValid = false
            verifiedErrors['tax_id'] = 'Please enter your Tax I.D.'
        }

        this.setState({
            verifiedErrors: verifiedErrors,
        })

        return isValid
    }

    handleVerifiedSubmit = (event) => {
        event.preventDefault()
        if (this.validationVerified()) {
            this.props.sendVerifiedData(
                this.state.verifiedInput,
                this.props.history
            )
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const {
            first_name,
            last_name,
            email_id,
            zipcode,
            dateName,
            yearName,
            monthName,
        } = this.state.input
        if (this.validate()) {
            const newData = {
                first_name: first_name,
                last_name: last_name,
                email_id: email_id,
                zipcode: zipcode,
                date_of_birth: `${monthName}/${dateName}/${yearName}`,
            }
            this.props.sendBasicInformation(newData).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({ addClass: true })
                } else {
                    this.setState({ addClass: false })
                }
            })
        }
    }
    disabledDate = (current) => {
        return current && current > moment().endOf('day')
    }
    handleProcessModal = () => {
        const { inProcessRegModal } = this.state
        this.setState({ inProcessRegModal: !inProcessRegModal })
    }
    closeInProcessModal = () => {
        const { inProcessRegModal } = this.state
        this.setState({ inProcessRegModal: !inProcessRegModal })
    }
    render() {
        const {
            errors,
            input,
            verifiedInput,
            verifiedErrors,
            addClass,
            inProcessRegModal,
            duplicateError,
            badEmail,
        } = this.state
        const { redirectionData } = this.props
        const enabled = Object.keys(input).length > 4
        const enabledVerified = Object.keys(verifiedInput).length > 2
        const errorsCheck = Object.keys(errors).length > 0
        const addNewClass = this.props.redirectionData.is_profile_completed
            ? true
            : false
        const monthList = monthListing.map((month, idx) => {
            return (
                <option key={idx} id={month} name={month} value={idx + 1}>
                    {month}
                </option>
            )
        })
        const yearList = yearListing.map((year, idx) => {
            return (
                <option key={idx} id={year} name={year} value={year}>
                    {year}
                </option>
            )
        })
        const dateList = dateListing.map((date, idx) => {
            return (
                <option key={idx} id={date} name={date} value={date}>
                    {date}
                </option>
            )
        })
        return (
            <>
                <div className="create_page_background"></div>
                <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                    {isMobile && (
                        <div className="mobile_account_info">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                {' '}
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => this.props.history.goBack()}
                                />
                            </nav>
                        </div>
                    )}
                    {!isMobile && (
                        <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block" />
                    )}
                    {!isMobile && (
                        <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
                    )}
                </nav>
                <div className="container-fluid basic_info_page">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-6 col-xl-6 p-0 text-center d-none d-md-block d-lg-block d-xl-block">
                            <div className="top_margin">
                                <h4 className="auth-heading mb-3">
                                    Already have an account?
                                </h4>
                                <p className="auth-content">
                                    Sign in to enjoy all the benefits Pompay{' '}
                                    <br />
                                    provides to optimize the financial
                                    experience.
                                </p>
                                <button
                                    type="button"
                                    className="btn white_btn custom_btn"
                                    onClick={this.handleProcessModal}
                                >
                                    Sign In
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center flex-column align-items-center p-0">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                                data-interval="false"
                            >
                                <div className="carousel-inner mt-5">
                                    <div
                                        className={
                                            !redirectionData.is_registered &&
                                            !redirectionData.is_profile_completed &&
                                            !redirectionData.is_verified
                                                ? 'carousel-item security_slide active'
                                                : 'carousel-item security_slide'
                                        }
                                    >
                                        <div className="carousel-wrapper">
                                            <h4 className="bold-heading mb-4">
                                                For security purposes, all
                                                providers must complete a short
                                                application before they can
                                                continue.
                                            </h4>
                                            <p
                                                className="text-center bold-content padding_left_right45"
                                                style={{
                                                    margin: '0 0 290px 0',
                                                }}
                                            >
                                                Once our team reviews your
                                                information and confirms your
                                                identity, you will be able to
                                                finish setting up your account.
                                            </p>
                                            <button
                                                className="btn green_btn custom_btn"
                                                data-target="#carouselExampleIndicators"
                                                data-slide-to="1"
                                            >
                                                Start
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            redirectionData.is_registered &&
                                            !redirectionData.is_profile_completed
                                                ? 'carousel-item basic_info_slide active'
                                                : 'carousel-item basic_info_slide'
                                        }
                                    >
                                        <BasicInfo
                                            input={input}
                                            errors={errors}
                                            handleChange={this.handleChange}
                                            handleSubmit={this.handleSubmit}
                                            enterEmailFunc={this.enterEmailFunc}
                                            enabled={enabled}
                                            errorsCheck={errorsCheck}
                                            disabledDate={this.disabledDate}
                                            duplicateError={duplicateError}
                                            badEmail={badEmail}
                                            dateList={dateList}
                                            monthList={monthList}
                                            yearList={yearList}
                                        />
                                    </div>
                                    <div
                                        className={
                                            redirectionData.is_registered &&
                                            redirectionData.is_profile_completed &&
                                            !redirectionData.is_verified
                                                ? 'carousel-item verify_slide active'
                                                : 'carousel-item verify_slide'
                                        }
                                    >
                                        <VerificationForm
                                            verifiedInput={verifiedInput}
                                            verifiedErrors={verifiedErrors}
                                            handleVerifiedChange={
                                                this.handleVerifiedChange
                                            }
                                            enabledVerified={enabledVerified}
                                            handleVerifiedSubmit={
                                                this.handleVerifiedSubmit
                                            }
                                        />
                                    </div>
                                </div>
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="0"
                                        className={
                                            addClass || addNewClass
                                                ? 'disabled_indicator'
                                                : redirectionData.is_registered &&
                                                  !redirectionData.is_profile_completed &&
                                                  !redirectionData.is_verified
                                                ? 'active'
                                                : undefined
                                        }
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="1"
                                        className={
                                            addClass || addNewClass
                                                ? 'disabled_indicator'
                                                : redirectionData.is_registered &&
                                                  !redirectionData.is_profile_completed
                                                ? 'active'
                                                : undefined
                                        }
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="2"
                                        className={
                                            redirectionData.is_registered &&
                                            redirectionData.is_profile_completed &&
                                            !redirectionData.is_verified
                                                ? 'active'
                                                : undefined
                                        }
                                    ></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ProcessRegModal
                            inProcessRegModal={inProcessRegModal}
                            closeInProcessModal={this.closeInProcessModal}
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        redirectionData: state.redirectionData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendBasicInformation: (data) => dispatch(sendBasicInformation(data)),
        checkDuplicateEmail: (data) => dispatch(checkDuplicateEmail(data)),
        sendVerifiedData: (data, history) =>
            dispatch(sendVerifiedData(data, history)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}
BasicInformation.propTypes = {
    sendBasicInformation: PropTypes.func,
    sendVerifiedData: PropTypes.func,
    checkDuplicateEmail: PropTypes.func,
    checkValidEmail: PropTypes.func,
    redirectionData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    history: PropTypes.object,
    isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation)
