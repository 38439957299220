export function patNotification(state = {}, action) {
    switch (action.type) {
        case 'PATIENT_NOTIFICATION':
            return action.patNotification
        default:
            return state
    }
}

export function phyNotification(state = [], action) {
    switch (action.type) {
        case 'PHYSICIAN_NOTIFICATION':
            return action.phyNotification
        default:
            return state
    }
}
