import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import CheckoutForm from './Banking/CheckoutForm'
import PropTypes from 'prop-types'
import { STRIPE_PUBLISHABLE_KEY } from 'config'
import AddBankForm from './Banking/AddBankForm'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const AddCard = (props) => {
    return (
        <div className="App">
            <div className="product">
                <div>
                    <Elements stripe={stripePromise}>
                        {/* <CheckoutForm patient={ props.patient } /> */}
                        <AddBankForm
                            patient={props.patient}
                            closeModal={props.closeModal}
                            setToAccount={props.setToAccount}
                        />
                    </Elements>
                </div>
            </div>
        </div>
    )
}
AddCard.propTypes = {
    patient: PropTypes.bool,
    closeModal: PropTypes.func,
    setToAccount: PropTypes.func,
}

export default AddCard
