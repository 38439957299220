import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import { calculateTransactionFee, currencyFormatter } from 'utils/helper'
import { singlePaymentAction } from 'actions/patientBankingActions'
import PatientSuccessReceipt from './PatientSuccessReceipt'
import SuccessAlert from 'shared/SuccessAlert'

function FinalAmountToPayMobile(props) {
    const dispatch = useDispatch()
    const {
        closeFinalPayAmount,
        physicianDetails,
        selectedCardDetails,
        history,
        installBillDetails,
        remaningBalancePageDetails,
    } = props
    const [isComplete, setIsComplete] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)

    const dueInstallmentSchedule =
        installBillDetails?.full_payment_schedule?.find(
            (x) => x.due_amount_status === 'Due'
        )

    const lateFees = dueInstallmentSchedule?.late_fee ?? 0
    const totalFees = dueInstallmentSchedule?.due_amount ?? 0
    const discountAmount = dueInstallmentSchedule?.discount_amount ?? 0
    const paidFees = parseFloat(totalFees) + parseFloat(lateFees)
    const originalAmount = parseFloat(totalFees) + parseFloat(discountAmount)

    const transactionFee = calculateTransactionFee(paidFees)

    const totalAmount = transactionFee + paidFees

    const confirmPayFunc = () => {
        const data = {
            card_id: selectedCardDetails.card_id,
            physician_id: physicianDetails.physician_id,
            payment_schedule_id: dueInstallmentSchedule?.payment_schedule_id,
            stripe_account_id: physicianDetails.stripe_account_id,
            due_amount: paidFees,
            patient_payment_id: physicianDetails.patient_payment_id
                ? physicianDetails.patient_payment_id
                : remaningBalancePageDetails.patient_payment_id,
            month: dueInstallmentSchedule?.month,
        }
        if (physicianDetails.stripe_account_id) {
            dispatch(singlePaymentAction(data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsComplete(!isComplete)
                }
                // else {
                //     setSuccessAlert(true)
                // }
            })
        } else {
            alert('Selected physician has not yet added bank account')
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy
    return (
        <>
            {!isComplete && (
                <div className="bill_details mobile-bill-details mobile_account_info mobireceipt-page">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon"
                                onClick={() => closeFinalPayAmount()}
                            />
                            <label className="nav-heading">
                                Make a payment
                            </label>
                        </nav>
                        <div className="mobile-content-height">
                            <div className="heading_section d-flex flex-column text-center pt-3">
                                <span className="main-balanceheading">
                                    Total Amount
                                </span>
                                <span className="main-amount">
                                    {currencyFormatter.format(totalAmount)}
                                </span>
                            </div>
                            <div className="details-info-wrapper">
                                <div className="my-4 text-center">
                                    <span className="content-heading">
                                        Payment Details
                                    </span>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">Physician</span>
                                        <span className="data">
                                            {physicianDetails.physician_name ||
                                                physicianDetails.physician_full_name}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">Date</span>
                                        <span className="data">
                                            {formattedToday}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">
                                            Bill amount
                                        </span>
                                        <span className="data">
                                            {currencyFormatter.format(
                                                originalAmount || 0
                                            )}
                                        </span>
                                    </div>
                                    {Boolean(discountAmount) && (
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Discount applied
                                            </span>
                                            <span className="data">
                                                -
                                                {currencyFormatter.format(
                                                    discountAmount
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    {Boolean(lateFees) && (
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Late fee
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    lateFees || 0
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">
                                            Transaction fee
                                        </span>
                                        <span className="data">
                                            {currencyFormatter.format(
                                                transactionFee
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="data">From</span>
                                        <span className="data">
                                            {selectedCardDetails.card_type} ****
                                            {selectedCardDetails.card_number}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <span className="bold-data">Total</span>
                                        <span className="bold-data">
                                            {currencyFormatter.format(
                                                totalAmount
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={confirmPayFunc}
                            >
                                Pay {currencyFormatter.format(totalAmount)}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isComplete && (
                <PatientSuccessReceipt
                    selectedCardDetails={selectedCardDetails}
                    history={history}
                    patientSuccessReceiptPage={isComplete}
                    outStandingBalance={totalAmount}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message="payment fail "
                />
            )}
        </>
    )
}
FinalAmountToPayMobile.propTypes = {
    closeFinalPayAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    selectedCardDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    remaningBalancePageDetails: PropTypes.object,
    // openRemaningPayDetailsModalDetails: PropTypes.object,
    selectedPayDetails: PropTypes.object,
}
export default FinalAmountToPayMobile
