import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from '../utils/helper'
import { triggerNotifier } from 'shared/notifier'

export function fetchAccountDetails(accountDetails) {
    return {
        type: 'FETCH_ACCOUNT_DETAILS',
        accountDetails,
    }
}

export function fetchOfficeInfo(OfficeInfo) {
    return {
        type: 'FETCH_OFFICE_INFO',
        OfficeInfo,
    }
}

export function fetchOfficeAddressDetails(officeAddressDetails) {
    return {
        type: 'FETCH_OFFICE_ADDRESS_DETAILS',
        officeAddressDetails,
    }
}

export function fetchBanking(banking) {
    return {
        type: 'FETCH_BANKING',
        banking,
    }
}

export function fetchTeams(teams) {
    return {
        type: 'FETCH_TEAMS',
        teams,
    }
}

export function fetchUserActivity(userActivity) {
    return {
        type: 'FETCH_USER_ACTIVITY',
        userActivity,
    }
}

// titles

export function getTitles() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/account/user/role',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Team Accounts

export function getTeamDetails() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/team/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchAccountDetails(res.data))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updateTeamAccountDetails(id, data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/team/account/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getTeamDetails())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Accounts

export function addProfileImageAction(role, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/account/upload/file/' + role,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'multipart/form-data',
            },
            data: data,
        })
            .then((response) => {
                const res = response.data
                dispatch(applicationIsLoading(false))
                dispatch(updateProfileImage(res.data.file_name.split('/')[1]))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function updateProfileImage(img) {
    const data = {
        profile_pic: img,
    }
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/update/profile/pic',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getAccountDetails())
                dispatch(getTeamDetails())
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function getAccountDetails() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchAccountDetails(res.data))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updateAccountDetails(id, data) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/account/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getAccountDetails())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getBanking() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchBanking(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function getUserActivity(startDate, endDate, role) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/physician/recent/activity?team_member=' +
                role +
                '&' +
                'start_date=' +
                startDate +
                '&' +
                'end_date=' +
                endDate,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchUserActivity(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Teams

export function getTeams() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/team',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': "application/json'",
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchTeams(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function addTeam(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/team/create',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getTeams())
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function removeTeamMember(id) {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + '/physician/team/remove/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getTeams())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getTeamMemberInfo(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/team/info/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': "application/json'",
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updateTeamMemberInfo(id, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/team/update/info/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getTeamMemberInfo(id))
                dispatch(getTeams())
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Guidelines

export function getGuidelines() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/guideline',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': "application/json'",
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function updateGuidelines(id, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/guideline/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getGuidelines())
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// office

export function getOfficeInfo() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/office',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchOfficeInfo(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                dispatch(fetchOfficeInfo({}))
                console.log(error)
                return error
            })
    }
}

export function addOfficeDetails(data) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/physician/office/create',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                // dispatch(applicationIsLoading(false));
                dispatch(getOfficeInfo())
                return res.data
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getOfficeAddressDetails(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/office/info/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchOfficeAddressDetails(res.data))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                dispatch(fetchOfficeInfo({}))
                console.log(error)
                return error
            })
    }
}

export function updateOfficeInfoDetails(id, data) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/office/update/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                // dispatch(applicationIsLoading(false));
                dispatch(getOfficeAddressDetails(id))
                dispatch(getOfficeInfo())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function removeOfficeAddress(id) {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + '/physician/office/remove/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getOfficeInfo())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Check valid email
export function checkValidEmail(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/email/verify',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}
