import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
const unAuthorizedRoutes = [
    '/',
    '/signup',
    '/select-role',
    '/update-number',
    '/success',
]

const AuthRoutes = ({ component, path }) => {
    const isAuthenticated = useSelector((state) => state.isAuthenticated)
    if (!isAuthenticated) {
        if (!unAuthorizedRoutes.includes(path)) {
            return <Redirect to="/" />
        } else {
            return <Route exact path={path} component={component} />
        }
    }
    if (isAuthenticated) {
        if (unAuthorizedRoutes.includes(path)) {
            return <Redirect to="/not-found" />
        }
        // if (tempRoutes.includes(path)) {
        //     sessionStorage.removeItem('accessToken')
        //     return <Redirect to='/notfound' />
        // }
        else {
            return <Route exact path={path} component={component} />
        }
    }
}

export default AuthRoutes
