import { combineReducers, StateFromReducersMapObject } from 'redux'
import {
    verifyEmailDetails,
    formData,
    isAuthenticated,
    otpModalOpen,
    isOtpCorrect,
    redirectionData,
    emailRedirectionKey,
    updateOtpValue,
    teamMember,
    isvalidPasscode,
    userAccountDetails,
    lockData,
} from './authReducer'
import {
    accountDetails,
    OfficeInfo,
    userActivity,
    banking,
    teams,
    officeAddressDetails,
} from './accountReducer'
import {
    cardModal,
    cardListing,
    transDetails,
    isStripeAccount,
    totalAmountStripe,
} from './bankingReducer'
import {
    applicationIsLoading,
    currentUser,
    modalIsLoading,
} from './commonReducer'
import {
    paymentActivityData,
    installBillDetails,
    patientHomePageDetails,
} from './patientReducer'
import { patNotification, phyNotification } from './notificationReducer'
import { patientTransactions } from './patientAccountReducer'
import {
    patientCardListing,
    transferFundData,
    paymentScheduleDetails,
    patientOutstandingBalance,
} from './patientBankingReducer'
import {
    physicianDetails,
    physicianList,
    patientAppointmentId,
    physicianProfileDetails,
} from './patientPhysicianReducer'
import {
    isAdmin,
    physicianVerification,
    adminPatientDetails,
    adminPhysicianDetails,
    adminTeamMemberDetails,
    adminDashboardDetails,
    adminTransactionsDetails,
} from './adminReducer'

const reducers = {
    accountDetails,
    applicationIsLoading,
    adminPatientDetails,
    adminPhysicianDetails,
    adminTeamMemberDetails,
    adminDashboardDetails,
    adminTransactionsDetails,
    banking,
    cardModal,
    cardListing,
    currentUser,
    emailRedirectionKey,
    formData,
    // fcmToken,
    // guidelines,
    isAuthenticated,
    isAdmin,
    isOtpCorrect,
    // isAccountExist,
    installBillDetails,
    isStripeAccount,
    isvalidPasscode,
    modalIsLoading,
    paymentActivityData,
    patNotification,
    patientAppointmentId,
    patientHomePageDetails,
    patientTransactions,
    patientCardListing,
    patientOutstandingBalance,
    paymentScheduleDetails,
    physicianProfileDetails,
    // patientCustomerAccount,
    physicianDetails,
    physicianList,
    phyNotification,
    physicianVerification,
    redirectionData,
    lockData,
    otpModalOpen,
    OfficeInfo,
    officeAddressDetails,
    // setGuideline,
    updateOtpValue,
    verifyEmailDetails,
    teams,
    teamMember,
    transDetails,
    transferFundData,
    totalAmountStripe,
    userActivity,
    userAccountDetails,
};

export type ReducersType = StateFromReducersMapObject<typeof reducers>;

export default combineReducers(reducers);