import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { updatePhoneNumber } from '../../actions/authActions'
import { connect } from 'react-redux'
import OtpModal from './OtpModal'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'

export class MobileNumberModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phone_number: '',
            openOtpModal: false,
            update_modal: true,
            input: {},
        }
    }
    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        let obj = event.target.value
        var numbers = obj.replace(/\D/g, ''),
            char = { 0: '(', 3: ') ', 6: '-' }
        obj = ''
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i]
            input[event.target.name] = obj
        }
        this.setState({
            input,
        })
    }

    handleSubmit = () => {
        const data = {
            phone_number: this.state.input.phone_number,
            email_id: this.props.email_id,
        }
        this.setState({
            openOtpModal: true,
        })
        this.props.updatePhoneNumber(data)
    }
    closeOtpModal = () => {
        this.setState({
            openOtpModal: false,
        })
    }
    render() {
        const { isOpen, closeMobileNumberModal } = this.props
        const { openOtpModal } = this.state
        return (
            <div>
                {!openOtpModal && (
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => closeMobileNumberModal()}
                        style={MODAL_STYLES}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <button
                            type="button"
                            className="close modal_close_icon"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => closeMobileNumberModal()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div
                            className="modal-dialog modal-dialog-centered update-mobile-number"
                            role="document"
                        >
                            <div className="modal-content otp_modal">
                                <div className="modal-body">
                                    <h4>Update Mobile Number</h4>
                                    <p>
                                        Enter your new number to receive a
                                        verification code.
                                    </p>
                                    <div className="d-flex justify-content-center align-items-baseline py-5">
                                        {/* <h5 className="mobile_number ml-3">+1</h5> */}
                                        <input
                                            type="text"
                                            maxLength="14"
                                            className="form-control input_field border-0"
                                            name="phone_number"
                                            placeholder="(xxx) xxx-xxxx"
                                            value={
                                                this.state.input.phone_number ||
                                                ''
                                            }
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn custom_btn green_btn d-block mx-auto mt-4"
                                        onClick={this.handleSubmit}
                                    >
                                        Send Code
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                {openOtpModal && (
                    <OtpModal
                        isOpen={openOtpModal}
                        closeOtpModal={this.closeOtpModal}
                        phone={this.state.input.phone_number}
                        email_id={this.props.email_id}
                        history={this.props.history}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        updateOtpValue: state.updateOtpValue,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePhoneNumber: (data) => dispatch(updatePhoneNumber(data)),
    }
}
MobileNumberModal.propTypes = {
    updatePhoneNumber: PropTypes.func,
    closeMobileNumberModal: PropTypes.func,
    history: PropTypes.object,
    isOpen: PropTypes.bool,
    updateOtpValue: PropTypes.bool,
    email_id: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileNumberModal)
