import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getAccountDetails,
    updateAccountDetails,
    getTeamDetails,
    updateTeamAccountDetails,
    addProfileImageAction,
    checkValidEmail,
} from 'actions/accountActions'
import { getTotalAmount } from 'actions/bankingActions'
import Avatar from 'react-avatar'
import Loading from 'shared/Loader'
import { MdLock } from 'react-icons/md'
import { maxLengthCheck } from 'utils/helper'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import camera from '../../static/images/camera.png'
import SuccessAlert from 'shared/SuccessAlert'

export class AccountDetails extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            successAlert: false,
            disableKey: false,
            loadingOuter: false,
        }
    }
    componentDidMount() {
        const { isTeam } = this.props
        this._isMounted = true
        this.props.getTotalAmount()
        this.setState({ loadingOuter: true })
        isTeam
            ? this.props.getTeamDetails().then((res) => {
                  if (res.statusCode === 200) {
                      this.setState({
                          input:
                              res &&
                              res.data.physician_team &&
                              res.data.physician_team,
                      })
                      setTimeout(
                          () => this.setState({ loadingOuter: false }),
                          1000
                      )
                  }
              })
            : this.props.getAccountDetails().then((res) => {
                  if (res.statusCode === 200) {
                      this.setState({
                          input: res.data.physician && res.data.physician[0],
                      })
                      setTimeout(
                          () => this.setState({ loadingOuter: false }),
                          1000
                      )
                  }
              })
    }

    componentWillUnmount() {
        this._isMounted = false
        this.setState({
            input: {},
            errors: {},
        })
    }
    uploadImage = (event) => {
        const input = this.state.input
        const { isTeam } = this.props
        const role = isTeam ? 'Teams' : 'Physicians'
        input[event.target.name] = event.target.files[0]
        var data = new FormData()
        data.append('file', input.profileImage)
        for (var pair of data.entries()) {
            console.log(pair[0] + ', ' + pair[1])
        }
        if (event.target.files[0]) {
            this.props.addProfileImageAction(role, data).then((result) => {
                if (result.statusCode === 200) {
                    setTimeout(() => {
                        this.props.getAccountDetails().then((res) => {
                            if (res.statusCode === 200) {
                                this.setState({
                                    input:
                                        res.data.physician &&
                                        res.data.physician[0],
                                })
                            }
                        })
                    }, 500)
                }
            })
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const { isTeam } = this.props
        const input = this.state.input
        const id = input.physician_id
        const teamId = input.id
        const teamData = {
            email_id: input.email_id,
            first_name: input.first_name,
            last_name: input.last_name,
            phone_number: input.phone_number,
            zipcode: input.zipcode,
        }
        const data = {
            email_id: input.email_id,
            first_name: input.first_name,
            last_name: input.last_name,
            owner_passcode: input.owner_passcode,
            phone_number: input.phone_number,
            zipcode: input.zipcode,
        }
        const check_email_data = {
            email_id: input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            isTeam
                                ? this.props
                                      .updateTeamAccountDetails(
                                          teamId,
                                          teamData
                                      )
                                      .then((res) => {
                                          if (res.statusCode === 200) {
                                              this.setState({
                                                  successAlert: true,
                                              })
                                          }
                                      })
                                : this.props
                                      .updateAccountDetails(id, data)
                                      .then((res) => {
                                          if (res.statusCode === 200) {
                                              this.setState({
                                                  successAlert: true,
                                              })
                                          }
                                      })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    validate() {
        const input = this.state.input
        const { isTeam } = this.props
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] =
                'Please enter your first name (alphabets only).'
        }
        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false
                errors['first_name'] =
                    'Please enter your first name (alphabets only).'
            }
        }

        if (!input['last_name']) {
            isValid = false
            errors['last_name'] =
                'Please enter your last name (alphabets only).'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] =
                    'Please enter your last name (alphabets only).'
            }
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your phone number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }
        if (!input['owner_passcode'] && !isTeam) {
            isValid = false
            errors['owner_passcode'] = 'Please enter your owner passcode.'
        }
        if (
            input['owner_passcode'] &&
            input['owner_passcode'].length !== 4 &&
            !isTeam
        ) {
            isValid = false
            errors['owner_passcode'] = 'Owner passcode should be of 4 digit.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    onSelect = (value) => {
        this.setState({
            value,
            selectedValue: value,
        })
    }

    onPanelChange = (value) => {
        this.setState({ value })
    }

  disableFunc = (event) => {
      event.preventDefault();
      //   this.setState({ disableKey: !this.state.disableKey })
      this.props.isTeam
          ? this.props.getTeamDetails().then((res) => {
              if (res.statusCode === 200) {
                  this.setState({
                      input: res && res.data.physician_team && res.data.physician_team,
                  });
              }
          })
          : this.props.getAccountDetails().then((res) => {
              if (res.statusCode === 200) {
                  this.setState({
                      input: res.data.physician && res.data.physician[ 0 ],
                  });
              }
          });
  };
  render() {
      const { isLoading, isTeam } = this.props;
      const { input, errors, successAlert, disableKey, loadingOuter } = this.state;
      const enabled = disableKey
      return (
          <>
              <div
                  className="account-page"
              >
                  <div className="row">
                      <div className="col-md-12">
                          <div className="d-flex">
                              <h2 className="tab_heading">Account Information</h2>
                          </div>
                      </div>
                  </div>
                  {(isLoading || loadingOuter) && (
                      <div className="row no-gutters">
                          <div className="col-12 mt-5">
                              <Loading />
                          </div>
                      </div>
                  )}
                  {(!isLoading && !loadingOuter) && (
                      <>
                          {' '}
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="d-flex mobi_profile_pic">
                                      <Avatar
                                          name={ input && input.first_name }
                                          className="profile_image"
                                          size="100"
                                          round={ true }
                                          src={ input && input.avatar }
                                      />
                                      <div className="upload-image-wrapper">
                                          <div className="image-upload">
                                              <label htmlFor="profileImage">
                                                  <img src= { camera } className="" />
                                              </label>
                                              <input id="profileImage" name="profileImage" className="file-upload" type="file" onChange={ this.uploadImage }/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>{' '}
                          <div className="row">
                              <div className="col-md-12">
                                  <form>
                                      <div className="form-row flex-column">
                                          <div style={ { height: '0px' } }>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              name="first_name"
                                                              type="text"
                                                              id="acc-firstname"
                                                              className="form-control account_info_field"
                                                              placeholder="Enter your first name"
                                                              value={ input.first_name || '' }
                                                              onChange={ this.handleChange }
                                                              disabled={ enabled }
                                                          />
                                                          <label className="input-group-text account_info_label" htmlFor="acc-firstname">
                                                              First name
                                                          </label>
                                                      </div>
                                                      <small className="text-danger">
                                                          {errors.first_name || ''}
                                                      </small>
                                                  </div>
                                              </div>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              name="last_name"
                                                              type="text"
                                                              id="acc-lastname"
                                                              className="form-control account_info_field"
                                                              placeholder="Enter your last name"
                                                              value={ input.last_name || '' }
                                                              onChange={ this.handleChange }
                                                              disabled={ enabled }
                                                          />
                                                          <label className="input-group-text account_info_label" htmlFor="acc-lastname">
                                                              Last name
                                                          </label>
                                                      </div>
                                                      <small className="text-danger">
                                                          {errors.last_name || ''}
                                                      </small>
                                                  </div>
                                              </div>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              disabled
                                                              name="last_name"
                                                              id="acc-date"
                                                              type="text"
                                                              className="form-control account_info_field"
                                                              placeholder="Enter your date of birth"
                                                              defaultValue={ input.date_of_birth || '' }
                                                              onChange={ this.handleChange }
                                                          />
                                                          <MdLock className="textfield-icon"/>
                                                          <label className="input-group-text account_info_label" htmlFor="acc-date">
                                                              Date of birth
                                                          </label>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              type="text"
                                                              maxLength="14"
                                                              name="phone_number"
                                                              id="acc-mobile-number"
                                                              className="form-control account_info_field"
                                                              placeholder="Enter mobile number"
                                                              value={ input.phone_number || '' }
                                                              onChange={ this.handleChange }
                                                              disabled={ enabled }
                                                          />
                                                          <label className="input-group-text account_info_label" htmlFor="acc-mobile-number">
                                                              Mobile phone
                                                          </label>
                                                          <IoMdCheckmarkCircle className="textfield-right-tick"/>
                                                      </div>
                                                      <small className="text-danger">
                                                          {errors.phone_number || ''}
                                                      </small>
                                                  </div>
                                              </div>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              name="email_id"
                                                              type="email"
                                                              id="acc-email"
                                                              className="form-control account_info_field"
                                                              placeholder="Enter email id"
                                                              value={ input.email_id || '' }
                                                              onChange={ this.handleChange }
                                                              disabled={ enabled }
                                                          />
                                                          <label className="input-group-text account_info_label" htmlFor="acc-email">
                                                              Email
                                                          </label>
                                                      </div>
                                                      <small className="text-danger">
                                                          {errors.email_id || ''}
                                                      </small>
                                                  </div>
                                              </div>
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 my-3 pl-0">
                                                  <div className="input-group">
                                                      <div className="input-group-prepend">
                                                          <input
                                                              type="number"
                                                              maxLength="5"
                                                              onInput={ maxLengthCheck }
                                                              name="zipcode"
                                                              autoComplete="off"
                                                              placeholder="Enter zipcode"
                                                              id="zipcode"
                                                              value={ input.zipcode || '' }
                                                              onChange={ this.handleChange }
                                                              disabled={ enabled }
                                                              className="form-control account_info_field"
                                                          />
                                                          <label className="input-group-text account_info_label" htmlFor="zipcode">
                                                              Zipcode
                                                          </label>
                                                      </div>
                                                      <small className="text-danger">
                                                          {errors.phone_number || ''}
                                                      </small>
                                                  </div>
                                              </div>
                                              {!isTeam && (
                                                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pl-0">
                                                      <div className="input-group">
                                                          <div className="input-group-prepend">
                                                              <input
                                                                  name="owner_passcode"
                                                                  type="password"
                                                                  maxLength="4"
                                                                  id="acc-passcode"
                                                                  onInput={ maxLengthCheck }
                                                                  className="form-control account_info_field"
                                                                  placeholder="Passcode"
                                                                  value={ input.owner_passcode || '' }
                                                                  onChange={ this.handleChange }
                                                                  disabled={ enabled }
                                                              />
                                                              <label className="input-group-text account_info_label" htmlFor="acc-passcode">
                                                                  Owner passcode
                                                              </label>
                                                          </div>
                                                          <small className="text-danger">
                                                              {errors.owner_passcode || ''}
                                                          </small>
                                                      </div>
                                                  </div>
                                              )}
                                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0 pb-4">
                                                  <div className="btn-card text-left">
                                                      <button
                                                          className="btn custom_btn green_btn"
                                                          type="submit"
                                                          onClick={ this.handleSubmit }
                                                      >
                                                          Save
                                                      </button>
                                                      <button
                                                          onClick={ this.disableFunc }
                                                          className="btn btn-link cancel-link"
                                                      >
                                                          Cancel
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </form>

                              </div>
                          </div>{' '}
                      </>
                  )}
              </div>
              {successAlert && <SuccessAlert isOpen={ successAlert } closeSuccessAlert ={ this.closeSuccessAlert } role='Account' message='Your account info has been updated successfully'/>}
          </>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        accountDetails: state.accountDetails,
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountDetails: () => dispatch(getAccountDetails()),
        getTotalAmount: () => dispatch(getTotalAmount()),
        getTeamDetails: () => dispatch(getTeamDetails()),
        updateAccountDetails: (id, data) =>
            dispatch(updateAccountDetails(id, data)),
        updateTeamAccountDetails: (id, data) =>
            dispatch(updateTeamAccountDetails(id, data)),
        addProfileImageAction: (role, data) =>
            dispatch(addProfileImageAction(role, data)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

AccountDetails.propTypes = {
    getAccountDetails: PropTypes.func,
    getTotalAmount: PropTypes.func,
    updateAccountDetails: PropTypes.func,
    getTeamDetails: PropTypes.func,
    updateTeamAccountDetails: PropTypes.func,
    addProfileImageAction: PropTypes.func,
    checkValidEmail: PropTypes.func,
    history: PropTypes.object,
    accountDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
