import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getCardListing,
    removeCard,
    transactionDetails,
    getPhysicianAccountExists,
} from 'actions/bankingActions'
import { MdChevronRight } from 'react-icons/md'
import { BsCreditCard } from 'react-icons/bs'
import AddCard from '../AddCard'
import ModalLoader from 'shared/ModalLoader'
import { StripeAccountCheck } from '../StripeAccountCheck'
import { CgChevronLeft } from 'react-icons/cg'
import RemoveCard from './RemoveCard'
import { sendBankingAuthDeatils } from 'actions/bankingActions'
import SuccessAlert from 'shared/SuccessAlert'

export class BankingMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bankSection: false,
            openAuthDetails: false,
            isRemoveCard: false,
            successAlert: false,
            successAlertMessage: '',
        }
    }
    componentDidMount() {
        this.props.getPhysicianAccountExists()
        this.props.getCardListing()
        this.props.transactionDetails()
    }
    handleBankAccountSection = () => {
        const { isTeam } = this.props
        if (isTeam) {
            // if (cardListing.is_locked) {
            this.setState({
                successAlert: true,
                successAlertMessage:
                    'You dont have access to perform this action',
            })
            // }
        } else {
            this.props.isStripeAccount
                ? this.setState({ bankSection: !this.state.bankSection })
                : this.setState({ openAuthDetails: true })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }
    handleRemoveCard = (data) => {
        // const id = data && data.id;
        // const { isTeam, cardListing } = this.props;
        // if (isTeam) {
        //     if (cardListing.is_locked) {
        //     }
        // } else {
        //     this.props.removeCard(id);
        // }
        this.setState({
            isRemoveCard: !this.state.removeCard,
            removeCardDetails: data,
        })
    }
    closeRemoveCard = () => {
        this.setState({
            isRemoveCard: false,
        })
    }
    render() {
        // const { bankSection } = this.state
        const {
            openAuthDetails,
            bankSection,
            isRemoveCard,
            removeCardDetails,
            successAlert,
            successAlertMessage,
        } = this.state
        const { isLoading, cardListing, isTeam } = this.props
        return (
            <>
                {!bankSection && !isRemoveCard && (
                    <div className="mobile_account_info" id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() =>
                                    this.props.history.push(
                                        '/account-homepage-mobile'
                                    )
                                }
                            />
                            <label className="nav-heading">
                                Add Bank Account
                            </label>
                            <span></span>
                        </nav>
                        {openAuthDetails && (
                            <StripeAccountCheck
                                isOpen={openAuthDetails}
                                closeStripeModal={this.closeStripeModal}
                                sendBankingAuthDeatils={
                                    this.props.sendBankingAuthDeatils
                                }
                            />
                        )}
                        <div>
                            <div
                                className="tab-pane fade show active banking"
                                id="nav-banking"
                                role="tabpanel"
                                aria-labelledby="nav-banking-tab"
                            >
                                {isLoading && (
                                    <div className="p-3">
                                        <ModalLoader />
                                    </div>
                                )}
                                {!isLoading && (
                                    <div>
                                        <div className="page-label p-3">
                                            Linked Account(s)
                                        </div>
                                        <table className="table table-borderless office_info mb-0">
                                            {cardListing &&
                                                cardListing.cardList &&
                                                cardListing.cardList.map(
                                                    (data, id) => {
                                                        return (
                                                            <tbody
                                                                key={id}
                                                                className="border-0 linked-accounts"
                                                            >
                                                                <tr
                                                                    className="table-border"
                                                                    onClick={() =>
                                                                        this.handleRemoveCard(
                                                                            data
                                                                        )
                                                                    }
                                                                >
                                                                    <td className="border-0 d-flex align-items-center table-row-padding">
                                                                        <div className="d-flex justify-content-between w-100">
                                                                            <div className="d-flex align-items-center">
                                                                                <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                                                <div className="ml-3">
                                                                                    <div className="office_address">
                                                                                        {
                                                                                            data.card_type
                                                                                        }{' '}
                                                                                        {'****' +
                                                                                            data.card_number}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <MdChevronRight
                                                                                className="float-right font24 mt-2 color_dark_grey"
                                                                                onClick={() =>
                                                                                    this.handleRemoveCard(
                                                                                        data
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                )}
                                        </table>
                                        <button
                                            className="btn btn-link px-3 py-2 add_element_link"
                                            onClick={
                                                this.handleBankAccountSection
                                            }
                                        >
                                            Add Bank Account...
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {bankSection && (
                    <div className="mobile_account_info add-bank-account-page">
                        {/* <CgChevronLeft
                        className="back_icon"
                        onClick={ this.handleBankAccountSection }
                    /> */}
                        <AddCard closeModal={this.handleBankAccountSection} />
                    </div>
                )}
                {isRemoveCard && (
                    <RemoveCard
                        isTeam={isTeam}
                        cardListing={cardListing}
                        removeCardDetails={removeCardDetails}
                        closeRemoveCard={this.closeRemoveCard}
                        closeRemoveCardPage={this.closeRemoveCard}
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        restrict={true}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        banking: state.banking,
        isLoading: state.applicationIsLoading,
        cardModal: state.cardModal,
        cardListing: state.cardListing,
        // transDetails: state.transDetails,
        isStripeAccount: state.isStripeAccount,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCardListing: () => dispatch(getCardListing()),
        transactionDetails: () => dispatch(transactionDetails()),
        getPhysicianAccountExists: () => dispatch(getPhysicianAccountExists()),
        removeCard: (id) => dispatch(removeCard(id)),
        sendBankingAuthDeatils: (data) =>
            dispatch(sendBankingAuthDeatils(data)),
    }
}

BankingMobile.propTypes = {
    history: PropTypes.object,
    getCardListing: PropTypes.func,
    sendBankingAuthDeatils: PropTypes.func,
    transactionDetails: PropTypes.func,
    getPhysicianAccountExists: PropTypes.func,
    removeCard: PropTypes.func,
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isStripeAccount: PropTypes.bool,
    totalAmountStripe: PropTypes.array,
    cardListing: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(BankingMobile)
