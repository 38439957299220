import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { FiChevronLeft } from 'react-icons/fi'
import EnterPatientAccountNumberMobile from './EnterPatientAccountNumberMobile'
import { getPhysicianProfileInfo } from 'actions/patientPhysicianActions'
import ModalLoader from 'shared/ModalLoader'
function PhysicianProfileMobile(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closePhysicianProfile,
        patientAppointmentId,
        physicianDetails,
        patientId,
    } = props
    useEffect(() => {
        dispatch(getPhysicianProfileInfo(physicianDetails.physician_id))
    }, [''])
    const profileDetails = useSelector((state) => state.physicianProfileDetails)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const [openPatientAccountNumber, setOpenPatientAccountNumber] =
        useState(false)
    const closePatientAccountNumber = () => {
        setOpenPatientAccountNumber(false)
    }
    return (
        <>
            {isOpen && !openPatientAccountNumber && (
                <div className="physician-profile mobile_account_info">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-start">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closePhysicianProfile()}
                            />
                            <span className="nav-heading">Profile</span>
                            <span></span>
                        </nav>
                        <div id="nav-tabContent">
                            <div className="d-flex justify-content-center">
                                <div className="mb-4">
                                    <Avatar
                                        name={
                                            physicianDetails.physician ||
                                            (physicianDetails.physician_full_name &&
                                                physicianDetails.physician_full_name.split(
                                                    '.'
                                                )[1]) ||
                                            (physicianDetails.physician_name &&
                                                physicianDetails.physician_name.split(
                                                    '.'
                                                )[1])
                                        }
                                        className="physician-image"
                                        size="100"
                                        src={profileDetails.avatar}
                                        round={true}
                                    />
                                </div>
                            </div>
                            {isModalLoading && <ModalLoader />}
                            {!isModalLoading && (
                                <div className="row no-gutters account-details-form">
                                    <div className="col-md-12 p-0">
                                        <form className="p-0">
                                            <div className="flex-column">
                                                <div className="form-row">
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="first_name"
                                                                    type="text"
                                                                    id="fname"
                                                                    className="form-control account_info_field"
                                                                    placeholder={
                                                                        patientAppointmentId
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Patient
                                                                    Account
                                                                    Number
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    id="physician"
                                                                    type="text"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.physician
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="physician"
                                                                >
                                                                    Physician
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    id="speciality"
                                                                    type="text"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        (profileDetails &&
                                                                            profileDetails.speciality) ||
                                                                        'General'
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="speciality"
                                                                >
                                                                    Speciality
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="address"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.address
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="address"
                                                                >
                                                                    Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="city"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.city
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="city"
                                                                >
                                                                    City
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="state"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.state
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="state"
                                                                >
                                                                    State
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="zipcode"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.zipcode
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="zipcode"
                                                                >
                                                                    Zipcode
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="phone-number"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.phone_number
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="phone-number"
                                                                >
                                                                    Phone number
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 my-2">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <input
                                                                    name="physician-name"
                                                                    type="text"
                                                                    id="website"
                                                                    className="form-control account_info_field"
                                                                    defaultValue={
                                                                        profileDetails &&
                                                                        profileDetails.website
                                                                    }
                                                                    autoComplete="off"
                                                                    disabled
                                                                />
                                                                <label
                                                                    className="input-group-text account_info_label"
                                                                    htmlFor="website"
                                                                >
                                                                    Website
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className="px-3 pb-3">
                    <button
                        className="btn mobile-green-btn"
                        onClick = { () => setOpenPatientAccountNumber(true) }
                    >
                        New Bill
                    </button>
                </div> */}
                </div>
            )}
            {openPatientAccountNumber && (
                <EnterPatientAccountNumberMobile
                    openAccountNumber={openPatientAccountNumber}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={patientId}
                    history={props.history}
                />
            )}
        </>
    )
}
PhysicianProfileMobile.propTypes = {
    closePhysicianProfile: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    patientAppointmentId: PropTypes.number,
    history: PropTypes.object,
    patientId: PropTypes.number,
}
export default PhysicianProfileMobile
