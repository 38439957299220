import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isMobile, ddmmyyFormat } from 'constants/Constant'
import { MdChevronRight } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { CgChevronLeft } from 'react-icons/cg'
import { getInstallBillDetails, getPayActivity } from 'actions/patientActions'
import { currencyFormatter, titleCapatilize } from 'utils/helper'
import Loading from 'shared/Loader'
const PatientRemainingBalance = (props) => {
    const dispatch = useDispatch()
    const paymentActivityData = useSelector(
        (state) => state.paymentActivityData
    )
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const phyId =
        props.location &&
        props.location.state &&
        props.location.state.physicianId
    const phyName =
        props.location &&
        props.location.state &&
        props.location.state.physicianName
    const details =
        props.location && props.location.state && props.location.state.details
    useEffect(() => {
        dispatch(getPayActivity(details.patient_id, phyId))
    }, [dispatch])
    const openBillDetails = (id) => {
        dispatch(getInstallBillDetails(id, phyId))
        props.history.push({
            pathname: '/billdetails-mobile',
        })
    }
    const openRemaningPay = (id) => {
        dispatch(getInstallBillDetails(id, phyId))
        props.history.push({
            pathname: '/billdetailsTotal-mobile',
        })
    }
    return (
        <div>
            {isMobile && (
                <div className="remaining_balance_page mobile_account_info ">
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">
                            {titleCapatilize(phyName)}
                        </label>
                        <BsThreeDots
                            style={{ zIndex: 1 }}
                            className="color_dark_grey mr-2"
                            onClick={() =>
                                props.history.push({
                                    pathname: '/patient-profile-mobile',
                                    state: {
                                        data: details,
                                    },
                                })
                            }
                        />
                    </nav>
                    <div className="content">
                        <div className="text-center mt-5 mb-3">
                            <div className="balance-label">
                                Remaining Balance
                            </div>
                            {/* {isModalLoading && <div className="px-3"><ModalLoader /></div>} */}
                            {
                                <div className="balance">
                                    {currencyFormatter.format(
                                        paymentActivityData.total_balance || 0
                                    )}
                                </div>
                            }
                        </div>
                        <div className="tab_section">
                            <ul
                                className="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="current-tab"
                                        data-toggle="tab"
                                        href="#current"
                                        role="tab"
                                        aria-controls="current"
                                        aria-selected="true"
                                    >
                                        Current
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="history-tab"
                                        data-toggle="tab"
                                        href="#history"
                                        role="tab"
                                        aria-controls="history"
                                        aria-selected="false"
                                    >
                                        History
                                    </a>
                                </li>
                            </ul>
                            {isModalLoading && <Loading />}
                            {!isModalLoading && (
                                <div
                                    className="tab-content overflowAuto"
                                    id="myTabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="current"
                                        role="tabpanel"
                                        aria-labelledby="current-tab"
                                    >
                                        {/* {isModalLoading && <div className="p-3"><ModalLoader /></div>} */}
                                        {paymentActivityData &&
                                            paymentActivityData.current &&
                                            paymentActivityData.current.map(
                                                (d, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters row-border"
                                                            key={id}
                                                        >
                                                            <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                <div className="d-flex flex-column">
                                                                    <span className="tab-lite-data">
                                                                        Due{' '}
                                                                        <span className="tab-bold-data">
                                                                            {moment(
                                                                                d.due_amount_date
                                                                            ).format(
                                                                                'dddd, MMMM Do'
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                    <span className="tab-lite-data">
                                                                        Remaining
                                                                        amount{' '}
                                                                        <span className="tab-bold-data">
                                                                            {currencyFormatter.format(
                                                                                d.remaining_amount ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-sm-5 right_row_padding">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="tab-superbold-data">
                                                                            {d.days_past_due &&
                                                                                currencyFormatter.format(
                                                                                    d.days_past_due ||
                                                                                        0
                                                                                )}
                                                                        </span>
                                                                        <span className="color_alert tab-superlite-data">
                                                                            {
                                                                                d.days_past_count
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight
                                                                        className="float-right font24 ml-2 d-flex align-items-center pointer"
                                                                        onClick={() =>
                                                                            openBillDetails(
                                                                                d.patient_payment_id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="history"
                                        role="tabpanel"
                                        aria-labelledby="history-tab"
                                    >
                                        {/* {isModalLoading && <div className="p-3"><ModalLoader /></div>} */}
                                        {paymentActivityData &&
                                            paymentActivityData.history &&
                                            paymentActivityData.history.map(
                                                (d, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters row-border"
                                                            key={id}
                                                        >
                                                            <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                <div className="d-flex flex-column">
                                                                    <span className="tab-lite-data">
                                                                        Amount{' '}
                                                                        <span className="tab-bold-data">
                                                                            {currencyFormatter.format(
                                                                                d.paid_amount ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                    <span className="tab-superlite-data">
                                                                        Final
                                                                        payment
                                                                        date{' '}
                                                                        <span className="tab-lighterbold-data">
                                                                            {moment(
                                                                                d.payment_date
                                                                            ).format(
                                                                                ddmmyyFormat
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-sm-5 right_row_padding d-flex align-items-center justify-content-end">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="bold-text">
                                                                            {
                                                                                d.payment_status
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight
                                                                        className="float-right font24 ml-2 d-flex align-items-center pointer"
                                                                        onClick={() =>
                                                                            openRemaningPay(
                                                                                d.patient_payment_id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
PatientRemainingBalance.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}

export default PatientRemainingBalance
