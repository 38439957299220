/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
function ConfirmDelete(props) {
    const { confirmRemove, isConfirmDelete, handleRemoveFunc, role, mode } =
        props
    return (
        <>
            <Modal
                isOpen={isConfirmDelete}
                style={MODAL_STYLES}
                ariaHideApp={false}
                id="delete-team-member"
            >
                <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content otp_modal">
                        <div className="modal-body">
                            <div>
                                <h4 className="text-left mt-0">
                                    Are you sure you want to
                                    <br /> remove this {mode ? mode : role}?
                                </h4>
                                <div className="delete-info text-left mb-4">
                                    {/* If yes, this {mode ? mode : role} will be deleted. These actions can't be undone. */}
                                    If yes, this{' '}
                                    {role === 'card' || mode
                                        ? role || mode
                                        : 'member'}{' '}
                                    will be deleted. These actions cannot be
                                    undone.
                                </div>
                            </div>
                        </div>
                        {mode ? (
                            <div className="d-flex justify-content-between btn-wrapper px-3 pt-2">
                                <button
                                    className="btn yes-btn"
                                    style={{ zIndex: 1 }}
                                    onClick={() => handleRemoveFunc()}
                                >
                                    {' '}
                                    Yes{' '}
                                </button>
                                <button
                                    className="btn btn-link cancel-link"
                                    onClick={() => confirmRemove()}
                                >
                                    {' '}
                                    No{' '}
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between btn-wrapper px-3 pt-2">
                                <button
                                    className="btn yes-btn"
                                    style={{ zIndex: 1 }}
                                    onClick={() => handleRemoveFunc()}
                                >
                                    {' '}
                                    Yes{' '}
                                </button>
                                <button
                                    className="btn btn-link cancel-link"
                                    onClick={() => confirmRemove()}
                                >
                                    {' '}
                                    Cancel{' '}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    )
}
ConfirmDelete.propTypes = {
    confirmRemove: PropTypes.func,
    handleRemoveFunc: PropTypes.func,
    isConfirmDelete: PropTypes.bool,
    role: PropTypes.string,
    mode: PropTypes.string,
}
export default ConfirmDelete
