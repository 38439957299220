import React from 'react'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { currencyFormatter } from 'utils/helper'

function HistoryTotalBillDetails(props) {
    const {
        openTotalPayDetailsModal,
        closeTotalPaymentFunc,
        openTotalPayDetailsModalDetails,
        physicianDetails,
    } = props
    const originalPaidAmout =
        parseFloat(openTotalPayDetailsModalDetails.paid_amount) +
        parseFloat(openTotalPayDetailsModalDetails.discount_amount)
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy
    return (
        <>
            {openTotalPayDetailsModal && (
                <Modal
                    isOpen={openTotalPayDetailsModal}
                    onRequestClose={closeTotalPaymentFunc}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    id="receipt-modal"
                    ariaHideApp={false}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeTotalPaymentFunc()}
                                />
                                <label className="patient-name">Receipt</label>
                                <span></span>
                            </div>
                            <div className="modal-body text-center">
                                <div className="modal-header-border total-amount-content mt-3">
                                    <span className="total-amount">
                                        Total Paid
                                    </span>
                                    <h3 className="amount">
                                        {currencyFormatter.format(
                                            openTotalPayDetailsModalDetails.paid_amount ||
                                                0
                                        )}
                                    </h3>
                                </div>
                                <div className="details-info-wrapper">
                                    <label className="details-label mt-4 mb-3">
                                        Payment details
                                    </label>
                                    <div className="mt-4">
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Physician
                                            </span>
                                            <span className="data">
                                                {
                                                    physicianDetails.physician_full_name
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">Date</span>
                                            <span className="data">
                                                {formattedToday}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Bill amount
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    originalPaidAmout || 0
                                                )}
                                            </span>
                                        </div>
                                        {Boolean(
                                            openTotalPayDetailsModalDetails.discount_amount
                                        ) && (
                                            <div className="d-flex justify-content-between my-4">
                                                <span className="data">
                                                    Discount applied
                                                </span>
                                                <span className="data">
                                                    {currencyFormatter.format(
                                                        openTotalPayDetailsModalDetails.discount_amount ||
                                                            0
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">From</span>
                                            <span className="data">
                                                Checking ****7664
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="bold-data">
                                                Total
                                            </span>
                                            <span className="bold-data">
                                                {currencyFormatter.format(
                                                    openTotalPayDetailsModalDetails.paid_amount
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
HistoryTotalBillDetails.propTypes = {
    closeTotalPaymentFunc: PropTypes.func,
    openTotalPayDetailsModal: PropTypes.bool,
    openTotalPayDetailsModalDetails: PropTypes.object,
    physicianDetails: PropTypes.object,
}
export default HistoryTotalBillDetails
