/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker, Space } from 'antd'
import AdminHeader from './AdminHeader'
import AdminSideBarNavigation from './AdminSideBarNavigation'
import SubFooter from './SubFooter'
import AdminBreadCrumb from './AdminBreadCrumb'
import {
    getAdminPatientList,
    getAdminPhysicianList,
    getAdminTransactions,
} from 'actions/adminActions'
import { Table } from 'antd'
import Loading from 'shared/Loader'
import { currencyFormatter } from 'utils/helper'
import { dateFormat, yyyymmddFormat } from 'constants/Constant'
const { RangePicker } = DatePicker

function AdminTransactions(props) {
    const { history } = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAdminTransactions('', '', '', ''))
        dispatch(getAdminPhysicianList())
        dispatch(getAdminPatientList())
    }, [''])
    const [serchValue, setSerchValue] = useState('')
    const [selectedPhysician, setSelectedPhysician] = useState('')
    const [selectedPatient, setSelectedPatient] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const adminTransactionsDetails = useSelector(
        (state) => state.adminTransactionsDetails
    )
    const patientDetails = useSelector((state) => state.adminPatientDetails)
    const physicianDetails = useSelector((state) => state.adminPhysicianDetails)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const columns = [
        {
            title: 'Physician',
            dataIndex: 'receiver_name',
            sorter: (a, b) => a.receiver_name.localeCompare(b.receiver_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Patient',
            dataIndex: 'sender_name',
            sorter: (a, b) => a.sender_name.localeCompare(b.sender_name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.amount - b.amount,
            render: (amount) => (
                <div className="patient-name">
                    {currencyFormatter.format(amount)}{' '}
                </div>
            ),
        },
        {
            title: 'Date',
            render: (key) => (
                <div className="patient-name">
                    {' '}
                    {moment(key.created_datetime).format('l')}
                </div>
            ),
        },
    ]
    const updateSearch = (event) => {
        event.preventDefault()
        setSerchValue(event.target.value.toLowerCase().substr(0, 20))
    }
    const handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }
    const handlePhysicianFilter = (event) => {
        setSelectedPhysician(event.target.value)
        setTimeout(() => {
            dispatch(
                getAdminTransactions(
                    selectedStartDate || '',
                    selectedEndDate || '',
                    event.target.value,
                    selectedPatient || ''
                )
            )
        }, 200)
    }
    const handlePatientFilter = (event) => {
        setSelectedPatient(event.target.value)
        setTimeout(() => {
            dispatch(
                getAdminTransactions(
                    selectedStartDate || '',
                    selectedEndDate || '',
                    selectedPhysician || '',
                    event.target.value
                )
            )
        }, 200)
    }
    const handleDateRange = (date) => {
        const startDate = date && moment(date[0]).format(yyyymmddFormat)
        const endDate = date && moment(date[1]).format(yyyymmddFormat)
        setSelectedStartDate(startDate)
        setSelectedEndDate(endDate)
        if (startDate && endDate) {
            setTimeout(() => {
                dispatch(
                    getAdminTransactions(
                        startDate,
                        endDate,
                        selectedPhysician,
                        selectedPatient
                    )
                )
            }, 200)
        }
    }
    const resetFilters = () => {
        dispatch(getAdminTransactions('', '', '', ''))
        setSelectedPatient('')
        setSelectedPhysician('')
        window.location.reload()
    }
    const filteredData =
        adminTransactionsDetails &&
        adminTransactionsDetails.filter((d) => {
            return (
                (d.receiver_name &&
                    d.receiver_name.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.sender_name &&
                    d.sender_name.toLowerCase().indexOf(serchValue) !== -1) ||
                (d.amount && d.amount.indexOf(serchValue) !== -1)
            )
        })
    const phyTempArray = []
    const patTempArray = []
    physicianDetails.map((data) => {
        return phyTempArray.push(data.full_name)
    })
    const uniquePhysician = [...new Set(phyTempArray)].map((data, id) => {
        return (
            <option key={id} value={data} name={data}>
                {data}
            </option>
        )
    })
    patientDetails.map((data) => {
        return patTempArray.push(data.full_name)
    })
    const uniquePatient = [...new Set(patTempArray)].map((data, id) => {
        return (
            <option key={id} value={data} name={data}>
                {data}
            </option>
        )
    })
    return (
        <div className="dashboard-main-wrapper admin-dashboard">
            <AdminHeader />
            <AdminSideBarNavigation history={history} />
            <div className="dashboard-wrapper">
                <div className="list-page">
                    <div className="container-fluid dashboard-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="page-header">
                                    <h3 className="mb-2 text-left">
                                        All Transactions{' '}
                                    </h3>
                                    <AdminBreadCrumb
                                        routes={[
                                            {
                                                path: '/admin-dashboard',
                                                name: 'Dashboard',
                                            },
                                            {
                                                path: '/admin-transaction',
                                                name: 'All Transactions',
                                                isActive: true,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="page-header">
                                    {/* <a style= { { zIndex: 1 } } className='float-right clear-all-link"'>Clear All</a> */}
                                    <button
                                        className=" float-right btn btn-link logout-link"
                                        style={{ zIndex: 1 }}
                                        onClick={resetFilters}
                                    >
                                        Clear All
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <form>
                                                    <input
                                                        className="form-control form-control-lg search-input"
                                                        type="search"
                                                        name="patientSearchParam"
                                                        value={serchValue || ''}
                                                        onChange={updateSearch}
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                    />
                                                </form>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                                <select
                                                    className="form-control"
                                                    onChange={
                                                        handlePhysicianFilter
                                                    }
                                                >
                                                    <option defaultValue="Filter By Physician">
                                                        {' '}
                                                        {selectedPhysician
                                                            ? selectedPhysician
                                                            : 'Filter By Physician'}
                                                    </option>
                                                    {uniquePhysician}
                                                </select>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                                <select
                                                    className="form-control"
                                                    onChange={
                                                        handlePatientFilter
                                                    }
                                                >
                                                    <option defaultValue="Filter By Patient">
                                                        {' '}
                                                        {selectedPatient
                                                            ? selectedPatient
                                                            : 'Filter By Patient'}
                                                    </option>
                                                    {uniquePatient}
                                                </select>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                                <div className="form-control">
                                                    <Space
                                                        direction="vertical"
                                                        size={12}
                                                    >
                                                        <RangePicker
                                                            defaultValue={[
                                                                moment(
                                                                    new Date()
                                                                ),
                                                                moment(
                                                                    new Date()
                                                                ),
                                                            ]}
                                                            format={dateFormat}
                                                            onChange={
                                                                handleDateRange
                                                            }
                                                        />
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </h5>
                                    <div className="card-body">
                                        {/* <div className="table-responsive"> */}
                                        {isLoading && (
                                            <div className="mt-5 mx-4">
                                                <Loading />
                                            </div>
                                        )}
                                        {!isLoading && (
                                            <Table
                                                columns={columns}
                                                dataSource={filteredData}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SubFooter />
                </div>
            </div>
        </div>
    )
}

AdminTransactions.propTypes = {
    history: PropTypes.object,
}
export default AdminTransactions
