import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createStore, applyMiddleware, AnyAction } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import rootReducer, { ReducersType } from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = createStore(rootReducer, {}, composedEnhancers);

export type AppState = ReducersType;
export type AppDispatch = typeof store.dispatch & ThunkDispatch<AppState, null, AnyAction>;

// Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;