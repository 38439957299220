import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './Banking/CheckoutForm'
import PropTypes from 'prop-types'
import { STRIPE_PUBLISHABLE_KEY } from 'config'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const AddBankCard = (props) => {
    return (
        <div className="App">
            <div className="product">
                <div>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm
                            patient={props.patient}
                            closeModal={props.closeModal}
                            addbankCardFunc={props.addbankCardFunc}
                            addbankCardPageFunc={props.addbankCardPageFunc}
                            addbankCardBackFunc={props.addbankCardBackFunc}
                            isVisiable={props.isVisiable}
                            totalBalancePage={props.totalBalancePage}
                            goToDebitCreditCardPageFunc={
                                props.goToDebitCreditCardPageFunc
                            }
                            goToPaymentMethodPageFunc={
                                props?.goToPaymentMethodPageFunc
                            }
                            goToPaymentMethodPageFromPastDueFunc={
                                props.goToPaymentMethodPageFromPastDueFunc
                            }
                            remainingBalancePage={props.remainingBalancePage}
                            goToDebitCreditCardPageFromPastDueFunc={
                                props.goToDebitCreditCardPageFromPastDueFunc
                            }
                            pastDuePage={props.pastDuePage}
                            goToPayMethodPageFunc={props.goToPayMethodPageFunc}
                            goToDebitCreditCardPageFromRemainingBalanceFunc={
                                props.goToDebitCreditCardPageFromRemainingBalanceFunc
                            }
                        />
                    </Elements>
                </div>
            </div>
        </div>
    )
}
AddBankCard.propTypes = {
    patient: PropTypes.bool,
    isVisiable: PropTypes.bool,
    closeModal: PropTypes.func,
    addbankCardFunc: PropTypes.func,
    addbankCardPageFunc: PropTypes.func,
    addbankCardBackFunc: PropTypes.func,
    totalBalancePage: PropTypes.string,
    goToDebitCreditCardPageFunc: PropTypes.func,
    goToPaymentMethodPageFunc: PropTypes.func,
    goToPaymentMethodPageFromPastDueFunc: PropTypes.func,
    remainingBalancePage: PropTypes.string,
    goToDebitCreditCardPageFromPastDueFunc: PropTypes.func,
    pastDuePage: PropTypes.string,
    goToPayMethodPageFunc: PropTypes.func,
    goToDebitCreditCardPageFromRemainingBalanceFunc: PropTypes.func,
}

export default AddBankCard
