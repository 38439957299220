import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// import { isMobile } from 'constants/Constant';
import { CgChevronLeft } from 'react-icons/cg'
import {
    getPatientNotifications,
    updatePatientUserDetails,
} from 'actions/notificationActions'
import 'antd/dist/antd.css'
import { Switch } from 'antd'
import ModalLoader from 'shared/ModalLoader'

const PatientNotificationMobile = (props) => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const patNotification = useSelector((state) => state.patNotification)
    useEffect(() => {
        dispatch(getPatientNotifications())
    }, [''])
    const onChange = (checked, x) => {
        const id = patNotification && patNotification.id
        patNotification[x] = checked ? 1 : 0
        delete patNotification.id
        dispatch(updatePatientUserDetails(id, patNotification))
    }
    return (
        <div className="mobile_account_info notification-page" id="content">
            <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                <CgChevronLeft
                    className="back_icon"
                    onClick={() => props.history.goBack()}
                />
                <label className="nav-heading">Notifications</label>
                <span></span>
                {/* Below component used for both web and mobile */}
            </nav>
            {isLoading && (
                <div className="p-3">
                    <ModalLoader />
                </div>
            )}
            <div className="row no-gutters">
                <div className="col-md-6">
                    <table className="table table-borderless text-left pat_notification">
                        {!isLoading && (
                            <tbody className="border-0">
                                <tr>
                                    <th>Actions</th>
                                    <th>SMS</th>
                                    <th>Email</th>
                                </tr>
                                <tr>
                                    <td> Upcoming Payment </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_upcoming_payment_sms_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_upcoming_payment_sms_status'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_upcoming_payment_email_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_upcoming_payment_email_status'
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td> Payment Is 2 Days Past Due </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_payment_two_days_past_due_sms_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_payment_two_days_past_due_sms_status'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_payment_two_days_past_due_email_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_payment_two_days_past_due_email_status'
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td> Payment Sent w/ AutoPay </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_an_edit_was_made_sms_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_an_edit_was_made_sms_status'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_an_edit_was_made_email_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_an_edit_was_made_email_status'
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td> An Edit Was Made </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_attempt_login_sms_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_attempt_login_sms_status'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Switch
                                            defaultChecked={
                                                patNotification.is_attempt_login_email_status ||
                                                ''
                                            }
                                            onChange={(checked) =>
                                                onChange(
                                                    checked,
                                                    'is_attempt_login_email_status'
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    )
}
PatientNotificationMobile.propTypes = {
    history: PropTypes.object,
    getNotifications: PropTypes.func,
    updatePatientUserDetails: PropTypes.func,
}
export default PatientNotificationMobile
