import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { getPatientPaymentSchedules } from 'actions/patientBankingActions'
import { FiChevronLeft } from 'react-icons/fi'
import BillPaymentOptionsMobile from './BillPaymentOptionsMobile'

function ConfirmEnterAmountMobile(props) {
    const dispatch = useDispatch()
    const {
        addBillAmount,
        isOpen,
        closeConfirmBillAmount,
        appointmentId,
        physicianDetails,
    } = props
    const [confirmBillAmount, setConfirmBillAmount] = useState('')
    const [confirmBillAmountError, setConfirmBillAmountError] = useState(false)
    const [billPayOption, setbillPayOption] = useState(false)

    const handleChange = (event) => {
        setConfirmBillAmount(event.target.value)
        setConfirmBillAmountError(false)
    }

    const handleNext = () => {
        const data = {
            patient_id: appointmentId,
            physician_id: physicianDetails.physician_id,
            amount: confirmBillAmount,
        }
        if (addBillAmount !== confirmBillAmount) {
            setConfirmBillAmountError('Amount not matched')
        } else {
            dispatch(getPatientPaymentSchedules(data))
            setbillPayOption(!billPayOption)
        }
    }
    const closeBillPayOptions = () => {
        setbillPayOption(!billPayOption)
    }
    return (
        <>
            {isOpen && !billPayOption && (
                <div className="mobile_account_info Re-enter-amount-of-your-bill">
                    <div id="content" className="mb-0">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <FiChevronLeft
                                className="back_icon pointer"
                                onClick={() => closeConfirmBillAmount()}
                            />
                            <label className="nav-heading">
                                {physicianDetails.physician_name ||
                                    physicianDetails.physician_full_name}
                            </label>

                            <button
                                type="button"
                                className="close close-bold-icon z-index1"
                                onClick={() => {
                                    window.location = '/home'
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </nav>
                        <div
                            id="nav-tabContent"
                            className="mobile-content-height"
                        >
                            <div className="heading-wrapper">
                                <span className="heading">
                                    Re-enter bill amount
                                    <br /> to confirm
                                </span>
                            </div>
                            <input
                                type="number"
                                autoFocus
                                className="balance-textfield"
                                name="accessCode"
                                autoComplete="off"
                                placeholder="$0"
                                value={confirmBillAmount}
                                onChange={handleChange}
                            />
                            {confirmBillAmountError && (
                                <small className="alert alert-danger error-msg d-flex justify-content-center w-100">
                                    {confirmBillAmountError}
                                </small>
                            )}
                        </div>
                        <div className="px-3">
                            <button
                                className="btn mobile-green-btn w-100"
                                onClick={handleNext}
                            >
                                {' '}
                                Next{' '}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {billPayOption && (
                <BillPaymentOptionsMobile
                    isOpen={billPayOption}
                    closeBillPayOptions={closeBillPayOptions}
                    billAmount={confirmBillAmount}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
        </>
    )
}

ConfirmEnterAmountMobile.propTypes = {
    closeConfirmBillAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    addBillAmount: PropTypes.string,
    appointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default ConfirmEnterAmountMobile
