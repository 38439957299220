import React from 'react'
import PropTypes from 'prop-types'
import { CardElement } from '@stripe/react-stripe-js'

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: '#fff',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#fce883' },
            '::placeholder': { color: '#87bbfd' },
        },
        invalid: {
            iconColor: '#ffc7ee',
            color: '#ffc7ee',
        },
    },
}

// eslint-disable-next-line react/prop-types
function CardSection({ onChange }) {
    // return <CardElement options={CARD_ELEMENT_OPTIONS} />;
    return (
        <fieldset className="FormGroup">
            <div className="FormRow">
                <CardElement options={CARD_OPTIONS} onChange={onChange} />
            </div>
        </fieldset>
    )
}

CardSection.prototype = {
    onChange: PropTypes.func,
}

export default CardSection
