import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { Table, Divider, Space } from 'antd'
import {
    getPayReview,
    getPatientProfileInfo,
    updatePatientProfileInfo,
} from '../../../../actions/patientActions'
import SidebarNavigation from '../../../account/SidebarNavigation'
import Loading from '../../../../shared/Loader'
import { HiChevronRight } from 'react-icons/hi'
import PompayTopLogo from 'shared/PompayTopLogo'
import { isMobile } from 'constants/Constant'
//TODO: Put this layout back to replace ComingSoonLayout when we fix all bugs under that page
//import PaymentReviewMobile from '../mobile/PaymentReviewMobile'
import ComingSoonLayout from '../mobile/ComingSoonLayout'
// import CurrentHistoryModal from './CurrentHistoryModal';
import { MdSearch } from 'react-icons/md'
import { ddmmyyFormat } from 'constants/Constant'
import { getAccountDetails } from 'actions/accountActions'
import { bolderNameFormatter } from 'utils/helper'
import PatientProfileModal from './PatientProfileModal'
import SuccessAlert from 'shared/SuccessAlert'
export class PaymentReview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            isOpen: false,
            allData: [],
            input: {},
            // isCurrentHistory: false,
            successAlert: false,
            serchValue: '',
            isProfile: false,
            columns: [
                {
                    title: 'Name',
                    dataIndex: '',
                    sorter: (a, b) => a.last_name.localeCompare(b.last_name),
                    sortDirections: ['ascend', 'descend'],
                    render: (key) => (
                        <div className="patient-name">
                            {bolderNameFormatter(key)}
                        </div>
                    ),
                },
                {
                    title: 'Patient I.D',
                    dataIndex: 'patient_appointment_id',
                },
                {
                    title: 'D.O.B',
                    dataIndex: 'date_of_birth',
                    render: (date_of_birth) =>
                        moment(date_of_birth).format(ddmmyyFormat),
                },
                {
                    title: 'Mobile Number',
                    dataIndex: 'phone_number',
                },
                {
                    title: 'Account Status',
                    dataIndex: 'payment_status',
                },
                {
                    title: 'Action',
                    dataIndex: '',
                    key: 'x',
                    render: (key) => (
                        <Space size="middle">
                            <a
                                className="ant-dropdown-link"
                                // onClick={ () => this.openCurrentHistory(key) }
                                onClick={() => this.handleOpenProfile(key)}
                            >
                                <HiChevronRight className="font18" />
                            </a>
                        </Space>
                    ),
                },
            ],
            data: [],
        }
    }

    componentDidMount() {
        this.props.getPayReview().then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    data: res.data.patient_profile_list,
                    allData: res.data,
                })
            }
        })
        this.props.getAccountDetails()
    }
    //   openCurrentHistory = (key) => {
    //       this.setState({ isCurrentHistory: true, currentData: key });
    //   };
    handleOpenProfile = (key) => {
        this.setState({ isProfile: true, profileDetail: key })
        this.props.getPatientProfileInfo(key.patient_id).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    input: res.data,
                })
            }
        })
    }
    handleProfileChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
        })
    }
    updateProfileInfo = () => {
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            zipcode,
            date_of_birth,
            patient_id,
            patient_appointment_id,
        } = this.state.input
        const data = {
            first_name: first_name,
            last_name: last_name,
            patient_id: patient_id,
            patient_appointment_id: patient_appointment_id,
            email_id: email_id,
            phone_number: phone_number,
            zipcode: zipcode,
            date_of_birth: date_of_birth,
        }
        this.props.updatePatientProfileInfo(data, patient_id).then((res) => {
            if (res.statusCode === 200) {
                this.setState({ successAlert: true, isProfile: false })
                setTimeout(() => {
                    this.props.getPayReview('')
                    window.location.reload()
                }, 500)
            }
        })
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false, isProfile: false })
    }
    handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    closeModal = () => {
        this.setState({ isProfile: false })
    }
    updateSearch = (event) => {
        this.setState({ serchValue: event.target.value.substr(0, 20) })
    }

    render() {
        const {
            data,
            columns,
            allData,
            //   isCurrentHistory,
            serchValue,
            //   currentData,
            isProfile,
            input,
            profileDetail,
            successAlert,
        } = this.state
        const {
            isLoading,
            //   paymentActivityData,
            history,
            //accountDetails,
        } = this.props
        const filteredData =
            data &&
            data.filter((d) => {
                const firstValue = d.first_name && d.first_name.toLowerCase()
                const secondValue = d.last_name && d.last_name.toLowerCase()
                const first_last_name = firstValue.concat(' ', secondValue)
                const last_first_name = secondValue.concat(' ', firstValue)
                return (
                    (d.first_name &&
                        d.first_name
                            .toLowerCase()
                            .indexOf(serchValue.toLowerCase()) !== -1) ||
                    (d.last_name &&
                        d.last_name
                            .toLowerCase()
                            .indexOf(serchValue.toLowerCase()) !== -1) ||
                    first_last_name
                        .toLowerCase()
                        .includes(serchValue.toLowerCase()) ||
                    last_first_name
                        .toLowerCase()
                        .includes(serchValue.toLowerCase()) ||
                    (d.patient_appointment_id &&
                        d.patient_appointment_id.toString() &&
                        d.patient_appointment_id
                            .toString()
                            .includes(serchValue.toLowerCase()))
                )
            })
        return (
            <>
                {!isMobile && (
                    <div>
                        <div className="patient_review">
                            <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar justify-content-between">
                                <div className="d-flex">
                                    <PompayTopLogo
                                        className="small_logo patient_list_logo"
                                        ancorClassName="pompay_small_logo mr-0 mt-2"
                                    />
                                    <div className="vertical-dash"></div>
                                    <form className="form-inline search_wrapper">
                                        <MdSearch className="color_dark_grey font20 m-2" />
                                        <input
                                            className="form-control mr-sm-2 patient_search"
                                            type="search"
                                            value={serchValue}
                                            onChange={this.updateSearch}
                                            placeholder="Search"
                                            aria-label="Search"
                                        />
                                    </form>
                                </div>
                                <div className="d-flex patient_right_nav align-items-center">
                                    {isLoading && (
                                        <div className="mt-5 mx-4">
                                            {' '}
                                            <Loading />{' '}
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <>
                                            <div className="vertical-dash"></div>
                                            <div className="d-flex mx-4 font12 align-items-center">
                                                <span className="nav-label mr-3">
                                                    All
                                                </span>
                                                <span className="nav-heding">
                                                    {allData.total_patient}
                                                </span>
                                            </div>
                                            <div className="d-flex font12 align-items-center nav-border px-4">
                                                <span className="nav-label mr-3">
                                                    Active
                                                </span>
                                                <span className="nav-heding">
                                                    {allData.active_patient_emi}
                                                </span>
                                            </div>
                                            <div className="d-flex font12 align-items-center  pl-4 pr-2">
                                                <span className="nav-label mr-3">
                                                    Late
                                                </span>
                                                <span className="nav-heding">
                                                    {allData.total_past_due}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </nav>
                            <div className="wrapper">
                                <SidebarNavigation history={history} />
                                <div id="content">
                                    <Divider />
                                    {isLoading && (
                                        <div className="mt-5 mx-4">
                                            <Loading />
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <Table
                                            columns={columns}
                                            dataSource={filteredData}
                                            onChange={this.handleChange}
                                            scroll={{ y: 535 }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isMobile && (
                    <>
                        {/* <PaymentReviewMobile
                        data={filteredData}
                        updateSearch={this.updateSearch}
                        serchValue={serchValue}
                        history={history}
                        physicianId={
                            accountDetails &&
                            accountDetails.physician &&
                            accountDetails.physician[0] &&
                            accountDetails.physician[0].physician_id
                        }
                    /> */}
                        <ComingSoonLayout
                            content="To view your patients, please use your computer,
                    laptop, or tablet"
                        ></ComingSoonLayout>
                    </>
                )}
                {isProfile && (
                    <PatientProfileModal
                        profileDetail={profileDetail}
                        isOpen={isProfile}
                        closeModal={this.closeModal}
                        input={input}
                        handleProfileChange={this.handleProfileChange}
                        updateProfileInfo={this.updateProfileInfo}
                    />
                )}
                {/* {isCurrentHistory && (
                  <CurrentHistoryModal
                      history={ history }
                      isOpen={ isCurrentHistory }
                      balanceDetails={ paymentActivityData }
                      closeModal={ this.closeModal }
                      currentData={ currentData }
                      physicianId={
                          accountDetails &&
              accountDetails.physician &&
              accountDetails.physician[ 0 ] &&
              accountDetails.physician[ 0 ].physician_id
                      }
                  />
              )} */}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message="The Patient profile has been updated"
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        paymentActivityData: state.paymentActivityData,
        accountDetails: state.accountDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPayReview: () => dispatch(getPayReview()),
        getAccountDetails: () => dispatch(getAccountDetails()),
        getPatientProfileInfo: (data) => dispatch(getPatientProfileInfo(data)),
        updatePatientProfileInfo: (data, id) =>
            dispatch(updatePatientProfileInfo(data, id)),
        // getPayActivity:(id) => dispatch(getPayActivity(id))
    }
}

PaymentReview.propTypes = {
    getPayReview: PropTypes.func,
    getAccountDetails: PropTypes.func,
    getPayActivity: PropTypes.func,
    getPatientProfileInfo: PropTypes.func,
    updatePatientProfileInfo: PropTypes.func,
    paymentActivityData: PropTypes.object,
    accountDetails: PropTypes.object,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReview)
