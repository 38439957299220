import React, { Component } from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import Loading from 'shared/Loader'
import { maxLengthCheck } from 'utils/helper'
import { CgChevronLeft } from 'react-icons/cg'
import ConfirmDelete from 'shared/ConfirmDelete'
import 'antd/dist/antd.css'
import SuccessAlert from 'shared/SuccessAlert'

export class EditOfficeMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDetails: false,
            addOffice: false,
            input: {},
            errors: {},
            isConfirmDelete: false,
            successAlert: false,
        }
    }

    componentDidMount() {
        this.props.getOfficeAddressDetails(this.props.officeId).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    input: res.data.physician_office,
                })
            }
        })
    }

    confirmOfficeRemove = () => {
        this.setState({
            isConfirmDelete: !this.state.isConfirmDelete,
        })
    }

    handleRemoveOffice = () => {
        const { isTeam, OfficeInfoData } = this.props
        if (isTeam) {
            if (OfficeInfoData.is_locked) {
                this.setState({
                    successAlert: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            } else {
                this.props.removeOfficeAddress(this.props.officeId)
            }
        } else {
            this.props.removeOfficeAddress(this.props.officeId)
            this.props.closeEditOfficeMobile()
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const input = this.state.input
        const data = {
            speciality: input.speciality,
            address1: input.address1,
            address2: '',
            city: input.city,
            state: input.state,
            country: '',
            zipcode: input.zipcode,
            office_hours: input.office_hours,
            website: input.website,
            office_days: input.office_days,
            fee: '',
            phone_number: input.phone_number,
        }
        if (this.validate()) {
            this.props
                .updateOfficeInfoDetails(this.state.input.id, data)
                .then((res) => {
                    if (res) {
                        this.props.closeEditOfficeMobile()
                    }
                })
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        if (event.target.id === 'office_hours') {
            let obj = event.target.value
            var numbersTime = obj.replace(/\D/g, ''),
                charTime = { 2: ':' }
            obj = ''
            for (var j = 0; j < numbersTime.length; j++) {
                obj += (charTime[j] || '') + numbersTime[j]
                input[event.target.name] = obj
            }
        } else {
            input[event.target.name] = event.target.value
            if (event.target.name === 'phone_number') {
                let obj = event.target.value
                var numbers = obj.replace(/\D/g, ''),
                    char = { 0: '(', 3: ') ', 6: '-' }
                obj = ''
                for (var i = 0; i < numbers.length; i++) {
                    obj += (char[i] || '') + numbers[i]
                    input[event.target.name] = obj
                }
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }
    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['speciality']) {
            isValid = false
            errors['speciality'] = 'Please enter speciality'
        }
        if (typeof input['speciality'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z]+$/)
            if (!pattern.test(input['speciality'])) {
                isValid = false
                errors['speciality'] = 'Please enter alphabets only'
            }
        }

        if (!input['address1']) {
            isValid = false
            errors['address1'] = 'Please enter address'
        }

        if (!input['city']) {
            isValid = false
            errors['city'] = 'Please enter city'
        }
        if (typeof input['city'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['city'])) {
                isValid = false
                errors['city'] = 'Please enter alphabets only'
            }
        }
        if (!input['state']) {
            isValid = false
            errors['state'] = 'Please enter state'
        }
        if (typeof input['state'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['state'])) {
                isValid = false
                errors['state'] = 'Please enter alphabets only'
            }
        }

        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter zipcode'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone_number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }

        if (!input['office_hours']) {
            isValid = false
            errors['office_hours'] = 'Please enter office hours'
        }
        // if (!input[ 'website' ]) {
        //     isValid = false;
        //     errors[ 'website' ] = 'Please enter website';
        // }
        if (input['website'] && !validator.isURL(input['website'])) {
            isValid = false
            errors['website'] = 'Please enter valid url'
        }

        if (!input['office_days']) {
            isValid = false
            errors['office_days'] = 'Please enter office days'
        }
        if (typeof input['office_days'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z -]+$/)
            if (!pattern.test(input['office_days'])) {
                isValid = false

                errors['office_days'] = 'Please enter in monday-friday format.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }

    render() {
        const { isLoading, closeEditOfficeMobile } = this.props
        const {
            input,
            errors,
            isConfirmDelete,
            successAlert,
            successAlertMessage,
        } = this.state
        const startDate =
            input &&
            input.office_hours &&
            input.office_hours.split('-')[0].split(' ')[0]
        const endDate =
            input &&
            input.office_hours &&
            input.office_hours.split('-')[1].split(' ')[0]
        return (
            <>
                <div className="location-details mobile_account_info">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-border navbar-light custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => closeEditOfficeMobile()}
                            />
                            <label className="nav-heading">
                                Location Details
                            </label>
                            <span></span>
                        </nav>
                        {isLoading && (
                            <div className="p-3">
                                <Loading />
                            </div>
                        )}
                        {!isLoading && (
                            <form>
                                <div className="form-row flex-column p-0">
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="speciality"
                                                    id="speciality"
                                                    value={
                                                        input.speciality || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="speciality"
                                                >
                                                    Speciality
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.speciality}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="address1"
                                                    id="address1"
                                                    value={input.address1 || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="address1"
                                                >
                                                    Address
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.address1}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    value={input.city || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="city"
                                                >
                                                    City
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.city}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="state"
                                                    id="state"
                                                    value={input.state || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="state"
                                                >
                                                    State
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.state}
                                        </small>
                                    </div>

                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    maxLength="5"
                                                    onInput={maxLengthCheck}
                                                    name="zipcode"
                                                    id="zipcode"
                                                    value={input.zipcode || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="zipcode"
                                                >
                                                    Zipcode
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.zipcode}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    maxLength="14"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    value={
                                                        input.phone_number || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="phone_number"
                                                >
                                                    Phone number
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.phone_number}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="website"
                                                    id="website"
                                                    value={input.website || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="website"
                                                >
                                                    Website (optional)
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.website}
                                        </small>
                                    </div>
                                    {/* <div className="col-sm-4 my-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text account_info_label">
                                                Fee
                                            </div>
                                            <input
                                                type="number"
                                                name="fee"
                                                id="fee"
                                                value={ input.fee || '' }
                                                onChange={ this.handleChange }
                                                className="form-control account_info_field"
                                            />
                                        </div>
                                    </div>
                                    <small className="text-danger float-left">{errors.fee}</small>
                                </div> */}
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <fieldset className="input-group-prepend">
                                                    <span className="AM-label left">
                                                        <input
                                                            type="type"
                                                            name="office_hours_start"
                                                            id="office_hours"
                                                            defaultValue={
                                                                startDate || ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            placeholder="00:00"
                                                            maxLength="5"
                                                            className="form-control account_info_field AM-field"
                                                        />
                                                    </span>
                                                    <span className="PM-label left">
                                                        <input
                                                            type="type"
                                                            name="office_hours_end"
                                                            id="office_hours"
                                                            defaultValue={
                                                                endDate || ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            placeholder="00:00"
                                                            maxLength="5"
                                                            className="form-control account_info_field PM-field"
                                                        />
                                                    </span>
                                                    <label
                                                        className="input-group-text account_info_label"
                                                        htmlFor="office_hours"
                                                    >
                                                        Office hours
                                                    </label>
                                                    {/* <TimePicker.RangePicker use12Hours format="h:mm A" onChange={ handleChange } className="form-control account_info_field" id="office-hours" /> */}
                                                </fieldset>
                                                {/* <label className="input-group-text account_info_label" htmlFor="time">
                                                Office hours
                                            </label> */}
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.office_hours}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="office_days"
                                                    id="office_days"
                                                    value={
                                                        input.office_days || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    placeholder="Monday-Friday"
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="office_days"
                                                >
                                                    Days open
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.office_days}
                                        </small>
                                    </div>
                                </div>
                            </form>
                        )}
                        <button
                            className="btn btn-link mobile-remove-link"
                            onClick={() => this.confirmOfficeRemove()}
                        >
                            Remove
                        </button>
                    </div>
                    <div className="px-3 pb-3">
                        <button
                            type="submit"
                            className="btn mobile-green-btn"
                            onClick={this.handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
                {isConfirmDelete && (
                    <ConfirmDelete
                        isConfirmDelete={isConfirmDelete}
                        confirmRemove={this.confirmOfficeRemove}
                        handleRemoveFunc={this.handleRemoveOffice}
                        role="office"
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

EditOfficeMobile.propTypes = {
    history: PropTypes.object,
    details: PropTypes.object,
    getOfficeInfo: PropTypes.func,
    removeOfficeAddress: PropTypes.func,
    getOfficeAddressDetails: PropTypes.func,
    updateOfficeInfoDetails: PropTypes.func,
    OfficeInfoData: PropTypes.object,
    isLoading: PropTypes.bool,
    officeId: PropTypes.number,
    closeEditOfficeMobile: PropTypes.func,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    accountDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default EditOfficeMobile
