import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'

function SuccessAlert(props) {
    const { isOpen, closeSuccessAlert, role, message, restrict } = props
    return (
        <>
            <Modal
                isOpen={isOpen}
                style={MODAL_STYLES}
                ariaHideApp={false}
                id="message-modal"
            >
                <div className="d-flex justify-content-center flex-column">
                    {!role && <h4 className="heading">{message}</h4>}
                    {!role && (
                        <span className="message">
                            If the information does not automatically update,
                            please refresh the page.
                        </span>
                    )}
                    {role && <h4 className="heading"> {message}</h4>}
                    {role && (
                        <span className="message">
                            If the information does not automatically update,
                            please refresh the page.
                        </span>
                    )}
                    <button
                        onClick={() => closeSuccessAlert()}
                        className="btn modal-btn"
                    >
                        {restrict ? 'Ok' : 'Done'}
                    </button>
                </div>
            </Modal>
        </>
    )
}

SuccessAlert.propTypes = {
    isOpen: PropTypes.bool,
    restrict: PropTypes.bool,
    role: PropTypes.string,
    message: PropTypes.string,
    description: PropTypes.string,
    closeSuccessAlert: PropTypes.func,
}

export default SuccessAlert
