import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import SelectPhysician from './SelectPhysician'
import FindPhysicianModal from '../physician/FindPhysicianModal'
import { FiChevronRight } from 'react-icons/fi'
function ChooseModal(props) {
    const { isOpen, closeOptionModal, physicianIds, physicianDetails } = props
    const [openPhysicianSelectModal, setOpenPhysicianSelectModal] =
        useState(false)
    const [openAddbillModal, setOpenAddbillModal] = useState(false)
    const handleSelectPhysician = () => {
        setOpenPhysicianSelectModal(!openPhysicianSelectModal)
    }
    const addNewBill = () => {
        setOpenAddbillModal(!openAddbillModal)
    }
    const closePhysiaicanSelect = () => {
        setOpenPhysicianSelectModal(!openPhysicianSelectModal)
    }
    const closeBillModal = () => {
        setOpenAddbillModal(!openAddbillModal)
    }
    return (
        <>
            {!openPhysicianSelectModal && !openAddbillModal && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeOptionModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="Choose-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => closeOptionModal()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="modal-body">
                                <div>
                                    <p className="modal-heading">
                                        Choose one to get started
                                    </p>
                                    <div className="d-flex flex-column">
                                        <button
                                            className="btn btn_user_custom_outline outline-btn"
                                            name="patient"
                                            value="2"
                                            type="button"
                                            onClick={addNewBill}
                                        >
                                            <span>
                                                Add a{' '}
                                                <span className="bold-text">
                                                    new bill
                                                </span>
                                            </span>
                                            <FiChevronRight className="float-right" />
                                        </button>
                                        <button
                                            className="btn btn_user_custom_outline outline-btn"
                                            name="provider"
                                            value="1"
                                            type="button"
                                            onClick={handleSelectPhysician}
                                        >
                                            <span>
                                                Add a{' '}
                                                <span className="bold-text">
                                                    physician
                                                </span>
                                            </span>
                                            <FiChevronRight className="float-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <button onClick={ handleNext }> Next </button> */}
                        </div>
                    </div>
                </Modal>
            )}
            {openPhysicianSelectModal && (
                <FindPhysicianModal
                    isOpen={openPhysicianSelectModal}
                    closeModal={closePhysiaicanSelect}
                    closeOptionModal={closeOptionModal}
                />
            )}
            {openAddbillModal && (
                <SelectPhysician
                    isOpen={openAddbillModal}
                    closePhysiaicanSelect={closeBillModal}
                    history={props.history}
                    physicianIds={physicianIds}
                    physicianDetails={physicianDetails}
                    closeOptionModal={closeOptionModal}
                />
            )}
        </>
    )
}

ChooseModal.propTypes = {
    closeOptionModal: PropTypes.func,
    isOpen: PropTypes.bool,
    history: PropTypes.object,
    transferFundData: PropTypes.object,
    physicianDetails: PropTypes.object,
    physicianIds: PropTypes.array,
}

export default ChooseModal
