import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'antd/dist/antd.css'
import {
    getPatientUserDetails,
    updatePatientUserDetails,
} from 'actions/patientAccountActions'
import { checkValidEmail } from 'actions/accountActions'
import { deactivatePatientAction } from 'actions/patientActions'
import Loading from 'shared/Loader'
import { maxLengthCheck } from 'utils/helper'
import { CgChevronLeft } from 'react-icons/cg'
import { MdLock } from 'react-icons/md'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdChevronRight } from 'react-icons/md'
import moment from 'moment'
import { dateFormat } from 'constants/Constant'
import ConfirmDelete from 'shared/ConfirmDelete'
export class PatientDetailsMobile extends Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            isEdit: false,
            isConfirmDelete: false,
        }
    }
    componentDidMount() {
        this._isMounted = true
        this.props.getPatientUserDetails().then((res) => {
            if (res) {
                this.setState({
                    input: res ? res : [],
                })
            }
        })
    }
    componentWillUnmount() {
        this._isMounted = false
        this.setState({ input: [] })
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const input = this.state.input
        const id = input.patient_id
        const data = {
            first_name: input.first_name,
            last_name: input.last_name,
            date_of_birth: input.date_of_birth,
            phone_number: input.phone_number,
            email_id: input.email_id,
            zipcode: input.zipcode,
            patient_appointment_id: input.patient_appointment_id,
        }
        const check_email_data = {
            email_id: input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            this.props.updatePatientUserDetails(id, data)
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
        this.setState({ isEdit: !this.state.isEdit })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter your first name.'
        }

        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }

        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter your last name.'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your phone number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (typeof input['phone_number'] !== 'undefined') {
            const pattern = new RegExp(/^[- +()]*[0-9][- +()0-9]*$/)
            if (!pattern.test(input['phone_number'])) {
                isValid = false

                errors['phone_number'] = 'Please enter valid numbers'
            }
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your owner passcode.'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Owner passcode should be of 5 digit.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    editDetails = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        })
    }
    deActivateAccount = (event) => {
        event && event.preventDefault()
        this.setState({ isConfirmDelete: !this.state.isConfirmDelete })
    }
    handleRemoveProfile = () => {
        this.props.deactivatePatientAction()
    }
    render() {
        const { isLoading, history } = this.props
        const { input, errors, isEdit, isConfirmDelete } = this.state
        const enabled = !isEdit
        return (
            <>
                <div className="mobile_account_info">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => history.goBack()}
                            />
                            <label className="nav-heading">Account</label>
                            <a
                                className={
                                    !isEdit ? 'edit-navlink' : 'cancel-navlink'
                                }
                                onClick={this.editDetails}
                            >
                                {!isEdit ? 'Edit' : 'Cancel'}
                            </a>
                        </nav>
                        <div className="mobile-content-height">
                            <div
                                className="tab-pane fade show active"
                                id="nav-account"
                                role="tabpanel"
                                aria-labelledby="nav-account-tab"
                            >
                                {isLoading && (
                                    <div className="p-3">
                                        {' '}
                                        <Loading />{' '}
                                    </div>
                                )}
                                {!isLoading && (
                                    <>
                                        <div className="row no-gutters account-details-form">
                                            <div className="col-md-12 p-0">
                                                <form>
                                                    <div className="flex-column">
                                                        <div className="form-row">
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            name="first_name"
                                                                            type="text"
                                                                            id="fname"
                                                                            className="form-control account_info_field"
                                                                            placeholder="Enter your first name"
                                                                            value={
                                                                                input.first_name ||
                                                                                ''
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                        />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="fname"
                                                                        >
                                                                            First
                                                                            name
                                                                        </label>
                                                                    </div>
                                                                    <small className="text-danger">
                                                                        {errors.first_name ||
                                                                            ''}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            name="last_name"
                                                                            type="text"
                                                                            id="lname"
                                                                            className="form-control account_info_field"
                                                                            placeholder="Enter your last name"
                                                                            value={
                                                                                input.last_name ||
                                                                                ''
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                        />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="lname"
                                                                        >
                                                                            Last
                                                                            name
                                                                        </label>
                                                                    </div>
                                                                    <small className="text-danger">
                                                                        {errors.last_name ||
                                                                            ''}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            disabled
                                                                            name="last_name"
                                                                            id="dob"
                                                                            type="text"
                                                                            className="form-control account_info_field"
                                                                            placeholder="Enter your last name"
                                                                            value={moment(
                                                                                input.date_of_birth
                                                                            ).format(
                                                                                dateFormat
                                                                            )}
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                        />
                                                                        <MdLock className="lock-icon" />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="dob"
                                                                        >
                                                                            Date
                                                                            of
                                                                            birth
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            name="phone_number"
                                                                            id="phn-number"
                                                                            type="text"
                                                                            maxLength="14"
                                                                            className="form-control account_info_field"
                                                                            placeholder="Enter mobile number"
                                                                            value={
                                                                                input.phone_number ||
                                                                                ''
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                        />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="phn-number"
                                                                        >
                                                                            Phone
                                                                            number
                                                                        </label>
                                                                    </div>
                                                                    <IoMdCheckmarkCircle className="lock-icon right-tick" />
                                                                    <small className="text-danger">
                                                                        {errors.phone_number ||
                                                                            ''}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            name="email"
                                                                            type="email"
                                                                            id="email"
                                                                            className="form-control account_info_field"
                                                                            placeholder="Enter email id"
                                                                            value={
                                                                                input.email_id ||
                                                                                ''
                                                                            }
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                        />
                                                                        <MdChevronRight className="lock-icon right-arrow" />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="email"
                                                                        >
                                                                            Email
                                                                        </label>
                                                                    </div>
                                                                    <small className="text-danger">
                                                                        {errors.email_id ||
                                                                            ''}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <input
                                                                            type="number"
                                                                            maxLength="5"
                                                                            onInput={
                                                                                maxLengthCheck
                                                                            }
                                                                            name="zipcode"
                                                                            autoComplete="off"
                                                                            placeholder="Enter zipcode"
                                                                            id="zipcode"
                                                                            disabled={
                                                                                enabled
                                                                            }
                                                                            value={
                                                                                input.zipcode ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .handleChange
                                                                            }
                                                                            className="form-control account_info_field"
                                                                        />
                                                                        <label
                                                                            className="input-group-text account_info_label"
                                                                            htmlFor="zipcode"
                                                                        >
                                                                            Zipcode
                                                                        </label>
                                                                    </div>
                                                                    <small className="text-danger">
                                                                        {errors.zipcode ||
                                                                            ''}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <span
                                                            className="mobile-remove-link"
                                                            onClick={
                                                                this
                                                                    .deActivateAccount
                                                            }
                                                        >
                                                            Deactivate my
                                                            account
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>{' '}
                                    </>
                                )}
                            </div>
                        </div>
                        {isEdit && (
                            <div className="px-3 pb-3">
                                <button
                                    disabled={enabled}
                                    className="btn mobile-green-btn w-full"
                                    onClick={this.handleSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {isConfirmDelete && (
                    <ConfirmDelete
                        isConfirmDelete={isConfirmDelete}
                        confirmRemove={this.deActivateAccount}
                        handleRemoveFunc={this.handleRemoveProfile}
                        role="Profile"
                        mode="Profile"
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientUserDetails: () => dispatch(getPatientUserDetails()),
        deactivatePatientAction: () => dispatch(deactivatePatientAction()),
        updatePatientUserDetails: (id, data) =>
            dispatch(updatePatientUserDetails(id, data)),
        checkValidEmail: (data) => dispatch(checkValidEmail(data)),
    }
}

PatientDetailsMobile.propTypes = {
    getPatientUserDetails: PropTypes.func,
    updatePatientUserDetails: PropTypes.func,
    deactivatePatientAction: PropTypes.func,
    checkValidEmail: PropTypes.func,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientDetailsMobile)
