import moment from 'moment'

/**
 * Format date.
 * @param {Date} date Date to formate
 * @param {string} formatString Date format
 * @returns Returns a new formatted date.
 */
function format(date, formatString = 'MM/DD/YYYY') {
    return moment(date).format(formatString)
}

/**
 * Subtracts an amount of month(s).
 * @param {Date} date Date
 * @param {number} amount Amount
 * @returns return new date
 */
function subtractMonth(date, amount) {
    return moment(date).subtract(amount, 'month').toDate()
}

/**
 * Subtracts an amount of year(s).
 * @param {Date} date Date
 * @param {number} amount Amount
 * @returns return new date
 */
function subtractYear(date, amount) {
    return moment(date).subtract(amount, 'year').toDate()
}

export const dateUtils = {
    format,
    subtractMonth,
    subtractYear,
}
