import React, { useState, useEffect } from 'react'
// import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import '../../static/stylesheets/account.scss'
import AccountDetails from './AccountDetails'
import OfficeInfo from './office/index'
import Guidelines from './guidelines/Guidelines'
import Banking from './Banking'
import Team from './team/Team'
import UserActivity from './userActivity/UserActivity'
import AccountTabsHeader from './AccountTabsHeader'
import SidebarNavigation from './SidebarNavigation'
import AccountTabs from './AccountTabs'
function Account(props) {
    const bankTab =
        props.location && props.location.state && props.location.state.bankTab
    const [input, setInput] = useState({
        accountDetails: true,
        officeInfo: false,
        guidelines: false,
        team: false,
        banking: false,
        userActivity: false,
    })
    useEffect(() => {
        setInput({
            banking: bankTab ? bankTab : '',
            accountDetails: bankTab ? false : true,
        })
    }, [''])
    const showSection = (e) => {
        setInput({
            accountDetails: e.target.id === 'nav-account-tab',
            officeInfo: e.target.id === 'nav-officeInfo-tab',
            guidelines: e.target.id === 'nav-guidlines-tab',
            team: e.target.id === 'nav-team-tab',
            banking: e.target.id === 'nav-banking-tab',
            userActivity: e.target.id === 'nav-activity-tab',
        })
    }
    const setToAccount = () => {
        const newInput = { ...input }
        newInput.accountDetails = true
        newInput.officeInfo = false
        newInput.guidelines = false
        newInput.team = false
        newInput.banking = false
        newInput.userActivity = false
        setInput(newInput)
        const elementRemoveClass = document.getElementById('nav-banking-tab')
        const elementAddClass = document.getElementById('nav-account-tab')
        elementRemoveClass.classList.remove('active')
        elementAddClass.classList.add('active')
    }
    return (
        <>
            <AccountTabsHeader history={props.history} />
            <div className="wrapper">
                <SidebarNavigation history={props.history} />
                <div id="content">
                    <AccountTabs showSection={showSection} bankTab={bankTab} />
                    <div
                        className="tab-content dashboard_tab_content_Wrapper"
                        id="nav-tabContent"
                    >
                        {input.accountDetails && <AccountDetails />}
                        {input.officeInfo && <OfficeInfo />}
                        {input.guidelines && <Guidelines />}
                        {input.team && <Team />}
                        {input.banking && (
                            <Banking setToAccount={setToAccount} />
                        )}
                        {input.userActivity && <UserActivity />}
                    </div>
                </div>
            </div>
        </>
    )
}

Account.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}

export default Account
