import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendPushNotifications } from 'actions/notificationActions'
import SuccessAlert from 'shared/SuccessAlert'
import { getAccountDetails } from 'actions/accountActions'
export class SendPushMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            successAlert: false,
            successAlertMessage: '',
            actionFailed: false,
        }
    }
    componentDidMount() {
        this.props.getAccountDetails()
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        // if (event.target.name === 'phone_number') {
        //     let obj = event.target.value
        //     var numbers = obj.replace(/\D/g, ''),
        //         char = { 0:'(',3:') ',6:'-' };
        //     obj = '';
        //     for (var i = 0; i < numbers.length; i++) {
        //         obj += (char[ i ] || '') + numbers[ i ];
        //         input[ event.target.name ] = obj;
        //     }
        // }
        this.setState({
            input,
            errors: {},
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        // if (this.validate()) {
        const input = {}
        input['phone_number'] = ''
        // input[ 'bill_amount' ] = '';
        this.setState({ input: input })
        // const data = {
        //     phone_number: this.state.input.phone_number,
        // }
        const data = {
            search_by: this.state.input.phone_number,
        }
        if (this.state.input.phone_number.trim() === '') {
            const errors = {}
            errors['phone_number'] =
                'Please enter your mobile number or patient ID.'
            this.setState({
                errors: errors,
            })
        } else {
            this.setState({
                errors: {},
            })
            this.props.sendPushNotifications(data).then((res) => {
                if (res.statusCode === 200) {
                    this.setState({
                        successAlert: true,
                        successAlertMessage:
                            'New bill has been sent successfully to patient.',
                    })
                }
            })
        }
        // }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your mobile number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        this.setState({
            errors: errors,
        })

        return isValid
    }

    render() {
        const { accountDetails } = this.props
        const {
            input,
            errors,
            successAlert,
            successAlertMessage,
            actionFailed,
        } = this.state
        //     const enabled =
        //   input &&
        //   input.phone_number &&
        //     input.phone_number.length === 14;
        return (
            <>
                <div className="col-md-12">
                    <div className="message_form padding_left100_right375">
                        <h3 className="text-center message-label">Message:</h3>
                        <p className="message-content">
                            This message is from Dr.{' '}
                            {accountDetails &&
                                accountDetails.physician &&
                                accountDetails.physician[0] &&
                                accountDetails.physician[0].first_name}{' '}
                            office. Your new bill is on its way…
                        </p>
                        <hr className="line_divider"></hr>
                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    maxLength="14"
                                    name="phone_number"
                                    value={input.phone_number || ''}
                                    onChange={this.handleChange}
                                    className="form-control input_field font-family_regular"
                                    placeholder="phone number, patient ID"
                                />
                                <small className="text-danger">
                                    {errors.phone_number || ''}
                                </small>
                            </div>
                            {/*<div className="form-group">
                           <input
                                type="number"
                                name= "bill_amount"
                                value={ input.bill_amount || '' }
                                onChange={ this.handleChange }
                                className="form-control input_field font-family_regular"
                                placeholder="Bill amount"
                            />
                            <small className="text-danger">
                                {errors.bill_amount || ''}
                            </small>
                        </div> */}
                            <button
                                type="submit"
                                className="btn custom_btn green_btn"
                                onClick={this.handleSubmit}
                                // disabled={ !enabled }
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successAlertMessage}
                        restrict={actionFailed ? true : false}
                    />
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        accountDetails: state.accountDetails,
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendPushNotifications: (data) => dispatch(sendPushNotifications(data)),
        getAccountDetails: () => dispatch(getAccountDetails()),
    }
}

SendPushMessage.propTypes = {
    sendPushNotifications: PropTypes.func,
    getAccountDetails: PropTypes.func,
    accountDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPushMessage)
