export function accountDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_ACCOUNT_DETAILS':
            return action.accountDetails
        default:
            return state
    }
}

export function OfficeInfo(state = {}, action) {
    switch (action.type) {
        case 'FETCH_OFFICE_INFO':
            return action.OfficeInfo
        default:
            return state
    }
}

export function officeAddressDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_OFFICE_ADDRESS_DETAILS':
            return action.officeAddressDetails
        default:
            return state
    }
}
// export function setGuideline(state = false, action) {
//   switch (action.type) {
//     case 'SET_GUIDELINES_LIST':
//       return action.setGuideline;
//     default:
//       return state;
//   }
// }

export function banking(state = [], action) {
    switch (action.type) {
        case 'FETCH_BANKING':
            return action.banking
        default:
            return state
    }
}

export function teams(state = [], action) {
    switch (action.type) {
        case 'FETCH_TEAMS':
            return action.teams
        default:
            return state
    }
}

export function userActivity(state = [], action) {
    switch (action.type) {
        case 'FETCH_USER_ACTIVITY':
            return action.userActivity
        default:
            return state
    }
}
