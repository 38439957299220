import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo';
import PompayTopLogo from 'shared/PompayTopLogo'
import { HiUserCircle } from 'react-icons/hi'
import { RiArrowLeftLine } from 'react-icons/ri'

export class RegisterByMobile extends Component {
    constructor() {
        super()
        this.state = {
            input: {},
            errors: {},
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        let obj = event.target.value
        var numbers = obj.replace(/\D/g, ''),
            char = { 0: '(', 3: ') ', 6: '-' }
        obj = ''
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i]
            input[event.target.name] = obj
        }
        this.setState({
            input,
            errors: {},
        })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your mobile number.'
        }

        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (typeof input['phone_number'] !== 'undefined') {
            const pattern = new RegExp(/^[- +()]*[0-9][- +()0-9]*$/)
            if (!pattern.test(input['phone_number'])) {
                isValid = false

                errors['phone_number'] = 'Please enter valid numbers'
            }
        }
        this.setState({
            errors: errors,
        })

      return isValid;
  };

  handleSelectRole = () => {
      if (this.validate())
          this.props.history.push({
              pathname: '/select-role',
              state: {
                  registration: true,
                  formData: this.state.input,
              },
          });
  };

  onKeyDown = (event) => {
      if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          this.handleSelectRole();
      }
  };

  render() {
      const enabled =
      this.state.input &&
      this.state.input.phone_number &&
      this.state.input.phone_number.length === 14;
      const { history } = this.props;
      return (
          <div>
              {/* Top navigation for back button */}
              <nav className="navbar navbar-expand-lg navbar-light custom_navbar align-items-baseline nav-bottom signUp-mobipage">
                  <a className="position-absolute">
                      <RiArrowLeftLine className="color_light_green font30" onClick={ () => history.push('') }/>
                  </a>
                  <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
              </nav>

              {/* <nav className="navbar navbar-expand-lg navbar-light custom_navbar align-items-baseline">
                      <a href="/#"><RiArrowLeftLine className="color_light_green font30"/></a>
                  </nav> */}
                <div className="create_page_background"></div>
                {/* Navigation for Pompay logo */}
                {/* <nav className="navbar navbar-light mb-4">
                      <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block"/>
                      <PompayTopLogo  className="small_logo d-md-none d-lg-none d-xl-none"/>
                  </nav> */}
                <div className="container-fluid create_account_page">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <h4 className="auth-heading mb-3">
                                    Already have an account?
                                </h4>
                                <p className="auth-content">
                                    Great, you can sign in here! With Pompay,
                                    managing your finances has never been
                                    easier.
                                </p>
                                <button
                                    type="button"
                                    className="btn custom_btn white_btn"
                                    onClick={() => history.push('/')}
                                >
                                    Sign In
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center">
                            <div className="form_body">
                                <HiUserCircle className="color_light_green font34 mb-4" />
                                <h4 className="page-heading">
                                    Create your account
                                </h4>
                                <p className="page-content">
                                    Tell us a bit about yourself. We just need
                                    the basics.
                                </p>
                                <form>
                                    <div className="form-group">
                                        <input
                                            autoFocus
                                            type="text"
                                            maxLength="14"
                                            autoComplete="off"
                                            name="phone_number"
                                            value={
                                                this.state.input.phone_number ||
                                                ''
                                            }
                                            onChange={this.handleChange}
                                            className="form-control input_field"
                                            placeholder="Mobile number"
                                            id="phone_number"
                                            onKeyDown={this.onKeyDown}
                                        />
                                        <small className="text-danger">
                                            {this.state.errors.phone_number ||
                                                ''}
                                        </small>
                                    </div>
                                    <p className="my-4">
                                        <a
                                            href="/update-number"
                                            className="link_hover Page-links"
                                        >
                                            New number? Update your account
                                        </a>
                                    </p>
                                    <button
                                        type="button"
                                        className="btn green_btn custom_btn mb-4 font-family_lite"
                                        onClick={this.handleSelectRole}
                                        disabled={!enabled}
                                    >
                                        <i
                                            className="fa fa-lock float-left lock_icon"
                                            aria-hidden="true"
                                        ></i>
                                        Continue
                                    </button>
                                </form>
                                <p className="text-center mb-2 page-content">
                                    We’ll send you a verification code via SMS.
                                </p>
                                <p className="text-center mb-2 page-content">
                                    By continuing, I agree to Pompay’s{' '}
                                    <a className="link_hover color_light_green">
                                        terms of service.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

RegisterByMobile.propTypes = {
    history: PropTypes.object,
}

export default RegisterByMobile
