import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Space } from 'antd'
// import { Progress } from 'antd';
import 'antd/dist/antd.css'
import moment from 'moment'
import { Table, Divider } from 'antd'
import {
    getPatientReviewListing,
    acceptPatientArrangement,
    updateArrangement,
    getReviewArrangementDetails,
    downloadArrangementPdf,
} from 'actions/patientActions'
import SidebarNavigation from '../../../account/SidebarNavigation'
import Loading from '../../../../shared/Loader'
import PompayTopLogo from 'shared/PompayTopLogo'
import { isMobile } from 'constants/Constant'
import { MdSearch } from 'react-icons/md'
import { bolderNameFormatter, currencyFormatter } from 'utils/helper'
import PatientArrangementDetailsModal from './PatientArrangementDetailsModal'
//TODO: Put this layout back to replace ComingSoonLayout when we fix all bugs under that page
//import ReviewArrangementMobile from '../mobile/ReviewArrangementMobile'
import ComingSoonLayout from '../mobile/ComingSoonLayout'
import SuccessAlert from 'shared/SuccessAlert'

export class ReviewArrangement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paid_amount: '',
            total_amount: '',
            unpaid_amount: '',
            selectionType: 'checkbox',
            isArrangementDetails: false,
            serchValue: '',
            isToday: '',
            paymentStatus: '',
            input: {},
            successAlert: false,
            successAlertMessage: '',
            selectedData: [],
            start_date: '',
            end_date: '',
            columns: [
                {
                    title: 'Name',
                    dataIndex: '',
                    sorter: (a, b) => a.last_name.localeCompare(b.last_name),
                    sortDirections: ['ascend', 'descend'],
                    render: (key) => (
                        <div className="patient-name">
                            {bolderNameFormatter(key)}
                        </div>
                    ),
                },
                {
                    title: 'Patient I.D',
                    dataIndex: 'patient_appointment_id',
                },
                {
                    title: 'Discount Applied',
                    dataIndex: 'discount_applied',
                    sorter: (a, b) =>
                        a.discount_applied.localeCompare(b.discount_applied),
                    sortDirections: ['ascend', 'descend'],
                    render: (discount_applied) => <>{discount_applied}</>,
                },
                {
                    title: 'Original Amount',
                    dataIndex: 'original_amount',
                    sorter: (a, b) => a.original_amount - b.original_amount,
                    render: (original_amount) => (
                        <>{currencyFormatter.format(original_amount)}</>
                    ),
                },
                {
                    title: 'Discount Amount',
                    dataIndex: 'discount_amount',
                    sorter: (a, b) => a.discount_amount - b.discount_amount,
                    render: (discount_amount) => (
                        <>{currencyFormatter.format(discount_amount)}</>
                    ),
                },
                {
                    title: 'Adjusted Amount',
                    dataIndex: 'adjusted_amount',
                    sorter: (a, b) => a.adjusted_amount - b.adjusted_amount,
                    render: (adjusted_amount) => (
                        <>{currencyFormatter.format(adjusted_amount)}</>
                    ),
                },
                {
                    title: '',
                    dataIndex: '',
                    // key: 'x',
                    render: (key) => (
                        <Space size="middle">
                            <button
                                className="btn btn-link edit-arrangement"
                                onClick={() =>
                                    this.handleOpenArrangementModal(key)
                                }
                                disabled={key.is_arrangement_accepted}
                            >
                                Edit
                            </button>
                        </Space>
                    ),
                },
                {
                    title: '',
                    dataIndex: '',
                    // key: 'x',
                    render: (key) => (
                        <>
                            {!key.is_arrangement_accepted ? (
                                <button
                                    className="btn accept-btn"
                                    onClick={() =>
                                        this.handleAcceptArrangement(key)
                                    }
                                >
                                    Accept
                                </button>
                            ) : (
                                <span className="accepted-label">Accepted</span>
                            )}
                        </>
                    ),
                },
            ],
            data: [],
        }
    }

    componentDidMount() {
        this.props.getPatientReviewListing('').then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    data: res.data.review_arrangements,
                    paidAmount: res.data.paid_amounts,
                    physicianAccountBalance: res.data.physician_account_balance,
                    unpaidBalance: res.data.unpaid_amounts,
                    pastDuePayments: res.data.total_pending_arrangements,
                    promptPayDiscount: res.data.prompt_pay_percentage,
                })
            }
        })
    }

    handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    onToggleSwitchChange = (check) => {
        this.setState({
            checked: check,
        })
    }
    handleAcceptArrangement = (key) => {
        const data = {
            is_arrangement_accepted: 1,
            patient_payment_ids: [key.patient_payment_id],
        }
        this.props.acceptPatientArrangement(data).then((response) => {
            if (response.statusCode === 200) {
                this.setState({
                    successAlert: true,
                    successAlertMessage:
                        'You have successfully accepted this arrangement',
                    isArrangementDetails: false,
                })
                this.props.getPatientReviewListing('').then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({
                            data: res.data.review_arrangements,
                            paidAmount: res.data.paid_amounts,
                            physicianAccountBalance:
                                res.data.physician_account_balance,
                            unpaidBalance: res.data.unpaid_amounts,
                            pastDuePayments:
                                res.data.total_pending_arrangements,
                            promptPayDiscount: res.data.prompt_pay_percentage,
                            isArrangementDetails: false,
                        })
                    }
                })
            }
        })
    }
    handleOpenArrangementModal = (key) => {
        this.setState({ isArrangementDetails: true, arrangementDetails: key })
        this.props
            .getReviewArrangementDetails(key.patient_payment_id)
            .then((res) => {
                if (res.statusCode === 200) {
                    this.setState({
                        input: res.data,
                    })
                }
            })
    }
    closeModal = () => {
        this.setState({ isArrangementDetails: false })
    }
    updateSearch = (event) => {
        this.setState({ serchValue: event.target.value.substr(0, 20) })
    }
    handleDropDown = (event) => {
        const dateValue = event.target.value
        const filterFlag = true
        var date = new Date()
        if (dateValue === '1-30') {
            const start_date = moment(new Date()).format('MM/DD/YYYY')
            const end_date = moment(date.setDate(date.getDate() - 30)).format(
                'MM/DD/YYYY'
            )
            this.setState({ start_date, end_date })
        } else if (dateValue === '31-60') {
            const start_date = moment(date.setDate(date.getDate() - 31)).format(
                'MM/DD/YYYY'
            )
            const end_date = moment(date.setDate(date.getDate() - 29)).format(
                'MM/DD/YYYY'
            )
            this.setState({ start_date, end_date })
        } else if (dateValue === '61-90') {
            const start_date = moment(date.setDate(date.getDate() - 61)).format(
                'MM/DD/YYYY'
            )
            const end_date = moment(date.setDate(date.getDate() - 29)).format(
                'MM/DD/YYYY'
            )
            this.setState({ start_date, end_date })
        } else if (dateValue === '91+') {
            const start_date = moment(date.setDate(date.getDate() - 91)).format(
                'MM/DD/YYYY'
            )
            const end_date = '01/01/1893'
            this.setState({ start_date, end_date })
        } else {
            const start_date = moment(new Date()).format('MM/DD/YYYY')
            const end_date = '01/01/1893'
            this.setState({ start_date, end_date })
        }
        setTimeout(() => {
            this.props
                .getPatientReviewListing(this.state, filterFlag)
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({
                            data: res.data.review_arrangements,
                            paidAmount: res.data.paid_amounts,
                            physicianAccountBalance:
                                res.data.physician_account_balance,
                            unpaidBalance: res.data.unpaid_amounts,
                            pastDuePayments:
                                res.data.total_pending_arrangements,
                            promptPayDiscount: res.data.prompt_pay_percentage,
                        })
                    }
                })
        }, 500)
    }
    handleArrangementChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (
            input.discount_by_physician > 0 &&
            this.state.arrangementDetails.discount_amount > 0
        ) {
            input.discount_amount =
                (input.original_amount * input.discount_by_physician) / 100
            input.adjusted_amount =
                input.original_amount -
                (input.original_amount * input.discount_by_physician) / 100
        } else {
            input.adjusted_amount =
                input.original_amount - input.discount_amount
        }
        this.setState({
            input,
        })
    }
    updateReviewArrangementFunc = () => {
        const {
            patient_appointment_id,
            original_amount,
            discount_amount,
            adjusted_amount,
            discount_by_physician,
            months,
            patient_id,
        } = this.state.input
        const data = {
            patient_appointment_id: patient_appointment_id,
            original_amount: original_amount && original_amount.toString(),
            discount_amount: discount_amount && discount_amount.toString(),
            adjusted_amount: adjusted_amount && adjusted_amount.toString(),
            discount_by:
                discount_by_physician && discount_by_physician.toString(),
            months: months && months.toString(),
            patient_id: patient_id && patient_id.toString(),
        }
        const id = this.state.arrangementDetails.patient_payment_id
        this.props.updateArrangement(data, id).then((response) => {
            if (response.statusCode === 200) {
                this.setState({
                    successAlert: true,
                    successAlertMessage:
                        'You have successfully accepted this arrangement',
                    isArrangementDetails: false,
                })
                this.props.getPatientReviewListing('').then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({
                            data: res.data.review_arrangements,
                            paidAmount: res.data.paid_amounts,
                            physicianAccountBalance:
                                res.data.physician_account_balance,
                            unpaidBalance: res.data.unpaid_amounts,
                            pastDuePayments:
                                res.data.total_pending_arrangements,
                            promptPayDiscount: res.data.prompt_pay_percentage,
                        })
                    }
                })
            }
        })
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }
    handleDownloadPdf = (event) => {
        const { selectedData } = this.state
        const tempArray = []
        const allIdsArray = []

        selectedData &&
            selectedData.map((row) => {
                return tempArray.push(row.patient_payment_id)
            })
        this.state.data &&
            this.state.data.map((d) => {
                if (d.is_arrangement_accepted === 0) {
                    return allIdsArray.push(d.patient_payment_id)
                }
            })
        const data = {
            is_arrangement_accepted: 1,
            patient_payment_ids: tempArray,
        }
        const allIdData = {
            is_arrangement_accepted: 1,
            patient_payment_ids: allIdsArray,
        }
        const finalData = tempArray && tempArray.length > 0 ? data : allIdData
        if (event.target.value === 'selected items') {
            // if (tempArray.length == 0) {
            //     this.setState({ successAlert: true, successAlertMessage: 'please select patient rows for accepting multiple arrangement' })
            // }
            if (tempArray.length == 0 && allIdsArray.length == 0) {
                this.setState({
                    successAlert: true,
                    successAlertMessage:
                        'Your all arrangements are already selected',
                })
            } else {
                this.props
                    .acceptPatientArrangement(finalData)
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.setState({
                                successAlert: true,
                                successAlertMessage:
                                    'You have successfully accepted this arrangement',
                                isArrangementDetails: false,
                            })
                            this.props
                                .getPatientReviewListing('')
                                .then((res) => {
                                    if (res.statusCode === 200) {
                                        this.setState({
                                            data: res.data.review_arrangements,
                                            paidAmount: res.data.paid_amounts,
                                            physicianAccountBalance:
                                                res.data
                                                    .physician_account_balance,
                                            unpaidBalance:
                                                res.data.unpaid_amounts,
                                            pastDuePayments:
                                                res.data
                                                    .total_pending_arrangements,
                                            promptPayDiscount:
                                                res.data.prompt_pay_percentage,
                                            isArrangementDetails: false,
                                        })
                                    }
                                    window.location.reload()
                                })
                        }
                    })
            }
        } else {
            this.props.downloadArrangementPdf()
        }
    }

    render() {
        const {
            data,
            columns,
            selectionType,
            promptPayDiscount,
            physicianAccountBalance,
            pastDuePayments,
            isArrangementDetails,
            arrangementDetails,
            serchValue,
            input,
            successAlert,
            successAlertMessage,
            selectedData,
        } = this.state
        const { isLoading, history } = this.props
        console.log(promptPayDiscount)
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedData: selectedRows })
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                name: record.name,
            }),
        }
        const filteredData =
            data &&
            data.filter((d) => {
                const firstValue = d.first_name && d.first_name.toLowerCase()
                const secondValue = d.last_name && d.last_name.toLowerCase()
                const first_last_name = firstValue.concat(' ', secondValue)
                const last_first_name = secondValue.concat(' ', firstValue)
                return (
                    //   (d.first_name && d.first_name.toLowerCase().indexOf(serchValue.toLowerCase()) !== -1) ||
                    (d.first_name &&
                        d.first_name
                            .toLowerCase()
                            .includes(serchValue.toLowerCase())) ||
                    (d.last_name &&
                        d.last_name
                            .toLowerCase()
                            .includes(serchValue.toLowerCase())) ||
                    first_last_name
                        .toLowerCase()
                        .includes(serchValue.toLowerCase()) ||
                    last_first_name
                        .toLowerCase()
                        .includes(serchValue.toLowerCase()) ||
                    (d.patient_appointment_id &&
                        d.patient_appointment_id.toString() &&
                        d.patient_appointment_id
                            .toString()
                            .includes(serchValue.toLowerCase()))
                    //   (d.discount_applied && d.discount_applied.toLowerCase().indexOf(serchValue.toLowerCase()) !== -1) ||
                    //   (d.amount && d.amount.indexOf(serchValue) !== -1) ||
                    //   (d.original_amount && d.original_amount.indexOf(serchValue) !== -1)
                )
            })
        return (
            <>
                {!isMobile && (
                    <div className="review-arrangement">
                        <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar">
                            <PompayTopLogo
                                className="small_logo patient_list_logo"
                                ancorClassName=""
                            />
                            <ul className="navbar-nav mr-auto patient_list_custom_navbar">
                                <li className="nav-item">
                                    {/* <div className="form-group m-0">
                                        <label className="wrap-select-arrangement">
                                            <select
                                                className="form-control header_drop_down"
                                                name="isToday"
                                                onChange={this.handleDropDown}
                                            >
                                                <option selected="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="1-30"
                                                >
                                                    1-30
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="31-60"
                                                >
                                                    31-60
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="61-90"
                                                >
                                                    61-90
                                                </option>
                                                <option
                                                    name="dateRange"
                                                    value="91+"
                                                >
                                                    91+
                                                </option>
                                            </select>
                                        </label>
                                    </div> */}
                                </li>
                                <div className="vertical-dash"></div>
                                <li className="nav-item search_wrapper d-flex align-items-center">
                                    <form className="form-inline">
                                        <MdSearch className="color_dark_grey font20 m-2" />
                                        <input
                                            className="form-control mr-sm-2 patient_search"
                                            type="search"
                                            name="patientSearchParam"
                                            value={serchValue || ''}
                                            onChange={this.updateSearch}
                                            placeholder="Search"
                                            aria-label="Search"
                                            autoComplete="off"
                                        />
                                    </form>
                                </li>
                            </ul>
                            <div className="d-flex patient_right_nav">
                                {isLoading && (
                                    <div className="mt-5 mx-4">
                                        {' '}
                                        <Loading />{' '}
                                    </div>
                                )}
                                {!isLoading && (
                                    <>
                                        <div className="vertical-dash"></div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span className="cash-flow">
                                                Pending
                                            </span>
                                            <span className="total_amount">
                                                {pastDuePayments}
                                            </span>
                                        </div>

                                        <div className="vertical-dash"></div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="d-flex align-items-center">
                                                <span className="mr-4 nav-label">
                                                    {' '}
                                                    Total
                                                </span>
                                                <span className="total_amount">
                                                    {currencyFormatter.format(
                                                        physicianAccountBalance ||
                                                            0
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="d-flex align-items-center margin_right10 pompay-progressbar">
                                    {/* <span className="mr-2 nav-label"> PromptPay </span>
                                  <Progress className="m-0" percent={ promptPayDiscount || 0 } /> */}
                                    <div className="select-item-dropdown-wrapper">
                                        <div className="form-group m-0">
                                            <label className="wrap-arrangement-selected-items">
                                                <select
                                                    className="form-control select-item-dropdown"
                                                    name="print"
                                                    onChange={
                                                        this.handleDownloadPdf
                                                    }
                                                >
                                                    <option
                                                        className="custom-option"
                                                        defaultValue="select day"
                                                    >
                                                        {' '}
                                                        Selected Items
                                                    </option>
                                                    <option
                                                        className="custom-option"
                                                        name="print"
                                                        value="today"
                                                    >
                                                        Print Transactions
                                                    </option>
                                                    <option
                                                        className="custom-option"
                                                        name="selected"
                                                        value="selected items"
                                                    >
                                                        {selectedData &&
                                                        selectedData.length > 0
                                                            ? 'Accept Selected'
                                                            : 'Accept All'}
                                                    </option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="wrapper">
                            <SidebarNavigation history={history} />

                            <div id="content">
                                <Divider />
                                {isLoading && (
                                    <div className="mt-5 mx-4">
                                        <Loading />
                                    </div>
                                )}
                                {!isLoading && (
                                    <Table
                                        rowSelection={{
                                            type: selectionType,
                                            ...rowSelection,
                                        }}
                                        columns={columns}
                                        dataSource={filteredData}
                                        onChange={this.handleChange}
                                        scroll={{ y: 525 }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {isMobile && (
                    <>
                        {/* <ReviewArrangementMobile history={history} /> */}
                        <ComingSoonLayout
                            content="To view new arrangements, please use your computer,
                        laptop, or tablet"
                        />
                    </>
                )}
                {isArrangementDetails && (
                    <PatientArrangementDetailsModal
                        arrangementDetails={arrangementDetails}
                        isOpen={isArrangementDetails}
                        closeModal={this.closeModal}
                        input={input}
                        handleArrangementChange={this.handleArrangementChange}
                        updateReviewArrangementFunc={
                            this.updateReviewArrangementFunc
                        }
                    />
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientReviewListing: (data, flag) =>
            dispatch(getPatientReviewListing(data, flag)),
        downloadArrangementPdf: (data) =>
            dispatch(downloadArrangementPdf(data)),
        getReviewArrangementDetails: (data) =>
            dispatch(getReviewArrangementDetails(data)),
        acceptPatientArrangement: (data) =>
            dispatch(acceptPatientArrangement(data)),
        updateArrangement: (data, id) => dispatch(updateArrangement(data, id)),
    }
}
ReviewArrangement.propTypes = {
    getPatientReviewListing: PropTypes.func,
    getReviewArrangementDetails: PropTypes.func,
    acceptPatientArrangement: PropTypes.func,
    downloadArrangementPdf: PropTypes.func,
    updateArrangement: PropTypes.func,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewArrangement)
