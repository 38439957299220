import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getPatientTransaction } from 'actions/patientAccountActions'
import Loading from 'shared/Loader'
import moment from 'moment'
import { DatePicker, Space } from 'antd'
import { mmdyyyFormat } from 'constants/Constant'
import { getPhysicianList } from 'actions/patientPhysicianActions'
import { currencyFormatter } from 'utils/helper'
const { RangePicker } = DatePicker
function Transaction() {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [physician, setPhysician] = useState('')
    const [sortAmount, setSortAmont] = useState('')
    const [sortDate, setSortDate] = useState('')
    const patientTransactions = useSelector(
        (state) => state.patientTransactions
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const physicianList = useSelector((state) => state.physicianList)
    useEffect(() => {
        dispatch(getPatientTransaction(''))
        dispatch(getPhysicianList())
    }, [])
    const handleDateRange = (date) => {
        setStartDate(moment(date[0]).format('l'))
        setEndDate(moment(date[1]).format('l'))
    }
    const handlePhysician = (event) => {
        setPhysician(event.target.value)
    }
    const handleApplyFilter = () => {
        const data = {
            start_date: startDate,
            end_date: endDate,
            physician: physician,
            sort_by_amount: sortAmount,
            last_date: sortDate,
        }
        dispatch(getPatientTransaction(data))
    }
    const physicianTeamMemeber =
        physicianList &&
        physicianList.physician_list &&
        physicianList.physician_list.map((data, id) => {
            return (
                <option key={id} name="physician" value={data.physician_name}>
                    {data.physician_name}
                </option>
            )
        })
    const handleClearFilter = () => {
        setStartDate('')
        setEndDate('')
        setPhysician('')
        setSortAmont('')
        setSortDate('')
        dispatch(getPatientTransaction(''))
    }
    return (
        <>
            <div className="row patient-transaction-page user_activity">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 recent_activity">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                        <div className="d-flex">
                            <h2 className="tab_heading">Transactions</h2>
                        </div>
                        {isLoading && <Loading />}
                        {!isLoading && (
                            <table className="table table-borderless office_info">
                                {patientTransactions &&
                                    patientTransactions.patient_list &&
                                    patientTransactions.patient_list.map(
                                        (data, idx) => {
                                            return (
                                                <tbody key={idx}>
                                                    <tr className="">
                                                        <td className="px-0 border-0">
                                                            <div className="doctor-name">
                                                                {
                                                                    data.physician_name
                                                                }
                                                            </div>
                                                            <div className="transaction-date">
                                                                {moment(
                                                                    data.datetime
                                                                ).format('l')}
                                                            </div>
                                                        </td>
                                                        <td className="px-0 text-center border-0">
                                                            <div className="transaction-amount">
                                                                {currencyFormatter.format(
                                                                    data.amount ||
                                                                        0
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        }
                                    )}
                            </table>
                        )}
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 sort_column d-none d-md-block d-lg-block d-xl-block">
                    <div className="col-md-9 col-lg-9 col-xl-9">
                        <div className="d-flex justify-content-between">
                            <h2 className="tab_heading">Sort/Filter</h2>
                            <p
                                className="clear-filter"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleClearFilter()}
                            >
                                Clear filter
                            </p>
                        </div>
                        <form>
                            <table className="sort_filter_table">
                                <tbody>
                                    <tr className="">
                                        <td className="px-0">
                                            <div className="sort_label">
                                                Date range:
                                            </div>
                                        </td>
                                        <td className="">
                                            <Space
                                                direction="vertical"
                                                size={12}
                                            >
                                                <RangePicker
                                                    defaultValue={[
                                                        moment(
                                                            new Date(),
                                                            mmdyyyFormat
                                                        ),
                                                        moment(
                                                            new Date(),
                                                            mmdyyyFormat
                                                        ),
                                                    ]}
                                                    format={mmdyyyFormat}
                                                    onChange={handleDateRange}
                                                />
                                            </Space>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-0">
                                            <div className="sort_label">
                                                Team member:
                                            </div>
                                        </td>
                                        <td className="pt-3 pb-4">
                                            <select
                                                className="form-control member-dropdown"
                                                name="physician"
                                                onChange={handlePhysician}
                                            >
                                                <option defaultValue="All">
                                                    {' '}
                                                    {physician
                                                        ? physician
                                                        : 'All'}
                                                </option>
                                                {physicianTeamMemeber}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label
                                                htmlFor="sort"
                                                className="sortby_heading"
                                            >
                                                Sort payments by:
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-0">
                                            <div className="sortby_label my-3 px-2">
                                                Amount
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="pending_tranfer_label my-3 px-2">
                                                <select
                                                    className="form-control member-dropdown"
                                                    name="sort_by_amount"
                                                    onChange={(event) => {
                                                        setSortAmont(
                                                            event.target.value
                                                        ),
                                                            console.log(
                                                                'EVENT',
                                                                event.target
                                                                    .value
                                                            )
                                                    }}
                                                >
                                                    <option
                                                        disabled
                                                        defaultValue="sort amount"
                                                    >
                                                        {' '}
                                                        {sortAmount
                                                            ? sortAmount
                                                            : 'sort amount'}
                                                    </option>
                                                    <option
                                                        name="sort_by_amount"
                                                        value="Highest to Lowest"
                                                    >
                                                        Highest to Lowest
                                                    </option>
                                                    <option
                                                        name="sort_by_amount"
                                                        value="Lowest to Highest"
                                                    >
                                                        Lowest to Highest
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-0">
                                            <div className="sortby_label my-3 px-2">
                                                Date
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="pending_tranfer_label my-3 px-2">
                                                <select
                                                    className="form-control member-dropdown"
                                                    name="last_date"
                                                    onChange={(event) =>
                                                        setSortDate(
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option
                                                        disabled
                                                        defaultValue="sort amount"
                                                    >
                                                        {' '}
                                                        {sortDate
                                                            ? sortDate
                                                            : 'sort date'}
                                                    </option>
                                                    <option
                                                        name="last_date"
                                                        value="30"
                                                    >
                                                        Last 30 days
                                                    </option>
                                                    <option
                                                        name="last_date"
                                                        value="60"
                                                    >
                                                        Last 60 days
                                                    </option>
                                                    <option
                                                        name="last_date"
                                                        value="90"
                                                    >
                                                        Last 90 days
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        <button
                            className="btn green_btn custom_btn my-5 w-100"
                            onClick={handleApplyFilter}
                        >
                            Apply Filter
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

Transaction.propTypes = {
    getPatientTransaction: PropTypes.func,
}

export default Transaction
