import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import { MODAL_STYLES } from 'constants/Constant'
import BillPaymentOption from './BillPaymentOption'
import { getPatientPaymentSchedules } from 'actions/patientBankingActions'
import { FiChevronLeft } from 'react-icons/fi'

function PhysicianConfirmBillAmount(props) {
    const dispatch = useDispatch()
    const {
        addBillAmount,
        isOpen,
        closeConfirmBillAmount,
        appointmentId,
        physicianDetails,
    } = props
    const [confirmBillAmount, setConfirmBillAmount] = useState('')
    const [confirmBillAmountError, setConfirmBillAmountError] = useState(false)
    const [billPayOption, setbillPayOption] = useState(false)
    const handleChange = (event) => {
        setConfirmBillAmount(event.target.value)
        setConfirmBillAmountError(false)
    }

    const handleNext = () => {
        const data = {
            patient_id: appointmentId,
            physician_id: physicianDetails.physician_id,
            amount: confirmBillAmount,
        }
        if (addBillAmount !== confirmBillAmount) {
            setConfirmBillAmountError('Amount not matched')
        } else {
            dispatch(getPatientPaymentSchedules(data))
            setbillPayOption(!billPayOption)
        }
    }
    const closeBillPayOptions = () => {
        setbillPayOption(!billPayOption)
    }
    return (
        <>
            {!billPayOption && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => closeConfirmBillAmount()}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="patient-amount-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (window.location = '/home')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeConfirmBillAmount()}
                                />
                                <span className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </span>
                                <span></span>
                            </div>
                            <div className="modal-body">
                                <h4 className="heading">
                                    Re-enter bill amount
                                    <br /> to confirm
                                </h4>
                                <span className="input-icon left">
                                    <input
                                        type="number"
                                        autoFocus
                                        className="patient-id-field"
                                        name="accessCode"
                                        autoComplete="off"
                                        value={confirmBillAmount}
                                        onChange={handleChange}
                                        data-dismiss="modal"
                                        placeholder="0.00"
                                    />
                                </span>
                                {confirmBillAmountError && (
                                    <small className="alert alert-danger error-msg">
                                        {confirmBillAmountError}
                                    </small>
                                )}
                            </div>
                            <div className="finish_box">
                                <button
                                    className="btn next-btn pointer"
                                    onClick={handleNext}
                                >
                                    {' '}
                                    Next{' '}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {billPayOption && (
                <BillPaymentOption
                    isOpen={billPayOption}
                    closeBillPayOptions={closeBillPayOptions}
                    billAmount={confirmBillAmount}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
        </>
    )
}

PhysicianConfirmBillAmount.propTypes = {
    closeConfirmBillAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    addBillAmount: PropTypes.string,
    appointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default PhysicianConfirmBillAmount
