import React from 'react'
import Modal from 'react-modal'
// import moment from 'moment';
import momentTime from 'moment-timezone'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MdChevronLeft } from 'react-icons/md'
import { dateFormat, MODAL_STYLES } from 'constants/Constant'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'

function TotalPaidDetailsModal(props) {
    const { totalBalanceDetails } = props
    const checkForInstantPay = Boolean(totalBalanceDetails.is_instant_paid)
    const instantPayData = totalBalanceDetails.payment_details
    const totalPayDetails =
        totalBalanceDetails && totalBalanceDetails.total_paid
            ? totalBalanceDetails.total_paid
            : []
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    return (
        <>
            <Modal
                isOpen={props.isTotalPaid}
                onRequestClose={() => props.closeModal()}
                style={MODAL_STYLES}
                contentLabel="Example Modal"
                id="bill-details-modal"
                ariaHideApp={false}
            >
                <div className="bill_details mb-5">
                    <div className="d-flex justify-content-between mb-2 modal_heading_section">
                        <MdChevronLeft
                            className="dark_icon back_icon color_light_green font38 pointer"
                            onClick={() => props.closeModal()}
                        />
                        <a className="navbar-brand">
                            <p className="modal-heading">Bill Details</p>
                        </a>
                        <button
                            type="button"
                            className="close modal_close_custom_icon"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.closeAll()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="heading_section d-flex flex-column text-center mt-5">
                        <span className="main-balanceheading">Total Paid</span>
                        {isModalLoading && <ModalLoader />}
                        {!isModalLoading && (
                            <span className="main-amount">
                                {currencyFormatter.format(
                                    checkForInstantPay
                                        ? instantPayData.total_paid
                                        : totalPayDetails.total_paid || 0
                                )}
                            </span>
                        )}
                    </div>
                    <div className="my-4 text-center">
                        <span className="content-heading">Payment Details</span>
                    </div>
                    {isModalLoading && <ModalLoader />}
                    {/* { isModalLoading && <div className="text-center"><ModalLoader /></div> } */}
                    {!isModalLoading && checkForInstantPay && (
                        <div>
                            <div className="data-wrapper">
                                <div className="d-flex justify-content-between">
                                    <span className="receipt-data">
                                        Original amount
                                    </span>
                                    <span className="receipt-data">
                                        {currencyFormatter.format(
                                            instantPayData.original_amount || 0
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="receipt-data d-flex flex-column">
                                        <span>Pompay discount</span>
                                        <span>
                                            ({instantPayData.discount_by}%)
                                        </span>
                                    </span>
                                    <span className="receipt-data">
                                        -
                                        {currencyFormatter.format(
                                            instantPayData.discount_amount || 0
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="receipt-data d-flex flex-column">
                                        Adjusted amount
                                    </span>
                                    <span className="receipt-data">
                                        {currencyFormatter.format(
                                            instantPayData.adjusted_amount || 0
                                        )}
                                    </span>
                                </div>
                                {Boolean(instantPayData.late_fee) && (
                                    <div className="d-flex justify-content-between">
                                        <span className="receipt-data d-flex flex-column">
                                            Late fee
                                        </span>
                                        <span className="receipt-data">
                                            {currencyFormatter.format(
                                                instantPayData.late_fee || 0
                                            )}
                                        </span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between">
                                    <span className="receipt-data d-flex flex-column">
                                        Payment date
                                    </span>
                                    <span className="receipt-data">
                                        {momentTime(
                                            instantPayData.payment_date
                                        ).format(dateFormat)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isModalLoading &&
                        !checkForInstantPay &&
                        totalPayDetails.full_payment_schedule &&
                        totalPayDetails.full_payment_schedule.map(
                            (data, id) => {
                                return (
                                    <div
                                        className="row no-gutters my-4 installment-row"
                                        key={id}
                                    >
                                        <div className="col-2 col-sm-2">
                                            <form className="radio_form">
                                                <label
                                                    className="radio_btn"
                                                    htmlFor="sms"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="radio"
                                                        checked="checked"
                                                        id="sms"
                                                        onChange={() => {}}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <div className="v-border"></div>
                                                </label>
                                            </form>
                                        </div>
                                        <div className="col-7 col-sm-7 text-left">
                                            <div className="d-flex flex-column ml-2">
                                                <span className="installment-label">
                                                    {id === 0 ? (
                                                        <span className="installment-label">
                                                            {id + 1}st
                                                            installment
                                                        </span>
                                                    ) : id === 1 ? (
                                                        <span className="installment-label">
                                                            {id + 1}nd
                                                            installment
                                                        </span>
                                                    ) : id === 2 ? (
                                                        <span className="installment-label">
                                                            {id + 1}rd
                                                            installment
                                                        </span>
                                                    ) : (
                                                        <span className="installment-label">
                                                            {id + 1}th
                                                            installment
                                                        </span>
                                                    )}
                                                </span>
                                                <span className="installment-date">
                                                    {/* { moment(data.payment_date).format('l')  */}
                                                    {data.payment_status}:{' '}
                                                    {momentTime(
                                                        data.payment_date
                                                    ).format('l')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-3 col-sm-3 d-flex flex-column">
                                            <span className="installment-label">
                                                {currencyFormatter.format(
                                                    data.paid_amount || 0
                                                )}
                                            </span>
                                            {Boolean(data?.late_fee) && (
                                                <span className="error installment-label">{` (+${currencyFormatter.format(
                                                    data.late_fee || 0
                                                )})`}</span>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        )}
                </div>
            </Modal>
        </>
    )
}

TotalPaidDetailsModal.propTypes = {
    isTotalPaid: PropTypes.bool,
    totalBalanceDetails: PropTypes.object,
    closeModal: PropTypes.func,
    closeAll: PropTypes.func,
}

export default TotalPaidDetailsModal
