import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loading from 'shared/Loader'
import Autosuggest from 'react-autosuggest'
import { isMobile } from 'constants/Constant'
import { CgChevronLeft } from 'react-icons/cg'
import { FiChevronLeft } from 'react-icons/fi'
import SuccessAlert from 'shared/SuccessAlert'
import { maxLengthCheck } from 'utils/helper'

export class EditTeamMember extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            value: '',
            suggestions: [],
            successAlert: false,
        }
    }
    componentDidMount() {
        this.props.getTeamMemberInfo(this.props.editTeamId).then((res) => {
            if (res.statusCode === 200) {
                this.setState({
                    input: res && res.data && res.data.physician_team,
                    value:
                        res &&
                        res.data &&
                        res.data.physician_team &&
                        res.data.physician_team.speciality,
                })
            }
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        const input = this.state.input
        const data = {
            first_name: input.first_name,
            last_name: input.last_name,
            phone_number: input.phone_number,
            email_id: input.email_id,
            date_of_birth: input.date_of_birth,
            speciality: this.state.value ? this.state.value : input.speciality,
            zipcode: input.zipcode,
        }
        const check_email_data = {
            email_id: input.email_id,
        }
        if (this.validate()) {
            const errors = {}
            this.props
                .checkValidEmail(check_email_data)
                .then((resValidEmail) => {
                    if (resValidEmail.statusCode === 200) {
                        if (resValidEmail.data.is_valid) {
                            this.props
                                .updateTeamMemberInfo(this.state.input.id, data)
                                .then((res) => {
                                    if (res.statusCode === 200) {
                                        this.setState({ successAlert: true })
                                        this.props.openEditTeam()
                                    }
                                })
                        } else {
                            // email not valid
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    } else {
                        // email not valid
                        var errResponse = resValidEmail.response
                        if (errResponse && errResponse.status === 400) {
                            errors['email_id'] =
                                'Please enter valid email address.'
                        }
                    }
                    this.setState({
                        errors: errors,
                    })
                })
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }
    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.name === 'phone_number') {
            let obj = event.target.value
            var numbers = obj.replace(/\D/g, ''),
                char = { 0: '(', 3: ') ', 6: '-' }
            obj = ''
            for (var i = 0; i < numbers.length; i++) {
                obj += (char[i] || '') + numbers[i]
                input[event.target.name] = obj
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length
        return inputLength === 0
            ? []
            : this.props &&
                  this.props.roles &&
                  this.props.roles.filter(
                      (lang) =>
                          lang.role_name.toLowerCase().slice(0, inputLength) ===
                          inputValue
                  )
    }

    getSuggestionValue = (suggestion) => suggestion.role_name

    renderSuggestion = (suggestion) => <div>{suggestion.role_name}</div>

    onValueChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }

    validate() {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['first_name']) {
            isValid = false
            errors['first_name'] = 'Please enter first name'
        }
        if (typeof input['first_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['first_name'])) {
                isValid = false

                errors['first_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['last_name']) {
            isValid = false
            errors['last_name'] = 'Please enter last name'
        }
        if (typeof input['last_name'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(input['last_name'])) {
                isValid = false

                errors['last_name'] = 'Please enter alphabets only.'
            }
        }
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        if (!input['date_of_birth']) {
            isValid = false
            errors['date_of_birth'] = 'Please enter your email.'
        }
        if (!input['email_id']) {
            isValid = false
            errors['email_id'] = 'Please enter your email.'
        }
        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter your zipcode.'
        }
        if (typeof input['email_id'] !== 'undefined') {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )
            if (!pattern.test(input['email_id'])) {
                isValid = false

                errors['email_id'] = 'Please enter valid email address.'
            }
        }

        if (this.state.input.speciality === '' || this.state.value === '') {
            isValid = false
            errors['speciality'] = 'Please enter title'
        }
        if (this.state.value.toLowerCase() == 'physician') {
            isValid = false
            errors['speciality'] =
                "You can't assign physician role to team member."
        }
        if (this.state.input.speciality === '' || this.state.value === '') {
            const pattern = new RegExp(/^[A-Za-z ]+$/)
            if (!pattern.test(this.state.value)) {
                isValid = false

                errors['speciality'] = 'Please enter alphabets only.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    disableFunc = (event) => {
        event.preventDefault()
        this.props.openEditTeam()
    }
    render() {
        const { input, errors, suggestions, value, successAlert } = this.state
        const { isLoading, editTeamId } = this.props
        const enabled =
            Object.keys(input).length > 3 &&
            input.phone_number &&
            input.phone_number.length === 14
        const inputProps = {
            placeholder: 'Enter title',
            value: value ? value : '',
            // value: input && input.speciality ? input.speciality : value,
            onChange: this.onValueChange,
        }
        return (
            <>
                <div className="mobile_account_info team-member-profile-page">
                    <div id="content">
                        {isMobile && (
                            <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon"
                                    onClick={() => this.props.openEditTeam()}
                                />
                                <label className="nav-heading">
                                    Team Member Profile
                                </label>
                                <span></span>
                            </nav>
                        )}
                        {!isMobile && (
                            <div className="team-member-profile">
                                <div className="d-flex heading-strip">
                                    <FiChevronLeft
                                        className="back-green-arrow"
                                        onClick={() =>
                                            this.props.openEditTeam()
                                        }
                                    />{' '}
                                    <h6 className="tab_heading">
                                        Team Member Profile
                                    </h6>
                                </div>
                            </div>
                        )}
                        {isLoading && (
                            <div className="row no-gutters">
                                <div className="col-12 p-3">
                                    <Loading />
                                </div>
                            </div>
                        )}
                        {!isLoading && (
                            <form>
                                <div className="form-row flex-column">
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    autoComplete="off"
                                                    id="first_name"
                                                    value={
                                                        input.first_name || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter first name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="first_name"
                                                >
                                                    First name
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.first_name}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    autoComplete="off"
                                                    id="last_name"
                                                    value={
                                                        input.last_name || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter last name"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="last_name"
                                                >
                                                    Last name
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.last_name}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    disabled
                                                    name="date_of_birth"
                                                    id="acc-date"
                                                    type="text"
                                                    className="form-control account_info_field"
                                                    placeholder="Date of birth"
                                                    defaultValue={
                                                        input.date_of_birth ||
                                                        ''
                                                    }
                                                    // onChange={ this.handleChange }
                                                />
                                                {/* <MdLock className="textfield-icon"/> */}
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="acc-date"
                                                >
                                                    Date of birth
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.date_of_birth}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="text"
                                                    maxLength="14"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    value={
                                                        input.phone_number || ''
                                                    }
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter mobile number"
                                                    autoComplete="off"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="phone_number"
                                                >
                                                    Mobile number
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.phone_number}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="email"
                                                    name="email_id"
                                                    autoComplete="off"
                                                    id="email_id"
                                                    value={input.email_id || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                    placeholder="Enter email"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="email_id"
                                                >
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.email_id}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <input
                                                    type="number"
                                                    maxLength="5"
                                                    onInput={maxLengthCheck}
                                                    name="zipcode"
                                                    autoComplete="off"
                                                    placeholder="Enter zipcode"
                                                    id="zipcode"
                                                    value={input.zipcode || ''}
                                                    onChange={this.handleChange}
                                                    className="form-control account_info_field"
                                                />
                                                <label
                                                    className="input-group-text account_info_label"
                                                    htmlFor="email_id"
                                                >
                                                    Zipcode
                                                </label>
                                            </div>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.zipcode}
                                        </small>
                                    </div>
                                    <div className="col-sm-4 my-2">
                                        <div className="input-group autosuggest-field">
                                            <fieldset className="input-group-prepend">
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={
                                                        this
                                                            .onSuggestionsFetchRequested
                                                    }
                                                    onSuggestionsClearRequested={
                                                        this
                                                            .onSuggestionsClearRequested
                                                    }
                                                    getSuggestionValue={
                                                        this.getSuggestionValue
                                                    }
                                                    renderSuggestion={
                                                        this.renderSuggestion
                                                    }
                                                    inputProps={inputProps}
                                                    className="form-control account_info_field"
                                                />
                                                <div className="input-group-text account_info_label">
                                                    Title
                                                </div>
                                            </fieldset>
                                        </div>
                                        <small className="text-danger float-left">
                                            {errors.speciality}
                                        </small>
                                    </div>
                                    {!isMobile && (
                                        <div className="col-sm-4">
                                            <div className="btn-card text-left">
                                                <button
                                                    type="submit"
                                                    className="btn custom_btn green_btn"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="btn btn-link cancel-link"
                                                    onClick={this.disableFunc}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        )}
                        {isMobile && (
                            <button
                                type="submit"
                                className="btn btn-link mobile-remove-link"
                                style={{ margin: '145px 0 40px 0' }}
                                onClick={() =>
                                    this.props.confirmTeamRemove(editTeamId)
                                }
                                disabled={!enabled}
                            >
                                Remove Member
                            </button>
                        )}
                    </div>
                    {isMobile && (
                        <div className="px-3 pb-3">
                            <button
                                type="submit"
                                className="btn mobile-green-btn"
                                onClick={this.handleSubmit}
                                disabled={!enabled}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        role="Team"
                        message="The team info has been updated."
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        details: state.officeAddressDetails,
    }
}

EditTeamMember.propTypes = {
    updateTeamMemberInfo: PropTypes.func,
    getTeamMemberInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleRemoveTeam: PropTypes.func,
    confirmTeamRemove: PropTypes.func,
    openEditTeam: PropTypes.func,
    checkValidEmail: PropTypes.func,
    editTeamId: PropTypes.number,
    roles: PropTypes.array,
    isLoading: PropTypes.bool,
}
export default connect(mapStateToProps, null)(EditTeamMember)
