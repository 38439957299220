import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { Switch, Progress } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { Table, Divider, Space } from "antd";
import {
  getPatientProfileInfo,
  updatePatientProfileInfo,
  markPostedAction,
} from "actions/patientActions";
import SidebarNavigation from "../../../../account/SidebarNavigation";
import Loading from "../../../../../shared/Loader";
import { HiChevronRight } from "react-icons/hi";
import PompayTopLogo from "shared/PompayTopLogo";
//TODO: Put this layout back to replace ComingSoonLayout when we fix all bugs under that page
//import PatientListMobile from '../../mobile/PatientListMobile'
import ComingSoonLayout from "../../mobile/ComingSoonLayout";
import { isMobile } from "constants/Constant";
import { MdSearch } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import PatientProfileModal from "../PatientProfileModal";
import PastDuePatientList from "../PastDuePatientList";
import { bolderNameFormatter, currencyFormatter } from "utils/helper";
import SuccessAlert from "shared/SuccessAlert";
import CurrentHistoryModal from "../CurrentHistoryModal";
import { getAccountDetails } from "actions/accountActions";
import { dateFormat } from "constants/Constant";
import { stringUtils } from "utils/string";
import { Selector } from "./components/selector";
import { dateUtils } from "utils/date.utils";
import { AppDispatch, AppState } from "store";
import { ColumnsType } from "antd/lib/table";
import { Key } from "antd/lib/table/interface";
import { HttpResponse } from "models/http";
import {
  DuePaymentsDetails,
  PatientInfo,
  PatientListResponse,
  DueStatus,
} from "models/GetPatientListResponse";
import {
  getPatientList,
  IGetPatientList,
} from "actions/patient/patient.actions";

enum QuickDateValue {
  Today = "Today",
  OneWeek = "OneWeek",
  OneMonth = "OneMonth",
  ThreeMonths = "ThreeMonths",
  SixMonths = "SixMonths",
  OneYear = "OneYear",
  All = "All",
}

enum PaymentStatus {
  All = "all",
  Awaiting = "Awaiting",
  Paid = "Paid",
  Posted = "Posted",
}

export interface PatientListProps {
  isLoading: boolean;
  history: any;
  accountDetails: {
    physician: {
      physician_id: string;
    }[];
  };
  paymentActivityData: {};
  getAccountDetails: () => Promise<any>;
  getPatientList: (data: IGetPatientList, flag?: string) => Promise<any>;
  getPatientProfileInfo: (data: {}) => Promise<any>;
  updatePatientProfileInfo: (data: {}, id?: string) => Promise<any>;
  markPostedAction: (data: {}) => Promise<any>;
}

interface PatientListInput {
  first_name?: string;
  last_name?: string;
  email_id?: string;
  phone_number?: string;
  zipcode?: string;
  date_of_birth?: string;
  patient_id?: string;
  patient_appointment_id?: string;
}

interface PatientListState {
  pastDueChecked: boolean;
  paid_percentage: string;
  paid_amount: number;
  total_amount: number;
  unpaid_amount: number;
  currentData?: any;
  selectionType: "checkbox";
  isProfile: boolean;
  isCurrentHistory: boolean;
  searchValue: string;
  isToday: string;
  paymentStatus: string;
  successAlert: boolean;
  input: PatientListInput;
  start_date: string;
  end_date: string;
  selectedData: Key[];
  selectValue: "select day";
  columns?: ColumnsType<any> | undefined;
  duePaymentsDetails: DuePaymentsDetails[];
  patientsInfo: PatientInfo[];
  loadingFirstTime: boolean;
}

const initialState: PatientListState = {
  pastDueChecked: false,
  paid_percentage: "",
  paid_amount: 0,
  total_amount: 0,
  unpaid_amount: 0,
  selectionType: "checkbox",
  isProfile: false,
  isCurrentHistory: false,
  searchValue: "",
  isToday: "",
  paymentStatus: PaymentStatus.All,
  successAlert: false,
  input: {},
  start_date: dateUtils.format(new Date()),
  end_date: dateUtils.format(new Date()),
  selectedData: [],
  selectValue: "select day",
  duePaymentsDetails: [],
  patientsInfo: [],
  loadingFirstTime: true,
};

export class PatientList extends React.Component<
  PatientListProps,
  PatientListState
> {
  constructor(props: PatientListProps) {
    super(props);

    this.state = {
      ...initialState,
      columns: [
        {
          title: "Name",
          dataIndex: "",
          sorter: (a, b) => a.last_name.localeCompare(b.last_name),
          sortDirections: ["ascend", "descend"],
          render: (key) => (
            <div className="patient-name">{bolderNameFormatter(key)}</div>
          ),
        },
        {
          title: "Patient I.D",
          dataIndex: "patient_appointment_id",
        },
        {
          title: "Due Date",
          dataIndex: "due_date",
          sortDirections: ["ascend", "descend"],
          render: (due_date) => <>{moment(due_date).format(dateFormat)}</>,
          sorter: (a, b) => moment.utc(a.due_date).diff(moment.utc(b.due_date)),
        },
        {
          title: "Paid On",
          dataIndex: "paid_date",
          sortDirections: ["ascend", "descend"],
          render: (paid_date) => (
            <>{paid_date ? moment(paid_date).format(dateFormat) : "-"}</>
          ),
          sorter: (a, b) =>
            moment.utc(a.paid_date).diff(moment.utc(b.paid_date)),
        },
        {
          title: "Amount",
          dataIndex: "amount",
          render: (amount, data) => {
            return (
              <>
                <span>{currencyFormatter.format(amount || 0)}</span>{" "}
                {Boolean(data?.late_fee) && (
                  <span className="error">
                    (+{currencyFormatter.format(data?.late_fee)})
                  </span>
                )}
              </>
            );
          },
        },
        {
          title: "Total Amount",
          dataIndex: "amount",
          render: (amount, data) => {
            return (
              <b>
                {currencyFormatter.format(
                  (amount || 0) + (data?.late_fee || 0)
                )}
              </b>
            );
          },
          sorter: (a, b) => a.amount - b.amount,
        },
        {
          title: "Status",
          dataIndex: "due_status",
        },
        {
          title: "",
          dataIndex: "",
          key: "x",
          render: (key) => (
            <Space key={key} size="middle">
              <a
                className="ant-dropdown-link"
                onClick={() => this.handleOpenCurrentHistory(key)}
              >
                <HiChevronRight className="font18" />
              </a>
            </Space>
          ),
        },
      ],
    };

    this.handleOpenProfile = this.handleOpenProfile.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onToggleSwitchChange = this.onToggleSwitchChange.bind(this);
    this.firstLoad = this.firstLoad.bind(this);
  }

  async handleGetPatientList(filterFlag = "") {
    const localParams = {
      isToday: this.state.isToday || "",
      paymentStatus: this.state.paymentStatus || "",
      alphabeticalOrder: "",
      sortByAmount: "",
      sortByDate: "",
      end_date: this.state.end_date,
      start_date: this.state.start_date,
    };

    const response: HttpResponse<PatientListResponse> =
      await this.props.getPatientList(localParams, filterFlag);
    if (response.statusCode !== 200) return;

    const patients: PatientInfo[] = [];
    const patientsPaymentsDetails: DuePaymentsDetails[] = [];

    response.data?.patient_info?.forEach((patientInfo) => {
      patients.push(patientInfo);

      if (patientInfo.due_payments_details?.length > 0) {
        patientInfo.due_payments_details.forEach((duePaymentsDetail) =>
          patientsPaymentsDetails.push(duePaymentsDetail)
        );
      }
    });

    this.setState({
      patientsInfo: patients,
      duePaymentsDetails: patientsPaymentsDetails,
      paid_percentage: response.data.paid_percentage,
      paid_amount: response.data.paid_amount,
      total_amount: response.data.total_amount,
      unpaid_amount: response.data.unpaid_amount,
      selectValue: "select day",
    });
  }

  firstLoad = () => {
    const pastDuePage: boolean =
      this.props.history?.location?.state?.pastDuePage;
    this.setState({ ...initialState, pastDueChecked: pastDuePage }, () => {
      this.handleGetPatientList().then(() => {
        setTimeout(() => {
          this.setState({ loadingFirstTime: false });
        }, 1000);
      });
    });
  };

  componentDidMount() {
    this.props.getAccountDetails();
    this.firstLoad();
  }

  closeModal = () => {
    this.setState({
      isCurrentHistory: false,
      isProfile: false,
    });
  };

  handleOpenCurrentHistory = (key: any) => {
    this.setState({ isCurrentHistory: true, currentData: key });
  };

  onToggleSwitchChange(check: boolean) {
    if (!check) {
      this.firstLoad();
    }
    this.setState({ pastDueChecked: check });
  }

  handleOpenProfile(key: any) {
    this.setState({ isProfile: true });
    this.props.getPatientProfileInfo(key.patient_id).then((res) => {
      if (res.statusCode === 200) {
        this.setState({
          input: res.data,
        });
      }
    });
  }

  updateSearch = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value.substr(0, 20) });
  };

  handleSelectItemDropdown = (event: ChangeEvent<HTMLSelectElement>) => {
    const getValue = event.target.value;
    if (getValue === "mark_as_posted") {
      const selectedId: number[] = [];
      for (let i = 0; i < this.state.duePaymentsDetails.length; i++) {
        for (let j = 0; j < this.state.selectedData.length; j++) {
          if (
            this.state.selectedData[j] === this.state.duePaymentsDetails[i].key
          ) {
            selectedId.push(this.state.duePaymentsDetails[i].id);
          }
        }
      }

      const markPostedActionParams = {
        payment_schedule_id: selectedId,
      };

      this.props.markPostedAction(markPostedActionParams).then((res1) => {
        if (res1.statusCode === 200) {
          this.handleGetPatientList();
        }
      });
    }
  };

  handleQuickDateChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const dateValue = event.target.value;

    const startDate = new Date();
    let endDate = new Date();

    switch (dateValue) {
      case QuickDateValue.Today:
        // Value is we in initialization
        break;

      case QuickDateValue.OneWeek:
        endDate.setDate(startDate.getDate() - 7);
        break;

      case QuickDateValue.OneMonth:
        endDate = dateUtils.subtractMonth(endDate, 1);
        break;

      case QuickDateValue.ThreeMonths:
        endDate = dateUtils.subtractMonth(startDate, 3);
        break;

      case QuickDateValue.SixMonths:
        endDate = dateUtils.subtractMonth(startDate, 6);
        break;

      case QuickDateValue.OneYear:
        endDate = dateUtils.subtractYear(startDate, 1);
        break;

      default:
        endDate = new Date("01/01/1893");
        break;
    }

    this.setState({
      start_date: dateUtils.format(startDate),
      end_date: dateUtils.format(endDate),
    });

    setTimeout(() => this.handleGetPatientList("custom"), 500);
  };

  handlePaymentStatusChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ paymentStatus: event.target.value });

    setTimeout(() => this.handleGetPatientList(), 500);
  };

  handleProfileChange = (event: ChangeEvent<HTMLInputElement>) => {
    let input = this.state.input;
    const inputKey: string | undefined = Object.keys(input).find(
      (key) => key === event.target.name
    );

    if (inputKey) {
      input = Object.assign(input, { [inputKey]: event.target.value });
      this.setState({ input });
    }
  };

  updateProfileInfo = () => {
    const {
      first_name,
      last_name,
      email_id,
      phone_number,
      zipcode,
      date_of_birth,
      patient_id,
      patient_appointment_id,
    } = this.state.input;

    const data = {
      first_name: first_name,
      last_name: last_name,
      patient_id: patient_id,
      patient_appointment_id: patient_appointment_id,
      email_id: email_id,
      phone_number: phone_number,
      zipcode,
      date_of_birth: date_of_birth,
    };

    this.props.updatePatientProfileInfo(data, patient_id).then((res) => {
      if (res.statusCode === 200) {
        this.setState({ successAlert: true, isProfile: false });
        setTimeout(() => {
          this.handleGetPatientList();
          window.location.reload();
        }, 500);
      }
    });
  };

  closeSuccessAlert = () => {
    this.setState({ successAlert: false, isProfile: false });
  };

  // eslint-disable-next-line no-unused-vars
  sortDuePaymentDetails = (
    left: DuePaymentsDetails,
    right: DuePaymentsDetails
  ): number => {
    const leftDueDate = new Date(left.paid_date).getTime();
    const rightDueDate = new Date(right.paid_date).getTime();

    // Past Due first
    if (left.due_status === DueStatus.PastDue) {
      if (right.due_status !== DueStatus.PastDue) return -1;

      return rightDueDate - leftDueDate;
    }

    // Payments that are due TODAY but have NOT been paid yet
    if (left.due_status === DueStatus.Paid) {
      if (right.due_status !== DueStatus.Paid) return -1;

      return rightDueDate - leftDueDate;
    }

    // Payments that are due TODAY and HAVE been paid
    if (
      left.due_status === DueStatus.Awaiting &&
      right.due_status !== DueStatus.Awaiting
    ) {
      return -1;
    }

    // TODO: Talk with Nicholas
    // Past due payments that were paid today will appear at the bottom
    // Future payments
    return rightDueDate - leftDueDate;
  };

  render() {
    const {
      duePaymentsDetails,
      columns,
      selectionType,
      paid_amount,
      unpaid_amount,
      total_amount,
      isProfile,
      searchValue,
      pastDueChecked,
      input,
      currentData,
      isCurrentHistory,
      paid_percentage,
      successAlert,
      loadingFirstTime,
    } = this.state;

    const { isLoading, history, accountDetails, paymentActivityData } =
      this.props;

    const filteredData = duePaymentsDetails?.filter((details) => {
      const firstValue = details?.first_name.toLowerCase();
      const secondValue = details?.last_name.toLowerCase();
      const first_last_name = `${firstValue} ${secondValue}`.trim();
      const last_first_name = `${secondValue} ${firstValue}`.trim();

      return (
        !searchValue ||
        stringUtils.containsIC(details.first_name, searchValue) ||
        stringUtils.containsIC(details.last_name, searchValue) ||
        stringUtils.containsIC(first_last_name, searchValue) ||
        stringUtils.containsIC(last_first_name, searchValue) ||
        stringUtils.containsIC(details?.patient_appointment_id, searchValue)
      );
    });

    return (
      <React.Fragment>
        {!isMobile && !pastDueChecked && (
          <div className="patient_list">
            <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar">
              <PompayTopLogo className="small_logo patient_list_logo" />
              <ul className="navbar-nav mr-auto patient_list_custom_navbar">
                <li className="nav-item">
                  <Selector
                    onChange={this.handleQuickDateChanged}
                    name="dateRange"
                    defaultValue={QuickDateValue.Today}
                    options={[
                      { value: QuickDateValue.Today, displayValue: "Today" },
                      { value: QuickDateValue.OneWeek, displayValue: "1W" },
                      { value: QuickDateValue.OneMonth, displayValue: "1M" },
                      { value: QuickDateValue.ThreeMonths, displayValue: "3M" },
                      { value: QuickDateValue.SixMonths, displayValue: "6M" },
                      { value: QuickDateValue.OneYear, displayValue: "1Y" },
                      { value: QuickDateValue.All, displayValue: "All Time" },
                    ]}
                  />
                </li>

                <li className="nav-item">
                  <Selector
                    onChange={this.handlePaymentStatusChanged}
                    name="paymentStatus"
                    defaultValue={PaymentStatus.All}
                    options={[
                      { value: PaymentStatus.All, displayValue: "All" },
                      { value: PaymentStatus.Awaiting },
                      { value: PaymentStatus.Paid },
                      { value: PaymentStatus.Posted },
                    ]}
                  />
                </li>

                <li className="nav-item d-flex align-items-center ml-4 mr-3 toggle_nav_btn">
                  <span className="mr-2 font12">Past Due</span>
                  <Switch
                    defaultChecked={this.state.pastDueChecked}
                    onChange={(check) => this.onToggleSwitchChange(check)}
                  />
                </li>

                <div className="vertical-dash"></div>

                <li className="nav-item search_wrapper d-flex align-items-center">
                  <form className="form-inline" autoComplete="off">
                    <MdSearch className="color_dark_grey font20 m-2" />
                    <input
                      className="form-control mr-sm-2 patient_search"
                      type="search"
                      name="patientSearchParam"
                      value={searchValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      onChange={this.updateSearch}
                      placeholder="Search"
                      aria-label="Search"
                      autoComplete="off"
                    />
                  </form>
                </li>
              </ul>

              {isLoading && (
                <div className="mt-5 mx-4">
                  {" "}
                  <Loading />{" "}
                </div>
              )}

              {!isLoading && (
                <>
                  <div className="d-flex patient_right_nav">
                    <div className="vertical-dash"></div>
                    <div className="d-flex justify-content-center flex-column margin_left_right20">
                      <span className="date">{moment().format("MM/DD")}</span>
                      <span className="cash-flow">Daily Cash Flow</span>
                    </div>

                    <div className="d-flex flex-column margin_right20">
                      <Progress
                        percent={Number.parseInt(
                          paid_percentage?.split("%")[0]
                        )}
                      />
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <span className="mr-2 nav-label"> Total</span>
                          <span className="total_amount">
                            {currencyFormatter.format(total_amount || 0)}
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="d-flex align-items-center justify-content-between mt-1">
                            <span className="d-flex">
                              <GoDotFill className="font14 color_light_green mx-1" />
                              <span className="nav-label">Paid &nbsp;</span>
                            </span>
                            <span className="specific_amount">
                              {currencyFormatter.format(paid_amount || 0)}
                            </span>
                          </span>
                          <span className="d-flex align-items-center justify-content-between my-1">
                            <span className="d-flex">
                              <GoDotFill className="font14 color_white_grey mx-1" />
                              <span className="nav-label">Unpaid &nbsp;</span>
                            </span>
                            <span className="specific_amount">
                              {" "}
                              {currencyFormatter.format(unpaid_amount || 0)}
                            </span>
                          </span>
                        </div>
                        <div className="select-item-dropdown-wrapper">
                          <div className="form-group m-0">
                            <label className="wrap-Selected-Items">
                              <select
                                className="form-control select-item-dropdown"
                                name="print"
                                onChange={this.handleSelectItemDropdown}
                                value={this.state.selectValue}
                              >
                                <option defaultValue="select day">
                                  {" "}
                                  Selected Items
                                </option>
                                <option value="print_transactions">
                                  Print Transactions
                                </option>
                                <option value="mark_as_posted">
                                  Mark as Posted
                                </option>
                              </select>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </nav>

            <div className="wrapper">
              <SidebarNavigation history={history} />

              <div id="content">
                <Divider />
                {(isLoading || loadingFirstTime) && (
                  <div className="mt-5 mx-4">
                    <Loading />
                  </div>
                )}

                {!isLoading && !loadingFirstTime && (
                  <Table
                    rowSelection={{
                      type: selectionType,
                      // ...rowSelection,
                      onSelect: (record) => {
                        console.log(record);
                      },
                      getCheckboxProps: (record) => ({
                        disabled: record.due_status !== "Paid",
                      }),
                      onChange: (key) => {
                        this.setState({
                          selectedData: key,
                        });
                      },
                    }}
                    columns={columns}
                    dataSource={filteredData.sort(this.sortDuePaymentDetails)}
                    scroll={{ y: 535 }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <>
            {/* <PatientListMobile history={history}></PatientListMobile> */}
            <ComingSoonLayout
              content="To view your payments, please use your computer,
                    laptop, or tablet"
            />
          </>
        )}

        {isProfile && (
          <PatientProfileModal
            isOpen={isProfile}
            closeModal={this.closeModal}
            input={input}
            handleProfileChange={this.handleProfileChange}
            updateProfileInfo={this.updateProfileInfo}
          />
        )}

        {pastDueChecked && (
          <PastDuePatientList
            checked={pastDueChecked}
            onToggleSwitchChange={this.onToggleSwitchChange}
          />
        )}

        {successAlert && (
          <SuccessAlert
            isOpen={successAlert}
            closeSuccessAlert={this.closeSuccessAlert}
            message="The Patient profile has been updated"
          />
        )}

        {isCurrentHistory && (
          <CurrentHistoryModal
            history={history}
            isOpen={isCurrentHistory}
            balanceDetails={paymentActivityData}
            closeModal={this.closeModal}
            currentData={currentData}
            physicianId={accountDetails?.physician?.[0]?.physician_id}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: state.applicationIsLoading,
    accountDetails: state.accountDetails,
    paymentActivityData: state.paymentActivityData,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getAccountDetails: () => getAccountDetails()(dispatch),
    getPatientList: (
      data: IGetPatientList | boolean,
      flag?: string | undefined
    ) => getPatientList(data, flag)(dispatch),
    getPatientProfileInfo: (data: any) => getPatientProfileInfo(data)(dispatch),
    updatePatientProfileInfo: (data: any, id: string | undefined) =>
      updatePatientProfileInfo(data, id)(dispatch),
    markPostedAction: (data: { payment_schedule_id?: number[] }) =>
      markPostedAction(data)(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
