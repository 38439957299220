export class UrlBuilder {
  prefix: string;
  cache: string | null = null;
  // eslint-disable-next-line no-undef
  parameters: Record<string, string> = {};

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  getParameter(name: string, defaultValue: string): string {
    return this.parameters[name] ?? defaultValue;
  }

  setParameter<T>(name: string, value: T): UrlBuilder {
    const parameter = this.parameters[name];
    if (typeof value === 'string' && parameter === value) {
      return this;
    }
    this.cache = null;
    if (value == null) {
      delete this.parameters[name];
    } else {
      this.parameters[name] = String(value);
    }

    return this;
  }

  toString(): string {
    if (this.cache === null) {
      let url = "";
      const entries = Object.entries(this.parameters);
      for (const entry of entries) {
        const name = encodeURIComponent(entry[0]);
        const value = encodeURIComponent(entry[1]);
        url += (url ? "&" : "?") + name + "=" + value;
      }
      this.cache = this.prefix + url;
    }

    return this.cache ?? "";
  }
}
