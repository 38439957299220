import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
// import { useDispatch } from 'react-redux'
import { MODAL_STYLES } from 'constants/Constant'
import { FiChevronLeft } from 'react-icons/fi'
import PhysicianConfirmBillAmount from './PhysicianConfirmBillAmount'

function PhysicianBillAmount(props) {
    const { isOpen, closeBillAmountModal, appointmentId, physicianDetails } =
        props
    const [addBillAmount, setAddBillAmount] = useState('')
    const [addBillAmountError, setAddBillAmountError] = useState(false)
    const [confirmBillAount, setconfirmBillAount] = useState(false)

    const handleChange = (event) => {
        setAddBillAmount(event.target.value)
        setAddBillAmountError(false)
    }
    const handleNext = () => {
        if (addBillAmount !== '' && addBillAmount > 0) {
            setconfirmBillAount(!confirmBillAount)
        } else {
            setAddBillAmountError('Please enter amount greater then 0')
        }
    }
    const closeConfirmBillAmount = () => {
        setconfirmBillAount(!confirmBillAount)
    }
    return (
        <>
            {!confirmBillAount && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => closeBillAmountModal()}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="patient-amount-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => (window.location = '/home')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeBillAmountModal()}
                                />
                                <span className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </span>
                                <span></span>
                            </div>
                            <div className="modal-body">
                                <h4 className="heading">
                                    Enter the amount
                                    <br /> of your bill
                                </h4>
                                <span className="input-icon left">
                                    <input
                                        type="number"
                                        autoFocus
                                        className="patient-id-field"
                                        name="accessCode"
                                        autoComplete="off"
                                        value={addBillAmount}
                                        onChange={handleChange}
                                        data-dismiss="modal"
                                        placeholder="0.00"
                                    />
                                </span>
                                {addBillAmountError && (
                                    <small className="alert alert-danger error-msg">
                                        {addBillAmountError}
                                    </small>
                                )}
                            </div>
                            <div className="px-3">
                                <button
                                    className="btn next-btn pointer"
                                    onClick={handleNext}
                                >
                                    {' '}
                                    Next{' '}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {confirmBillAount && (
                <PhysicianConfirmBillAmount
                    addBillAmount={addBillAmount}
                    isOpen={confirmBillAount}
                    closeConfirmBillAmount={closeConfirmBillAmount}
                    appointmentId={appointmentId}
                    physicianDetails={physicianDetails}
                    history={props.history}
                />
            )}
        </>
    )
}

PhysicianBillAmount.propTypes = {
    closeBillAmountModal: PropTypes.func,
    isOpen: PropTypes.bool,
    appointmentId: PropTypes.number,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
}

export default PhysicianBillAmount
