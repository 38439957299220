import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import App from './app'
import store from './store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker.js'

const AppContainer: React.FC = () => {
    return (
        <Provider store={store}>
            <ToastContainer />
            <App />
        </Provider>
    )
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorker.register()
