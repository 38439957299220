import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
// import { MdChevronLeft } from 'react-icons/md';
// screen 112
function PromisePayModal(props) {
    const { isOpen, handlePompayInfo } = props
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={handlePompayInfo}
                style={MODAL_STYLES}
                ariaHideApp={false}
                id="promise-to-pay"
            >
                <div className="text-center">
                    <h4 className="modal-heading">Promise to pay</h4>
                    <p className="modal-heading">
                        <span>
                            Not able to pay your bill on the day its due?
                        </span>
                        <span>No problem</span>
                    </p>
                    <p className="modal-data">
                        We all have financial emergencies. Setting up promise to
                        pay gives you more time to make a payment and can keep
                        your account from going to collections
                    </p>
                    <button
                        onClick={handlePompayInfo}
                        className="btn got-it-btn"
                    >
                        Got it
                    </button>
                </div>
            </Modal>
        </>
    )
}
PromisePayModal.propTypes = {
    handlePompayInfo: PropTypes.func,
    isOpen: PropTypes.bool,
}
export default PromisePayModal
