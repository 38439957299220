import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Avatar from 'react-avatar'
import { MODAL_STYLES } from 'constants/Constant'
import { useDispatch, useSelector } from 'react-redux'
import { addPhysicianData } from 'actions/patientPhysicianActions'
// import ModalLoader from 'shared/ModalLoader';
import SuccessAlert from 'shared/SuccessAlert'
function AddPhysicianModal(props) {
    const dispatch = useDispatch()
    const [successAlert, setSuccessAlert] = useState(false)
    const physicianDetails = useSelector((state) => state.physicianDetails)
    const addPhysician = () => {
        const data = {
            physician_id: physicianDetails.physician_id,
            access_code: physicianDetails.access_code,
        }
        dispatch(addPhysicianData(data)).then((res) => {
            if (res.statusCode === 200) {
                setSuccessAlert(!successAlert)
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
        props.closeOptionModal()
        props.closeModal()
    }
    return (
        <>
            {!successAlert && (
                <Modal
                    isOpen={props.isOpen}
                    onRequestClose={props.closeModal}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="add-physician-modal"
                >
                    <button
                        type="button"
                        className="close modal_close_icon z-index1"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.closeModal()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="modal-body text-center">
                                <div className="d-flex justify-content-center">
                                    <Avatar
                                        name={
                                            physicianDetails.full_name &&
                                            physicianDetails.full_name.split(
                                                '.'
                                            )[1]
                                        }
                                        className="physician-image"
                                        size="100"
                                        round={true}
                                        src={
                                            physicianDetails.physician_profile_pic
                                        }
                                    />
                                </div>
                                <div>
                                    <h2 className="physician-name">
                                        {physicianDetails.full_name}
                                    </h2>
                                    <p className="physician-info">
                                        {physicianDetails.speciality}
                                    </p>
                                    <p className="physician-info">
                                        {physicianDetails.office_address &&
                                            physicianDetails
                                                .office_address[0] &&
                                            physicianDetails.office_address[0]
                                                .address1}
                                    </p>
                                    <p className="physician-info">
                                        {physicianDetails.office_address &&
                                            physicianDetails
                                                .office_address[0] &&
                                            physicianDetails.office_address[0]
                                                .office_hours}
                                    </p>
                                    <p className="physician-info">
                                        {physicianDetails.office_address &&
                                            physicianDetails
                                                .office_address[0] &&
                                            physicianDetails.office_address[0]
                                                .office_days}
                                    </p>
                                    <div className="physician-info">
                                        {physicianDetails.phone_number}
                                    </div>
                                </div>
                                {/* } */}
                                <button
                                    className="btn physician-button add-btn"
                                    onClick={addPhysician}
                                >
                                    Add Physician
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="The Physician has been added."
                />
            )}
        </>
    )
}

AddPhysicianModal.propTypes = {
    closeModal: PropTypes.func,
    closeOptionModal: PropTypes.func,
    openAddModal: PropTypes.func,
    isOpen: PropTypes.bool,
}

export default AddPhysicianModal
