import { applicationIsLoading } from '../commonActions'
import { decryptFunc } from '../../utils/helper'
import { AppDispatch } from 'store'
import { UrlBuilder } from 'actions/urlBuilder'
import { REACT_APP_BASE_URL } from '../../constants/common'
import { http } from 'actions/http'

export interface IGetPatientList {
	isToday?: string,
	paymentStatus?: string,
	is_past_due?: number,
	start_date?: string,
	end_date?: string,
	alphabeticalOrder?: string,
	sortByDate?: string,
	sortByAmount?: string,
	is_past_due_date_range?: number,
}

export function getPatientList(data?: IGetPatientList | boolean | null, flag?: string | undefined) {
	let new_start_date = ''
	let new_end_date = ''
	let new_alphabeticalOrder = ''
	let new_sortByDate = ''
	let new_sortByAmount = ''
	let isToday = ''
	let paymentStatus = ''

	if (typeof data !== 'boolean' && data) {
		isToday = data.isToday ?? ''
		paymentStatus = data.paymentStatus ?? ''
		new_start_date = data.start_date ?? ''
		new_end_date = data.end_date ?? ''
		new_alphabeticalOrder = data.alphabeticalOrder ?? ''
		new_sortByDate = data.sortByDate ?? ''
		new_sortByAmount = data.sortByAmount ?? ''
	}

	return async (dispatch: AppDispatch) => {
		const urlBuilder = new UrlBuilder(`${REACT_APP_BASE_URL}/patient/list`);

		if (flag === 'custom') {
			urlBuilder
				.setParameter('is_past_due_date_range', 0)
				.setParameter('start_date', new_start_date)
				.setParameter('end_date', new_end_date)
				.setParameter('payment_status', paymentStatus);
		} else if (flag) {
			urlBuilder
				.setParameter('is_past_due', 1)

			if(new_start_date) {
				urlBuilder.setParameter('start_date', new_start_date)
				.setParameter('is_past_due_date_range', 1);
			}

			if(new_end_date) {
				urlBuilder.setParameter('end_date', new_end_date)
				.setParameter('is_past_due_date_range', 1);
			}

		} else if (data === true) {
			urlBuilder.setParameter('is_past_due', 1);
		}
		else {
			urlBuilder
				.setParameter('is_today', isToday)
				.setParameter('payment_status', paymentStatus)
				.setParameter('sort_by_name', new_alphabeticalOrder)
				.setParameter('sort_by_date', new_sortByDate)
				.setParameter('sort_by_amount', new_sortByAmount)
				.setParameter('start_date', new_start_date)
				.setParameter('end_date', new_end_date)
		}

		try {
			dispatch(applicationIsLoading(true))

			const response = await http.GET(
				urlBuilder.toString(),
				{ headers: { 'content-type': 'multipart/form-data', } })

			return decryptFunc(response.data)
		} catch (error) {
			return error
		} finally {
			dispatch(applicationIsLoading(false))
		}
	}
}