import axios from 'axios'
import { REACT_APP_BASE_URL } from 'constants/common'
import { applicationIsLoading, modalIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from 'utils/helper'
import { getPatientHomePageDetails } from './patientActions'
import { getPhysicianList } from './patientPhysicianActions'
import { triggerNotifier } from 'shared/notifier'

export function fetchPatientCardListing(patientCardListing) {
    return {
        type: 'FETCH_PATIENT_CARD_LISTING',
        patientCardListing,
    }
}
export function fetchTransferFundData(transferFundData) {
    return {
        type: 'FETCH_TRANSFER_FUND_DATA',
        transferFundData,
    }
}

export function fetchPaymentScheduleDetails(paymentScheduleDetails) {
    return {
        type: 'FETCH_PAYMENTS_SCHEDULE_DETAILS',
        paymentScheduleDetails,
    }
}
export function fetchTotalOutstandingBalance(patientOutstandingBalance) {
    return {
        type: 'FETCH_TOTAL_OUTSTANDING_BALANCE',
        patientOutstandingBalance,
    }
}

// Check account

export function getAccountExists() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/account/exists',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                // dispatch(fetchAccountExists(res.data && res.data.account_exists))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function sendCustomerPatientAccount() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/customer/account',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Cards

export function sendPatientCardToken(id) {
    return (dispatch) => {
        // dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/card',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(id) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                // dispatch(applicationIsLoading(false));
                dispatch(getPatientCardListing())
                return res
            })
            .catch((error) => {
                const message =
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getPatientCardListing() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/card/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchPatientCardListing(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function removePatientCard(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + '/patient/card/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getPatientCardListing())
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}
// Patient outstanding balance

export function getPatientOutstandingBalance() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/remaining/balance',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(fetchTotalOutstandingBalance(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// Patient Payment Transfer

export function getTransferFundsData() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/banking/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getPatientCardListing())
                dispatch(fetchTransferFundData(res.data && res.data.data))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}
// Lumsum payment

export function sendTransferFundData(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/pay',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getTransferFundsData())
                dispatch(getPatientOutstandingBalance())
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Single pay api

export function singlePaymentAction(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/pay/emi',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(getPatientOutstandingBalance())
                dispatch(getPatientHomePageDetails())
                dispatch(getTransferFundsData())
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

// Patient payment data

export function getPatientPaymentSchedules(data) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/patient/payment/installment/option?amount=' +
                data.amount +
                '&' +
                'physician_id=' +
                data.physician_id +
                '&' +
                'patient_appointment_id=' +
                data.patient_id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                dispatch(fetchPaymentScheduleDetails(res.data))
                return res.data
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}

export function createEmiPayment(data) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/payment/installment/create',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                dispatch(getPhysicianList())
                dispatch(getPatientHomePageDetails())
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function promiseToPayAction(data) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/promise-to-pay',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}
