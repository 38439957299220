import axios from 'axios'
import { REACT_APP_BASE_URL } from 'constants/common'
import { applicationIsLoading, modalIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from 'utils/helper'
import { triggerNotifier } from 'shared/notifier'

export function fetchPhysicianDetails(physicianDetails) {
    return {
        type: 'FETCH_PHYSICIAN_DETAILS',
        physicianDetails,
    }
}
export function fetchPhysicianList(physicianList) {
    return {
        type: 'FETCH_PHYSICIAN_LIST',
        physicianList,
    }
}
export function fetchPatientAppointmentId(patientAppointmentId) {
    return {
        type: 'FETCH_PATIENT_APPOINTMENT_ID',
        patientAppointmentId,
    }
}
export function fetchPhysicianProfileDetails(physicianProfileDetails) {
    return {
        type: 'FETCH_PHYSICIAN_PROFILE_DETAILS',
        physicianProfileDetails,
    }
}

export function getPhysicianList() {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/patient/physician/list',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPhysicianList(res.data))
                dispatch(applicationIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                console.log(error)
                return error
            })
    }
}
export function sendPhysicianAccessCode(data) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + '/physician/search?access_code=' + data,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPhysicianDetails(res.data))
                dispatch(modalIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}
export function addPhysicianData(data) {
    return (dispatch) => {
        // dispatch(modalIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/patient/add/physician',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getPhysicianList())
                return res
            })
            .catch((error) => {
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function getPhysicianAppointmentId(id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        dispatch(fetchPatientAppointmentId(0))
        // dispatch(fetchPatientAppointmentId(0))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/patient/physician/appointmentid?physician_id=' +
                id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(
                    fetchPatientAppointmentId(res.data.patient_appointment_id)
                )
                dispatch(modalIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}

// physician profile

export function getPhysicianProfileInfo(id) {
    return (dispatch) => {
        dispatch(modalIsLoading(true))
        return axios({
            method: 'get',
            url:
                REACT_APP_BASE_URL +
                '/patient/physician/profile?physician_id=' +
                id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchPhysicianProfileDetails(res.data))
                dispatch(modalIsLoading(false))
                return res.data
            })
            .catch((error) => {
                dispatch(modalIsLoading(false))
                console.log(error)
                return error
            })
    }
}
