import React from 'react'
import PropTypes from 'prop-types'
// import { DatePicker, Space } from 'antd';
// import { dateFormat } from 'constants/Constant';
import { maxLengthCheck } from 'utils/helper'
function BasicInfo(props) {
    const {
        input,
        errors,
        handleChange,
        handleSubmit,
        enabled,
        dateList,
        monthList,
        yearList,
        // disabledDate,
        enterEmailFunc,
        errorsCheck,
        duplicateError,
        badEmail,
    } = props
    return (
        <>
            <div className="form_body w-100">
                <h4 className="bold-heading text-center">Basic Information </h4>
                <p className="light-content padding_left_right25 my-4">
                    Let’s get you set up so you can start getting paid faster
                    than ever before.
                </p>
                <form className="basic_info_form">
                    <div className="form-row flex-column">
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        className="form-control account_info_field"
                                        placeholder="Enter first name"
                                        autoComplete="off"
                                        value={input.first_name || ''}
                                        onChange={handleChange}
                                    />
                                    <label className="input-group-text account_info_label">
                                        First name
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {errors.first_name || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        className="form-control account_info_field"
                                        placeholder="Enter last name"
                                        autoComplete="off"
                                        value={input.last_name || ''}
                                        onChange={handleChange}
                                    />
                                    <label className="input-group-text account_info_label">
                                        Last name
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {errors.last_name || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group date_field">
                                <fieldset className="input-group-prepend d-flex flex-row w-100">
                                    {/* <Space direction="vertical">
                                        <DatePicker
                                            className="form-control account_info_field test1"
                                            format={ dateFormat }
                                            onChange={ handleChange }
                                            disabledDate={ disabledDate }
                                        />
                                    </Space> */}
                                    <select
                                        className="form-control dob-field rounded-0 month-field"
                                        name="monthName"
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled selected>
                                            Month
                                        </option>
                                        {monthList}
                                    </select>
                                    <select
                                        className="form-control dob-field rounded-0"
                                        name="dateName"
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled selected>
                                            Date
                                        </option>
                                        {dateList}
                                    </select>
                                    <select
                                        className="form-control dob-field rounded-right"
                                        name="yearName"
                                        onChange={handleChange}
                                        style={{ borderRadius: '0px' }}
                                    >
                                        <option value="" disabled selected>
                                            Year
                                        </option>
                                        {yearList}
                                    </select>
                                    <div
                                        className="input-group-text account_info_label rounded-left"
                                        style={{}}
                                    >
                                        Date of birth
                                    </div>
                                </fieldset>
                            </div>
                            <small className="text-danger">
                                {errors.date_of_birth || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="email"
                                        name="email_id"
                                        id="email_id"
                                        className="form-control account_info_field"
                                        placeholder="Enter email"
                                        autoComplete="off"
                                        value={input.email_id || ''}
                                        onChange={handleChange}
                                        onBlur={enterEmailFunc}
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="email_id"
                                    >
                                        Email
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {errors.email_id || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        maxLength="5"
                                        onInput={maxLengthCheck}
                                        name="zipcode"
                                        id="zipcode"
                                        className="form-control account_info_field"
                                        placeholder="Enter zipcode"
                                        autoComplete="off"
                                        value={input.zipcode || ''}
                                        onChange={handleChange}
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="zipcode"
                                    >
                                        Zipcode
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {errors.zipcode || ''}
                            </small>
                        </div>
                    </div>
                </form>
            </div>
            <button
                type="button"
                className="btn green_btn custom_btn"
                onClick={handleSubmit}
                data-target="#carouselExampleIndicators"
                data-slide-to={
                    !errorsCheck && !duplicateError && !badEmail ? 2 : 1
                }
                disabled={!enabled}
            >
                Continue
            </button>
        </>
    )
}

BasicInfo.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    disabledDate: PropTypes.func,
    enterEmailFunc: PropTypes.func,
    input: PropTypes.object,
    errors: PropTypes.object,
    enabled: PropTypes.bool,
    errorsCheck: PropTypes.bool,
    addClass: PropTypes.bool,
    yearList: PropTypes.array,
    monthList: PropTypes.array,
    dateList: PropTypes.array,
    duplicateError: PropTypes.bool,
    badEmail: PropTypes.bool,
}

export default BasicInfo
