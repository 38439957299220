import React from 'react'
import PropTypes from 'prop-types'

function VerificationForm(props) {
    const {
        verifiedInput,
        verifiedErrors,
        handleVerifiedChange,
        handleVerifiedSubmit,
        enabledVerified,
    } = props
    return (
        <>
            <div className="form_body">
                <h4 className="bold-heading text-center mb-4 padding_left_right45">
                    It is important we can verify your identity.{' '}
                </h4>
                <form className="basic_info_form">
                    <div className="form-row flex-column">
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="speciality"
                                        autoComplete="off"
                                        placeholder="Enter speciality"
                                        id="speciality"
                                        className="form-control account_info_field"
                                        value={verifiedInput.speciality || ''}
                                        onChange={handleVerifiedChange}
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="speciality"
                                    >
                                        Speciality
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {verifiedErrors.speciality || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        name="npi_number"
                                        id="npi_number"
                                        autoComplete="off"
                                        placeholder="Enter NPI"
                                        className="form-control account_info_field"
                                        value={verifiedInput.npi_number || ''}
                                        onChange={handleVerifiedChange}
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="npi_number"
                                    >
                                        NPI
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {verifiedErrors.npi_number || ''}
                            </small>
                        </div>
                        <div className="col-sm-12 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="tax_id"
                                        id="tax_id"
                                        autoComplete="off"
                                        placeholder="Enter Tax I.D."
                                        className="form-control account_info_field"
                                        value={verifiedInput.tax_id || ''}
                                        onChange={handleVerifiedChange}
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="tax_id"
                                    >
                                        Tax I.D.
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger">
                                {verifiedErrors.tax_id || ''}
                            </small>
                        </div>
                    </div>
                </form>
            </div>
            <button
                type="button"
                className="btn green_btn custom_btn"
                onClick={handleVerifiedSubmit}
                disabled={!enabledVerified}
            >
                Finish
            </button>
        </>
    )
}

VerificationForm.propTypes = {
    handleVerifiedChange: PropTypes.func,
    handleVerifiedSubmit: PropTypes.func,
    verifiedInput: PropTypes.object,
    verifiedErrors: PropTypes.object,
    enabledVerified: PropTypes.bool,
}

export default VerificationForm
