import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AddBankCard from 'components/account/AddBankCard'
import Modal from 'react-modal'
import {
    getAccountExists,
    sendCustomerPatientAccount,
    getPatientCardListing,
    removePatientCard,
} from 'actions/patientBankingActions'
import TransferPayment from './TransferPayment'
import { MODAL_STYLES } from 'constants/Constant'
import { BsCreditCard } from 'react-icons/bs'
import ModalLoader from 'shared/ModalLoader'
import ConfirmDelete from 'shared/ConfirmDelete'

function PaymentMethods() {
    const dispatch = useDispatch()
    const [openCardModal, setOpenCardModal] = useState(false)
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const [confirmEditId, setConfirmEditId] = useState('')
    confirmEditId
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const patientCardListing = useSelector((state) => state.patientCardListing)
    useEffect(() => {
        dispatch(getAccountExists()).then((res) => {
            if (res.statusCode === 200) {
                if (!res.data.account_exists) {
                    dispatch(sendCustomerPatientAccount())
                } else {
                    dispatch(getPatientCardListing())
                }
            }
        })
    }, [getAccountExists])
    const handleRemove = () => {
        dispatch(removePatientCard(confirmEditId))
        setIsConfirmDelete(!isConfirmDelete)
    }
    const confirmRemove = (data) => {
        setConfirmEditId(data && data.id)
        setIsConfirmDelete(!isConfirmDelete)
    }
    const handleAddCardModal = () => {
        setOpenCardModal(!openCardModal)
    }

    const { cardList } = patientCardListing || {}

    return (
        <>
            <div className="banking patient-payments-method-page">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <div className="d-flex">
                                <h2 className="tab_heading">Linked Card(s)</h2>
                            </div>
                            {isLoading && <ModalLoader />}
                            {!isLoading && (
                                <table className="table table-borderless office_info">
                                    {cardList?.map((data, id) => {
                                        return (
                                            <tbody
                                                key={id}
                                                className="border-0 linked-accounts table-padding"
                                            >
                                                <tr>
                                                    <td className="px-0 border-0 d-flex align-items-center">
                                                        <BsCreditCard className="color_dark_grey font20 mr-2 account-icon" />
                                                        <div>
                                                            <div className="office_address">
                                                                {data.card_type}{' '}
                                                                {'****' +
                                                                    data.card_number}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-0 border-0 text-right">
                                                        <button
                                                            className="btn remove_element_btn"
                                                            onClick={() =>
                                                                confirmRemove(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            )}
                            <button
                                className="btn btn-link pl-0 add_element_link"
                                onClick={handleAddCardModal}
                            >
                                Add a card…
                            </button>
                        </div>
                    </div>
                    <Modal
                        id="addBankAccountModal"
                        isOpen={openCardModal}
                        onRequestClose={handleAddCardModal}
                        style={MODAL_STYLES}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <button
                            type="button"
                            className="close modal_close_icon z-index1"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => handleAddCardModal()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <AddBankCard
                                closeModal={handleAddCardModal}
                                patient={true}
                            />
                        </div>
                    </Modal>
                    {cardList === undefined ? (
                        <TransferPayment />
                    ) : (
                        <TransferPayment patientCardListing={cardList} />
                    )}
                </div>
            </div>
            {isConfirmDelete && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={confirmRemove}
                    handleRemoveFunc={handleRemove}
                    role="card"
                />
            )}
        </>
    )
}

PaymentMethods.propTypes = {
    getAccountExists: PropTypes.func,
    sendCustomerPatientAccount: PropTypes.func,
    getPatientCardListing: PropTypes.func,
}

export default PaymentMethods
