import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutAction } from 'actions/authActions'
import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo'
import PompayTopLogo from 'shared/PompayTopLogo'

export class Verified extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleSubmit() {
        const isMobile = window.innerWidth < 480
        !isMobile
            ? this.props.history.push('/create-passcode')
            : this.props.history.push('/account-homepage-mobile')
    }
    handleLogout = () => {
        this.props.logoutAction(this.props.history)
    }
    render() {
        const enabled =
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.response &&
            this.props.location.state.response.data &&
            this.props.location.state.response.data.is_verified
                ? true
                : false
        return (
            <div>
                <div className="create_page_background"></div>
                <nav className="navbar navbar-light">
                    <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block" />
                    <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
                    <a
                        className="logout mobi-logout"
                        onClick={this.handleLogout}
                    >
                        Log Out
                    </a>
                </nav>
                <div className="container-fluid thank_you_page">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                            <div className="create_account_section">
                                <h4 className="auth-heading mb-3">
                                    Already have an account?
                                </h4>
                                <p className="auth-content">
                                    The easiest way to pay your medical bills.
                                    Discounts, payment plans, and more!
                                </p>
                                <button
                                    type="button"
                                    className="btn white_btn custom_btn"
                                    onClick={this.handleLogout}
                                >
                                    Sign In
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-column align-items-center verified">
                            {/* <a className="logout" onClick={ this.handleLogout }>Logout</a> */}
                            <div className="form_body text-center">
                                <h4
                                    className="bold-heading margin_left_right45"
                                    style={{ marginBottom: '170px' }}
                                >
                                    Thank you for submitting your application to
                                    Pompay!{' '}
                                </h4>
                                <p
                                    className="bold-content padding_left_right45"
                                    style={{ marginBottom: '170px' }}
                                >
                                    Your information is being reviewed. A
                                    representative will reach out to you in the
                                    next 48 hours to verify your identity
                                </p>
                            </div>
                            <button
                                type="button"
                                disabled={!enabled}
                                className="btn green_btn custom_btn"
                                onClick={this.handleSubmit}
                            >
                                Back to home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        emailRedirectionKey: state.emailRedirectionKey,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutAction: (history) => dispatch(logoutAction(history)),
    }
}

Verified.propTypes = {
    logoutAction: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Verified)
