// export function isAccountExist(state = false, action) {
//     switch(action.type) {
//     case 'FETCH_ACCOUNT_EXISTS':
//         return action.isAccountExist
//     default:
//         return state;
//     }
// }

// export function patientCustomerAccount(state = false, action) {
//     switch (action.type) {
//     case 'FETCH_PATIENT_CUSTOMER_ACCOUNT':
//         return action.patientCustomerAccount;
//     default:
//         return state;
//     }
// }
export function patientCardListing(state = [], action) {
    switch (action.type) {
        case 'FETCH_PATIENT_CARD_LISTING':
            return action.patientCardListing
        default:
            return state
    }
}
export function transferFundData(state = [], action) {
    switch (action.type) {
        case 'FETCH_TRANSFER_FUND_DATA':
            return action.transferFundData
        default:
            return state
    }
}
export function paymentScheduleDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PAYMENTS_SCHEDULE_DETAILS':
            return action.paymentScheduleDetails
        default:
            return state
    }
}
export function patientOutstandingBalance(state = {}, action) {
    switch (action.type) {
        case 'FETCH_TOTAL_OUTSTANDING_BALANCE':
            return action.patientOutstandingBalance
        default:
            return state
    }
}
