import axios from 'axios'
import { REACT_APP_BASE_URL } from '../constants/common'
import { applicationIsLoading } from './commonActions'
import { encryptFunc, decryptFunc } from '../utils/helper'
import { getAccountDetails } from './accountActions'
import { triggerNotifier } from 'shared/notifier'

export function isAuthenticated(bool) {
    return {
        type: 'IS_AUTHENTICATED',
        isAuthenticated: bool,
    }
}

export function otpModalOpen(bool) {
    return {
        type: 'OTP_MODAL_OPEN',
        otpModalOpen: bool,
    }
}
export function fetchTeamMember(bool) {
    return {
        type: 'FETCH_TEAM_MEMBER',
        teamMember: bool,
    }
}

export function updateOtpModal(bool) {
    return {
        type: 'UPDATE_OTP_MODAL',
        updateOtpValue: bool,
    }
}

export function saveFormData(formData) {
    return {
        type: 'SAVE_FORM_DATA',
        formData,
    }
}

export function emailRedirectionKeys(emailRedirectionKey) {
    return {
        type: 'EMAIL_REDIRECTION_KEYS',
        emailRedirectionKey,
    }
}

export function redirectionKeys(redirectionData) {
    return {
        type: 'REDIRECTION_KEYS',
        redirectionData,
    }
}

export function saveVerifyEmailData(verifyEmailDetails) {
    return {
        type: 'SAVE_VERIFY_EMAIL_DATA',
        verifyEmailDetails,
    }
}

export function verificationOtpError(bool) {
    return {
        type: 'VERIFICATION_OTP_ERROR',
        isOtpCorrect: bool,
    }
}

export function fetchValidPasscodeError(bool) {
    return {
        type: 'FETCH_VALID_PASSCODE_ERROR',
        isvalidPasscode: bool,
    }
}
export function fetchUserAccountDetails(userAccountDetails) {
    return {
        type: 'FETCH_USER_ACCOUNT_DETAILS',
        userAccountDetails,
    }
}
export function lockedDetails(bool) {
    return {
        type: 'LOCK_DETAILS',
        lockData: bool,
    }
}
export function sendFormData(data) {
    return (dispatch) => {
        dispatch(saveFormData(data))
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/register',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                console.log('OTP', res.data)
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(false))
                dispatch(otpModalOpen(true))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(true))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function sendUpdateEmail(data) {
    return (dispatch) => {
        dispatch(saveVerifyEmailData(data))
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/email/send/code',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                console.log('verification code', res.data.verification_otp)
                dispatch(verificationOtpError(false))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function sendOtp(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/verify/otp',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                if (res.data.access_token) {
                    sessionStorage.setItem('accessToken', res.data.access_token)
                    dispatch(applicationIsLoading(false))
                    dispatch(isAuthenticated(true))
                    dispatch(redirectionKeys(res.data))
                    dispatch(
                        fetchTeamMember(
                            res.data &&
                                res.data.is_team_member &&
                                res.data.is_team_member
                                ? res.data.is_team_member
                                : 0
                        )
                    )
                    dispatch(otpModalOpen(false))
                    dispatch(verificationOtpError(false))
                }
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(true))
                return error
            })
    }
}

// Check duplicate email
export function checkDuplicateEmail(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/email/exists',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                const message =
                    error.response &&
                    error.response.data &&
                    (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error
            })
    }
}

export function sendBasicInformation(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/create/information',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function sendVerifiedData(data, history) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/verify/identity',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                history.push('/verified')
                return res.data
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function sendOtpEmail(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/verify/email/code',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                if (res.data.access_token) {
                    sessionStorage.setItem('accessToken', res.data.access_token)
                    dispatch(isAuthenticated(true))
                    dispatch(emailRedirectionKeys(res.data))
                }
                dispatch(otpModalOpen(false))
                dispatch(verificationOtpError(false))
                return res
            })
            .catch((error) => {
                dispatch(verificationOtpError(true))
                return error
            })
    }
}

export function updatePhoneNumber(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/update/phonenumber',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                console.log('verification code', res.data.verification_otp)
                dispatch(updateOtpModal(true))
                dispatch(verificationOtpError(false))
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function loginAction(data) {
    return (dispatch) => {
        dispatch(saveFormData(data))
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/login',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                console.log('verification code', res.data.verification_otp)
                dispatch(fetchUserAccountDetails(res.data))
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(false))
                dispatch(otpModalOpen(true))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(true))
                return error
            })
    }
}

export function checkValidNumberAction(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/verify/phonenumber',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                dispatch(verificationOtpError(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}

export function logoutAction(history) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/logout',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                sessionStorage.removeItem('accessToken')
                history ? history.push('/') : (window.location.href = '/')
                dispatch(isAuthenticated(false))
                return res.data
            })
            .catch((error) => {
                console.log(error)
                return error
            })
    }
}

// passcode

export function sendPasscode(id, data) {
    return (dispatch) => {
        dispatch(saveFormData(data))
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + '/physician/unlock/screen/' + id,
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(lockedDetails(res.data.is_locked === 1 ? true : false))
                dispatch(getAccountDetails())
                dispatch(fetchValidPasscodeError(false))
                return res
            })
            .catch((error) => {
                dispatch(fetchValidPasscodeError(true))
                return error
            })
    }
}

// Create passcode

export function sendPasscodeData(data) {
    return (dispatch) => {
        dispatch(saveFormData(data))
        dispatch(applicationIsLoading(true))
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + '/account/create/passcode',
            headers: {
                Authorization:
                    'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false))
                return res
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false))
                return error
            })
    }
}
