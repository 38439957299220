import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { IoQrCodeOutline } from 'react-icons/io5'
import AddPhysicianMobile from './AddPhysicianMobile'
import { useDispatch } from 'react-redux'
import { sendPhysicianAccessCode } from 'actions/patientPhysicianActions'
import ScanCodePage from './ScanCodePage'
// import { isMobile } from 'constants/Constant';

function EnterAccessCode(props) {
    const dispatch = useDispatch()
    const [addPhysician, setAddPhysician] = useState(false)
    const [scanCodePage, setScanCodePage] = useState(false)
    const [accessCode, setAccessCode] = useState('')
    // const [ accessCodeError, setAccessCodeError ] = useState(false);
    const validate = (value) => {
        let isValid = true
        if (value === '') {
            isValid = false
            // setAccessCodeError('Please enter access code')
        }
        // if (value !== 'undefined') {
        //     const pattern = new RegExp(/^[0-9]{6,8}$/);
        //     if (!pattern.test(value)) {
        //         isValid = false;
        //         setAccessCodeError('Access code lenght must be of 7 digits')
        //     }
        // }
        return isValid
    }
    const openAddModal = (value) => {
        if (validate(value)) {
            dispatch(sendPhysicianAccessCode(value)).then((res) => {
                if (res.statusCode === 200) {
                    setScanCodePage(!scanCodePage)
                    setAddPhysician(!addPhysician)
                }
                // else {
                //     setAccessCodeError('InValid access code');
                //     // props.history.push('/home')
                //     // {isMobile && props.history.push('/add-physician-mobile')}
                // }
            })
        }
        // if (value !== '')
        //     if (value !== '') {
        //         setAddPhysician(!addPhysician);
        //         dispatch(sendPhysicianAccessCode(value));
        //     } else {
        //         setAccessCodeError('please enter access code ');
        //     }
    }
    const handleBack = () => {
        props.history.push('/home')
    }
    const handleScanCodePage = () => {
        setScanCodePage(!scanCodePage)
    }
    const handleChange = (event) => {
        setAccessCode(event.target.value)
        // setAccessCodeError(false);
        const value = event.target.value
        if (value.length === 6) {
            setTimeout(() => {
                openAddModal(value)
            }, 500)
        }
    }
    return (
        <>
            {!addPhysician && !scanCodePage && (
                <div className="Enter-physician-code-page">
                    <div className="d-flex flex-column" id="content">
                        <button
                            type="button"
                            className="close cross-icon"
                            onClick={handleBack}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h3 className="page-heading">
                            Enter the physician
                            <br /> access code
                        </h3>
                        <input
                            type="text"
                            autoFocus
                            className="code-textfield"
                            name="accessCode"
                            autoComplete="off"
                            placeholder="Enter access code"
                            value={accessCode}
                            onChange={handleChange}
                            data-dismiss="modal"
                        />
                        {/* {accessCodeError && <small className="alert alert-danger error-msg mx-3">Access code lenght must be of 6 digits</small>} */}
                        {/* <button onClick={ ()=> openAddModal() }>next</button> */}
                    </div>
                    {/*TODO: Open this up when the scanning feature is ready*/}
                    {/* <div className="scan-footer">
                        <div className="d-flex flex-column align-items-center">
                            <button className="btn scan-btn">
                                <IoQrCodeOutline
                                    className="scan-icon"
                                    onClick={() => handleScanCodePage()}
                                />
                            </button>
                            <label>Scan Code</label>
                        </div>
                    </div> */}
                </div>
            )}
            {addPhysician && (
                <AddPhysicianMobile
                    addPhysician={addPhysician}
                    history={props.history}
                />
            )}
            {scanCodePage && (
                <ScanCodePage
                    isScan={scanCodePage}
                    closePage={handleScanCodePage}
                    history={props.history}
                    openAddModal={openAddModal}
                />
            )}
        </>
    )
}

EnterAccessCode.propTypes = {
    closeModal: PropTypes.func,
    openAddModal: PropTypes.func,
    sendPhysicianAccessCode: PropTypes.func,
    isOpen: PropTypes.bool,
    history: PropTypes.object,
}

export default EnterAccessCode
