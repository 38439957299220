import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    getGuidelines,
    // addGuidelines,
    updateGuidelines,
} from 'actions/accountActions';
import { Switch } from 'antd';
import 'antd/dist/antd.css';
import Loading from 'shared/Loader';
import { MdInfoOutline } from 'react-icons/md';
import Modal from 'react-modal';
import { MODAL_STYLES } from 'constants/Constant';
import SuccessAlert from 'shared/SuccessAlert';
// import VarianceInfo from '../mobile/VarianceInfo';
export class Guidelines extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            isVarianceInfo: false,
            isPayDateInfo: false,
            successAlert: false,
            restrict: false,
        }
    }

    componentDidMount() {
        this.props.getGuidelines().then((response) => {
            if (response.statusCode === 200) {
                this.setState({
                    input:
                        response.data.physician_payment_guidelines &&
                        response.data.physician_payment_guidelines[0],
                    isLocked: response.data.is_locked,
                })
            }
        })
    }

    onToggleChange = (checked, x) => {
        const input = this.state.input
        input[x] = checked ? 1 : 0
        this.setState({ input })
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
            errors: {},
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { isLocked } = this.state
        if (this.props.isTeam) {
            if (isLocked) {
                this.setState({
                    successAlert: true,
                    restrict: true,
                    successAlertMessage:
                        'Please ask your physician to unlock profile',
                })
            }
        } else {
            if (this.validate()) {
                const obj = this.state.input
                obj &&
                    obj.id &&
                    this.props.updateGuidelines(obj.id, obj).then((res) => {
                        if (res.statusCode === 200) {
                            this.setState({
                                successAlert: true,
                                successAlertMessage:
                                    'Payment guideline info has been updated successfully.',
                            })
                        }
                    })
            }
        }
    }
    closeSuccessAlert = () => {
        this.setState({ successAlert: false })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        const mp = Number(input['minimum_monthly_payment'])
        const av = Number(input['deviation_of_minimum_payment'])
        const mm = Number(input['max_number_of_months'])
        const p = Number(input['time_period_to_choose_start_date'])
        const ppd = Number(input['prompt_pay_discount'])

        if (!input['minimum_monthly_payment']) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Please enter minimum monthly payment.'
        }
        if (mp < 10) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Minimum monthly payment must be greater then $10.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['minimum_monthly_payment'] =
                'Please enter only whole numbers.'
        }

        if (!input['deviation_of_minimum_payment']) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Please enter deviation minimum payment.'
        }
        if (av < 2) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Amount variance must be greater then $2.'
        }
        if (av >= mp) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Amount variance must be less then Min. monthly payment'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['deviation_of_minimum_payment'] =
                'Please enter only whole numbers.'
        }

        if (!input['max_number_of_months']) {
            isValid = false
            errors['max_number_of_months'] =
                'Please enter max number of months.'
        }
        if (mm < 2) {
            isValid = false
            errors['max_number_of_months'] = 'must be greater then 1 month.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['max_number_of_months'] = 'Please enter only whole numbers.'
        }

        if (!input['time_period_to_choose_start_date']) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter time period.'
        }
        if (p < 0) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter only whole numbers.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['time_period_to_choose_start_date'] =
                'Please enter only whole numbers.'
        }

        if (!input['prompt_pay_discount']) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter prompt pay discount.'
        }
        if (ppd < 0) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter only whole numbers.'
        }
        if (!Number.isInteger(mp)) {
            isValid = false
            errors['prompt_pay_discount'] = 'Please enter only whole numbers.'
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    disableFunc = (event) => {
        event.preventDefault()
        this.props.getGuidelines().then((response) => {
            if (response.statusCode === 200) {
                this.setState({
                    input:
                        response.data.physician_payment_guidelines &&
                        response.data.physician_payment_guidelines[0],
                    isLocked: response.data.is_locked,
                })
            }
        })
    }
    handlePayDateInfo = () => {
        this.setState({
            isPayDateInfo: !this.state.isPayDateInfo,
        })
    }
    handleVarianceInfo = () => {
        this.setState({
            isVarianceInfo: !this.state.isVarianceInfo,
        })
    }

    render() {
        const {
            input,
            errors,
            isPayDateInfo,
            isVarianceInfo,
            successAlert,
            successAlertMessage,
            restrict,
        } = this.state
        const { isLoading } = this.props
        return (
            <>
                <div
                    className="guidelines"
                    // id="nav-guidlines"
                    // role="tabpanel"
                    // aria-labelledby="nav-guidlines-tab"
                >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2 className="tab_heading">Payment Guidelines</h2>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="row no-gutters">
                            <div className="col-12 mt-5">
                                <Loading />
                            </div>
                        </div>
                    )}
                    {!isLoading && (
                        <div className="row">
                            <div className="col-md-12">
                                <form>
                                    <div className="form-row flex-column">
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-icon left">
                                                        <input
                                                            type="number"
                                                            id="min_monthly_payment"
                                                            name="minimum_monthly_payment"
                                                            value={
                                                                input.minimum_monthly_payment ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="min_monthly_payment"
                                                        >
                                                            Minimum monthly
                                                            payment
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                            <small className="text-danger float-left">
                                                {errors.minimum_monthly_payment ||
                                                    ''}
                                            </small>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <fieldset className="input-icon left">
                                                        <input
                                                            type="number"
                                                            id="amount_variance"
                                                            name="deviation_of_minimum_payment"
                                                            value={
                                                                input.deviation_of_minimum_payment ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="amount_variance"
                                                        >
                                                            Amount variance{' '}
                                                        </label>
                                                    </fieldset>
                                                    <span className="variance-icon icon-wrapper">
                                                        <MdInfoOutline
                                                            className="info_icon pointer"
                                                            onClick={
                                                                this
                                                                    .handleVarianceInfo
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <small className="text-danger float-left">
                                                {errors.deviation_of_minimum_payment ||
                                                    ''}
                                            </small>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-wrapper">
                                                        <input
                                                            type="number"
                                                            id="max_months"
                                                            name="max_number_of_months"
                                                            value={
                                                                input.max_number_of_months ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field account_info_field_padding"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="max_months"
                                                        >
                                                            Max number of months
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                            <small className="text-danger float-left">
                                                {errors.max_number_of_months ||
                                                    ''}
                                            </small>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <fieldset className="input-wrapper">
                                                        <input
                                                            type="number"
                                                            id="Choose_date"
                                                            name="time_period_to_choose_start_date"
                                                            value={
                                                                input.time_period_to_choose_start_date ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field account_info_field_padding"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="Choose_date"
                                                        >
                                                            Choose start date
                                                            (in days){' '}
                                                        </label>
                                                    </fieldset>
                                                    <span className="startdate-icon icon-wrapper">
                                                        <MdInfoOutline
                                                            className="info_icon pointer"
                                                            onClick={
                                                                this
                                                                    .handlePayDateInfo
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <small className="text-danger float-left">
                                                {errors.time_period_to_choose_start_date ||
                                                    ''}
                                            </small>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-wrapper">
                                                        <input
                                                            type="number"
                                                            id="Pompay_discount"
                                                            name="prompt_pay_discount"
                                                            value={
                                                                input.prompt_pay_discount ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field account_info_field_padding"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="Pompay_discount"
                                                        >
                                                            Pompay discount %
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                            <small className="text-danger float-left">
                                                {errors.prompt_pay_discount ||
                                                    ''}
                                            </small>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-icon left">
                                                        <input
                                                            type="number"
                                                            id="fees"
                                                            name="late_fee"
                                                            value={
                                                                input.late_fee ||
                                                                ''
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-control account_info_field guidelines_info_field"
                                                        />
                                                        <label
                                                            className="input-group-text payment_guideline_label"
                                                            htmlFor="fees"
                                                        >
                                                            Late fee
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 my-3">
                                            <div className="form-check p-0 text-left">
                                                <label
                                                    className="form-check-label Promise_Pay d-flex "
                                                    htmlFor="Promise-To-Pay"
                                                >
                                                    <span className="mr-5 font16">
                                                        Enable Promise-To-Pay
                                                    </span>
                                                    <Switch
                                                        checked={
                                                            input.enable_promise_to_pay ||
                                                            ''
                                                        }
                                                        onChange={(checked) =>
                                                            this.onToggleChange(
                                                                checked,
                                                                'enable_promise_to_pay'
                                                            )
                                                        }
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5 mt-2 payment_para text-left">
                                            <p className="font16 color_dark_grey font-weight500 line-height_18 mb-0">
                                                {' '}
                                                When your patients need more
                                                time to pay, make it easy for
                                                them to
                                                <br /> choose a new due date for
                                                their payment.{' '}
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-5">
                                            <div className="btn-card text-left">
                                                <button
                                                    type="submit"
                                                    className="btn custom_btn green_btn"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={this.disableFunc}
                                                    className="btn btn-link cancel-link"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    <div className="row"></div>
                </div>
                {isVarianceInfo && (
                    <Modal
                        isOpen={isVarianceInfo}
                        onRequestClose={this.handleVarianceInfo}
                        style={MODAL_STYLES}
                        ariaHideApp={false}
                        id="amount-variance"
                    >
                        <div className="text-center">
                            <h4 className="modal-heading">Amount Variance</h4>
                            <p className="modal-heading">
                                This makes it possible for you to accept
                                payments less than your minimum monthly amount.
                            </p>
                            <p className="modal-data">
                                Example: Your minimum monthly payment is set at
                                $25 and the max # of months is 3. Your patient
                                has a bill that is $74.{' '}
                            </p>
                            <p className="modal-data">
                                $74 ÷ 3 = $24.66, which is less than your $25
                                min. Setting a variance makes it possible for
                                you to accept this amount.
                            </p>
                            <button
                                onClick={this.handleVarianceInfo}
                                className="btn got-it-btn"
                            >
                                Got it
                            </button>
                        </div>
                    </Modal>
                )}
                {isPayDateInfo && (
                    <Modal
                        isOpen={isPayDateInfo}
                        onRequestClose={this.handlePayDateInfo}
                        style={MODAL_STYLES}
                        ariaHideApp={false}
                        id="pay-info"
                    >
                        <div className="text-center">
                            <h4 className="modal-heading">
                                Payment Start Date
                            </h4>
                            <p className="modal-heading">
                                Allow your patients to choose their payment
                                start date. For patients that use payment plans,
                                this date will be used as the monthly due date
                                until the balance is paid.
                            </p>
                            <p className="modal-data content-margin  pb-5 mb-0">
                                Providing your patient’s with the flexibility to
                                choose when to make their payment will increase
                                the chances of bills being paid in full and
                                on-time.
                            </p>
                            <button
                                onClick={this.handlePayDateInfo}
                                className="btn got-it-btn"
                            >
                                Got it
                            </button>
                        </div>
                    </Modal>
                )}
                {successAlert && (
                    <SuccessAlert
                        isOpen={successAlert}
                        closeSuccessAlert={this.closeSuccessAlert}
                        restrict={restrict ? true : false}
                        role={restrict ? false : 'Guidelines'}
                        message={successAlertMessage}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGuidelines: () => dispatch(getGuidelines()),
        // addGuidelines: (data) => dispatch(addGuidelines(data)),
        updateGuidelines: (id, data) => dispatch(updateGuidelines(id, data)),
    }
}

Guidelines.propTypes = {
    history: PropTypes.object,
    getGuidelines: PropTypes.func,
    // addGuidelines: PropTypes.func,
    updateGuidelines: PropTypes.func,
    isLoading: PropTypes.bool,
    cardListing: PropTypes.object,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    transDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Guidelines)
