export function paymentActivityData(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PAYMENT_ACTIVITY_DATA':
            return action.paymentActivityData
        default:
            return state
    }
}

export function installBillDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_INSTALL_BILL_DETAILS':
            return action.installBillDetails
        case 'UPDATE_AUTO_PAY_STATUS':
            return {
                ...state,
                payment_details: {
                    ...state.payment_details,
                    is_auto_pay: action.isAutoPay,
                },
            }
        default:
            return state
    }
}

export function patientHomePageDetails(state = {}, action) {
    switch (action.type) {
        case 'FETCH_PATIENT_HOME_PAGE_DETAILS':
            return action.patientHomePageDetails
        default:
            return state
    }
}
