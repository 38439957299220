import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import { getPhysicianList } from 'actions/patientPhysicianActions'
import { MdKeyboardArrowRight } from 'react-icons/md'
import ModalLoader from 'shared/ModalLoader'
import { alphabets } from 'constants/Constant'
import EnterPatientAccountNumberMobile from './EnterPatientAccountNumberMobile'
import SuccessAlert from 'shared/SuccessAlert'

function SelectPhysicianMobile(props) {
    const dispatch = useDispatch()
    const {
        history: { location },
    } = props
    const [openAccountNumber, setOpenAccountNumber] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const [physicianId, setPhysicianId] = useState({})
    const patientAppointmentId = useSelector(
        (state) => state.patientAppointmentId
    )
    // const [ patientAppointmentIdd, setPatientAppointmentIdd ] = useState()
    useEffect(() => {
        dispatch(getPhysicianList())
        // if(patientAppointmentId !== 0 ) {
        //     setPatientAppointmentIdd(patientAppointmentId)
        // } else {
        //     // window.location.reload(false);
        // }
    }, [''])
    const physicianList = useSelector((state) => state.physicianList)
    const physicianID = location.state && location.state.physicianIds
    const handleSelectPhysician = (data) => {
        if (data.stripe_account_id) {
            if (physicianID.includes(data && data.physician_id)) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'Your previous arrangement is still under review'
                )
            } else {
                setPhysicianId(data)
                // dispatch(getPhysicianAppointmentId(data));
                setOpenAccountNumber(!openAccountNumber)
            }
        } else {
            setSuccessAlert(true)
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
        setSuccessAlertMessage('')
    }
    const closePatientAccountNumber = () => {
        setOpenAccountNumber(!openAccountNumber)
    }
    const sortedUser =
        physicianList &&
        physicianList.physician_list &&
        physicianList.physician_list.sort((a, b) =>
            a.last_name.localeCompare(b.last_name)
        )
    return (
        <>
            {!openAccountNumber && (
                <div className="select-your-physician-page physician-list">
                    <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                        <span></span>
                        <label className="nav-heading-bold">New Bill</label>
                        <button
                            type="button"
                            className="close close-bold-icon z-index1"
                            onClick={() => props.history.goBack()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </nav>
                    <h3 className="heading">Select your physician</h3>
                    <div className="d-flex flex-column">
                        {isLoading && <ModalLoader />}
                        {!isLoading &&
                            alphabets.map((dx) => {
                                let checkCount = 0
                                return (
                                    sortedUser &&
                                    sortedUser.map((d, idx) => {
                                        if (
                                            dx.toLowerCase() ==
                                            d.last_name.charAt(0).toLowerCase()
                                        ) {
                                            checkCount++
                                            return (
                                                <div key={idx}>
                                                    {checkCount == 1 && (
                                                        <div className="alphabet-divider d-flex align-items-center">
                                                            <span>{dx}</span>
                                                        </div>
                                                    )}
                                                    <div
                                                        className="d-flex justify-content-between align-items-center row-padding"
                                                        onClick={() =>
                                                            handleSelectPhysician(
                                                                d
                                                            )
                                                        }
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <Avatar
                                                                // name={ d.physician_name }
                                                                name={
                                                                    d.physician_name &&
                                                                    d.physician_name.split(
                                                                        '.'
                                                                    )[1]
                                                                }
                                                                className="profile-image"
                                                                size="50"
                                                                round={true}
                                                            />
                                                            <div className="d-flex flex-column text-left">
                                                                <span className="physician-name">
                                                                    {
                                                                        d.physician_name
                                                                    }
                                                                </span>
                                                                <span className="physician-info">
                                                                    {
                                                                        d.speciality
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <MdKeyboardArrowRight
                                                            className="float-right font24 icon-margin pointer"
                                                            onClick={() =>
                                                                handleSelectPhysician(
                                                                    d
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                )
                            })}
                    </div>
                </div>
            )}
            {openAccountNumber && (
                <EnterPatientAccountNumberMobile
                    openAccountNumber={openAccountNumber}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianId}
                    history={props.history}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}

SelectPhysicianMobile.propTypes = {
    closePhysiaicanSelect: PropTypes.func,
    isOpen: PropTypes.bool,
    patientAppointmentId: PropTypes.number,
    history: PropTypes.object,
}

export default SelectPhysicianMobile
