import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { loginAction, checkValidNumberAction } from '../../actions/authActions'
import { connect } from 'react-redux'
import OtpModal from './OtpModal'
import PompayTopLogo from 'shared/PompayTopLogo'
import { HiUserCircle } from 'react-icons/hi'
import { RiArrowLeftLine } from 'react-icons/ri'

export class Login extends Component {
    constructor() {
        super()
        this.state = {
            input: {},
            errors: {},
            openVerify: false,
            buttonActive: true,
            isValidNumber: false,
        }
    }

    handleChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        let obj = event.target.value
        var numbers = obj.replace(/\D/g, ''),
            char = { 0: '(', 3: ') ', 6: '-' }
        obj = ''
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i]
            input[event.target.name] = obj
        }
        this.setState({ isValidNumber: false })
        if (event.target.value.length === 14) {
            const data = {
                phone_number: event.target.value,
            }
            this.props.checkValidNumberAction(data).then((res) => {
                if (res.statusCode === 200) {
                    if (res.data.is_phone_number_valid) {
                        this.setState({ isValidNumber: false })
                    } else {
                        this.setState({ isValidNumber: true })
                    }
                }
            })
        }
        this.setState({
            input,
            errors: {},
            buttonActive: false,
        })
    }
    handleSubmit = () => {
        const { input, openVerify, isValidNumber } = this.state
        if (!isValidNumber) {
            if (this.validate()) {
                this.setState({ openVerify: !openVerify })
                this.props.loginAction(input)
            }
        } else {
            this.setState({ isValidNumber: true })
        }
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter your mobile number.'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }
        this.setState({
            errors: errors,
        })

        return isValid
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            this.handleSubmit()
        }
    }
    closeOtpModal = () => {
        this.setState({ openVerify: !this.state.openVerify })
    }

    render() {
        const { openVerify, input, isValidNumber } = this.state
        const enabled =
            input && input.phone_number && input.phone_number.length === 14
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light custom_navbar align-items-baseline nav-bottom signIn-mobipage">
                    <a className="position-absolute">
                        <RiArrowLeftLine className="color_light_green font30" />
                    </a>
                    <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
                </nav>
                <div className="page_background"></div>
                {/* Navigation for Pompay logo */}
                {/* <nav className="navbar navbar-light mb-5">
                  <PompayTopLogo className="big_logo" />
              </nav> */}
                <div className="container-fluid sign_in_page">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center">
                            <div className="form_body">
                                {isValidNumber && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        The phone number you entered is invalid.
                                    </div>
                                )}
                                <HiUserCircle className="color_light_green font34 mb-4" />
                                <h4 className="page-heading">Sign in</h4>
                                <p className="page-content">
                                    We use your mobile number to sign in to your
                                    account.
                                </p>
                                <form>
                                    <div className="form-group">
                                        <input
                                            autoFocus
                                            type="text"
                                            maxLength="14"
                                            autoComplete="off"
                                            className="form-control input_field"
                                            placeholder="Mobile number"
                                            name="phone_number"
                                            value={
                                                this.state.input.phone_number ||
                                                ''
                                            }
                                            onChange={this.handleChange}
                                            id="phone_number"
                                            //   onKeyPress={ this.onkeypress }
                                            onKeyDown={this.onKeyDown}
                                        />
                                        <small className="text-danger">
                                            {this.state.errors.phone_number ||
                                                ''}
                                        </small>
                                    </div>
                                    <p className="my-4">
                                        <a
                                            href="/update-number"
                                            className="link_hover Page-links"
                                        >
                                            New number? Update your account
                                        </a>
                                    </p>
                                    <button
                                        type="button"
                                        className="btn green_btn custom_btn mb-4"
                                        onClick={this.handleSubmit}
                                        disabled={!enabled}
                                    >
                                        Continue
                                    </button>
                                </form>
                                <p className="text-center mb-2 page-content">
                                    We’ll send you a verification code via SMS.
                                </p>
                                <p className="text-center mb-2 page-content">
                                    By continuing, I agree to Pompay’s{' '}
                                    <a
                                        href="/#"
                                        className="link_hover color_light_green"
                                    >
                                        terms of service.
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                            <div className="create_account_section">
                                <h4 className="auth-heading mb-3">
                                    Don’t have an account yet?
                                </h4>
                                <p className="auth-content">
                                    Sign up today! Pompay makes it easy for
                                    patients and providers to manage their
                                    finances.
                                </p>
                                <button
                                    type="button"
                                    className="btn custom_btn white_btn font-family_lite"
                                    onClick={() =>
                                        this.props.history.push('/signup')
                                    }
                                >
                                    {' '}
                                    Create Account{' '}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {openVerify && (
                    <OtpModal
                        formData={this.props.formData}
                        history={this.props.history}
                        isOpen={openVerify}
                        closeOtpModal={this.closeOtpModal}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        formData: state.formData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginAction: (data) => dispatch(loginAction(data)),
        checkValidNumberAction: (data) =>
            dispatch(checkValidNumberAction(data)),
    }
}
Login.propTypes = {
    loginAction: PropTypes.func,
    checkValidNumberAction: PropTypes.func,
    history: PropTypes.object,
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
