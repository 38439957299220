import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import momentTime from 'moment-timezone'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { dateFormat, MODAL_STYLES } from 'constants/Constant'
import BillDetailsModal from './BillDetailsModal'
import {
    getInstallBillDetails,
    getPatientProfileInfo,
    getPayActivity,
    getPayReview,
    updatePatientProfileInfo,
} from 'actions/patientActions'
import TotalPaidDetailsModal from './TotalPaidDetailsModal'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'
import PatientProfileModal from './PatientProfileModal'
import SuccessAlert from 'shared/SuccessAlert'
import moment from 'moment-timezone'

function CurrentHistoryModal(props) {
    const [isBill, setIsBill] = useState(false)
    const [isProfile, setIsProfile] = useState(false)
    const [isTotalPaid, setIsTotalPaid] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [isSelectedTab, setIsSelectedTab] = useState(true)
    const [input, setInput] = useState({})
    const [profileDetail, setProfileDetail] = useState({})
    const dispatch = useDispatch()
    const installBillDetails = useSelector((state) => state.installBillDetails)
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    const paydetails = props.balanceDetails ? props.balanceDetails : []
    useEffect(() => {
        dispatch(
            getPayActivity(props.currentData.patient_id, props.physicianId)
        )
    }, [])
    const openRemaningBillDetails = (id) => {
        dispatch(getInstallBillDetails(id, props.physicianId))
        setIsBill(true)
    }
    const openTotalPaidDetails = (id) => {
        dispatch(getInstallBillDetails(id, props.physicianId))
        setIsTotalPaid(true)
    }
    const closeModal = () => {
        setIsBill(false)
        setIsSelectedTab(true)
    }
    const closeTotalModalpaid = () => {
        setIsTotalPaid(false)
        setIsSelectedTab(false)
    }
    const hanldeOpenProfile = () => {
        setIsProfile(!isProfile)
        setProfileDetail(props.currentData)
        dispatch(getPatientProfileInfo(props.currentData.patient_id)).then(
            (res) => {
                if (res.statusCode === 200) {
                    setInput(res.data)
                }
            }
        )
    }
    const closeProfileModal = () => {
        setIsProfile(!isProfile)
    }
    const updateProfileInfo = () => {
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            zipcode,
            date_of_birth,
            patient_id,
            patient_appointment_id,
        } = input
        const data = {
            first_name: first_name,
            last_name: last_name,
            patient_id: patient_id,
            patient_appointment_id: patient_appointment_id,
            email_id: email_id,
            phone_number: phone_number,
            zipcode: zipcode,
            date_of_birth: date_of_birth,
        }
        dispatch(updatePatientProfileInfo(data, patient_id)).then((res) => {
            if (res.statusCode === 200) {
                setIsProfile(!isProfile)
                setSuccessAlert(!successAlert)
                setTimeout(() => {
                    dispatch(getPayReview())
                }, 500)
            }
        })
    }
    const closeSuccessAlert = () => {
        setIsProfile(false)
        setSuccessAlert(false)
    }
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy = today.getFullYear()

    today = yyyy + '-' + mm + '-' + dd
    return (
        <>
            {!isBill && !isTotalPaid && !isProfile && !successAlert && (
                <Modal
                    isOpen={props.isOpen}
                    onRequestClose={props.closeModal}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    id="remaining-balance-modal"
                    ariaHideApp={false}
                >
                    <div className="remaining_balance_page">
                        <div className="d-flex justify-content-between mb-2 modal_heading_section">
                            <a className="mt-2" onClick={hanldeOpenProfile}>
                                <BsThreeDots className="color_dark_grey mr-2 font20" />
                            </a>
                            <a className="navbar-brand m-auto">
                                <p className="modal-heading">
                                    {props.currentData.first_name +
                                        ' ' +
                                        props.currentData.last_name}
                                </p>
                            </a>
                            <button
                                type="button"
                                className="close modal_close_custom_icon"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.closeModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="content">
                            <div className="text-center mt-5">
                                <span className="balance-label">
                                    Total Balance
                                </span>
                                {/* {isModalLoading && <ModalLoader />} */}
                                <h3 className="balance">
                                    {isModalLoading ? (
                                        <ModalLoader />
                                    ) : (
                                        currencyFormatter.format(
                                            paydetails.total_balance || 0
                                        )
                                    )}
                                </h3>
                            </div>
                            <div className="tab_section">
                                <ul
                                    className="nav nav-tabs"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={
                                                isSelectedTab
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                            id="current-tab"
                                            data-toggle="tab"
                                            href="#current"
                                            role="tab"
                                            aria-controls="current"
                                            aria-selected="true"
                                        >
                                            Current
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={
                                                isSelectedTab
                                                    ? 'nav-link'
                                                    : 'nav-link active'
                                            }
                                            id="history-tab"
                                            data-toggle="tab"
                                            href="#history"
                                            role="tab"
                                            aria-controls="history"
                                            aria-selected="false"
                                        >
                                            History
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content overflowAuto"
                                    id="myTabContent"
                                >
                                    {isModalLoading && (
                                        <div className="d-flex justify-content-center mt-5">
                                            <ModalLoader />
                                        </div>
                                    )}
                                    {!isModalLoading && (
                                        <div
                                            className={
                                                isSelectedTab
                                                    ? 'tab-pane fade show active'
                                                    : 'tab-pane fade show'
                                            }
                                            id="current"
                                            role="tabpanel"
                                            aria-labelledby="current-tab"
                                        >
                                            {paydetails &&
                                            paydetails.current ? (
                                                paydetails.current.map(
                                                    (data, id) => {
                                                        return (
                                                            <div
                                                                className="row no-gutters"
                                                                key={id}
                                                            >
                                                                <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="tab-lite-data">
                                                                            Due&nbsp;
                                                                            <span className="tab-bold-data">
                                                                                {data.due_amount_date ===
                                                                                today
                                                                                    ? momentTime(
                                                                                          data.due_amount_date
                                                                                      ).format(
                                                                                          'dddd, MMMM Do'
                                                                                      )
                                                                                    : momentTime(
                                                                                          data.due_amount_date
                                                                                      ).format(
                                                                                          'dddd, MMMM Do'
                                                                                      )}
                                                                            </span>
                                                                        </span>
                                                                        <span className="tab-lite-data">
                                                                            Remaining
                                                                            amount
                                                                            &nbsp;
                                                                            <span className="tab-bold-data">
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount ||
                                                                                        0
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-sm-5 right_row_padding">
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className="d-flex flex-column text-right">
                                                                            <span className="tab-bold-data d-flex">
                                                                                <span>
                                                                                    {currencyFormatter.format(
                                                                                        data.days_past_due ||
                                                                                            0
                                                                                    )}
                                                                                </span>
                                                                                {Boolean(
                                                                                    data?.late_fee
                                                                                ) && (
                                                                                    <span className="error">{` (+${currencyFormatter.format(
                                                                                        data.late_fee ||
                                                                                            0
                                                                                    )})`}</span>
                                                                                )}
                                                                            </span>
                                                                            <span className="color_alert tab-superlite-data">
                                                                                {
                                                                                    data.days_past_count
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <MdChevronRight
                                                                            className="float-right font24 ml-2 d-flex align-items-center pointer"
                                                                            onClick={() =>
                                                                                openRemaningBillDetails(
                                                                                    data.patient_payment_id
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )
                                            ) : (
                                                <p>There are no active bills</p>
                                            )}
                                        </div>
                                    )}
                                    <div
                                        className={
                                            isSelectedTab
                                                ? 'tab-pane fade show'
                                                : 'tab-pane fade show active'
                                        }
                                        id="history"
                                        role="tabpanel"
                                        aria-labelledby="history-tab"
                                    >
                                        {paydetails && paydetails.history ? (
                                            paydetails.history.map(
                                                (data, id) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters"
                                                            key={id}
                                                        >
                                                            <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                <div className="d-flex flex-column">
                                                                    <span className="tab-lite-data">
                                                                        Amount{' '}
                                                                        <span className="tab-bold-data">
                                                                            {currencyFormatter.format(
                                                                                data.paid_amount ||
                                                                                    0
                                                                            )}
                                                                        </span>
                                                                        {Boolean(
                                                                            data?.late_fee_amount
                                                                        ) && (
                                                                            <span className="tab-bold-data error">{` (+${currencyFormatter.format(
                                                                                data.late_fee_amount ||
                                                                                    0
                                                                            )})`}</span>
                                                                        )}
                                                                    </span>
                                                                    <span className="tab-superlite-data">
                                                                        Final
                                                                        payment
                                                                        date&nbsp;
                                                                        <span className="tab-lighterbold-data">
                                                                            {data.payment_date ===
                                                                            today
                                                                                ? moment(
                                                                                      data.payment_date
                                                                                  ).format(
                                                                                      dateFormat
                                                                                  )
                                                                                : moment(
                                                                                      data.payment_date
                                                                                  ).format(
                                                                                      dateFormat
                                                                                  )}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-sm-5 right_row_padding d-flex align-items-center justify-content-end">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <div className="d-flex flex-column text-right">
                                                                        <span className="bold-text">
                                                                            {
                                                                                data.payment_status
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <MdChevronRight
                                                                        className="float-right font24 ml-2 d-flex align-items-center pointer"
                                                                        onClick={() =>
                                                                            openTotalPaidDetails(
                                                                                data.patient_payment_id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        ) : (
                                            <p>There are no active bills</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {isBill && !isTotalPaid && (
                <BillDetailsModal
                    isBill={isBill}
                    remBalanceDetails={installBillDetails.remaining_balance}
                    closeModal={closeModal}
                    closeAll={props.closeModal}
                />
            )}
            {isTotalPaid && !isBill && (
                <TotalPaidDetailsModal
                    totalBalanceDetails={installBillDetails}
                    isTotalPaid={isTotalPaid}
                    closeModal={closeTotalModalpaid}
                    closeAll={props.closeModal}
                />
            )}
            {isProfile && (
                <PatientProfileModal
                    profileDetail={profileDetail}
                    isOpen={isProfile}
                    closeModal={closeProfileModal}
                    input={input}
                    onlyView={true}
                    // handleProfileChange={ handleProfileChange }
                    updateProfileInfo={updateProfileInfo}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message="The Patient profile has been updated"
                />
            )}
        </>
    )
}

CurrentHistoryModal.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    balanceDetails: PropTypes.object,
    firstName: PropTypes.string,
    history: PropTypes.object,
    currentData: PropTypes.object,
    physicianId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // isLoading: PropTypes.bool
}

export default CurrentHistoryModal
