import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { BiTachometer } from 'react-icons/bi'
import { MdCreditCard, MdRateReview } from 'react-icons/md'
import { getAccountDetails, getTeamDetails } from 'actions/accountActions'
import { getTotalAmount } from 'actions/bankingActions'
import { IoMdPeople } from 'react-icons/io'
import Avatar from 'react-avatar'

const BottomBarNavigation = () => {
    const dispatch = useDispatch()
    const pathUrl = window.location.pathname
    const accountDetails = useSelector((state) => state.accountDetails)
    const isTeam = useSelector((state) => state.teamMember)
    useEffect(() => {
        isTeam ? dispatch(getTeamDetails()) : dispatch(getAccountDetails())
        dispatch(getTotalAmount())
    }, [''])
    const accountHolderName =
        accountDetails && accountDetails.physician
            ? accountDetails.physician[0].first_name
            : accountDetails.physician_team &&
              accountDetails.physician_team.first_name
    const accountProfilePic =
        accountDetails && accountDetails.physician
            ? accountDetails.physician[0].avatar
            : accountDetails.physician_team &&
              accountDetails.physician_team.avatar
    return (
        <div className="mobile-navbar d-flex justify-content-around align-items-center">
            <a
                href="/physician-dashboard"
                className={pathUrl === '/physician-dashboard' ? 'active' : ''}
            >
                <BiTachometer className="react_font" />
            </a>
            <a
                href="/payments"
                className={pathUrl === '/payments' ? 'active' : ''}
            >
                <MdCreditCard className="react_font" />
            </a>
            <a
                href="/review-arrangement"
                className={pathUrl === '/review-arrangement' ? 'active' : ''}
            >
                <MdRateReview className="react_font" />
            </a>
            <a
                href="/patient-list"
                className={pathUrl === '/patient-list' ? 'active' : ''}
            >
                <IoMdPeople className="react_font" />
            </a>
            <a
                href="/account-homepage-mobile"
                className={
                    pathUrl === '/account-homepage-mobile' ? 'active' : ''
                }
            >
                <Avatar
                    name={accountHolderName}
                    size="30"
                    round={true}
                    src={accountProfilePic}
                />
            </a>
        </div>
    )
}
BottomBarNavigation.propTypes = {
    accountHolderName: PropTypes.string,
    pathUrl: PropTypes.string,
}

export default BottomBarNavigation
