import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import PompayTopWhiteLogo from 'shared/PompayTopWhiteLogo'
import PompayTopLogo from 'shared/PompayTopLogo'
import { logoutAction } from 'actions/authActions'
import { maxLengthCheck, onlyNumber } from 'utils/helper'
function CreatePassode(props) {
    const [ownerPasscode, setOwnerPasscode] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setOwnerPasscode(event.target.value)
        setError(false)
    }
    const handleSubmit = () => {
        const data = { owner_passcode: ownerPasscode }
        const pattern = new RegExp(/^\d+$/)
        if (!pattern.test(ownerPasscode)) {
            setError(true)
        } else {
            props.history.push({
                pathname: '/re-enter-passcode',
                state: {
                    data: data,
                },
            })
        }
    }
    const handleLogout = () => {
        dispatch(logoutAction(props.history))
    }
    const enabled = ownerPasscode && ownerPasscode.length === 4
    return (
        <div>
            <div className="create_page_background"></div>
            <nav className="navbar navbar-light">
                <PompayTopWhiteLogo className="big_logo d-none d-md-block d-lg-block d-xl-block" />
                <PompayTopLogo className="small_logo d-md-none d-lg-none d-xl-none" />
            </nav>
            <div className="container-fluid create-passcode">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-6 text-center d-none d-md-block d-lg-block d-xl-block">
                        <div className="create_account_section">
                            <h4 className="auth-heading mb-3">
                                Already have an account?
                            </h4>
                            <p className="auth-content">
                                The easiest way to pay your medical bills.
                                Discounts, payment plans, and more!
                            </p>
                            <button
                                type="button"
                                className="btn white_btn custom_btn"
                                onClick={handleLogout}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-column align-items-center">
                        <div className="form_body text-center">
                            {error && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    Passcode must be of 4 digit number.
                                </div>
                            )}
                            <h4 className="bold-heading mb-4">
                                Create a 4-digit passcode{' '}
                            </h4>
                            <p
                                className="bold-content padding_left_right90"
                                style={{ marginBottom: '100px' }}
                            >
                                This passcode will prevent anyone from making
                                changes to information that is guarded.
                            </p>
                        </div>
                        <input
                            type="password"
                            maxLength="4"
                            onInput={maxLengthCheck}
                            className="passcode-textfield"
                            name="owner_passcode"
                            autoComplete="off"
                            value={ownerPasscode || ''}
                            onChange={handleChange}
                            onKeyPress={(event) => onlyNumber(event)}
                            placeholder="----"
                            data-dismiss="modal"
                            style={{ marginBottom: '120px' }}
                        />
                        <button
                            type="button"
                            className="btn green_btn custom_btn"
                            disabled={!enabled}
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

CreatePassode.propTypes = {
    // sendPasscodeData: PropTypes.func,
    history: PropTypes.object,
}

export default CreatePassode
