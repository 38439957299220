import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getPatientTransaction } from 'actions/patientAccountActions'
import { MdTune, MdClose } from 'react-icons/md'
import moment from 'moment'
import { getPhysicianList } from 'actions/patientPhysicianActions'
import { MdChevronRight } from 'react-icons/md'
import { CgChevronLeft } from 'react-icons/cg'
import PatientTransactionReceipt from './PatientTransactionReceipt'
import { DatePicker, Space } from 'antd'
import { mmdyyyyFormat } from 'constants/Constant'
import Loading from 'shared/Loader'
import { currencyFormatter } from 'utils/helper'
// import { currencyFormatter } from 'utils/helper';
const { RangePicker } = DatePicker
const PatientTransactionMobile = (props) => {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [physician, setPhysician] = useState('')
    const [sortByAmount, setSortByAmount] = useState('')
    const [sortByDate, setSortByDate] = useState('')
    const [isOpenTranHistory, setIsOpenTranHistory] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [patTransactionDetails, setPatTransactionDetails] = useState({})
    const patientTransactions = useSelector(
        (state) => state.patientTransactions
    )
    const physicianList = useSelector((state) => state.physicianList)
    const isLoading = useSelector((state) => state.applicationIsLoading)

    useEffect(() => {
        dispatch(getPatientTransaction(''))
        dispatch(getPhysicianList())
    }, [])
    const openTransactionReceiptFunc = (data) => {
        setIsOpenTranHistory(!isOpenTranHistory)
        setPatTransactionDetails(data)
    }
    const handleFilter = () => {
        setIsFilterOpen(!isFilterOpen)
    }
    const handleDateRange = (date) => {
        setStartDate(moment(date[0]).format('l'))
        setEndDate(moment(date[1]).format('l'))
    }
    const handlePhysician = (event) => {
        setPhysician(event.target.value)
    }
    const removePhysician = () => {
        setPhysician('')
        dispatch(getPatientTransaction(''))
    }
    const handleApplyFilter = () => {
        const data = {
            start_date: startDate,
            end_date: endDate,
            physician: physician,
            sort_by_amount: sortByAmount,
            last_date: '',
            sort_by_date: sortByDate,
        }
        dispatch(getPatientTransaction(data))
        setIsFilterOpen(!isFilterOpen)
    }
    const hanldeResetFilter = () => {
        setEndDate('')
        setStartDate('')
        setPhysician('')
        setSortByAmount('')
        setSortByDate('')
        dispatch(getPatientTransaction('')).then((res) => {
            if (res.statusCode === 200) {
                setIsFilterOpen(!isFilterOpen)
            }
        })
    }
    const physicianTeamMemeber =
        physicianList &&
        physicianList.physician_list &&
        physicianList.physician_list.map((data, id) => {
            return (
                <option key={id} name="physician" value={data.physician_name}>
                    {data.physician_name}
                </option>
            )
        })
    return (
        <>
            {!isOpenTranHistory && !isFilterOpen && (
                <div className="mobile_account_info transaction-page">
                    <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex justify-content-between align-items-center">
                        <CgChevronLeft
                            className="back_icon"
                            onClick={() => props.history.goBack()}
                        />
                        <label className="nav-heading">Transaction</label>
                        <MdTune
                            className="dark_icon float-right font22 z-index1"
                            onClick={handleFilter}
                        />
                    </nav>
                    <div id="nav-tabContent" className="mobile-content-height">
                        <div>
                            {physician !== '' && (
                                <span className="badge badge-pill badge-green d-flex justify-content-between align-items-center float-left">
                                    <label className="mb-0">{physician}</label>
                                    <MdClose
                                        onClick={removePhysician}
                                        className="font16 ml-2"
                                    />
                                </span>
                            )}
                        </div>
                        {isLoading && <Loading />}
                        <table className="table table-borderless office_info">
                            <tbody>
                                {!isLoading &&
                                    patientTransactions &&
                                    patientTransactions.patient_list &&
                                    patientTransactions.patient_list.map(
                                        (data, idx) => {
                                            return (
                                                <tr
                                                    key={idx}
                                                    onClick={() =>
                                                        openTransactionReceiptFunc(
                                                            data
                                                        )
                                                    }
                                                >
                                                    <td className="border-0">
                                                        <div className="patient-name">
                                                            {
                                                                data.physician_name
                                                            }
                                                        </div>
                                                        <div className="patient-date">
                                                            Date:{' '}
                                                            {moment(
                                                                data.datetime
                                                            ).format('l')}
                                                        </div>
                                                    </td>
                                                    <td className="text-center border-0 d-flex align-items-center justify-content-end">
                                                        <div className="patient-amount">
                                                            {currencyFormatter.format(
                                                                (data?.amount ||
                                                                    0) +
                                                                    (data?.transaction_fee ||
                                                                        0)
                                                            )}
                                                        </div>
                                                        <MdChevronRight className="float-right font22 color_dark_grey" />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {isFilterOpen && (
                <div className="mobile_account_info patient-filter">
                    <div id="content">
                        <nav className="navbar navbar-expand-lg bg_primary_color navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon text-white"
                                onClick={() => handleFilter()}
                            />
                            <label className="nav-heading text-white">
                                Filter
                            </label>
                            <span
                                className="clear-all-link text-white"
                                style={{ zIndex: 1 }}
                                onClick={() => hanldeResetFilter()}
                            >
                                Clear all
                            </span>
                        </nav>
                        <div id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-patient-filter"
                                role="tabpanel"
                                aria-labelledby="nav-patient-filter-tab"
                            >
                                <div className="row mt-2 mb-4">
                                    <div className="col-5 text-left">
                                        <span className="form-label">
                                            Date range:
                                        </span>
                                    </div>
                                    <div className="col-7">
                                        <Space direction="vertical" size={12}>
                                            <RangePicker
                                                defaultValue={[
                                                    moment(
                                                        new Date(),
                                                        mmdyyyyFormat
                                                    ),
                                                    moment(
                                                        new Date(),
                                                        mmdyyyyFormat
                                                    ),
                                                ]}
                                                format={mmdyyyyFormat}
                                                onChange={handleDateRange}
                                            />
                                        </Space>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-5 text-left">
                                        <span className="form-label">
                                            Physician:
                                        </span>
                                    </div>
                                    <div className="col-7">
                                        <select
                                            className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                            name="paymentStatus"
                                            onChange={handlePhysician}
                                        >
                                            <option defaultValue="all">
                                                {' '}
                                                {physician ? physician : 'All'}
                                            </option>
                                            {physicianTeamMemeber}
                                        </select>
                                    </div>
                                </div>
                                <div className="row box-shadow"></div>
                                <div className="row bg_thin_gray">
                                    <span className="form-label p-3">
                                        Sort payments by:
                                    </span>
                                </div>
                                <div className="row my-3">
                                    <div className="col-5 text-left my-2">
                                        <span className="form-label">
                                            Amount
                                        </span>
                                    </div>
                                    <div className="col-7 text-right my-2">
                                        <select
                                            className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                            name="sortByAmount"
                                            onChange={() =>
                                                setSortByAmount(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <option defaultValue="all">
                                                {' '}
                                                {sortByAmount
                                                    ? sortByAmount
                                                    : 'All'}
                                            </option>
                                            <option
                                                name="sortByAmount"
                                                value="Highest to Lowest"
                                            >
                                                Highest to Lowest
                                            </option>
                                            <option
                                                name="sortByAmount"
                                                value="Lowest to Highest"
                                            >
                                                Lowest to Highest
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-5 text-left my-2">
                                        <span className="form-label">Date</span>
                                    </div>
                                    <div className="col-7 text-right my-2">
                                        <select
                                            className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                            name="sortByDate"
                                            onChange={() =>
                                                setSortByDate(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <option defaultValue="all">
                                                {' '}
                                                {sortByDate
                                                    ? sortByDate
                                                    : 'All'}
                                            </option>
                                            <option
                                                name="sortByDate"
                                                value="Newest to Oldest"
                                            >
                                                Newest to Oldest
                                            </option>
                                            <option
                                                name="sortByDate"
                                                value="Oldest to Newest"
                                            >
                                                Oldest to Newest
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        <button
                            className="btn custom_btn green_btn d-md-none d-lg-none d-xl-none"
                            onClick={handleApplyFilter}
                        >
                            Apply Filter
                        </button>
                    </div>
                </div>
            )}
            {isOpenTranHistory && (
                <PatientTransactionReceipt
                    isOpenTranHistory={isOpenTranHistory}
                    openTransactionReceiptFunc={openTransactionReceiptFunc}
                    patTransactionDetails={patTransactionDetails}
                />
            )}
        </>
    )
}
PatientTransactionMobile.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}
export default PatientTransactionMobile
