import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'antd/dist/antd.css'
import { CgChevronRight } from 'react-icons/cg'
import { MdSearch, MdTune } from 'react-icons/md'
import BottomBarNavigation from 'components/account/mobile/BottomBarNavigation'
import { connect } from 'react-redux'
import { Progress } from 'antd'
import {
    getPatientProfileInfo,
    updatePatientProfileInfo,
} from 'actions/patientActions'
import { DatePicker, Space } from 'antd'
import { mmdyyyyFormat } from '../../../../constants/Constant'
import { CgChevronLeft } from 'react-icons/cg'
import Loading from 'shared/Loader'
import { currencyFormatter } from 'utils/helper'
import { getPatientList } from 'actions/patient/patient.actions'
const { RangePicker } = DatePicker

export class PatientListMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            paid_amount: '',
            total_amount: '',
            unpaid_amount: '',
            selectionType: 'checkbox',
            isProfile: false,
            serchValue: '',
            isToday: '',
            paymentStatus: '',
            input: {},
            data: [],
            startDate: '',
            endDate: '',
            isFilter: false,
        }
    }

    componentDidMount() {
        const params = {
            isToday: this.state.isToday || '',
            paymentStatus: this.state.paymentStatus || '',
        }
        this.props.getPatientList(params).then((res) => {
            if (res.statusCode === 200) {
                const duePay =
                    res.data &&
                    res.data.patient_info &&
                    res.data.patient_info.map((d) => {
                        return d.due_payments_details
                    })
                const payDetailsArray = []
                duePay.map((d) => {
                    return d.map((data) => {
                        payDetailsArray.push(data)
                    })
                })
                this.setState({
                    data: payDetailsArray,
                    paid_percentage: res.data.paid_percentage,
                    paid_amount: res.data.paid_amount,
                    total_amount: res.data.total_amount,
                    unpaid_amount: res.data.unpaid_amount,
                })
            }
        })
    }

    handleChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    closeModal = () => {
        this.setState({ isProfile: false })
    }
    updateSearch = (event) => {
        this.setState({ serchValue: event.target.value.substr(0, 20) })
    }
    handleDateRange = (date) => {
        const startDate = moment(date[0]).format('l')
        const endDate = moment(date[1]).format('l')
        this.setState({ startDate, endDate })
    }
    handleFilterChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        this.setState({
            input,
        })
    }
    handleFilter = () => {
        this.setState({
            isFilter: !this.state.isFilter,
        })
    }
    applyFilter = () => {
        const { input, startDate, endDate } = this.state
        const obj = input
        obj.startDate = startDate
        obj.endDate = endDate
        obj.endDate = endDate
        obj.isToday = ''
        this.props.getPatientList(obj).then((res) => {
            if (res.statusCode === 200) {
                const condCheck =
                    res.data &&
                    res.data.patient_info &&
                    res.data.patient_info[0] &&
                    res.data.patient_info[0].due_payments_details
                        ? true
                        : false
                const payDetailsArray = []
                if (condCheck) {
                    const duePay =
                        res.data &&
                        res.data.patient_info &&
                        res.data.patient_info.map((d) => {
                            return d.due_payments_details
                        })
                    //   const payDetailsArray = [];
                    duePay.map((d) => {
                        return (
                            d &&
                            d.map((updateData) => {
                                payDetailsArray.push(updateData)
                            })
                        )
                    })
                } else {
                    const duePay = res.data && res.data.patient_info
                    duePay.map((d) => {
                        payDetailsArray.push(d)
                    })
                }
                this.setState({
                    data: payDetailsArray,
                    paid_amount: res.data.paid_amount,
                    paid_percentage: res.data.paid_percentage,
                    total_amount: res.data.total_amount,
                    unpaid_amount: res.data.unpaid_amount,
                    isFilter: !this.state.isFilter,
                })
            }
            // }
        })
    }
    handleResetFilters = () => {
        const params = {
            isToday: this.state.isToday || '',
            paymentStatus: this.state.paymentStatus || '',
        }
        this.props.getPatientList(params).then((res) => {
            if (res.statusCode === 200) {
                const duePay =
                    res.data &&
                    res.data.patient_info &&
                    res.data.patient_info.map((d) => {
                        return d.due_payments_details
                    })
                const payDetailsArray = []
                duePay.map((d) => {
                    return (
                        d &&
                        d.map((data) => {
                            payDetailsArray.push(data)
                        })
                    )
                })
                this.setState({
                    data: payDetailsArray,
                    isFilter: !this.state.isFilter,
                })
            }
        })
    }
    render() {
        const {
            data,
            paid_amount,
            total_amount,
            serchValue,
            paid_percentage,
            isFilter,
            input,
            startDate,
            endDate,
        } = this.state
        const { isLoading } = this.props
        const enabled =
            Object.keys(input).length > 0 ||
            startDate.length > 0 ||
            endDate.length > 0
        const filteredData =
            data &&
            data.filter((d) => {
                return (
                    (d.last_name &&
                        d.last_name
                            .toLowerCase()
                            .indexOf(serchValue.toLowerCase()) !== -1) ||
                    (d.first_name &&
                        d.first_name
                            .toLowerCase()
                            .indexOf(serchValue.toLowerCase()) !== -1) ||
                    (d.amount && d.amount.indexOf(serchValue) !== -1) ||
                    (d.patient_appointment_id &&
                        d.patient_appointment_id.toString() &&
                        d.patient_appointment_id
                            .toString()
                            .indexOf(serchValue) !== -1) ||
                    (d.due_status &&
                        d.due_status
                            .toLowerCase()
                            .indexOf(serchValue.toLowerCase()) !== -1) ||
                    (d.days_left &&
                        d.days_left.toString().indexOf(serchValue) !== -1)
                )
            })
        return (
            <>
                {!isFilter && (
                    <div className="daily_cash_flow_page payment-list-page search_patient">
                        <div className="header_sction text-white p-4">
                            <div className="paid-patients mb-3">
                                {moment().format('MM/DD')}
                            </div>
                            <div className="cash-flow-label mb-2">
                                Daily Cash Flow
                            </div>
                            {/* {isLoading && <ModalLoader />} */}
                            <div className="total-amount mb-2">
                                {currencyFormatter.format(paid_amount || 0)}
                            </div>
                            {/* <div className="total-amount mb-2">{ '$'+numberFormatter(paid_amount)}</div> */}
                            <Progress
                                percent={
                                    paid_percentage &&
                                    paid_percentage.split('%')[0]
                                }
                                strokeColor={'#BBEFCB'}
                            />
                            <div className="d-flex justify-content-between mt-2">
                                <span className="percentage">
                                    {paid_percentage}
                                </span>
                                <span className="total-unpaid">
                                    <span className="total-paid">
                                        {currencyFormatter.format(
                                            paid_amount || 0
                                        )}
                                    </span>
                                    /
                                    {currencyFormatter.format(
                                        total_amount || 0
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="body_sction">
                            <div className="d-flex justify-content-between p-2 bottom_border">
                                <div className="d-flex align-items-center">
                                    <MdSearch className="font28 color_dark_grey mr-2" />
                                    <input
                                        type="search"
                                        className="form-control search-box"
                                        value={serchValue || ''}
                                        onChange={this.updateSearch}
                                    />
                                </div>
                                <span className="d-flex align-items-center">
                                    <MdTune
                                        className="font24 color_dark_grey"
                                        onClick={this.handleFilter}
                                    />
                                </span>
                            </div>
                            {isLoading && (
                                <div className="px-3 pt-3">
                                    <Loading />
                                </div>
                            )}
                            {!isLoading &&
                                filteredData.map((d, id) => {
                                    return (
                                        <div
                                            className="cash_flow_data"
                                            key={id}
                                        >
                                            <div className="row no-gutters mx-3 py-4 color_dark_grey">
                                                <div className="col-5 text-left">
                                                    <div className="d-flex flex-column">
                                                        <div className="">
                                                            <span className="font14 medium-label">
                                                                {d.last_name},
                                                            </span>{' '}
                                                            <span className="font14 medium-label">
                                                                {d.first_name}
                                                            </span>
                                                        </div>
                                                        <span className="patient-id">
                                                            {
                                                                d.patient_appointment_id
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-left">
                                                    <div className="d-flex flex-column">
                                                        <span className="patient-date">
                                                            {moment(
                                                                d.due_date
                                                            ).format('l')}
                                                        </span>
                                                        {d.due_status ===
                                                            'Awaiting' && (
                                                            <div className="patient-days">
                                                                {d.days_left
                                                                    ? d.days_left +
                                                                      ' ' +
                                                                      'days late'
                                                                    : ''}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <div className="d-flex flex-column text-right">
                                                            <span className="patient-amount">
                                                                {currencyFormatter.format(
                                                                    d.amount ||
                                                                        0
                                                                )}
                                                            </span>
                                                            <span
                                                                className={
                                                                    d.due_status ===
                                                                    'Paid'
                                                                        ? 'patient-status color_light_green'
                                                                        : d.due_status ===
                                                                          'Due On'
                                                                        ? 'patient-status color_alert'
                                                                        : 'patient-status'
                                                                }
                                                            >
                                                                {d.due_status}
                                                            </span>
                                                        </div>
                                                        <CgChevronRight
                                                            className="font20 color_dark_grey ml-2"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    {
                                                                        pathname:
                                                                            '/patient-remaning-mobile',
                                                                        state: {
                                                                            physicianId:
                                                                                d.id,
                                                                            physicianName:
                                                                                d.first_name,
                                                                            details:
                                                                                d,
                                                                        },
                                                                        // pathname: '/patient-profile-mobile',
                                                                        // state: {
                                                                        //     data: d,
                                                                        // },
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                        <BottomBarNavigation />
                    </div>
                )}
                {isFilter && (
                    <div className="mobile_account_info patient-filter">
                        <div id="content">
                            <nav className="navbar navbar-expand-lg bg_primary_color navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                                <CgChevronLeft
                                    className="back_icon text-white"
                                    onClick={() => this.handleFilter()}
                                />
                                <label className="nav-heading text-white">
                                    Filter
                                </label>
                                <a
                                    className="clear-all-link text-white"
                                    style={{ zIndex: 1 }}
                                    onClick={() => this.handleResetFilters()}
                                >
                                    Clear all
                                </a>
                            </nav>
                            <div id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="nav-patient-filter"
                                    role="tabpanel"
                                    aria-labelledby="nav-patient-filter-tab"
                                >
                                    <div className="row mt-2 mb-4">
                                        <div className="col-sm-5 col-5 text-left">
                                            <span className="form-label">
                                                Date range:
                                            </span>
                                        </div>
                                        <div className="col-sm-7 col-7">
                                            <Space
                                                direction="vertical"
                                                size={12}
                                            >
                                                <RangePicker
                                                    defaultValue={[
                                                        moment(
                                                            new Date(),
                                                            mmdyyyyFormat
                                                        ),
                                                        moment(
                                                            new Date(),
                                                            mmdyyyyFormat
                                                        ),
                                                    ]}
                                                    format={mmdyyyyFormat}
                                                    onChange={
                                                        this.handleDateRange
                                                    }
                                                />
                                            </Space>
                                        </div>
                                    </div>
                                    <div className="row my-4">
                                        <div className="col-sm-5 col-5 text-left">
                                            <span className="form-label">
                                                Status:
                                            </span>
                                        </div>
                                        <div className="col-sm-7 col-7">
                                            <select
                                                className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                                name="paymentStatus"
                                                onChange={
                                                    this.handleFilterChange
                                                }
                                            >
                                                <option defaultValue="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="paymentStatus"
                                                    value="Paid"
                                                >
                                                    Paid
                                                </option>
                                                <option
                                                    name="paymentStatus"
                                                    value="Due On"
                                                >
                                                    Due On
                                                </option>
                                                <option
                                                    name="paymentStatus"
                                                    value="Awaiting"
                                                >
                                                    Awaiting
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row box-shadow"></div>
                                    <div className="row bg_thin_gray">
                                        <span className="form-label p-3">
                                            Sort payments by:
                                        </span>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-6 text-left my-2">
                                            <span className="form-label">
                                                Name
                                            </span>
                                        </div>
                                        <div className="col-6 text-right my-2">
                                            <select
                                                className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                                name="alphabeticalOrder"
                                                onChange={
                                                    this.handleFilterChange
                                                }
                                            >
                                                <option defaultValue="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="alphabeticalOrder"
                                                    value="A-Z"
                                                >
                                                    A-Z
                                                </option>
                                                <option
                                                    name="alphabeticalOrder"
                                                    value="Z-A"
                                                >
                                                    Z-A
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-6 text-left my-2">
                                            <span className="form-label">
                                                Amount
                                            </span>
                                        </div>
                                        <div className="col-6 text-right my-2">
                                            <select
                                                className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                                name="sortByAmount"
                                                onChange={
                                                    this.handleFilterChange
                                                }
                                            >
                                                <option defaultValue="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="sortByAmount"
                                                    value="Highest to Lowest"
                                                >
                                                    Highest to Lowest
                                                </option>
                                                <option
                                                    name="sortByAmount"
                                                    value="Lowest to Highest"
                                                >
                                                    Lowest to Highest
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-6 text-left my-2">
                                            <span className="form-label">
                                                Date
                                            </span>
                                        </div>
                                        <div className="col-6 text-right my-2">
                                            <select
                                                className="form-control sort_team_member font16 font-weight500 color_dark_grey"
                                                name="sortByDate"
                                                onChange={
                                                    this.handleFilterChange
                                                }
                                            >
                                                <option defaultValue="all">
                                                    {' '}
                                                    All
                                                </option>
                                                <option
                                                    name="sortByDate"
                                                    value="Newest to Oldest"
                                                >
                                                    Newest to Oldest
                                                </option>
                                                <option
                                                    name="sortByDate"
                                                    value="Oldest to Newest"
                                                >
                                                    Oldest to Newest
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-3">
                            <button
                                disabled={!enabled}
                                className="btn custom_btn green_btn d-md-none d-lg-none d-xl-none"
                                onClick={this.applyFilter}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPatientList: (data) => dispatch(getPatientList(data)),
        getPatientProfileInfo: (data) => dispatch(getPatientProfileInfo(data)),
        updatePatientProfileInfo: (data, id) =>
            dispatch(updatePatientProfileInfo(data, id)),
    }
}

PatientListMobile.propTypes = {
    getPatientList: PropTypes.func,
    getPatientProfileInfo: PropTypes.func,
    updatePatientProfileInfo: PropTypes.func,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientListMobile)
