import React from 'react'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import { maxLengthCheck } from 'utils/helper'
import { isMobile } from 'constants/Constant'
import { FiChevronLeft } from 'react-icons/fi'
import 'antd/dist/antd.css'

function AddOfficeInfoModal(props) {
    const {
        handleChange,
        handleSubmit,
        input,
        errors,
        closeAddOffice,
        closeModal,
        disableFunc,
    } = props
    return (
        <div className="mobile_account_info location-details-page">
            <div id="content">
                <div className="office-address-details">
                    {isMobile && (
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                onClick={() => closeAddOffice()}
                                className="back_icon"
                            />
                            <label className="nav-heading">
                                Office Location
                            </label>
                            <span></span>
                        </nav>
                    )}
                    {!isMobile && (
                        <div className="d-flex heading-strip">
                            <FiChevronLeft
                                className="back-green-arrow"
                                onClick={() => closeModal()}
                            />{' '}
                            <h6 className="tab_heading">Office Location</h6>
                        </div>
                    )}
                </div>
                <form>
                    <div className="form-row flex-column py-0">
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Enter speciality"
                                        name="speciality"
                                        id="speciality"
                                        value={input.speciality || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="speciality"
                                    >
                                        Speciality
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.speciality}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="address1"
                                        autoComplete="off"
                                        placeholder="Enter address"
                                        id="address1"
                                        value={input.address1 || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="address1"
                                    >
                                        Address
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.address1}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="city"
                                        autoComplete="off"
                                        placeholder="Enter city"
                                        id="city"
                                        value={input.city || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="city"
                                    >
                                        City
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.city}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="state"
                                        autoComplete="off"
                                        placeholder="Enter state"
                                        id="state"
                                        value={input.state || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="state"
                                    >
                                        State
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.state}
                            </small>
                        </div>

                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        maxLength="5"
                                        onInput={maxLengthCheck}
                                        name="zipcode"
                                        autoComplete="off"
                                        placeholder="Enter zipcode"
                                        id="zipcode"
                                        value={input.zipcode || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="zipcode"
                                    >
                                        Zipcode
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.zipcode}
                            </small>
                        </div>
                        {/* </div> */}
                        {/* <div className="form-row"> */}
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        maxLength="14"
                                        autoComplete="off"
                                        placeholder="Enter phone number"
                                        name="phone_number"
                                        id="phone_number"
                                        value={input.phone_number || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="phone_number"
                                    >
                                        Phone number
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.phone_number}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="url"
                                        name="website"
                                        autoComplete="off"
                                        placeholder="Enter website"
                                        id="website"
                                        value={input.website || ''}
                                        onChange={handleChange}
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="website"
                                    >
                                        Website (optional)
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.website}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <fieldset className="input-group-prepend  account-office-loc">
                                    <span className="AM-label left">
                                        <input
                                            type="type"
                                            name="office_hours_start"
                                            id="office_hours"
                                            value={
                                                input.office_hours_start || ''
                                            }
                                            onChange={handleChange}
                                            placeholder="00:00"
                                            maxLength="5"
                                            className="form-control account_info_field  AM-field"
                                        />
                                    </span>
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="office_days"
                                    >
                                        Office hours(ST)
                                    </label>
                                </fieldset>
                            </div>
                            <small className="text-danger float-left">
                                {errors.office_hours_start}
                            </small>
                        </div>
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <fieldset className="input-group-prepend  account-office-loc">
                                    <span className="PM-label left">
                                        <input
                                            type="type"
                                            name="office_hours_end"
                                            id="office_hours"
                                            value={input.office_hours_end || ''}
                                            onChange={handleChange}
                                            placeholder="00:00"
                                            maxLength="5"
                                            className="form-control account_info_field PM-field"
                                        />
                                    </span>
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="website"
                                    >
                                        Office hours(ET)
                                    </label>
                                </fieldset>
                            </div>
                            <small className="text-danger float-left">
                                {' '}
                                {errors.office_hours_end}{' '}
                            </small>
                        </div>
                        {/* <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <fieldset className="input-group-prepend account-office-loc">
                                    <span className="AM-label left">
                                        <input
                                            type="type"
                                            name="office_hours_start1"
                                            id="office_hours1"
                                            value={ input.office_hours_start || '' }
                                            onChange={ handleChange }
                                            placeholder="00:00"
                                            maxLength='5'
                                            className="form-control account_info_field AM-field"
                                        />
                                    </span>
                                    <span className="PM-label left">
                                        <input
                                            type="type"
                                            name="office_hours_end"
                                            id="office_hours"
                                            value={ input.office_hours_end || '' }
                                            onChange={ handleChange }
                                            placeholder="00:00"
                                            maxLength='5'
                                            className="form-control account_info_field PM-field"
                                        />
                                    </span>
                                    <label className="input-group-text account_info_label" htmlFor="office_hours">
                                        Office hours
                                    </label>
                                </fieldset>
                                <small className="text-danger float-left">
                                    {errors.office_hours_start}
                                </small>
                                <small className="text-danger float-left">
                                    {errors.office_hours_end}
                                </small>
                            </div>
                        </div> */}
                        <div className="col-sm-4 my-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        name="office_days"
                                        autoComplete="off"
                                        id="office_days"
                                        value={input.office_days || ''}
                                        onChange={handleChange}
                                        placeholder="Monday-Friday"
                                        className="form-control account_info_field"
                                    />
                                    <label
                                        className="input-group-text account_info_label"
                                        htmlFor="office_days"
                                    >
                                        Days open
                                    </label>
                                </div>
                            </div>
                            <small className="text-danger float-left">
                                {errors.office_days}
                            </small>
                        </div>
                        {!isMobile && (
                            <div className="col-sm-4 px-0">
                                <div className="btn-card text-left">
                                    <button
                                        type="submit"
                                        className="btn custom_btn green_btn"
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-link cancel-link"
                                        onClick={disableFunc}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            {isMobile && (
                <div className="px-3 pb-3">
                    <button
                        type="submit"
                        className="btn mobile-green-btn"
                        onClick={handleSubmit}
                    >
                        Add
                    </button>
                </div>
            )}
        </div>
    )
}

AddOfficeInfoModal.propTypes = {
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    disableFunc: PropTypes.func,
    closeModal: PropTypes.func,
    input: PropTypes.object,
    errors: PropTypes.object,
    enabled: PropTypes.bool,
    closeAddOffice: PropTypes.func,
    handleSelectTime: PropTypes.func,
}

export default AddOfficeInfoModal
