import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CgChevronLeft } from 'react-icons/cg'
import PatientPayMethod from './PatientPayMethod'
// import { MdChevronRight } from 'react-icons/md'
import { getTransferFundsData } from 'actions/patientBankingActions'
import { getPatientOutstandingBalance } from 'actions/patientBankingActions'
import PatientBalanceBillDetailsHistory from './PatientBalanceBillDetailsHistory'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'
import PatientPaymentMobile from './PatientPaymentMobile'

const PatientPayBalance = (props) => {
    const dispatch = useDispatch()
    const [payMethodPage, setPayMethodPage] = useState(false)
    const [isOpenBillHistory, setIsOpenBillHistory] = useState(false)
    const [installmentDetails, setInstallmentDetails] = useState({})
    const [isEmptyList, setIsEmptyList] = useState(false)
    const transferFundData = useSelector((state) => state.transferFundData)
    const isLoading = useSelector((state) => state.applicationIsLoading)
    const patientCardListing = useSelector((state) => state.patientCardListing)
    const patientOutstandingBalance = useSelector(
        (state) => state.patientOutstandingBalance
    )
    const outStandingBalance =
        patientOutstandingBalance &&
        patientOutstandingBalance.total_remaining_balance
    useEffect(() => {
        dispatch(getTransferFundsData())
        dispatch(getPatientOutstandingBalance())
    }, [''])
    const handlePayMethodPage = () => {
        if (Object.keys(patientCardListing).length === 0) {
            console.log('Inside If')
            setIsEmptyList(true)
        } else {
            console.log('Inside Else')
            setPayMethodPage(!payMethodPage)
            setIsEmptyList(false)
            setIsOpenBillHistory(false)
        }
    }
    const openBillDetailsHistory = (data) => {
        setIsOpenBillHistory(!isOpenBillHistory)
        setInstallmentDetails(data)
    }
    const goTotalBalancePageFunc = () => {
        setIsEmptyList(false)
    }
    const goToPaymentMethodPageFunc = () => {
        setPayMethodPage(!payMethodPage)
    }
    const enabled = outStandingBalance > 0
    return (
        <>
            {!payMethodPage && !isOpenBillHistory && !isEmptyList && (
                <div className="mobile_account_info pay-balance-page total-balance-page-one">
                    <div
                        id="content"
                        className="content content-box mb-0 min-height-reset"
                    >
                        <nav className="navbar navbar-expand-lg navbar-light custom_navbar navbar-border d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() => props.history.goBack()}
                            />
                            <label className="nav-heading">Total Balance</label>
                            <span></span>
                        </nav>
                        <div className="content mobile-content-height">
                            <div
                                className="tab-pane fade show active tab_section"
                                id="nav-banking"
                                role="tabpanel"
                                aria-labelledby="nav-banking-tab"
                            >
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-center flex-column tranfer-wrapper">
                                            <span className="balance-label">
                                                Total Balance
                                            </span>
                                            <span className="balance">
                                                {isLoading ? (
                                                    <ModalLoader />
                                                ) : (
                                                    currencyFormatter.format(
                                                        outStandingBalance || 0
                                                    )
                                                )}
                                            </span>
                                        </div>
                                        <div className="transfer-to-label px-3 py-1">
                                            Open Accounts
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="tbl-wrapper tab-content">
                                            <table className="table table-borderless office_info">
                                                {isLoading && (
                                                    <div className="p-3 text-center">
                                                        <ModalLoader />
                                                    </div>
                                                )}
                                                {!isLoading &&
                                                    transferFundData &&
                                                    transferFundData.map(
                                                        (fundData, id) => {
                                                            return (
                                                                <tbody
                                                                    className="border-0 linked-accounts"
                                                                    key={id}
                                                                >
                                                                    <tr
                                                                        className="transfer-row-border"
                                                                        // onClick={() =>
                                                                        //     openBillDetailsHistory(
                                                                        //         fundData
                                                                        //     )
                                                                        // }
                                                                    >
                                                                        <td className="border-0 d-flex align-items-center justify-content-between">
                                                                            <div className="transfer-label">
                                                                                <div className="tab-bold-data">
                                                                                    {fundData
                                                                                        .physician
                                                                                        .first_name +
                                                                                        ' ' +
                                                                                        fundData
                                                                                            .physician
                                                                                            .last_name}
                                                                                </div>
                                                                                <span className="tab-lite-data">
                                                                                    Remaining
                                                                                    amount
                                                                                </span>
                                                                                <span className="tab-bold-data">
                                                                                    {' '}
                                                                                    {currencyFormatter.format(
                                                                                        fundData.totalInstallment ||
                                                                                            0
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            {/* <div className="d-flex align-items-center">
                                                                                <MdChevronRight className="float-right font24 color_dark_grey" />
                                                                            </div> */}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        }
                                                    )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        <button
                            disabled={!enabled}
                            className="btn mobile-green-btn w-100"
                            onClick={handlePayMethodPage}
                        >
                            Make a payment
                        </button>
                    </div>
                </div>
            )}
            {payMethodPage && (
                <PatientPayMethod
                    payMethodPage={payMethodPage}
                    handlePayMethodPage={handlePayMethodPage}
                    history={props.history}
                    outStandingBalance={outStandingBalance}
                    transferFundData={transferFundData}
                    installmentDetails={installmentDetails}
                    isRemainingPage={true}
                    fullPayment={true}
                    globalPayment={true}
                />
            )}
            {isOpenBillHistory && (
                <PatientBalanceBillDetailsHistory
                    isOpenBillHistory={isOpenBillHistory}
                    openBillDetailsHistory={openBillDetailsHistory}
                    // history={ props.history }
                    installmentDetails={installmentDetails}
                    // outStandingBalance={ outStandingBalance }
                    // transferFundData={ transferFundData }
                />
            )}
            {isEmptyList && (
                <PatientPaymentMobile
                    totalBalancePage={'one'}
                    goTotalBalancePageFunc={goTotalBalancePageFunc}
                    goToPaymentMethodPageFunc={goToPaymentMethodPageFunc}
                />
            )}
        </>
    )
}
PatientPayBalance.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}
export default PatientPayBalance
