import React from 'react'
import PropTypes from 'prop-types'
import PompayTopLogo from 'shared/PompayTopLogo'

function SuccessUpdateNumber(props) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light custom_navbar d-flex justify-content-between">
                <a href="/#">
                    <i
                        className="fa fa-angle-left font30 ml-3 color_light_green back_icon"
                        aria-hidden="true"
                    ></i>
                </a>
                <PompayTopLogo className="small_logo" />
                <p></p>
            </nav>
            <div className="container success_updaing_number">
                <div className="row">
                    <div className="mt-5 offset-md-3 col-md-6 offset-lg-4 col-lg-4 offset-xl-4 col-xl-4">
                        <div className="form_body">
                            <h4 className="color_dark_grey font17 font-weight700 letter-spacing_point5 text-center my-5">
                                Thank you for updating your phone number!
                            </h4>
                            <p className="font14 color_dark_grey px-5 text-center my-5">
                                This {props.location.state.phoneNumber} number
                                you will now use to sign in to Pompay.{' '}
                            </p>
                            <button
                                type="button"
                                className="btn green_btn custom_btn mt-5"
                                onClick={() => props.history.push('/')}
                            >
                                Go to home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

SuccessUpdateNumber.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}

export default SuccessUpdateNumber
