import React from 'react'

function AdminFooter() {
    return (
        <div className="footer admin-dashboard">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-left p-0">
                        Copyright © 2021 Concept. All rights reserved by{' '}
                        <a href="#">Pompay</a>.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter
