import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import AuthRoutes from 'app/app_routes/AuthRoutes'
import AdminRoutes from 'app/app_routes/AdminRoutes'
import RegisterByMobile from 'components/authentication/RegisterByMobile'
import Login from 'components/authentication/Login'
import Account from 'components/account/index'
import UpdateMobileNumber from 'components/authentication/UpdateMobileNumber'
import BasicInformation from 'components/authentication/BasicInformation'
import SelectUserRole from 'components/authentication/SelectUserRole'
import SuccessUpdateNumber from 'components/authentication/SuccessUpdateNumber'
import Notification from 'components/notification/index'
import Verified from 'components/authentication/Verified'
import HomePageMobile from 'components/account/mobile/HomePageMobile'
import BankingMobile from 'components/account/mobile/BankingMobile'
import GuidelinesMobile from 'components/account/mobile/GuidelinesMobile'
import TeamMobile from 'components/account/mobile/TeamMobile'
import DetailsMobile from 'components/account/mobile/DetailsMobile'
import UserActivityMobile from 'components/account/mobile/UserActivityMobile'
import OfficeMobile from 'components/account/mobile/OfficeMobile'
import Balance from 'components/account/mobile/Balance'
import PatientList from 'components/patient/dashboard/web/patientList/PatientList'
import PaymentReview from 'components/patient/dashboard/web/PaymentReview'
import PatientFilter from 'components/patient/dashboard/web/PatientFilter'
import PatientProfileMobile from 'components/patient/dashboard/mobile/PatientProfileMobile'
import PatientRemainingBalance from 'components/patient/dashboard/mobile/PatientRemainingBalance'
import BillDetails from 'components/patient/dashboard/mobile/BillDetails'
import PatientAccount from 'components/patient/account'
import PatientBasicProfile from 'components/patient/profile/index'
import CreatePassode from 'components/authentication/CreatePassode'
import ReEnterPasscode from 'components/authentication/ReEnterPasscode'
import PhysicianListView from 'components/patient/physician/PhysicianListView'
import PatientHomePage from 'components/patient/home/PatientHomePage'
import PhysicianNotification from 'components/physicianNotification'
import BillDetailsTotal from 'components/patient/dashboard/mobile/BillDetailsTotal'
import PatientPayBalance from 'components/patient/account/mobile/PatientPayBalance'
import PatientDetailsMobile from 'components/patient/account/mobile/PatientDetailsMobile'
import PatientPaymentMobile from 'components/patient/account/mobile/PatientPaymentMobile'
import PatientTransactionMobile from 'components/patient/account/mobile/PatientTransactionMobile'
import PatientNotificationMobile from 'components/patient/account/mobile/PatientNotificationMobile'
import ReviewArrangement from 'components/patient/dashboard/web/ReviewArrangement'
import PatientArrangementDetailsMobile from 'components/patient/dashboard/mobile/PatientArrangementDetailsMobile'
import SelectPhysicianMobile from 'components/patient/mobile/SelectPhysicianMobile'
import EnterAccessCode from 'components/patient/mobile/EnterAccessCode'
import AdminLogin from 'components/admin/AdminLogin'
import AdminDashboard from 'components/admin/AdminDashboard'
import AdminPhysicianList from 'components/admin/AdminPhysicianList'
import AdminPatientList from 'components/admin/AdminPatientList'
import AdminTeamMemberList from 'components/admin/AdminTeamMemberList'
import PhysicianDashboard from 'components/patient/dashboard/web/PhysicianDashboard'
import NotFound from 'shared/NotFound'
import QrCode from 'components/account/mobile/QrCode'
import AdminTransactions from 'components/admin/AdminTransactions'
const AppRoutes = () => (
    <Router>
        <Switch>
            <AuthRoutes exact path="/" component={Login} />
            <AuthRoutes exact path="/signup" component={RegisterByMobile} />
            <AuthRoutes exact path="/select-role" component={SelectUserRole} />
            <AuthRoutes
                exact
                path="/update-number"
                component={UpdateMobileNumber}
            />
            <AuthRoutes exact path="/success" component={SuccessUpdateNumber} />

            <AuthRoutes exact path="/basic-info" component={BasicInformation} />
            <AuthRoutes exact path="/verified" component={Verified} />
            <AuthRoutes
                exact
                path="/create-passcode"
                component={CreatePassode}
            />
            <AuthRoutes
                exact
                path="/re-enter-passcode"
                component={ReEnterPasscode}
            />
            <AuthRoutes exact path="/notification" component={Notification} />
            <AuthRoutes
                exact
                path="/physician-notification"
                component={PhysicianNotification}
            />
            <AuthRoutes exact path="/account" component={Account} />
            <AuthRoutes
                exact
                path="/physician-dashboard"
                component={PhysicianDashboard}
            />
            <AuthRoutes exact path="/balance-mobile" component={Balance} />
            <AuthRoutes exact path="/qr-mobile" component={QrCode} />
            <AuthRoutes
                exact
                path="/details-mobile"
                component={DetailsMobile}
            />
            <AuthRoutes exact path="/office-mobile" component={OfficeMobile} />
            <AuthRoutes
                exact
                path="/banking-mobile"
                component={BankingMobile}
            />
            <AuthRoutes
                exact
                path="/guidelines-mobile"
                component={GuidelinesMobile}
            />
            <AuthRoutes exact path="/team-mobile" component={TeamMobile} />
            <AuthRoutes
                exact
                path="/useractivity-mobile"
                component={UserActivityMobile}
            />
            {/* patient-profile-mobile */}
            <AuthRoutes
                exact
                path="/patient-filter"
                component={PatientFilter}
            />
            <AuthRoutes exact path="/payments" component={PatientList} />
            <AuthRoutes
                exact
                path="/review-arrangement"
                component={ReviewArrangement}
            />
            <AuthRoutes exact path="/patient-list" component={PaymentReview} />
            <AuthRoutes
                exact
                path="/arrangement-details-mobile"
                component={PatientArrangementDetailsMobile}
            />
            <AuthRoutes
                exact
                path="/patient-profile-mobile"
                component={PatientProfileMobile}
            />
            <AuthRoutes
                exact
                path="/patient-remaning-mobile"
                component={PatientRemainingBalance}
            />
            <AuthRoutes
                exact
                path="/billdetails-mobile"
                component={BillDetails}
            />
            <AuthRoutes
                exact
                path="/billdetailsTotal-mobile"
                component={BillDetailsTotal}
            />
            <AuthRoutes
                exact
                path="/account-homepage-mobile"
                component={HomePageMobile}
            />
            <AuthRoutes
                exact
                path="/patient-basic-profile"
                component={PatientBasicProfile}
            />
            <AuthRoutes
                exact
                path="/patient-account"
                component={PatientAccount}
            />
            <AuthRoutes
                exact
                path="/patient-pay-balance"
                component={PatientPayBalance}
            />
            <AuthRoutes
                exact
                path="/account-details-mobile"
                component={PatientDetailsMobile}
            />
            <AuthRoutes
                exact
                path="/patient-payments-mobile"
                component={PatientPaymentMobile}
            />
            <AuthRoutes
                exact
                path="/patient-transaction-mobile"
                component={PatientTransactionMobile}
            />
            <AuthRoutes
                exact
                path="/patient-notification-mobile"
                component={PatientNotificationMobile}
            />
            <AuthRoutes
                exact
                path="/physician-list-view"
                component={PhysicianListView}
            />
            <AuthRoutes exact path="/home" component={PatientHomePage} />
            {/* patient section mobile routes */}
            <AuthRoutes
                exact
                path="/select-physician-mobile"
                component={SelectPhysicianMobile}
            />
            <AuthRoutes
                exact
                path="/add-physician-mobile"
                component={EnterAccessCode}
            />
            {/* Admin routes */}
            <AdminRoutes exact path="/admin" component={AdminLogin} />
            <AdminRoutes
                exact
                path="/admin-dashboard"
                component={AdminDashboard}
            />
            <AdminRoutes
                exact
                path="/admin-physician"
                component={AdminPhysicianList}
            />
            <AdminRoutes
                exact
                path="/admin-patient"
                component={AdminPatientList}
            />
            <AdminRoutes
                exact
                path="/admin-team-member"
                component={AdminTeamMemberList}
            />
            <AdminRoutes
                exact
                path="/admin-transaction"
                component={AdminTransactions}
            />
            <AuthRoutes exact path="/not-found" component={NotFound} />
        </Switch>
    </Router>
)

export default AppRoutes
