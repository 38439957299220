import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { MdChevronRight } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { MODAL_STYLES } from 'constants/Constant'
import PatientAccountNumberModal from './PatientAccountNumberModal'
import { getPhysicianAppointmentId } from 'actions/patientPhysicianActions'
import { getInstallBillDetails, getPayActivity } from 'actions/patientActions'
import ModalLoader from 'shared/ModalLoader'
import { currencyFormatter } from 'utils/helper'
import HistoryTotalBillDetails from './HistoryTotalBillDetails'
import CurrentRemaningPayDetails from './CurrentRemaningPayDetails'
import PhysicianProfile from './PhysicianProfile'
import SuccessAlert from 'shared/SuccessAlert'
import moment from 'moment'
import SelectPaymentMethods from './SelectPaymentMethods'

function PhysicianBillRecord(props) {
    const {
        isOpen,
        closeBillRecordModal,
        physicianDetails,
        patientId,
        history,
        physicianIds,
        isHideNewBill,
    } = props
    const dispatch = useDispatch()
    const [openPhysicianProfile, setOpenPhysicianProfile] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [openPatientAccountNumber, setOpenPatientAccountNumber] =
        useState(false)
    const [openTotalPayDetailsModal, setOpenTotalPayDetailsModal] =
        useState(false)
    const [openRemaningPayModal, setOpenRemaningPayModal] = useState(false)
    const [
        openTotalPayDetailsModalDetails,
        setOpenTotalPayDetailsModalDetails,
    ] = useState({})
    const [
        openRemaningPayDetailsModalDetails,
        setOpenRemaningPayDetailsModalDetails,
    ] = useState({})
    const patientAppointmentId = useSelector(
        (state) => state.patientAppointmentId
    )
    const isModalLoading = useSelector((state) => state.modalIsLoading)
    // const paymentActivityData = useSelector((state) => state.paymentActivityData);
    const [paymentActivityData, setPaymentActivityData] = useState([])

    const [totalBalance, setTotalBalance] = useState('')
    useEffect(() => {
        dispatch(getPhysicianAppointmentId(physicianDetails.physician_id))
        dispatch(getPayActivity(patientId, physicianDetails.physician_id)).then(
            (res) => {
                setPaymentActivityData(res && res)
                setTotalBalance(res && res.total_balance)
            }
        )
    }, [''])
    const closePatientAccountNumber = () => {
        setOpenPatientAccountNumber(false)
    }
    const handleTotalPaymentFunc = (data) => {
        dispatch(
            getInstallBillDetails(
                data.patient_payment_id,
                physicianDetails.physician_id
            )
        )
        setOpenTotalPayDetailsModal(true)
        setOpenTotalPayDetailsModalDetails(data)
    }
    const closeTotalPaymentFunc = () => {
        setOpenTotalPayDetailsModal(false)
    }
    const handleRemaningBalanceFunc = (data) => {
        if (physicianDetails && physicianDetails.stripe_account_id) {
            dispatch(
                getInstallBillDetails(
                    data.patient_payment_id,
                    physicianDetails.physician_id
                )
            )
            setOpenRemaningPayModal(!openRemaningPayModal)
            setOpenRemaningPayDetailsModalDetails(data)
        } else {
            setSuccessAlert(true)
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    const closeRemaningPaymentFunc = () => {
        setOpenRemaningPayModal(!openRemaningPayModal)
    }
    const handleOpenProfile = () => {
        setOpenPhysicianProfile(!openPhysicianProfile)
    }
    const closePhysicianProfile = () => {
        setOpenPhysicianProfile(!openPhysicianProfile)
    }
    const handleNewBill = () => {
        if (physicianDetails && physicianDetails.stripe_account_id) {
            if (
                physicianIds.includes(
                    physicianDetails && physicianDetails.physician_id
                )
            ) {
                setSuccessAlertMessage(
                    'Your previous arrangement is still under review'
                )
                setSuccessAlert(true)
            } else {
                setOpenPatientAccountNumber(true)
            }
        } else {
            setSuccessAlertMessage(
                'This physician is yet to setup a payment acceptance method'
            )
            setSuccessAlert(true)
        }
    }
    const installBillDetails = useSelector(
        (state) =>
            state.installBillDetails &&
            state.installBillDetails.remaining_balance
    )
    const [paymentOption, setPaymentOption] = useState(false)
    const closePaymentMethod = () => {
        setPaymentOption(!paymentOption)
    }
    return (
        <>
            {!openPatientAccountNumber &&
                !openTotalPayDetailsModal &&
                !openRemaningPayModal &&
                !openPhysicianProfile && (
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeBillRecordModal}
                        style={MODAL_STYLES}
                        contentLabel="Example Modal"
                        id="remaining-balance-modal"
                        ariaHideApp={false}
                    >
                        <div className="remaining_balance_page">
                            <div className="d-flex justify-content-between mb-2 modal_heading_section">
                                <a className="mt-2">
                                    <BsThreeDots
                                        className="color_dark_grey mr-2 font20"
                                        onClick={() => handleOpenProfile()}
                                    />
                                </a>
                                <a className="navbar-brand">
                                    <label className="nav-heading">
                                        {(physicianDetails.physician_full_name &&
                                            'Dr.' +
                                                physicianDetails.physician_full_name.split(
                                                    '.'
                                                )[1]) ||
                                            physicianDetails.physician_name}
                                    </label>
                                </a>
                                <button
                                    type="button"
                                    className="close modal_close_custom_icon"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closeBillRecordModal()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="content">
                                <div className="text-center mt-5">
                                    <span className="balance-label">
                                        Remaining Balance
                                    </span>
                                    {isModalLoading && <ModalLoader />}
                                    {!isModalLoading && totalBalance !== '' && (
                                        <div>
                                            <h3 className="balance">
                                                {' '}
                                                {currencyFormatter.format(
                                                    totalBalance || ''
                                                )}{' '}
                                            </h3>
                                            {paymentActivityData &&
                                                paymentActivityData.total_balance >
                                                    0 && (
                                                    <MdChevronRight
                                                        className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer header-arrow"
                                                        onClick={() =>
                                                            closePaymentMethod(
                                                                paymentActivityData &&
                                                                    paymentActivityData.total_balance &&
                                                                    paymentActivityData.total_balance
                                                            )
                                                        }
                                                    />
                                                )}
                                        </div>
                                    )}
                                </div>
                                <div className="tab_section">
                                    <ul
                                        className="nav nav-tabs"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="current-tab"
                                                data-toggle="tab"
                                                href="#current"
                                                role="tab"
                                                aria-controls="current"
                                                aria-selected="true"
                                            >
                                                Current
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="history-tab"
                                                data-toggle="tab"
                                                href="#history"
                                                role="tab"
                                                aria-controls="history"
                                                aria-selected="false"
                                            >
                                                History
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="tab-content overflowAuto"
                                        id="myTabContent"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="current"
                                            role="tabpanel"
                                            aria-labelledby="current-tab"
                                        >
                                            {isModalLoading && (
                                                <div className="d-flex justify-content-center mt-5">
                                                    {' '}
                                                    <ModalLoader />{' '}
                                                </div>
                                            )}
                                            {!isModalLoading &&
                                            paymentActivityData &&
                                            paymentActivityData.current ? (
                                                paymentActivityData.current.map(
                                                    (data, id) => {
                                                        return (
                                                            <div
                                                                className="row no-gutters"
                                                                key={id}
                                                            >
                                                                <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="tab-lite-data">
                                                                            {' '}
                                                                            Due{' '}
                                                                            <span className="tab-bold-data">
                                                                                {/* {data.due_amount_date}{' '} {' '} */}
                                                                                {moment(
                                                                                    data.due_amount_date
                                                                                ).format(
                                                                                    'l'
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        <span className="tab-lite-data">
                                                                            {' '}
                                                                            Remaining
                                                                            amount{' '}
                                                                            <span className="tab-bold-data">
                                                                                {' '}
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount ||
                                                                                        0
                                                                                )}{' '}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-sm-5 right_row_padding">
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className="d-flex flex-column text-right">
                                                                            <span className="tab-bold-data">
                                                                                {currencyFormatter.format(
                                                                                    data.remaining_amount -
                                                                                        (data?.late_fee ??
                                                                                            0)
                                                                                )}
                                                                                {Boolean(
                                                                                    data?.late_fee
                                                                                ) && (
                                                                                    <span className="error">{` (+${currencyFormatter.format(
                                                                                        data.late_fee ||
                                                                                            0
                                                                                    )})`}</span>
                                                                                )}
                                                                            </span>
                                                                            {data.payment_left ? (
                                                                                <span>
                                                                                    {
                                                                                        data.payment_left
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                <span className="color_alert tab-superlite-data">
                                                                                    {
                                                                                        data.days_past_count
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <MdChevronRight
                                                                            onClick={() =>
                                                                                handleRemaningBalanceFunc(
                                                                                    data
                                                                                )
                                                                            }
                                                                            className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )
                                            ) : (
                                                <p className="no-items text-center">
                                                    No items to display
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="history"
                                            role="tabpanel"
                                            aria-labelledby="history-tab"
                                        >
                                            {isModalLoading && (
                                                <div className="d-flex justify-content-center mt-5">
                                                    {' '}
                                                    <ModalLoader />
                                                </div>
                                            )}
                                            {!isModalLoading &&
                                                paymentActivityData &&
                                                paymentActivityData.history &&
                                                paymentActivityData.history.map(
                                                    (data, id) => {
                                                        return (
                                                            <div
                                                                className="row no-gutters"
                                                                key={id}
                                                            >
                                                                <div className="col-7 col-sm-7 text-left left_row_padding">
                                                                    <div className="d-flex flex-column">
                                                                        <span className="tab-lite-data">
                                                                            Amount{' '}
                                                                            <span className="tab-bold-data">
                                                                                {currencyFormatter.format(
                                                                                    data.paid_amount
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                        <span className="tab-superlite-data">
                                                                            Final
                                                                            payment
                                                                            date{' '}
                                                                            <span className="tab-lighterbold-data">
                                                                                {/* {data.payment_date} */}
                                                                                {moment(
                                                                                    data.payment_date
                                                                                ).format(
                                                                                    'l'
                                                                                )}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-sm-5 right_row_padding d-flex align-items-center justify-content-end">
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className="d-flex flex-column text-right">
                                                                            <span className="bold-text">
                                                                                {' '}
                                                                                {
                                                                                    data.payment_status
                                                                                }{' '}
                                                                            </span>
                                                                        </div>
                                                                        <MdChevronRight
                                                                            onClick={() =>
                                                                                handleTotalPaymentFunc(
                                                                                    data
                                                                                )
                                                                            }
                                                                            className="float-right font28 ml-2 color_dark_grey d-flex align-items-center pointer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="theme-btn pointer new-bill-btn"
                                onClick={handleNewBill}
                            >
                                New Bill
                            </button>
                        </div>
                    </Modal>
                )}
            {openPatientAccountNumber && (
                <PatientAccountNumberModal
                    isOpen={openPatientAccountNumber}
                    closePatientAccountNumber={closePatientAccountNumber}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianDetails}
                    history={history}
                />
            )}
            {openTotalPayDetailsModal && (
                <HistoryTotalBillDetails
                    openTotalPayDetailsModal={openTotalPayDetailsModal}
                    openTotalPayDetailsModalDetails={
                        openTotalPayDetailsModalDetails
                    }
                    closeTotalPaymentFunc={closeTotalPaymentFunc}
                    physicianDetails={physicianDetails}
                />
            )}
            {openRemaningPayModal && (
                <CurrentRemaningPayDetails
                    openRemaningPayModal={openRemaningPayModal}
                    closeRemaningPaymentFunc={closeRemaningPaymentFunc}
                    physicianDetails={physicianDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    history={history}
                />
            )}
            {openPhysicianProfile && (
                <PhysicianProfile
                    isOpen={openPhysicianProfile}
                    closePhysicianProfile={closePhysicianProfile}
                    patientAppointmentId={patientAppointmentId}
                    physicianDetails={physicianDetails}
                    physicianIds={physicianIds}
                    isHideNewBill={isHideNewBill}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message={successAlertMessage}
                />
            )}
            {paymentOption && (
                <SelectPaymentMethods
                    isOpen={paymentOption}
                    closePaymentMethod={closePaymentMethod}
                    physicianDetails={physicianDetails}
                    openRemaningPayDetailsModalDetails={
                        openRemaningPayDetailsModalDetails
                    }
                    history={history}
                    installBillDetails={installBillDetails}
                    fullPayment={true}
                    paymentActivityData={paymentActivityData}
                />
            )}
        </>
    )
}

PhysicianBillRecord.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    closeBillRecordModal: PropTypes.func,
    physicianDetails: PropTypes.object,
    // transferFundData: PropTypes.object,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    history: PropTypes.object,
    physicianIds: PropTypes.array,
    isHideNewBill: PropTypes.bool,
}

export default PhysicianBillRecord
