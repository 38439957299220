import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FiChevronLeft } from 'react-icons/fi'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import { currencyFormatter } from 'utils/helper'
import ThankYouModal from 'shared/ThankYouModal'
import { singlePaymentAction } from 'actions/patientBankingActions'
import SuccessAlert from 'shared/SuccessAlert'
import { triggerNotifier } from 'shared/notifier'
import { calculateTransactionFee } from './../../../utils/helper'

function FinalAmountToPay(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closeFinalPayAmount,
        physicianDetails,
        selectedCardDetails,
        openRemaningPayDetailsModalDetails,
        selectedPayDetails,
        history,
        installBillDetails,
    } = props
    const [isComplete, setIsComplete] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [closeAllModal, setCloseAllModal] = useState(false)

    const dueInstallmentSchedule =
        installBillDetails?.full_payment_schedule?.find(
            (x) => x.due_amount_status === 'Due'
        )

    const lateFees = dueInstallmentSchedule?.late_fee ?? 0
    const totalFees = dueInstallmentSchedule?.due_amount ?? 0
    const discountAmount = dueInstallmentSchedule?.discount_amount ?? 0

    const paidFees = parseFloat(totalFees) + parseFloat(lateFees)

    const transactionFee = calculateTransactionFee(paidFees)

    const totalAmount = transactionFee + paidFees

    const originalAmount = parseFloat(totalFees) + parseFloat(discountAmount)
    const confirmPayFunc = () => {
        const data = {
            card_id: selectedCardDetails.card_id,
            physician_id: physicianDetails.physician_id,
            payment_schedule_id: dueInstallmentSchedule.payment_schedule_id,
            stripe_account_id:
                physicianDetails && physicianDetails.stripe_account_id,
            due_amount: paidFees,
            patient_payment_id:
                openRemaningPayDetailsModalDetails.patient_payment_id,
            month: dueInstallmentSchedule.month,
        }
        if (physicianDetails && physicianDetails.stripe_account_id) {
            dispatch(singlePaymentAction(data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsComplete(!isComplete)
                }
            })
        } else {
            const message = 'Selected physician has not yet added bank account'
            triggerNotifier({ type: 'error', message })
        }
    }
    const closeSuccessAlert = () => {
        setSuccessAlert()
    }
    const closeConfirmPayFunc = () => {
        setIsComplete(!isComplete)
        setCloseAllModal(true)
    }
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1 // Months start at 0!
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = mm + '-' + dd + '-' + yyyy
    return (
        <>
            {isOpen && !isComplete && !closeAllModal && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeFinalPayAmount}
                    style={MODAL_STYLES}
                    contentLabel="Example Modal"
                    id="receipt-modal"
                    ariaHideApp={false}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border">
                                <FiChevronLeft
                                    className="back-icon pointer"
                                    onClick={() => closeFinalPayAmount()}
                                />
                                <label className="patient-name">
                                    {physicianDetails.physician_name ||
                                        physicianDetails.physician_full_name}
                                </label>
                                <span></span>
                            </div>
                            <div className="modal-body text-center p-0">
                                <div className="modal-header-border total-amount-content mt-3">
                                    <span className="total-amount">
                                        Total Paid
                                    </span>
                                    <h3 className="amount">
                                        {currencyFormatter.format(
                                            totalAmount || 0
                                        )}
                                    </h3>
                                </div>
                                <div className="details-info-wrapper">
                                    <label className="details-label mt-4 mb-3">
                                        Payment details
                                    </label>
                                    <div className="mt-4">
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Physician
                                            </span>
                                            <span className="data">
                                                {physicianDetails.physician_name ||
                                                    physicianDetails.physician_full_name}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">Date</span>
                                            <span className="data">
                                                {formattedToday}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Bill amount
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    originalAmount || 0
                                                )}
                                            </span>
                                        </div>
                                        {Boolean(discountAmount) && (
                                            <div className="d-flex justify-content-between my-4">
                                                <span className="data">
                                                    Discount applied
                                                </span>
                                                <span className="data">
                                                    -
                                                    {currencyFormatter.format(
                                                        discountAmount || 0
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        {Boolean(lateFees) && (
                                            <div className="d-flex justify-content-between my-4">
                                                <span className="data">
                                                    Late fee
                                                </span>
                                                <span className="data">
                                                    {currencyFormatter.format(
                                                        lateFees
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">
                                                Transaction fee
                                            </span>
                                            <span className="data">
                                                {currencyFormatter.format(
                                                    transactionFee
                                                )}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="data">From</span>
                                            <span className="data">
                                                {selectedCardDetails.card_type}{' '}
                                                ****
                                                {
                                                    selectedCardDetails.card_number
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between my-4">
                                            <span className="bold-data">
                                                Total
                                            </span>
                                            <span className="bold-data">
                                                {currencyFormatter.format(
                                                    totalAmount
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn next-btn final-btn"
                                onClick={confirmPayFunc}
                            >
                                Pay {currencyFormatter.format(totalAmount)}
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {isComplete && !closeAllModal && (
                <ThankYouModal
                    isComplete={isComplete}
                    closeConfirmPayFunc={closeConfirmPayFunc}
                    selectedCardDetails={selectedCardDetails}
                    physicianDetails={physicianDetails}
                    selectedPayDetails={selectedPayDetails}
                    history={history}
                    installBillDetails={installBillDetails}
                    paidFees={totalAmount}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    restrict={true}
                    message="Payment fail please check your details"
                    description="please check payment details and amount"
                />
            )}
        </>
    )
}
FinalAmountToPay.propTypes = {
    closeFinalPayAmount: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    selectedCardDetails: PropTypes.object,
    history: PropTypes.object,
    installBillDetails: PropTypes.object,
    openRemaningPayDetailsModalDetails: PropTypes.object,
    selectedPayDetails: PropTypes.object,
}
export default FinalAmountToPay
