import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { MODAL_STYLES } from 'constants/Constant'
import moment from 'moment'
import { yyyymmddFormat } from 'constants/Constant'
import { createEmiPayment } from 'actions/patientBankingActions'
import ConfirmDelete from 'shared/ConfirmDelete'
import { currencyFormatter } from 'utils/helper'
import SuccessAlert from 'shared/SuccessAlert'
import Loading from 'shared/Loader'
function SetUpPlanPaymentModal(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        handleSetUpPlanFunc,
        physicianDetails,
        dateValue,
        billAmount,
        paymentScheduleDetails,
        pompayDisountId,
        selectedDate,
        closeAllModalFunc,
        history,
    } = props
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [closeAll, setCloseAll] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const confirmRemove = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const cancelPayment = () => {
        setIsConfirmDelete(!isConfirmDelete)
    }
    const handleRemoveFunc = () => {
        setIsConfirmDelete(!isConfirmDelete)
        setCloseAll(true)
        history.push('/home')
    }
    const handleConfirm = () => {
        const data = {
            original_amount: billAmount,
            discount_amount: pompayDisountId
                ? paymentScheduleDetails.saved_amount.toString()
                : '0',
            discount_by: pompayDisountId
                ? parseInt(paymentScheduleDetails.discount_by)
                : 0,
            adjusted_amount: pompayDisountId
                ? paymentScheduleDetails.instant_pay_amount.toString()
                : billAmount,
            months: pompayDisountId
                ? 0
                : paymentScheduleDetails.installment_option[0].month,
            first_installment_amount:
                paymentScheduleDetails.installment_option[0].first_installment_amount.toString(),
            last_installment_amount: pompayDisountId
                ? '0'
                : (paymentScheduleDetails.installment_option[0]
                      .last_installment_amount &&
                      paymentScheduleDetails.installment_option[0].last_installment_amount.toString()) ||
                  '0',
            physician_id: physicianDetails.physician_id,
            due_amount_date: moment(dateValue).format(yyyymmddFormat),
            patient_appointment_id:
                paymentScheduleDetails.patient_appointment_id,
        }
        dispatch(createEmiPayment(data)).then((res) => {
            setIsLoading(true)
            if (res.statusCode === 200) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'You have successfully created your arrangement'
                )
                setIsLoading(false)
            } else {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'Your previous arrangements are already pending'
                )
                setIsLoading(false)
            }
        })
    }
    const closeSuccessAlert = () => {
        closeAllModalFunc()
        setSuccessAlert(false)
    }
    return (
        <>
            {isOpen && !closeAll && !successAlert && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={handleSetUpPlanFunc}
                    style={MODAL_STYLES}
                    ariaHideApp={false}
                    id="setup-payment-plan"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content otp_modal">
                            <div className="d-flex justify-content-between align-items-center modal-header-border pb-0">
                                <a
                                    className="link-text link-text-green"
                                    onClick={() => handleSetUpPlanFunc()}
                                >
                                    Edit
                                </a>
                                <button
                                    type="button"
                                    className="close modal_close_custom_icon mt-2"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => cancelPayment()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="content-wrapper">
                                {pompayDisountId ? (
                                    <span className="heading">
                                        Thank you for choosing the
                                        <br /> Pompay discount
                                    </span>
                                ) : (
                                    <span className="heading">
                                        You have set up a new
                                        <br /> payment plan.{' '}
                                    </span>
                                )}
                                {pompayDisountId ? (
                                    <div className="content">
                                        Your physician will be notified of your
                                        new payment arrangement. We will send
                                        you a text once your physician has
                                        accepted the arrangement or if any edits
                                        were made.
                                    </div>
                                ) : (
                                    <div className="content">
                                        Your physician will be notified of your
                                        new payment arrangement. We will send
                                        you a text once your physician has
                                        accepted the arrangement or if any edits
                                        were made.{' '}
                                    </div>
                                )}
                                <div className="details-label">Details</div>
                                <div className="details-plan">
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">
                                            Physician:
                                        </span>
                                        <span className="value">
                                            {physicianDetails.physician_full_name ||
                                                physicianDetails.physician_name}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">Date:</span>
                                        <span className="value">
                                            {selectedDate}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">
                                            Patient I.D. :
                                        </span>
                                        <span className="value">
                                            {
                                                paymentScheduleDetails.patient_appointment_id
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between my-3">
                                        <span className="label">Amount:</span>
                                        <span className="value">
                                            {currencyFormatter.format(
                                                billAmount
                                            )}
                                        </span>
                                    </div>
                                    {pompayDisountId ? (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="label">
                                                Discount:
                                            </span>
                                            <span className="value">
                                                -
                                                {currencyFormatter.format(
                                                    paymentScheduleDetails.saved_amount
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="label">
                                                Installments:
                                            </span>
                                            <span className="value">
                                                {
                                                    paymentScheduleDetails
                                                        .installment_option[0]
                                                        .month
                                                }
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {pompayDisountId ? (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="bold-label">
                                                Total:
                                            </span>
                                            <span className="bold-value">
                                                {currencyFormatter.format(
                                                    paymentScheduleDetails.instant_pay_amount
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-between my-3">
                                            <span className="bold-label">
                                                1st Installment:
                                            </span>
                                            <span className="bold-value">
                                                {currencyFormatter.format(
                                                    paymentScheduleDetails
                                                        .installment_option[0]
                                                        .first_installment_amount
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between my-3">
                                    <span className="bold-label">Due Date</span>
                                    <span className="bold-value">
                                        {selectedDate}
                                    </span>
                                </div>
                            </div>
                            {isLoading ? (
                                <div className="mt-5 mx-4">
                                    {' '}
                                    <Loading />{' '}
                                </div>
                            ) : (
                                <button
                                    className="mt-2 btn next-btn"
                                    onClick={handleConfirm}
                                >
                                    {' '}
                                    Finish{' '}
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {isConfirmDelete && !closeAll && (
                <ConfirmDelete
                    isConfirmDelete={isConfirmDelete}
                    confirmRemove={confirmRemove}
                    mode="bill"
                    handleRemoveFunc={handleRemoveFunc}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}

SetUpPlanPaymentModal.propTypes = {
    handleSetUpPlanFunc: PropTypes.func,
    createEmiPayment: PropTypes.func,
    isOpen: PropTypes.bool,
    physicianDetails: PropTypes.object,
    history: PropTypes.object,
    pompayDisountId: PropTypes.string,
    billAmount: PropTypes.string,
    paymentScheduleDetails: PropTypes.array,
    selectedDate: PropTypes.number,
    dateValue: PropTypes.oneOfType([PropTypes.date, PropTypes.number]),
    closeAllModalFunc: PropTypes.func,
    closeModal: PropTypes.func,
}

export default SetUpPlanPaymentModal
