import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import '../../static/stylesheets/admin.scss'
import logo from '../../static/images/logo.svg'
// import UpdateAdminNumber from './UpdateAdminNumber';
import AdminOtpModal from './AdminOtpModal'
import { adminLoginAction } from 'actions/adminActions'
import AdminEmailUpdate from './AdminEmailUpdate'

const AdminLogin = (props) => {
    const dispatch = useDispatch()
    const { history } = props
    const [mobileNumber, setMobileNumber] = useState('')
    const [mobileNumberError, setMobileNumberError] = useState(false)
    const [openUpdateNumberPage, setOpenUpdateNumberPage] = useState(false)
    const [openOtpModal, setOpenOtpModal] = useState(false)

    const handleUpdateNumberFunc = () => {
        setOpenUpdateNumberPage(!openUpdateNumberPage)
        setMobileNumberError(false)
    }
    const closeOtpModal = () => {
        setOpenOtpModal(false)
    }
    const handleOtpModalFunc = () => {
        const data = {
            phone_number: mobileNumber,
            // device_token: '',
            // device_type: '',
            user_role: '0',
        }
        if (mobileNumber) {
            setOpenOtpModal(!openOtpModal)
            dispatch(adminLoginAction(data))
        } else {
            setMobileNumberError(true)
        }
    }
    const handleChange = (event) => {
        let obj = event.target.value
        var numbers = obj.replace(/\D/g, ''),
            char = { 0: '(', 3: ') ', 6: '-' }
        obj = ''
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i]
            obj
        }
        setMobileNumber(obj)
        setMobileNumberError(false)
    }
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            handleOtpModalFunc()
        }
    }
    return (
        <>
            {!openUpdateNumberPage && (
                <div className="admin-dashboard splash-container d-flex justify-content-center align-items-center">
                    <div className="card mb-0">
                        <div className="card-header text-center border-0">
                            <img className="logo-img" src={logo} alt="logo" />
                            <span className="splash-description">
                                We use your mobile number to sign in to your
                                account.
                            </span>
                        </div>
                        <div className="card-body d-flex justify-content-center">
                            <form>
                                <div className="form-group">
                                    <input
                                        className="form-control form-control-lg"
                                        value={mobileNumber}
                                        id="mobile-number"
                                        type="text"
                                        maxLength="14"
                                        onChange={handleChange}
                                        onKeyDown={onKeyDown}
                                        placeholder="Mobile number"
                                        autoComplete="off"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block btn-radius"
                                    onClick={handleOtpModalFunc}
                                >
                                    Sign in
                                </button>
                                {mobileNumberError && (
                                    <div className="alert alert-danger mb-0 mt-3 text-center">
                                        Please enter mobile number
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="card-footer bg-white p-0 d-flex justify-content-center">
                            <div className="card-footer-item card-footer-item-bordered">
                                <a
                                    onClick={handleUpdateNumberFunc}
                                    className="footer-link"
                                >
                                    Update mobile number
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {openUpdateNumberPage && (
                <AdminEmailUpdate
                    openUpdateNumberPage={openUpdateNumberPage}
                    handleUpdateNumberFunc={handleUpdateNumberFunc}
                    history={history}
                    mobileNumber={mobileNumber}
                />
            )}
            {openOtpModal && (
                <AdminOtpModal
                    isOpen={openOtpModal}
                    closeOtpModal={closeOtpModal}
                    history={history}
                    mobileNumber={mobileNumber}
                />
            )}
        </>
    )
}
AdminLogin.propTypes = {
    history: PropTypes.object,
}
export default AdminLogin
