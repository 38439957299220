import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { maxLengthCheck } from 'utils/helper'

function AddTeamModal(props) {
    const {
        handleChange,
        submitTeamData,
        inputProps,
        enabled,
        input,
        errors,
        suggestions,
        onSuggestionsFetchRequested,
        onSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
        yearList,
        monthList,
        dateList,
    } = props
    return (
        <div>
            <h5 className="modal-heading mb-4">New Team Member</h5>
            <form className="new-team-member-form">
                <div className="form-row flex-column">
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <input
                                    type="text"
                                    name="first_name"
                                    autoComplete="off"
                                    id="first_name"
                                    value={input.first_name || ''}
                                    onChange={handleChange}
                                    className="form-control account_info_field"
                                    placeholder="Enter first name"
                                />
                                <label
                                    className="input-group-text account_info_label"
                                    htmlFor="first_name"
                                >
                                    First name
                                </label>
                            </div>
                        </div>
                        <small className="text-danger">
                            {errors.first_name}
                        </small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <input
                                    type="text"
                                    name="last_name"
                                    autoComplete="off"
                                    id="last_name"
                                    value={input.last_name || ''}
                                    onChange={handleChange}
                                    className="form-control account_info_field"
                                    placeholder="Enter last name"
                                />
                                <label
                                    className="input-group-text account_info_label"
                                    htmlFor="last_name"
                                >
                                    Last name
                                </label>
                            </div>
                        </div>
                        <small className="text-danger">
                            {errors.last_name}
                        </small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <fieldset className="input-group-prepend">
                                {/* <Space direction="horizontal">
                                        <DatePicker
                                            className="form-control account_info_field dob-field"
                                            format={ dateFormat }
                                            placeholder="Date of birth"
                                            id="dob"
                                            onChange={ handleChange }
                                            disabledDate={ disabledDate }
                                        />
                                    </Space> */}
                                <select
                                    className="form-control dob-field-controls month-field rounded-0"
                                    name="monthName"
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Month
                                    </option>
                                    {monthList}
                                </select>
                                <select
                                    className="form-control dob-field-controls rounded-0"
                                    name="dateName"
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Date
                                    </option>
                                    {dateList}
                                </select>
                                <select
                                    className="form-control dob-field-controls rounded-right"
                                    name="yearName"
                                    onChange={handleChange}
                                    style={{ borderRadius: '0px' }}
                                >
                                    <option value="" disabled selected>
                                        Year
                                    </option>
                                    {yearList}
                                </select>
                                <label
                                    className="input-group-text account_info_label rounded-left"
                                    htmlFor="dob"
                                >
                                    Date of birth
                                </label>
                            </fieldset>
                        </div>
                        <small className="text-danger">
                            {errors.date_of_birth}
                        </small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <input
                                    type="text"
                                    maxLength="14"
                                    name="phone_number"
                                    id="phone_number"
                                    value={input.phone_number || ''}
                                    onChange={handleChange}
                                    className="form-control account_info_field"
                                    placeholder="Enter mobile number"
                                    autoComplete="off"
                                />
                                <label
                                    className="input-group-text account_info_label"
                                    htmlFor="phone_number"
                                >
                                    Mobile number
                                </label>
                            </div>
                        </div>
                        <small className="text-danger">
                            {errors.phone_number}
                        </small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <input
                                    type="email"
                                    name="email_id"
                                    autoComplete="off"
                                    id="email_id"
                                    value={input.email_id || ''}
                                    onChange={handleChange}
                                    className="form-control account_info_field"
                                    placeholder="Enter email"
                                />
                                <label
                                    className="input-group-text account_info_label"
                                    htmlFor="email_id"
                                >
                                    Email
                                </label>
                            </div>
                        </div>
                        <small className="text-danger">{errors.email_id}</small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <input
                                    type="number"
                                    maxLength="5"
                                    onInput={maxLengthCheck}
                                    name="zipcode"
                                    autoComplete="off"
                                    placeholder="Enter zipcode"
                                    id="zipcode"
                                    value={input.zipcode || ''}
                                    onChange={handleChange}
                                    className="form-control account_info_field"
                                />
                                <label
                                    className="input-group-text account_info_label"
                                    htmlFor="zipcode"
                                >
                                    Zipcode
                                </label>
                            </div>
                        </div>
                        <small className="text-danger">{errors.zipcode}</small>
                    </div>
                    <div className="col-sm-12 my-2">
                        <div className="input-group">
                            <fieldset className="input-group-prepend">
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    className="form-control account_info_field"
                                />
                                <div className="input-group-text account_info_label">
                                    Title
                                </div>
                            </fieldset>
                        </div>
                        <small className="text-danger">
                            {errors.speciality}
                        </small>
                    </div>
                </div>
                <button
                    type="submit"
                    className="btn save_team_member_btn mt-4 d-block mr-auto ml-auto"
                    onClick={submitTeamData}
                    disabled={!enabled}
                >
                    Save
                </button>
            </form>
        </div>
    )
}

AddTeamModal.propTypes = {
    submitTeamData: PropTypes.func,
    handleChange: PropTypes.func,
    disabledDate: PropTypes.func,
    onSuggestionsFetchRequested: PropTypes.func,
    onSuggestionsClearRequested: PropTypes.func,
    getSuggestionValue: PropTypes.func,
    renderSuggestion: PropTypes.func,
    yearList: PropTypes.array,
    monthList: PropTypes.array,
    dateList: PropTypes.array,
    suggestions: PropTypes.array,
    input: PropTypes.object,
    inputProps: PropTypes.object,
    errors: PropTypes.object,
    enabled: PropTypes.bool,
}

export default AddTeamModal
