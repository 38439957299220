import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import validator from 'validator'
import {
    getOfficeInfo,
    addOfficeDetails,
    removeOfficeAddress,
    getOfficeAddressDetails,
    updateOfficeInfoDetails,
} from 'actions/accountActions'
import { MdKeyboardArrowRight } from 'react-icons/md'
import Loading from 'shared/Loader'
import AddOfficeInfoModal from '../office/AddOfficeInfoModal'
// import { maxLengthCheck } from 'utils/helper';
import { CgChevronLeft } from 'react-icons/cg'
import { EditOfficeMobile } from './EditOfficeMobile'
export class OfficeMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDetails: false,
            addOffice: false,
            input: {},
            errors: {},
        }
    }

    componentDidMount() {
        this.props.getOfficeInfo()
    }

    handleOpenOfficeDetails = (data) => {
        this.setState({
            openDetails: !this.state.openDetails,
            officeId: data && data.id,
        })
    }
    handleAddOffice = () => {
        this.setState({
            addOffice: !this.state.addOffice,
            openDetails: !this.state.openDetails,
        })
    }

    validate = () => {
        const input = this.state.input
        const errors = {}
        let isValid = true
        if (!input['speciality']) {
            isValid = false
            errors['speciality'] = 'Please enter speciality'
        }

        if (!input['address1']) {
            isValid = false
            errors['address1'] = 'Please enter address'
        }

        if (!input['city']) {
            isValid = false
            errors['city'] = 'Please enter city'
        }

        // if (!input[ 'fee' ]) {
        //     isValid = false;
        //     errors[ 'fee' ] = 'Please enter fee';
        // }

        if (!input['state']) {
            isValid = false
            errors['state'] = 'Please enter state'
        }

        if (!input['zipcode']) {
            isValid = false
            errors['zipcode'] = 'Please enter zipcode'
        }
        if (input['zipcode'] && input['zipcode'].length !== 5) {
            isValid = false
            errors['zipcode'] = 'Zipcode should be of 5 digit.'
        }

        if (!input['phone_number']) {
            isValid = false
            errors['phone_number'] = 'Please enter phone_number'
        }
        if (input['phone_number'] && input['phone_number'].length !== 14) {
            isValid = false
            errors['phone_number'] = 'Mobile number should be of 10 digit.'
        }

        if (!input['office_hours_start']) {
            isValid = false
            errors['office_hours_start'] = 'Please enter office hours'
        }
        if (!input['office_hours_end']) {
            isValid = false
            errors['office_hours_end'] = 'Please enter office hours'
        }
        if (input['website'] && !validator.isURL(input['website'])) {
            isValid = false
            errors['website'] = 'Please enter valid url'
        }
        if (!input['office_days']) {
            isValid = false
            errors['office_days'] = 'Please enter office days'
        }
        if (typeof input['office_days'] !== 'undefined') {
            const pattern = new RegExp(/^[A-Za-z -]+$/)
            if (!pattern.test(input['office_days'])) {
                isValid = false

                errors['office_days'] = 'Please enter in monday-friday format.'
            }
        }

        this.setState({
            errors: errors,
        })

        return isValid
    }
    // Add office section
    handleAddSubmit = (event) => {
        event.preventDefault()
        if (this.validate()) {
            const input = {}
            this.setState({ input: input })
            this.props.addOfficeDetails(this.state.input).then((res) => {
                if (res) {
                    this.setState({
                        addOffice: !this.state.addOffice,
                        openDetails: !this.state.openDetails,
                    })
                }
            })
        }
    }

    handleAddChange = (event) => {
        const input = this.state.input
        input[event.target.name] = event.target.value
        if (event.target.id === 'office_hours') {
            let obj = event.target.value
            var numbersTime = obj.replace(/\D/g, ''),
                charTime = { 2: ':' }
            obj = ''
            for (var j = 0; j < numbersTime.length; j++) {
                obj += (charTime[j] || '') + numbersTime[j]
                input[event.target.name] = obj
            }
        } else {
            if (event.target.name === 'phone_number') {
                let obj = event.target.value
                var numbers = obj.replace(/\D/g, ''),
                    char = { 0: '(', 3: ') ', 6: '-' }
                obj = ''
                for (var i = 0; i < numbers.length; i++) {
                    obj += (char[i] || '') + numbers[i]
                    input[event.target.name] = obj
                }
            }
        }
        this.setState({
            input,
            errors: {},
        })
    }
    closeAddOffice = () => {
        this.setState({
            addOffice: false,
            openDetails: false,
        })
    }

    closeEditOfficeMobile = () => {
        this.setState({
            openDetails: !this.state.openDetails,
        })
    }

    render() {
        const { OfficeInfoData, isLoading, accountDetails, isTeam } = this.props
        const { openDetails, input, errors, addOffice, officeId } = this.state
        const enabled = Object.keys(this.state.input).length > 8
        return (
            <>
                {!openDetails && !addOffice && (
                    <div className="mobile_account_info" id="content">
                        <nav className="navbar navbar-expand-lg navbar-light navbar-border custom_navbar d-flex justify-content-between align-items-center">
                            <CgChevronLeft
                                className="back_icon"
                                onClick={() =>
                                    this.props.history.push(
                                        '/account-homepage-mobile'
                                    )
                                }
                            />
                            <label className="nav-heading">
                                Office Information
                            </label>
                            <span></span>
                        </nav>
                        <div>
                            <div
                                className="tab-pane fade show active officeInfo"
                                id="nav-officeInfo"
                                role="tabpanel"
                                aria-labelledby="nav-officeInfo-tab"
                            >
                                {isLoading && (
                                    <div className="p-3">
                                        <Loading />
                                    </div>
                                )}
                                {!isLoading && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <table className="table table-borderless office_info mb-0">
                                                {OfficeInfoData &&
                                                    OfficeInfoData.physician_office &&
                                                    OfficeInfoData.physician_office.map(
                                                        (data, id) => {
                                                            return (
                                                                <tbody
                                                                    key={id}
                                                                    className="border-0"
                                                                >
                                                                    <tr
                                                                        className="tabel-row-border"
                                                                        onClick={() =>
                                                                            this.handleOpenOfficeDetails(
                                                                                data
                                                                            )
                                                                        }
                                                                    >
                                                                        <td className="border-0">
                                                                            <div className="office_name open-off-page">
                                                                                {
                                                                                    data.address1
                                                                                }
                                                                            </div>
                                                                            <div className="office_address">
                                                                                {
                                                                                    data.city
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <MdKeyboardArrowRight
                                                                                className="float-right font24 mt-2 green"
                                                                                onClick={() =>
                                                                                    this.handleOpenOfficeDetails(
                                                                                        data
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        }
                                                    )}
                                            </table>
                                            <button
                                                className="btn btn-link add-new-link add_element_link"
                                                onClick={this.handleAddOffice}
                                            >
                                                Add office location…
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {openDetails && !addOffice && (
                    <EditOfficeMobile
                        openOfficeDetails={this.handleOpenOfficeDetails}
                        accountDetails={accountDetails}
                        officeId={officeId}
                        isTeam={isTeam}
                        OfficeInfoData={OfficeInfoData}
                        isLoading={isLoading}
                        closeEditOfficeMobile={this.closeEditOfficeMobile}
                        removeOfficeAddress={this.props.removeOfficeAddress}
                        getOfficeAddressDetails={
                            this.props.getOfficeAddressDetails
                        }
                        updateOfficeInfoDetails={
                            this.props.updateOfficeInfoDetails
                        }
                    />
                )}
                {addOffice && (
                    <AddOfficeInfoModal
                        handleChange={this.handleAddChange}
                        handleSubmit={this.handleAddSubmit}
                        closeAddOffice={this.closeAddOffice}
                        input={input}
                        errors={errors}
                        enabled={enabled}
                    />
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.applicationIsLoading,
        OfficeInfoData: state.OfficeInfo,
        accountDetails: state.accountDetails,
        isTeam: state.teamMember,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOfficeInfo: () => dispatch(getOfficeInfo()),
        addOfficeDetails: (data) => dispatch(addOfficeDetails(data)),
        removeOfficeAddress: (id) => dispatch(removeOfficeAddress(id)),
        getOfficeAddressDetails: (id) => dispatch(getOfficeAddressDetails(id)),
        updateOfficeInfoDetails: (id, data) =>
            dispatch(updateOfficeInfoDetails(id, data)),
    }
}

OfficeMobile.propTypes = {
    history: PropTypes.object,
    details: PropTypes.object,
    getOfficeInfo: PropTypes.func,
    addOfficeDetails: PropTypes.func,
    removeOfficeAddress: PropTypes.func,
    getOfficeAddressDetails: PropTypes.func,
    updateOfficeInfoDetails: PropTypes.func,
    OfficeInfoData: PropTypes.object,
    isLoading: PropTypes.bool,
    isTeam: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    accountDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeMobile)
