import 'antd/dist/antd.css'
import PropTypes from 'prop-types'
import BottomBarNavigation from 'components/account/mobile/BottomBarNavigation'
import ComingSoonIllustration from 'static/images/coming-soon.jpeg'

const ComingSoonLayout = ({ content }) => {
    return (
        <div className="coming-soon-layout">
            <div className="coming-soon-content">
                <h4>This page is coming soon!</h4>
                <img
                    className="illustration"
                    src={ComingSoonIllustration}
                ></img>
                <h5>{content}</h5>
                <BottomBarNavigation />
            </div>
        </div>
    )
}

ComingSoonLayout.propTypes = {
    content: PropTypes.string,
}

export default ComingSoonLayout
